import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import TotalsTable from './TotalsTable';
import Datepicker from '../../Datepicker/Datepicker';
import Loader from '../../../common/Loader';

import { DATE_PICKER } from '../../../constants/common';
import { TOTALS_TABLE_HEADERS } from 'constants/totals';
import { getTotals } from 'store/bdm/bdm.thunk';
import { AppState } from '../../../store/configureStore';

import styles from '../../tableStyles.module.scss';

const Totals: React.FC = () => {
  const dispatch = useDispatch();

  const { totals, loading } = useSelector((state: AppState) => state.bdmReducer);

  const [date, setDate] = useState<Date>(new Date());

  const getTotalsList = useCallback(() => {
    dispatch(getTotals({ date }));
  }, [dispatch, date]);

  useEffect(() => {
    getTotalsList();
  }, [getTotalsList, dispatch]);

  return (
    <div className={cx(styles.wrapper, 'container-fluid')}>
      <div className={styles.container}>
        <div className="d-flex align-items-center justify-content-space-between">
          <div className="filters-block">
            <div className={styles.inputWrap}>
              <div>Date:</div>
              <div className="dropdown-filter mb-0">
                <Datepicker
                  selected={date}
                  dateFormat={DATE_PICKER.dateFormatMonth}
                  showMonthYearPicker
                  onChange={(date: Date) => {
                    setDate(date);
                    sessionStorage.TotalsDate = date;
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mb-3">{loading && <Loader />}</div>
        </div>

        <div>
          {!!totals?.length ? (
            <TotalsTable data={totals} headers={TOTALS_TABLE_HEADERS} />
          ) : (
            <div>Totals are empty</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Totals;
