import React, { useMemo } from 'react';
import cx from 'classnames';

import { Table } from 'reactstrap';
import RequestStatus from 'components/RequestStatus/RequestStatus';

import { formatDate, secondsToHours } from '../../utils/dates';
import { PropsType, TableItemsRR } from 'models/interfaces/resourceRequest.interface';
import { BILLING_SOURCE } from 'models/enums/billing-source.enum';

import styles from '../tableStyles.module.scss';

const ResourceRequestTable = ({ data, currentPage, perPage }: PropsType) => {
  const coefForNumberFirstColumn = useMemo(
    () => (currentPage && perPage ? perPage * currentPage - perPage + 1 : 1),
    [currentPage, perPage]
  );

  return (
    <Table
      responsive
      hover
      className={cx(styles.tableContainer, styles.minWidth1300, 'align-middle mb-0 custom-table text-left')}
    >
      <thead>
        <tr>
          <th className={styles.firstCellWidth}>#</th>
          <th>Ticket key</th>
          <th>Project</th>
          <th>Status</th>
          <th>Start date</th>
          <th>End date</th>
          <th>Service type</th>
          <th>Grade</th>
          <th>Workforce</th>
          <th>HpD</th>
          <th>Plan</th>
          <th>Fact</th>
          <th>Billing Source</th>
          <th>Created At</th>
        </tr>
      </thead>

      <tbody>
        {data?.map((item: TableItemsRR, index: number) => {
          return (
            <tr key={index}>
              <td>{index + coefForNumberFirstColumn}</td>
              <td>
                <a href={item.issueLink} target="_blank" rel="noopener noreferrer">
                  {item.issueKey}
                </a>
              </td>
              <td>
                <a href={item.linkedProjectLink} target="_blank" rel="noopener noreferrer">
                  {item.linkedProjectKey}
                </a>
              </td>
              <td>
                <RequestStatus status={item.status} />
              </td>
              <td>{item.periodStartDate}</td>
              <td>{item.periodEndDate}</td>
              <td>{item.serviceType}</td>
              <td>{item.grade}</td>
              <td>
                {item.workforce?.firstName} {item.workforce?.lastName}
              </td>
              <td>{secondsToHours(item.timePerDay)}</td>
              <td>{!!item?.estimatedBillableTime ? secondsToHours(item?.estimatedBillableTime) : '-'}</td>
              <td>{!!item?.factualBillableTime ? secondsToHours(item?.factualBillableTime) : '-'}</td>
              <td
                className={
                  item.billingSource?.toLocaleLowerCase() === BILLING_SOURCE.external
                    ? styles.greenRow
                    : item.billingSource
                    ? styles.redRow
                    : ''
                }
              >
                {item.billingSource}
              </td>
              <td>{formatDate(item.createdAt)}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ResourceRequestTable;
