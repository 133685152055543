import { createAsyncThunk } from '@reduxjs/toolkit';
import projectsService from 'services/projectsService';
import { RequestParams } from 'utils/mapParams';

export const getProjectsList = createAsyncThunk(
  'projects/getProjectsList',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const res = await projectsService.getProjectsList(params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
