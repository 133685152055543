import httpService, { HttpService } from './httpService';
import { RequestParams } from '../utils/mapParams';
import HolidaysAdapter from '../models/adapters/holidays.adapter';
import { BodyHolidayRequestDto } from '../models/dto/holidays.dto';

export class HolidaysService {
  constructor(private _httpService: HttpService) {}

  async getHolidaysList(params: Partial<RequestParams>) {
    const res = await this._httpService.get('/holidays', {
      queryStringParameters: params,
    });
    return res?.items.map((holiday: { date: string }) => holiday?.date);
  }

  async getHolidays(params: Partial<RequestParams>) {
    const { items } = await this._httpService.get('/holidays', {
      queryStringParameters: params,
    });

    return HolidaysAdapter.mapHolidaysDtoToHolidaysModel(items);
  }

  async getHolidayById(id: number) {
    const res = await this._httpService.get(`/holidays/${id}`);

    return HolidaysAdapter.mapHolidayDtoToHolidayModel(res);
  }

  async createHoliday(values: BodyHolidayRequestDto) {
    const res = await this._httpService.post('/holidays', {
      body: values,
    });

    return HolidaysAdapter.mapHolidayDtoToHolidayModel(res);
  }

  async editHoliday(data: { id: number; values: BodyHolidayRequestDto }) {
    const res = await this._httpService.put(`/holidays/${data.id}`, {
      body: data.values,
    });

    return HolidaysAdapter.mapHolidayDtoToHolidayModel(res);
  }

  async deleteHoliday(id: number) {
    const res = await this._httpService.delete(`/holidays/${id}`);

    return res;
  }
}

const holidaysService = new HolidaysService(httpService);

export default holidaysService;
