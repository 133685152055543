import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, startOfMonth, endOfMonth } from 'date-fns';

import { Card, CardBody } from 'reactstrap';

import WorkforseHoursTable from './WorkforseHoursTable';
import WorkforseHoursFooter from './WorkforseHoursFooter';
import Loader from '../../../common/Loader';
import Datepicker from '../../../components/Datepicker/Datepicker';
import TeamFilter from 'common/TeamFilter/TeamFilter';

import { DATE_PICKER } from '../../../constants/common';
import { useQuery } from 'hooks/queryHook';

import { AppState } from '../../../store/configureStore';
import { getAvailableHoursList } from '../../../store/bdm/bdm.thunk';
import { CommonRequestParams } from 'models/dto/params.dto';

const WorkforseHours: React.FC = () => {
  const dispatch = useDispatch();

  const { availableHours, loading } = useSelector((state: AppState) => state.bdmReducer);

  const { fromDate, toDate, team, setFromToDate, setTeam } = useQuery();

  const getData = useCallback(() => {
    if (!fromDate || !toDate) {
      setFromToDate(
        format(startOfMonth(new Date()), DATE_PICKER.dateFormatToPayload),
        format(endOfMonth(new Date()), DATE_PICKER.dateFormatToPayload)
      );
    } else {
      const params: CommonRequestParams = {
        fromDate: fromDate,
        toDate: toDate,
      };

      if (team) {
        params.team = +team;
      }

      dispatch(getAvailableHoursList(params));
    }
  }, [dispatch, fromDate, toDate, team]);

  useEffect(() => {
    getData();
  }, [dispatch, getData]);

  const onDateChange = (date: Date) => {
    setFromToDate(
      format(startOfMonth(date), DATE_PICKER.dateFormatToPayload),
      format(endOfMonth(date), DATE_PICKER.dateFormatToPayload)
    );
  };

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className="bp-header">
          <div className="filters-block">
            <div className="dropdown-filter">
              <p className="label-wrapper">Date</p>
              <Datepicker
                selected={fromDate ? new Date(fromDate) : startOfMonth(new Date())}
                dateFormat={DATE_PICKER.dateFormatMonth}
                showMonthYearPicker
                onChange={(date: Date) => onDateChange(date)}
              />
            </div>

            <TeamFilter team={team} setTeam={setTeam} planning={true} />
          </div>
        </div>

        {!!availableHours?.items.length && !loading ? (
          <>
            <WorkforseHoursTable data={availableHours.items} />
            <WorkforseHoursFooter data={availableHours.totals} />
          </>
        ) : loading ? (
          <Loader />
        ) : (
          <div>Available hours are empty</div>
        )}
      </CardBody>
    </Card>
  );
};
export default WorkforseHours;
