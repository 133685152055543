import cx from 'classnames';
import { createPortal } from 'react-dom';

import { DeleteModalProps } from 'models/interfaces/commonComponents.interface';

import styles from '../../components/modal.module.scss';

const DeleteModal = ({ title, itemToDelete, onClose, onDelete }: DeleteModalProps) => {
  return createPortal(
    <div className={styles.modal}>
      <div className={styles.backDrop} onClick={onClose}></div>
      <div className={cx(styles.block, 'text-left')}>
        <button type="button" className={styles.close} onClick={onClose} />
        <div className={styles.header}>Delete {title}</div>

        <div className={styles.title}>Are you sure you want to delete {itemToDelete}?</div>

        <div className={styles.buttonWrapper}>
          <button type="button" onClick={onClose} className={styles.redLineButton}>
            Cancel
          </button>
          <button type="button" className={styles.redButton} onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default DeleteModal;
