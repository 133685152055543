import { ResourceRequestsIssuesDto } from './dto/resourceRequests.dto';

class ResourceRequestDealDetailsModel {
  issueKey: string;
  issueLink: string;
  linkedProjectKey: string;
  linkedProjectLink: string;
  status: string;
  periodStartDate: string;
  periodEndDate: string;
  workforce: { firstName: string };
  timePerDay: number;
  estimatedBillableTime: number;
  factualBillableTime: number;
  paymentType: string;
  createdAt: string;

  constructor(data: ResourceRequestsIssuesDto) {
    this.issueKey = data.key;
    this.issueLink = data.link;
    this.linkedProjectKey = data.linkedProject;
    this.linkedProjectLink = data.linkedProjectLink;
    this.status = data.status;
    this.periodStartDate = data.startDate;
    this.periodEndDate = data.endDate;
    this.workforce = { firstName: data.resourceName };
    this.timePerDay = data.timePerDay;
    this.estimatedBillableTime = data.estimatedBillableHours;
    this.factualBillableTime = data.factualBillableHours;
    this.paymentType = data.paymentType;
    this.createdAt = data.createdAt;
  }
}

export default ResourceRequestDealDetailsModel;
