import AppHeader from '../../Layout/AppHeader';
import React, { FC, Suspense, PropsWithChildren } from 'react';
import AppSidebar from '../../Layout/AppSidebar';
import Loader from '../../common/Loader';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import ResizeDetector from 'react-resize-detector';

import { AppState } from '../../store/configureStore';

const Dashboard: FC<PropsWithChildren<any>> = ({ children }) => {
  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = useSelector((state: AppState) => state.themeOptions);

  return (
    <ResizeDetector
      handleWidth
      // @ts-ignore
      render={({ width }: { width: number }) => (
        <div
          className={cx(
            'app-container app-theme-' + colorScheme,
            { 'fixed-header': enableFixedHeader },
            { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
            { 'fixed-footer': enableFixedFooter },
            { 'closed-sidebar': enableClosedSidebar || width < 1250 },
            { 'sidebar-mobile-open': enableMobileMenu },
            { 'body-tabs-shadow-btn': enablePageTabsAlt }
          )}
        >
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader />
                  </div>
                  <h6 className="mt-3">
                    Please wait while we load all the Dashboards examples
                    <small>
                      Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen
                      in a real live app!
                    </small>
                  </h6>
                </div>
              </div>
            }
          >
            <AppHeader />
            <div className="app-main">
              <AppSidebar />
              <div className="app-main__outer">
                <div className="app-main__inner">{children}</div>
              </div>
            </div>
          </Suspense>
        </div>
      )}
    />
  );
};

export default Dashboard;
