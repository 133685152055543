import React, { FC, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useHistory } from 'react-router-dom';
import get from 'lodash.get';
import cx from 'classnames';

import { Card, CardBody } from 'reactstrap';
import { TeamEditing } from './TeamEditing';
import TeamManager from '../TeamManager';
import Loader from 'common/Loader';

import { getFullName, getParentTeams } from 'helpers/companyStructureHeplers';

import TeamModel from 'models/team.model';
import { EmployeeDto } from 'models/dto/employee.dto';

import { getTeamInfo, editTeamProfile, deleteTeam } from 'store/companyStucture/companyStructure.thunk';
import { getTeamsListThunk } from 'store/teams/teams.thunk';
import { clearState } from 'store/companyStucture';
import { AppState } from 'store/configureStore';
import { edit, remove } from 'constants/icons';

import styles from './teamProfile.module.scss';
import tableStyles from '../../tableStyles.module.scss';
import DeleteModal from 'common/Modals/DeleteModal';

const TeamProfile: FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const { team, employees, loading } = useSelector((state: AppState) => state.companyStructureReducer);
  const { teams } = useSelector((state: AppState) => state.teamsReducer);
  const { role } = useSelector((state: AppState) => state.account.user);

  const canEdit = role === 'vp' || role === 'hr';

  const teamEmployees: EmployeeDto[] =
    employees &&
    employees
      .filter((employee: EmployeeDto) => get(employee, 'team.id') === +id)
      .sort((employeeA: EmployeeDto, employeeB: EmployeeDto) => (employeeA.firstName < employeeB.firstName ? -1 : 1));

  const getTeamData = () => {
    dispatch(getTeamInfo({ id, searchParams: { expand: 'type,parent,children' } }));
  };

  useEffect(() => {
    dispatch(getTeamsListThunk({ expand: 'type' }));

    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (id) {
      getTeamData();
    }
  }, [dispatch, id]);

  const closeEditing = () => setIsEditing(false);
  const openEditing = () => setIsEditing(true);

  const teamLead: EmployeeDto = useMemo(
    () => employees && team && employees.find((employee: EmployeeDto) => employee.id === team.teamleadId),
    [employees, team]
  );

  const techLeads: EmployeeDto[] = useMemo(
    () =>
      employees &&
      team &&
      employees.filter((employee: EmployeeDto) => employee.team?.id === team.id && employee.isTechlead),
    [employees, team]
  );

  const parentTeams: TeamModel[] = useMemo(() => {
    const parentTeamsIds: number[] = getParentTeams(team?.parent);

    if (teams) {
      return parentTeamsIds
        .map(id => {
          return teams.find((item: TeamModel) => item.id === id);
        })
        .reverse();
    }
    return [];
  }, [team, teams]);

  const onSubmit = (values: TeamModel) => {
    const {
      id,
      typeId,
      title,
      email,
      isAvailableForPlanning,
      openDate,
      closeDate,
      parentId,
      teamleadId,
      members,
      children,
      confluencePageId,
      confluenceTeamleadsGroup,
    } = values;
    const teamInfo = {
      title,
      typeId,
      email,
      isAvailableForPlanning,
      openDate,
      closeDate,
      parentId,
      teamleadId,
      confluencePageId,
      confluenceTeamleadsGroup,
    };
    if (!teamInfo.teamleadId) {
      delete teamInfo.teamleadId;
    }

    const teamMembers = members?.filter(member => (member.team && member.team.id !== id) || !member.team);
    const newMembers = teamMembers?.map(member => ({ id: member.id, employee: { teamId: id } }));
    const removedMembers = teamEmployees
      .filter(member => !members?.map(el => el.id).includes(member.id))
      .map(member => ({ id: member.id, employee: { teamId: null } }));

    const newChildTeams = children
      ?.filter(child => child.parentId !== id || !child.parentId)
      ?.map(child => ({ id: child.id, team: { parentId: id } }));
    const removedChildTeams = team.children
      ?.filter((child: TeamModel) => !children?.map(el => el.id).includes(child.id))
      .map((child: TeamModel) => ({ id: child.id, team: { parentId: null } }));
    let isMakeEdit = false;

    for (let key in teamInfo) {
      if (
        (teamInfo[key as keyof typeof teamInfo] !== team[key] || (!teamInfo.teamleadId && team.teamleadId)) &&
        teamInfo[key as keyof typeof teamInfo] !== undefined
      ) {
        isMakeEdit = true;
      }
    }

    dispatch(
      editTeamProfile({
        id: String(id),
        teamInfo,
        isTeamEdit: isMakeEdit,
        callback: () => {
          getTeamData();
          setIsEditing(false);
        },
        newMembers,
        removedMembers,
        newChildTeams,
        removedChildTeams,
      })
    );
  };

  const onTeamDelete = () => {
    dispatch(deleteTeam({ id: team.id, callback: () => history.replace('/dashboard/company_structure') }));
    setOpenDelete(false);
  };

  if (loading) return <Loader />;

  if (isEditing) {
    return (
      <TeamEditing
        team={team}
        teams={teams}
        employees={employees}
        teamEmployees={teamEmployees}
        onClose={closeEditing}
        buttonName="save"
        onSubmit={onSubmit}
        teamLead={teamLead}
      />
    );
  }
  return (
    <>
      {canEdit && (
        <div className={cx(tableStyles.buttonWrap, tableStyles.topButton)}>
          <button className={cx(tableStyles.button)} onClick={openEditing}>
            {edit} Edit team
          </button>

          <button
            className={cx(tableStyles.button, tableStyles.deleteBtn)}
            onClick={() => setOpenDelete(true)}
            disabled={!!teamEmployees?.length || !!team?.children?.length}
          >
            {remove} Delete team
          </button>
        </div>
      )}

      <Card className="main-card mb-3">
        <CardBody>
          <div className={styles.container}>
            {parentTeams?.length ? (
              <div className="d-flex align-items-center">
                {parentTeams.map(parentTeam => (
                  <div key={parentTeam.id} className="d-flex align-items-center">
                    <h3 className={styles.teamTitle}>
                      <Link to={`/dashboard/team_profile/${parentTeam.id}`}>{parentTeam.title}</Link>
                    </h3>
                    <span className={cx(styles.circle, 'mr-3 ml-3')} />
                  </div>
                ))}

                <h3 className={styles.teamTitle}>{get(team, 'title')}</h3>
              </div>
            ) : (
              <h3 className={styles.teamTitle}>{get(team, 'title')}</h3>
            )}
            {parentTeams?.length ? (
              <div className={styles.teams}>
                {parentTeams.map(parentTeam => (
                  <TeamManager key={parentTeam.id} team={parentTeam} employees={employees} />
                ))}
              </div>
            ) : null}

            <div className={cx(styles.generalInfo, styles.teamInfoWrapper)}>
              <div>
                <div className="mb-3">
                  <div className={styles.infoTitle}>{team?.type?.managerTitle}</div>
                  {teamLead ? <Link to={`/dashboard/profile/${teamLead.id}`}>{getFullName(teamLead)}</Link> : '-'}
                </div>
                <div>
                  <div className={styles.infoTitle}>Tech Lead</div>
                  <div className={styles.techLeadInfo}>
                    {techLeads?.length
                      ? techLeads.map(employee => (
                          <Link key={employee.id} to={`/dashboard/profile/${employee.id}`}>
                            {getFullName(employee)}
                          </Link>
                        ))
                      : '-'}
                  </div>
                </div>
              </div>

              <div>
                <div className={styles.infoTitle}>Confluence TL group</div>
                <div className={styles.infoData}>{get(team, 'confluenceTeamleadsGroup') || '-'}</div>
              </div>

              <div>
                <div className={styles.infoTitle}>Team Email</div>
                <div className={styles.infoData}>{get(team, 'email') || '-'}</div>
              </div>

              <div>
                <div className="mb-3">
                  <div className={styles.infoTitle}>Open Date</div>
                  <div className={styles.infoData}>{get(team, 'openDate') || '-'}</div>
                </div>
                <div>
                  <div className={styles.infoTitle}>Close Date</div>
                  <div className={styles.infoData}>{get(team, 'closeDate') || '-'}</div>
                </div>
              </div>

              <div>
                <div className={styles.infoTitle}>Confluence Page ID</div>
                <div className={styles.infoData}>
                  {team?.confluencePageLink ? (
                    <a href={team.confluencePageLink} target="_blank">
                      {get(team, 'confluencePageId')}
                    </a>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>

            {team?.children?.length ? (
              <div className={styles.teamInfoWrapper}>
                <div className={styles.infoTitle}>Child Teams</div>
                <div className={styles.teamMembers}>
                  {team.children.map((childTeam: TeamModel) => (
                    <Link key={childTeam.id} to={`/dashboard/team_profile/${childTeam.id}`}>
                      {childTeam.title}
                    </Link>
                  ))}
                </div>
              </div>
            ) : null}

            <div className={styles.teamInfoWrapper}>
              <div className={styles.infoTitle}>Team members</div>
              <div className={styles.teamMembers}>
                {teamEmployees &&
                  teamEmployees.map(employee => (
                    <Link key={employee.id} to={`/dashboard/profile/${employee.id}`}>
                      {getFullName(employee)}
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      {openDelete && (
        <DeleteModal
          title={team.title}
          itemToDelete="this Team"
          onClose={() => setOpenDelete(false)}
          onDelete={onTeamDelete}
        />
      )}
    </>
  );
};

export default TeamProfile;
