import React, { FC, useMemo } from 'react';

import { COLORS } from 'config/theme';

import styles from '../HR/PerformanceReview/performanceReview.module.scss';

interface StatusT {
  status: string;
}

const RequestStatus: FC<StatusT> = ({ status }) => {
  const colors: Record<string, string> = {
    Active: COLORS.green,
    Approved: COLORS.green,
    Confirmed: COLORS.green,
    Completed: COLORS.blue,
    Draft: COLORS.gray,
    'Fin Check': COLORS.darkGray,
    'In Review': COLORS.orange,
    'Invalid obligations': COLORS.red,
    'Needs approval': COLORS.red,
    Outdated: COLORS.red,
    Pending: COLORS.orange,
    'Non-approved': COLORS.gray,
    Purchased: COLORS.purple,
    Sent: COLORS.green,
    'Sent for approval': COLORS.teal,
    Rejected: COLORS.red,
    Requested: COLORS.blue,
    'Waiting for confirmation': COLORS.teal,
  };

  const statusColor: string = colors[status] || COLORS.blue;

  return (
    <div className={styles.wrapperStatus}>
      <div className={styles.statusMark} style={{ backgroundColor: statusColor }} />
      <div style={{ color: statusColor }}>{status}</div>
    </div>
  );
};

export default RequestStatus;
