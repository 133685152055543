import { createAsyncThunk } from '@reduxjs/toolkit';
import employeesService from 'services/employeesService';
import issueService from 'services/issueService';
import { RequestParams } from 'utils/mapParams';
import { EmployeeDto } from 'models/dto/employee.dto';
import { ISSUE_TYPES } from 'constants/issues.constants';

export const getOvertimeRequests = createAsyncThunk(
  'overtimeRequests/getOvertimeRequests',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const { employees } = await employeesService.getEmployeesFullData({
        date: params.fromDate,
        team: params?.team,
        department: params?.department,
      });

      if (employees?.length) {
        const usersWithAtlassianId: string[] = employees.reduce((acc: string[], employee: EmployeeDto) => {
          if (employee.atlassianId) {
            acc.push(employee.atlassianId);
          }
          return acc;
        }, []);

        const data = await issueService.getIssuesList(ISSUE_TYPES.ovr, usersWithAtlassianId, {
          fromDate: params.fromDate,
          toDate: params.toDate,
        });
        return data;
      }
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
