import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import { format } from 'date-fns';
import cx from 'classnames';

import Status from './Status';
import ActionButtons from './ActionButtons';
import SortButton from 'common/SortButton/SortButton';

import { useSort } from 'hooks/sortHook';
import { DATE_PICKER } from '../../../constants/common';
import PerformanceReviewModel from 'models/performanceReview.model';
import sortHeaderDto from 'models/dto/sort.dto';
import { AppState } from 'store/configureStore';

import styles from '../../tableStyles.module.scss';

type TProps = {
  data: Array<PerformanceReviewModel>;
  accessEdit: boolean;
  onOpenEdit: () => void;
  headers: sortHeaderDto[];
};

const PerformanceReviewTable: React.FC<TProps> = ({ data, accessEdit, onOpenEdit, headers }) => {
  const { sortedData, sortKey, sortOrder, onSortingChange } = useSort({ data: data });

  const { loadingModal } = useSelector((state: AppState) => state.performanceReviewReducer);

  return (
    <div className={cx(styles.tableWrapper, 'mb-3')}>
      <Table responsive hover className={cx(styles.tableContainer, 'align-middle mb-0 custom-table')}>
        <thead>
          <tr>
            {headers?.map(item => (
              <th key={item.value}>
                <SortButton
                  onClick={() => onSortingChange(item.value, item.sortType)}
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  sortField={item.value}
                  sortTitle={item.title}
                />
              </th>
            ))}
            {accessEdit && <th>Actions</th>}
          </tr>
        </thead>

        <tbody>
          {sortedData?.map((item: PerformanceReviewModel, index: number) => {
            return (
              <tr key={index}>
                <td>
                  <Link to={`/dashboard/performance_review_details/${item.id}?page=1&size=50`}>{item.title}</Link>
                </td>
                <td>{format(new Date(item.startDate), DATE_PICKER.dateFormatToPayload)}</td>
                <td>{!!item.endDate && format(new Date(item.endDate), DATE_PICKER.dateFormatToPayload)}</td>
                <td>
                  <Status status={item.status} />
                </td>
                {accessEdit && (
                  <td width={200} className={styles.lastCell}>
                    <ActionButtons item={item} onOpenEdit={onOpenEdit} loading={loadingModal} />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default PerformanceReviewTable;
