import { createPortal } from 'react-dom';
import { format } from 'date-fns';
import { currentDayOrangeMarker } from 'constants/icons';
import { DATE_PICKER } from 'constants/common';
import { TodayLineProps } from 'models/interfaces/projectCalendar.interface';
import styles from '../projectCalendarStyles.module.scss';

const TodayLine = ({ linePosition, mouseHoverPosition, setMouseHoverPosition }: TodayLineProps) => {
  const todayTooltipWidth = 80;

  return (
    <>
      <tr
        className={styles.verticalLine}
        style={{ left: `${linePosition}px` }}
        id="today"
        onMouseEnter={event => {
          setMouseHoverPosition({
            x:
              window.innerWidth - event.pageX - 6 > todayTooltipWidth
                ? event.pageX
                : event.pageX - (window.innerWidth - event.pageX),
            y: event.pageY,
          });
        }}
        onMouseLeave={() =>
          setMouseHoverPosition({
            x: 0,
            y: 0,
          })
        }
      >
        <th>{currentDayOrangeMarker}</th>
      </tr>

      {mouseHoverPosition.x && mouseHoverPosition.y
        ? createPortal(
            <div
              className={styles.tooltipWrap}
              style={{ left: mouseHoverPosition.x + 6, top: mouseHoverPosition.y + 8 }}
            >
              {format(new Date(), DATE_PICKER.dateFormatToPayload)}
            </div>,
            document.body
          )
        : null}
    </>
  );
};

export default TodayLine;
