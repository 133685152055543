import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationType } from 'services/notificationService';
import resourceRequestsBulkService from 'services/resourceRequestServiceBulkService';
import { enqueueSnackbar } from 'store/notifications';
import { UpdateRRParams } from 'models/interfaces/updateRR.interface';

export const updateResourceRequestsBulk = createAsyncThunk(
  'rrsBulk/updateResourceRequestsBulk',
  async (params: UpdateRRParams, thunkApi) => {
    try {
      await resourceRequestsBulkService.updateResourceRequestsBulk(params.requestBody);

      if (params.callback && params.id) {
        thunkApi.dispatch(params.callback({ id: params.id, requestBody: params.requestBody }));
      }
      thunkApi.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Resource Requests updated successfully!',
            title: 'Resource Requests',
          },
        })
      );
      return params;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      thunkApi.rejectWithValue(err.message.original);
    }
  }
);
