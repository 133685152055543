import { PayloadAction, ThunkDispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '../configureStore';

import { getEmployeeByIdThunk } from '../employee';
import { getProfileVsdIssues } from '../issues';
import employeeService from '../../services/employeeService';

export const getEmployeeByIdWithVsd =
  (id: string, expand: string, fromDate: string, toDate: string) =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>, getState: any) => {
    await dispatch(getEmployeeByIdThunk(id, expand));
    const { employee } = getState()?.employeeReducer;

    if (!employee.atlassianId) return;

    const userWithAtlassianId = [employee?.atlassianId];

    await dispatch(
      getProfileVsdIssues({
        users: userWithAtlassianId,
        fromDate,
        toDate,
      })
    );
  };

export const getSalesGradesThunk = createAsyncThunk('profile/getSalesGrades', async (_, thunkAPI) => {
  try {
    const res = await employeeService.getSalesGrades();
    return res;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});
