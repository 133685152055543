import React, { FC, useState } from 'react';
import cx from 'classnames';

import EmployeeInfoRow from './EmployeeInfoRow';

import { arrowRight } from 'constants/icons';
import { TeamTableProps, TransformedWorkloadItem } from 'models/interfaces/workloadCalendar.interface';
import { CalendarDayInterface } from 'models/interfaces/calendar-day.interface';

import styles from '../workloadStyle.module.scss';

const TeamTableRow: FC<TeamTableProps> = ({ tableData, dateRange, expandTable, hoveredColumn, setHoveredColumn }) => {
  const [selected, setSelected] = useState(true);

  return (
    <>
      <tr
        onClick={() => setSelected(!selected)}
        className={cx(styles.teamWrap, expandTable ? styles.tableCellsFixed : styles.firstCellFixed)}
      >
        <td className={styles.team}>
          <div className={styles.flex}>
            <span className={selected ? cx(styles.teamArrow, styles.arrowOpen) : styles.teamArrow}>{arrowRight}</span>
            {tableData.title}
          </div>
        </td>

        {expandTable && (
          <>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </>
        )}

        {dateRange.map((item: CalendarDayInterface, index: number) => (
          <td
            key={index}
            onMouseEnter={() => setHoveredColumn(index)}
            onMouseLeave={() => setHoveredColumn(null)}
            style={{
              background: index === hoveredColumn ? '#DCE3FF' : item.isHoliday ? '#E6E7ED' : '',
              borderLeft: item.isCurrentDay ? '1px solid #4F75FF' : '1px solid #E6E7ED',
              borderRight: item.isCurrentDay ? '1px solid #4F75FF' : '1px solid #E6E7ED',
            }}
          />
        ))}
      </tr>

      {selected &&
        tableData.employees.map((employee: TransformedWorkloadItem) => (
          <React.Fragment key={employee.id}>
            <EmployeeInfoRow
              employee={employee}
              expandTable={expandTable}
              hoveredColumn={hoveredColumn}
              setHoveredColumn={setHoveredColumn}
              dateRange={dateRange}
            />
          </React.Fragment>
        ))}
    </>
  );
};

export default TeamTableRow;
