import { useSelector } from 'react-redux';

import PageTitle from '../../Layout/AppMain/PageTitle';
import DealDetails from '../../components/BDM/DealDetails/DealDetails';
import { dealsIcon } from 'constants/icons';

import { AppState } from 'store/configureStore';

const DealsPage = () => {
  const { deal } = useSelector((state: AppState) => state.bdmReducer);

  return (
    <>
      <PageTitle heading={deal?.name ? deal.name : 'Deal Details'} icon={dealsIcon} />
      <DealDetails />
    </>
  );
};

export default DealsPage;
