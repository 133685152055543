import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAvailableHoursList,
  getDealGroupsList,
  getDealsList,
  getDealById,
  getFullAvailabilityData,
  getDealPipelines,
  getDealStages,
  getRatesCards,
  getTotals,
  getSalesGradesList,
} from './bdm.thunk';
import { formatSalesGrades } from 'utils/formatData';
import AvailableHoursModel from '../../models/availableHours.model';
import DealGroupsModel from '../../models/dealGroups.model';
import AvailabilityModel from '../../models/availability.model';
import { FilterT, ItemDealsOutInterface } from '../../models/dto/bdm.dto';
import TotalModel from 'models/totals.model';

interface BdmStateInterface {
  availableHours: AvailableHoursModel | null;
  dealGroups: DealGroupsModel[];
  availabilityData: AvailabilityModel[];
  deals: ItemDealsOutInterface[];
  deal: ItemDealsOutInterface | null;
  tempPipelines: string[];
  tempStages: string[];
  ratesCards: any[];
  totals: TotalModel[];
  salesGrades: FilterT[];
  loading: boolean;
  errors: string | null;
  pages: number;
  total: number;
}

const initialState: BdmStateInterface = {
  availableHours: null,
  dealGroups: [],
  availabilityData: [],
  deals: [],
  deal: null,
  tempPipelines: [],
  tempStages: [],
  ratesCards: [],
  totals: [],
  salesGrades: [],
  loading: false,
  errors: null,
  pages: 0,
  total: 0,
};

const bdmSlice = createSlice({
  name: 'bdm',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setAvailableHours: (state, action: PayloadAction<AvailableHoursModel>) => {
      return {
        ...state,
        availableHours: action.payload,
      };
    },
    clearState: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    [getAvailableHoursList.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [getAvailableHoursList.fulfilled.toString()]: (state, { payload }) => {
      state.availableHours = payload;
      state.loading = false;
    },
    [getAvailableHoursList.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    [getDealGroupsList.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [getDealGroupsList.fulfilled.toString()]: (state, { payload }) => {
      state.dealGroups = payload;
      state.loading = false;
    },
    [getDealGroupsList.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    [getFullAvailabilityData.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [getFullAvailabilityData.fulfilled.toString()]: (state, { payload }) => {
      state.availabilityData = payload.items;
      state.pages = payload.pages;
      state.total = payload.total;
      state.loading = false;
    },
    [getFullAvailabilityData.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    [getDealsList.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [getDealsList.fulfilled.toString()]: (state, { payload }) => {
      state.deals = payload.items;
      state.pages = payload.pages;
      state.total = payload.total;
      state.loading = false;
    },
    [getDealsList.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    [getDealById.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [getDealById.fulfilled.toString()]: (state, { payload }) => {
      state.deal = payload;
      state.loading = false;
    },
    [getDealById.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    [getDealPipelines.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [getDealPipelines.fulfilled.toString()]: (state, { payload }) => {
      state.tempPipelines = payload;
      state.loading = false;
    },
    [getDealPipelines.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    [getDealStages.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [getDealStages.fulfilled.toString()]: (state, { payload }) => {
      state.tempStages = payload;
      state.loading = false;
    },
    [getDealStages.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    [getRatesCards.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [getRatesCards.fulfilled.toString()]: (state, { payload }) => {
      state.ratesCards = payload;
      state.loading = false;
    },
    [getRatesCards.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    [getTotals.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [getTotals.fulfilled.toString()]: (state, { payload }) => {
      state.totals = payload;
      state.loading = false;
    },
    [getTotals.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
    [getSalesGradesList.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [getSalesGradesList.fulfilled.toString()]: (state, { payload }) => {
      (state.salesGrades = formatSalesGrades(payload)), (state.loading = false);
    },
    [getSalesGradesList.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
  },
});

export const { setAvailableHours, clearState, setLoading } = bdmSlice.actions;

export default bdmSlice.reducer;
