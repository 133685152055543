import { TeamT } from 'models/dto/team.dto';

const TeamsWithChildren = ({ item }: { item: TeamT }) => {
  return (
    <div
      style={{
        fontWeight: item.hasChildren ? (item.level < 1 ? '700' : '500') : '400',
      }}
      className="d-flex align-items-center"
    >
      {item.level > 0 && <span className={`mr-1 ${item.level > 1 && 'ml-2'}`}>&#8226;</span>} {item.title}
    </div>
  );
};

export default TeamsWithChildren;
