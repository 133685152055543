import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISSUE_TYPES } from 'constants/issues.constants';
import ResourceRequestAdapter from 'models/adapters/resourceRequest.adapter';
import issueService from 'services/issueService';
import resourceRequestsService from 'services/resourceRequestsService';
import { RequestParams } from 'utils/mapParams';

export const getResourceRequests = createAsyncThunk(
  'resourceRequests/getResourceRequests',
  async (data: { params: Partial<RequestParams>; id?: number }, thunkApi) => {
    try {
      const res = await resourceRequestsService.getResourceRequests(data.params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkApi.rejectWithValue(err.message.original);
    }
  }
);

export const getResourceRequestsIssues = createAsyncThunk(
  'resourceRequests/getResourceRequestsIssues',
  async (data: { usersId: string[]; params: Partial<RequestParams> }, thunkApi) => {
    try {
      const res = await issueService.getIssuesList(ISSUE_TYPES.rr, data.usersId, data.params);

      return ResourceRequestAdapter.mapResourceRequestDtoToDealDetails(res);
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkApi.rejectWithValue(err.message.original);
    }
  }
);
