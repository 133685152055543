import React, { Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Slider } from 'react-burgers';

import cx from 'classnames';

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'reactstrap';

import { setEnableMobileMenu, setEnableMobileMenuSmall } from '../../store/themeOptions';

const AppMobileMenu = () => {
  const dispatch = useDispatch();
  const { enableMobileMenu, enableMobileMenuSmall } = useSelector(state => state.themeOptions);
  const [toggles, setToggles] = useState({
    active: false,
    activeSecondaryMenuMobile: false,
  });

  const toggleMobileSidebar = useCallback(() => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  }, [dispatch, enableMobileMenu]);

  const toggleMobileSmall = useCallback(() => {
    dispatch(setEnableMobileMenuSmall(!enableMobileMenuSmall));
  }, [dispatch, enableMobileMenuSmall]);

  return (
    <Fragment>
      <div className="app-header__mobile-menu">
        <div onClick={toggleMobileSidebar}>
          <Slider
            width={26}
            lineHeight={2}
            lineSpacing={5}
            color="#6c757d"
            active={toggles.active}
            onClick={() => setToggles({ ...toggles, active: !toggles.active })}
          />
        </div>
      </div>
      <div className="app-header__menu">
        <span onClick={toggleMobileSmall}>
          <Button
            size="sm"
            className={cx('btn-icon btn-icon-only', {
              active: toggles.activeSecondaryMenuMobile,
            })}
            color="primary"
            onClick={() =>
              setToggles({
                ...toggles,
                activeSecondaryMenuMobile: !toggles.activeSecondaryMenuMobile,
              })
            }
          >
            <div className="btn-icon-wrapper">
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
          </Button>
        </span>
      </div>
    </Fragment>
  );
};

export default AppMobileMenu;
