import { PerformanceReviewDto } from './dto/performanceReview.dto';

class PerformanceReviewModel {
  id: number;
  title: string;
  startDate: string;
  endDate?: string;
  status: string;

  constructor(data: PerformanceReviewDto) {
    this.id = data.id;
    this.title = data.title;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.status = data.status;
  }
}

export default PerformanceReviewModel;
