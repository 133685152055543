import { createSlice } from '@reduxjs/toolkit';
import { getSelectedEmployeeVsdData, getVsdData } from './vsd.thunk';
import { formatVsdData } from 'components/AbsenceCalendar/constants';
import { VsdInitialState } from 'models/interfaces/vsd.interface';

const initialState: VsdInitialState = {
  data: null,
  dataWithId: null,
  loading: false,
  error: null,
};

const vsdSlice = createSlice({
  name: 'vsd',
  initialState,
  reducers: {},
  extraReducers: {
    [getVsdData.pending.toString()]: state => {
      state.loading = true;
      state.data = null;
    },
    [getVsdData.fulfilled.toString()]: (state, { payload }) => {
      state.loading = false;
      state.data = formatVsdData(payload);
    },
    [getVsdData.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getSelectedEmployeeVsdData.pending.toString()]: state => {
      state.loading = true;
      state.data = null;
    },
    [getSelectedEmployeeVsdData.fulfilled.toString()]: (state, { payload }) => {
      state.loading = false;
      state.dataWithId = {
        ...state.dataWithId,
        ...payload.items,
      };
    },
    [getSelectedEmployeeVsdData.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default vsdSlice.reducer;
