import { CreateRrActionButtons } from 'models/interfaces/createRR.interface';

const ActionButtons = ({
  className,
  cancelText,
  submitText,
  onCancel,
  onSubmit,
  isSubmitBtn,
  disabled,
}: CreateRrActionButtons) => {
  return (
    <div className={className}>
      <button type="button" onClick={onCancel}>
        {cancelText}
      </button>

      <button type={isSubmitBtn ? 'submit' : 'button'} onClick={onSubmit ? onSubmit : undefined} disabled={disabled}>
        {submitText}
      </button>
    </div>
  );
};

export default ActionButtons;
