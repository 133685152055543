import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { Tooltip } from 'reactstrap';
import RequestStatus from 'components/RequestStatus/RequestStatus';
import ColoredCircle from 'components/ColoredCircle/ColoredCircle';

import { updateResourceRequestsBulk } from 'store/resourceRequestsBulk/resourceRequestsBulkUpdate.thunk';
import { updateDataWithId } from 'store/resourceRequests';

import { useClickOutside } from 'hooks/useClickOutside';
import { getNextPossibleStatuses, getNextStatusBgColor } from '../constants';
import { arrowRight } from 'constants/icons';
import { DurationCellProps } from 'models/interfaces/projectCalendar.interface';

import styles from '../projectCalendarStyles.module.scss';

const ItemDuration: FC<DurationCellProps> = ({
  month,
  item,
  isFirstCellWithFill,
  status,
  color,
  isPir,
  canCreateRR,
  parentPirId,
  isLastMontInList,
}) => {
  const dispatch = useDispatch();

  const durationRef = useRef<HTMLDivElement>(null);
  const statusRef = useRef<HTMLDivElement>(null);

  const [durationWidth, setDurationWidth] = useState(0);
  const [statusWidth, setStatusWidth] = useState(0);
  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    if (durationRef.current) {
      setDurationWidth(durationRef.current.offsetWidth);
    }
    if (statusRef.current) {
      setStatusWidth(statusRef.current.offsetWidth);
    }
  }, []);

  const itemStartDate = item.periodStartDate < month.startDate ? month.startDate : item.periodStartDate;
  const itemEndDate = item.periodEndDate > month.endDate ? month.endDate : item.periodEndDate;

  const itemStartDay: number = new Date(itemStartDate).getDate();
  const itemEndDay: number = new Date(itemEndDate).getDate();
  const monthEndDay: number = new Date(month.endDate).getDate();

  let itemWidth;
  let beforeItemWidth;

  if (itemStartDate === month.startDate && itemEndDate === month.endDate) {
    itemWidth = 100;
  } else if (itemStartDate === month.startDate) {
    itemWidth = (itemEndDay * 100) / monthEndDay;
  } else {
    if (itemStartDate === itemEndDate) {
      itemWidth = 100 / monthEndDay;
    } else {
      itemWidth = ((itemEndDay - itemStartDay + 1) * 100) / monthEndDay;
    }

    beforeItemWidth = ((itemStartDay - 1) * 100) / monthEndDay;
  }

  const closePopup = () => {
    setOpenTooltip(false);
  };

  useClickOutside(statusRef, closePopup);

  return (
    <div className="d-flex">
      {beforeItemWidth && <div style={{ width: `${beforeItemWidth}%` }}></div>}
      <div
        className={isPir ? styles.pirDuration : styles.rrDuration}
        style={{ width: `${itemWidth}%` }}
        ref={isFirstCellWithFill ? durationRef : null}
      >
        {isFirstCellWithFill && (
          <div
            ref={statusRef}
            // RR status transition, functionality on hold, need requirements update
            // onClick={() => (canCreateRR ? setOpenTooltip(true) : null)}
            className={styles.statusWrapper}
            style={{
              marginLeft:
                isLastMontInList && durationWidth - statusWidth < 9 ? `${durationWidth - statusWidth - 8}px` : '8px',
            }}
          >
            <span className="mr-1">
              <ColoredCircle color={color} />
            </span>
            {status}

            {/* part of RR status transition
            {canCreateRR && <span className={cx(styles.updateArrow, 'ml-1')}>{arrowRight}</span>} 
            */}

            {
              <Tooltip
                placement="right-start"
                isOpen={openTooltip}
                target={statusRef}
                className="changeStatusRRtooltip"
              >
                <strong className="ml-2">Change status to:</strong>

                {getNextPossibleStatuses(status.toLowerCase()).map((status: string) => (
                  <div
                    key={status}
                    className={cx(styles.nextStatus, styles[status.toLowerCase().split(' ').join('-')])}
                    onClick={() =>
                      dispatch(
                        updateResourceRequestsBulk({
                          id: parentPirId,
                          requestBody: { status, issueKeys: [item.issueKey] },
                          callback: updateDataWithId,
                        })
                      )
                    }
                  >
                    <div
                      className={styles.wrapRRstatus}
                      style={{ background: getNextStatusBgColor(status.toLowerCase()) }}
                    >
                      <RequestStatus status={status} />
                    </div>
                  </div>
                ))}
              </Tooltip>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemDuration;
