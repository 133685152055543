import { AnyAction, combineReducers } from 'redux';
import themeOptions from './themeOptions';
import notificationsReducer from './notifications';
import account from './account';
import calendarReducer from './calendar';
import teamsReducer from './teams';
import teamTypesReducer from './teamTypes';
import eventsSyncUsers from './syncUsers';
import issuesReducer from './issues';

//employees
import eventsEmployees from './eventsEmployees';
import employeeReducer from './employee';
import employeesReducer from './employees';

// company structure
import companyStructureReducer from './companyStucture';
import holidaysReducer from './holidays';
import workingYearReducer from './workingYear/workingYear.thunk';
import vsdReducer from './vsd';

// finance
import companyExpensesReducer from './companyExpenses';
import expenseCategoriesReducer from './companyExpensesCategories';
import compensationOrdersReducer from './compensationOrders';

// project management
import resourceRequestsReducer from './resourceRequests';
import overtimeRequestsReducer from './overtimeRequests';
import projectCalendarReducer from './projectCalendar';
import projectsReducer from './projects';
import projectInitRequestsReducer from './projectInitRequests';

// human resources
import performanceReviewReducer from './performanceReview';
import allEmployeesReducer from './allEmployees';

// team lead
import vpDashboardReducer from './VpDashboard/vpDashboard.reducer';
import budgetingReducer from './budgeting';

// business development
import eventsDeals from './eventsDeals';
import bdmReducer from './bdm';

// workload
import workloadCalendarReducer from './workloadCalendar';

// profile
import profileReducer from './Profile';
import profileContactsReducer from './ProfileContacts';
import cvReducer from './CV';
import compensationReducer from './compensation';

//obligation
import obligationsReducer from './obligation';
import expenseTypeReducer from './expenseTypes';

const combinedReducer = combineReducers({
  themeOptions,
  account,
  eventsEmployees,
  eventsDeals,
  vsdReducer,
  eventsSyncUsers,
  profileReducer,
  companyStructureReducer,
  vpDashboardReducer,
  employeesReducer,
  issuesReducer,
  expenseTypeReducer,
  obligationsReducer,
  notificationsReducer,
  holidaysReducer,
  workingYearReducer,
  calendarReducer,
  teamsReducer,
  teamTypesReducer,
  employeeReducer,
  profileContactsReducer,
  bdmReducer,
  performanceReviewReducer,
  cvReducer,
  companyExpensesReducer,
  expenseCategoriesReducer,
  compensationReducer,
  workloadCalendarReducer,
  compensationOrdersReducer,
  projectCalendarReducer,
  allEmployeesReducer,
  resourceRequestsReducer,
  overtimeRequestsReducer,
  projectsReducer,
  projectInitRequestsReducer,
  budgetingReducer,
});

const rootReducer = (state: any, action: AnyAction): any => {
  return combinedReducer(state, action);
};

export default rootReducer;
