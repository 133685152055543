import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { Table } from 'reactstrap';

import { AppState } from 'store/configureStore';
import { setStatePerfomanceRequest } from 'store/performanceReview';

import { remove } from 'constants/icons';
import { PerformanceReviewsDetailsItemDto, PRDetailsListItemDto } from 'models/dto/performanceReview.dto';

import styles from '../../tableStyles.module.scss';
import stylesPR from '../PerformanceReview/performanceReview.module.scss';

type TProps = {
  data: Array<PRDetailsListItemDto>;
  accessEdit: boolean;
  currentPage: number;
  perPage: number;
};

const PerformanceReviewDetailsTable: React.FC<TProps> = ({ data, accessEdit, currentPage, perPage }) => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state: AppState) => state.performanceReviewReducer);

  const coefForNumberFirstColumn = useMemo(() => perPage * currentPage - perPage + 1, [currentPage, perPage]);

  const onDeleteEmployee = (item: PerformanceReviewsDetailsItemDto) => {
    dispatch(setStatePerfomanceRequest({ type: 'selectedCycleEmployee', data: item }));
  };

  return (
    <>
      <Table responsive hover className={cx(styles.tableContainer, 'align-middle mb-0 custom-table')}>
        <thead>
          <tr>
            <th className={styles.firstCellWidth}>#</th>
            <th>Employee</th>
            <th>Private Confluence Page</th>
            <th>Review Results</th>
            <th>Meetings</th>
            {accessEdit && <th>Actions</th>}
          </tr>
        </thead>

        <tbody>
          {data?.map((item, index: number) => (
            <tr key={item.id}>
              <td>{index + coefForNumberFirstColumn}</td>
              <td>
                <Link to={`/dashboard/profile/${item.employee.id}`}>
                  {item.employee.firstName || ''} {item.employee.lastName || ''}
                </Link>
              </td>
              <td>{item.confluencePageLink ? <a href={item.confluencePageLink}>{item.confluencePageLink}</a> : '-'}</td>
              <td>{item.jiraEpicLink ? <a href={item.jiraEpicLink}>{item.jiraEpicLink}</a> : '-'}</td>
              <td>{item.googleMeetLink ? <a href={item.googleMeetLink}>{item.googleMeetLink}</a> : '-'}</td>
              {accessEdit && (
                <td>
                  <div className={stylesPR.buttonsWrap}>
                    <button
                      className={cx(stylesPR.delete, { [stylesPR.disabled]: loading })}
                      onClick={() => onDeleteEmployee(item)}
                      disabled={loading}
                    >
                      {remove} Delete
                    </button>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default PerformanceReviewDetailsTable;
