import { createAsyncThunk } from '@reduxjs/toolkit';
import { DealDealsOutInterface } from '../../models/dto/bdm.dto';
import { CommonRequestParams } from '../../models/dto/params.dto';
import bdmService from '../../services/bdmService';
import workforceService from 'services/workforceService';
import employeeService from 'services/employeeService';
import { formatPipelines, formatStages } from 'utils/formatDatas';
import ratesCardsMockData from '../../mockData/ratesCardsMockData.json';
import totalsMockData from '../../mockData/totalsMockData.json';
import TotalsAdapter from 'models/adapters/totals.adapter';
import { RequestParams } from 'utils/mapParams';

export const getAvailableHoursList = createAsyncThunk(
  'bdm/getAvailableHoursList',
  async (params: CommonRequestParams, thunkAPI) => {
    try {
      const availableHours = await bdmService.getAvailableHoursList(params);
      return availableHours;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const getDealGroupsList = createAsyncThunk(
  'bdm/getDealGroupsList',
  async (data: { type: string; params: CommonRequestParams }, thunkAPI) => {
    try {
      const dealGroups = await bdmService.getDealGroupsList(data);
      return dealGroups;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const getFullAvailabilityData = createAsyncThunk(
  'bdm/getFullAvailabilityData',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const workforce = await workforceService.getWorkforce(params);

      return workforce;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const getDealsList = createAsyncThunk('bdm/getDealsList', async (params: CommonRequestParams, thunkAPI) => {
  try {
    const dealsFullInfo = await bdmService.getDealsList(params);
    return dealsFullInfo;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const getDealById = createAsyncThunk(
  'bdm/getDealById',
  async (data: { id: number; params?: CommonRequestParams }, thunkAPI) => {
    try {
      const deal: DealDealsOutInterface = await bdmService.getDealById(data);

      return deal;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const getDealPipelines = createAsyncThunk('bdm/getDealPipelines', async (_, thunkAPI) => {
  try {
    const { items } = await bdmService.getDealPipelines();
    return formatPipelines(items);
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const getDealStages = createAsyncThunk('bdm/getDealStages', async (params: { pipeline: string }, thunkAPI) => {
  try {
    const { items } = await bdmService.getDealStages(params);
    return formatStages(items);
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const getRatesCards = createAsyncThunk('bdm/getRatesCards', async (params: any, thunkAPI) => {
  try {
    // const data = await bdmService.getRatesCards(params);
    return ratesCardsMockData;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const getTotals = createAsyncThunk('bdm/getTotals', async (params: any, thunkAPI) => {
  try {
    // const data = await bdmService.getTotals(params);
    return TotalsAdapter.mapTotalsDtoToTotalsModel(totalsMockData);
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const getSalesGradesList = createAsyncThunk('bdm/getSalesGradesList', async (_, thunkAPI) => {
  try {
    const res = await employeeService.getSalesGrades();
    return res;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});
