import httpService, { HttpService } from './httpService';
import TeamDto from '../models/dto/team.dto';
import TeamAdapter from '../models/adapters/team.adapter';
import TeamModel from 'models/team.model';

export class TeamService {
  constructor(private _httpService: HttpService) {}

  async getTeamsList(params?: { expand?: string; planning?: boolean }) {
    const { data, headers } = await this._httpService.get('/teams', {
      queryStringParameters: params,
      response: true,
    });
    sessionStorage.setItem('backendVersion', `${headers['x-app-version']} (${headers['x-build-number']})`);
    return data.map((team: TeamDto) => TeamAdapter.mapTeamDtoToTeam(team));
  }

  async getTeamsListById(params: { id: string; searchParams?: { expand: string } }) {
    const res = await this._httpService.get(`/teams/${params.id}`, { queryStringParameters: params.searchParams });
    return res;
  }

  async updateTeamsListById(params: { id: string; team: Omit<TeamModel, 'id'> }) {
    const res = await this._httpService.put(`/teams/${params.id}`, {
      body: params.team,
    });
    return res;
  }

  async editTeamById(params: { id: number; team: Partial<TeamModel> }) {
    const res = await this._httpService.patch(`/teams/${params.id}`, {
      body: params.team,
    });
    return res;
  }

  async createTeam(params: Partial<TeamModel>) {
    const res = await this._httpService.post(`/teams`, {
      body: params,
    });
    return res;
  }

  async deleteTeam(id: number) {
    const res = await this._httpService.delete(`/teams/${id}`);
    return res;
  }
}

export default new TeamService(httpService);
