import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import ObligationTable from './ObligationTable';
import DeleteModal from 'common/Modals/DeleteModal';
import ConfirmModal from 'common/Modals/ConfirmModal';

import { approveOrCancelObligation, deleteObligation } from 'store/obligation/obligation.thunk';
import { ObligationDetailsProps } from 'models/interfaces/obligation.interface';

import { check, remove } from 'constants/icons';

import styles from './obligation.module.scss';
import buttonStyles from '../buttons.module.scss';
import './obligationInputStyle.scss';

const ObligationDetails: FC<ObligationDetailsProps> = ({
  obligation,
  addObligation,
  employeeId,
  userId,
  isEditRole,
  isAllowedToApprove,
  isUpdatedObligation,
  index,
}) => {
  const dispatch = useDispatch();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const onObligationApprove = () => {
    dispatch(approveOrCancelObligation({ id: obligation.id, command: 'approve', approvedBy: userId }));
    setOpenConfirmModal(false);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-center mb-2 mt-3">
        <div className={styles.heading}>Obligation {index}</div>

        {isAllowedToApprove && (
          <div className={buttonStyles.buttonsWrap}>
            {!obligation.approvedBy && (
              <button
                onClick={() => setOpenConfirmModal(true)}
                className={buttonStyles.approve}
                disabled={isUpdatedObligation || addObligation}
              >
                {check}
                Approve
              </button>
            )}
            <button
              onClick={() => setOpenDeleteModal(true)}
              className={cx(buttonStyles.reject, buttonStyles.deleteBin)}
              disabled={isUpdatedObligation || addObligation}
            >
              {remove}
            </button>
          </div>
        )}
      </div>

      <ObligationTable
        obligation={obligation}
        addObligation={addObligation}
        employeeId={employeeId}
        isEditRole={isEditRole}
      />

      {openDeleteModal && (
        <DeleteModal
          title={'obligation'}
          itemToDelete={'this obligation'}
          onClose={() => setOpenDeleteModal(false)}
          onDelete={() =>
            obligation.approvedBy
              ? dispatch(approveOrCancelObligation({ id: obligation.id, command: 'cancel' }))
              : dispatch(deleteObligation(obligation.id))
          }
        />
      )}

      {openConfirmModal && (
        <ConfirmModal
          title={'Approve obligation'}
          confirmQuestion={'Are you sure you want to approve this obligation?'}
          confirmButtonText={'Approve'}
          onClose={() => setOpenConfirmModal(false)}
          onConfirm={onObligationApprove}
        />
      )}
    </>
  );
};

export default ObligationDetails;
