import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Loader from 'common/Loader';
import ObligationDetails from 'components/Obligation/ObligationDetails';
import EmployeeCompensationOrder from './EmployeeCompensationOrder';

import { AppState } from 'store/configureStore';
import { getCompensation } from 'store/compensation/compensation.thunk';
import { getObligations } from 'store/obligation/obligation.thunk';
import { getCompensationOrders } from 'store/compensationOrders/compensationOrders.thunk';
import { clearObligations } from 'store/obligation';

import { SalaryHistoryT } from 'models/interfaces/employeeProfileProps';
import ObligationModel from 'models/obligation.model';
import { CompensationOrder } from 'models/compensationOrders.interface';
import { getStartAndEndDateOfPreviousMonth } from 'utils/dates';
import { Role } from 'constants/roles';

import styles from '../Profile.module.scss';
import buttonStyles from '../../tableStyles.module.scss';

const SalaryHistory: FC<SalaryHistoryT> = ({ employeeId, userId }) => {
  const dispatch = useDispatch();

  const { compensation, loading } = useSelector((state: AppState) => state.compensationReducer);
  const { compensationOrders, loading: loadingOrders } = useSelector(
    (state: AppState) => state.compensationOrdersReducer
  );
  const { obligations, loading: loadingObligations } = useSelector((state: AppState) => state.obligationsReducer);
  const { role } = useSelector((state: AppState) => state.account?.user);

  const canSeeCompensationOrder = useMemo(
    () => role === Role.vp || role === Role.finance || userId === employeeId,
    [userId, employeeId]
  );

  const getEmployeeCompensationOrder = () => {
    const [previousMonthStartDate, previousMonthEndDate] = getStartAndEndDateOfPreviousMonth();
    dispatch(
      getCompensationOrders({
        fromDate: previousMonthStartDate,
        toDate: previousMonthEndDate,
        page: 1,
        expand: 'compensations',
        employee: +employeeId,
      })
    );
  };

  useEffect(() => {
    if (compensation === null) {
      dispatch(getCompensation());
    }
    return () => {
      dispatch(clearObligations());
    };
  }, []);

  useEffect(() => {
    if (employeeId) {
      dispatch(getObligations({ employee: employeeId, active: true }));
    }
    if (canSeeCompensationOrder) {
      getEmployeeCompensationOrder();
    }
  }, [dispatch, employeeId]);

  return (
    <div className={styles.blockPadding}>
      <div className="d-flex align-items-center justify-content-between mb-4">
        {userId === employeeId && (
          <div className="d-flex align-items-center">
            <div>Estimated Compensation:</div>
            <div className={styles.amount}>
              {loading ? (
                <div className="ml-4">
                  <Loader />
                </div>
              ) : (
                `${compensation?.amount} ${compensation?.currency}`
              )}
            </div>
          </div>
        )}

        <Link to={`/dashboard/obligation/${employeeId}`} className={buttonStyles.lineButton}>
          Show history
        </Link>
      </div>

      {canSeeCompensationOrder &&
        !!compensationOrders?.length &&
        compensationOrders.map((order: CompensationOrder) => (
          <EmployeeCompensationOrder
            key={order.id}
            order={order}
            employeeId={employeeId}
            userId={userId}
            loading={loadingOrders}
          />
        ))}

      {((loadingOrders && !compensationOrders?.length) || (loadingObligations && !obligations)) && <Loader />}

      {obligations &&
        obligations.map((obligation: ObligationModel, index: number) => (
          <Fragment key={obligation.id}>
            <ObligationDetails
              obligation={obligation}
              employeeId={employeeId}
              userId={+userId}
              isEditRole={false}
              index={obligations.length - index}
            />
            {index + 1 !== obligations.length && <hr />}
          </Fragment>
        ))}
    </div>
  );
};

export default SalaryHistory;
