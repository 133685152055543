import cx from 'classnames';

import { Table } from 'reactstrap';
import BudgetRow from './BudgetRow';

import { TEAM_BUDGET_TABLE_HEADERS } from 'constants/peopleTableHeaders';
import { TeamBudgeting } from 'models/interfaces/budgeting.interface';

import tableStyles from '.././tableStyles.module.scss';
import styles from './teamBudgetStyles.module.scss';

const BudgetTable = ({ teams }: { teams: TeamBudgeting[] }) => {
  return (
    <div
      className={cx(tableStyles.tableWrapper, styles.tableWrapper, 'mb-1')}
      style={{ overflow: 'unset', position: 'relative' }}
    >
      <Table className={cx(tableStyles.tableContainer, 'align-middle mb-0 custom-table text-left')}>
        <thead>
          <tr className={styles.tableHeader}>
            {TEAM_BUDGET_TABLE_HEADERS.map(header => (
              <th key={header.id}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {teams.map(team => (
            <BudgetRow key={team.id} team={team} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default BudgetTable;
