import React, { FC, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Table } from 'reactstrap';

import SortButton from 'common/SortButton/SortButton';

import { useSort } from 'hooks/sortHook';

import sortHeaderDto from 'models/dto/sort.dto';
import AvailabilityModel from 'models/availability.model';

import styles from '../../tableStyles.module.scss';

interface TableProps {
  data: AvailabilityModel[];
  headers: sortHeaderDto[];
  currentPage: number;
  perPage: number;
}

const TableSticky: FC<TableProps> = ({ data, headers, currentPage, perPage }) => {
  const { sortedData, sortKey, sortOrder, onSortingChange } = useSort({ data: data });

  const coefForNumberFirstColumn = useMemo(() => perPage * currentPage - perPage + 1, [currentPage, perPage]);

  const renderList = useCallback(() => {
    if (!!sortedData) {
      return sortedData.map((item: AvailabilityModel, index: number) => {
        return (
          <tr key={item.email}>
            <td>{index + coefForNumberFirstColumn}</td>
            <td>
              <a href={`#/dashboard/profile/${item?.id}`}>{item.email}</a>
            </td>
            <td>{item.team}</td>
            <td>{item.salesGrade}</td>
            <td>{item.title}</td>
            <td>{item.englishLevel}</td>
            <td className={cx(styles[item.styleRow])}>{item.expiration}</td>
            <td>
              <ul className={styles.tableRowList}>
                {item.deals?.map(deal => (
                  <li key={deal.title}>
                    <Link to={{ pathname: `/dashboard/deal-details/${deal.id}` }}>{deal.title}</Link>
                  </li>
                ))}
              </ul>
            </td>
            <td>{item.location}</td>
          </tr>
        );
      });
    } else return null;
  }, [sortedData]);
  const renderTable = () => {
    if (sortedData?.length) {
      return (
        <Table
          responsive
          hover
          className={cx(styles.tableContainer, 'align-middle mb-0 custom-table text-truncate text-left')}
        >
          <thead>
            <tr>
              <th>#</th>
              {headers?.map((item: sortHeaderDto) => (
                <th key={item.value}>
                  <SortButton
                    onClick={() => onSortingChange(item.value, item.sortType)}
                    sortKey={sortKey}
                    sortOrder={sortOrder}
                    sortField={item.value}
                    sortTitle={item.title}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderList()}</tbody>
        </Table>
      );
    } else return null;
  };

  return renderTable();
};

export default TableSticky;
