import { RequestParams } from 'utils/mapParams';
import httpService, { HttpService } from './httpService';

export class ProjectInitRequestsService {
  constructor(private _httpService: HttpService) {}

  async getProjectInitRequests(params: Partial<RequestParams>) {
    const res = this._httpService.get('/project-init-requests', { queryStringParameters: params });
    return res;
  }
}

const projectInitRequestsService = new ProjectInitRequestsService(httpService);

export default projectInitRequestsService;
