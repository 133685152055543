import { AnyAction, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { RootState } from 'App';
import { ItemDealsOutInterface } from 'models/dto/bdm.dto';

export const EVENTS_DEALS_REQUEST = 'DEALS/EVENTS_DEALS_REQUEST';
export const EVENTS_DEALS_SUCCESS = 'DEALS/EVENTS_DEALS_SUCCESS';
export const EVENTS_DEALS_FAILURE = 'DEALS/EVENTS_DEALS_FAILURE';

interface ActionT {
  type: string;
  data: Array<ItemDealsOutInterface> | null;
  payload: PayloadAction;
  error: null | Error;
}

const initialState = {
  data: null,
  fetching: false,
  fetched: false,
  error: null,
};

export default function eventReducer(state = initialState, action: ActionT) {
  switch (action.type) {
    case EVENTS_DEALS_REQUEST: {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case EVENTS_DEALS_SUCCESS: {
      return {
        ...state,
        fetched: true,
        fetching: false,
        data: action.data,
      };
    }
    case EVENTS_DEALS_FAILURE: {
      return {
        ...state,
        fetched: false,
        fetching: false,
        data: null,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

export function getDeals(): ThunkAction<void, RootState, unknown, AnyAction> {
  return function (dispatch) {
    dispatch({ type: EVENTS_DEALS_REQUEST });
    return API.get('helloAPI', '/hubspot', {})
      .then(data => {
        dispatch({
          type: EVENTS_DEALS_SUCCESS,
          data: data,
        });
      })
      .catch(error => {
        dispatch({ type: EVENTS_DEALS_FAILURE, error });
        return Promise.reject(error);
      });
  };
}
