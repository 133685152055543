import httpService, { HttpService } from './httpService';
import { CreateRrT } from 'models/interfaces/createRR.interface';
import { UpdateRRrequestBody } from 'models/interfaces/updateRR.interface';

export class ResourceRequestsBulkService {
  constructor(private _httpService: HttpService) {}

  async createResourceRequestsBulk(params: CreateRrT[]) {
    const res = this._httpService.post('/resource-requests-bulk/create', { body: params });
    return res;
  }

  async updateResourceRequestsBulk(params: UpdateRRrequestBody) {
    const res = this._httpService.post('/resource-requests-bulk/update', { body: params });
    return res;
  }
}

const resourceRequestsBulkService = new ResourceRequestsBulkService(httpService);

export default resourceRequestsBulkService;
