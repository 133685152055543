import React, { useState, FC, useMemo, useCallback, useRef, useEffect } from 'react';
import { Formik } from 'formik';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import DeleteModal from 'common/Modals/DeleteModal';
import ContactItem from './ContactItem';
import ContactsModal from './ContactsModal';

import { ContactItemInterface } from '../../../models/dto/employee.dto';
import { createContactThunk, updateContactThunk, deleteContactThunk } from '../../../store/ProfileContacts';
import { CONTACT_SCHEMA } from '../constants';

import styles from '../Profile.module.scss';
import stylesCurrent from './Contacts.module.scss';

type ContactsT = {
  contacts: ContactItemInterface[] | undefined;
  title: string;
  isEditRole: boolean;
  employeeId: string;
};

const Contacts: FC<ContactsT> = ({ contacts, title, isEditRole, employeeId }) => {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(0);

  const [formValues, setFormValues] = useState({
    value: '',
    contactItemId: 0,
  });

  const contactFormRef = useRef(null);

  // to avoid automatically submitting by parent form
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && event.target !== contactFormRef.current) {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleOpenModalContact = (value: string, id: number) => {
    setFormValues({
      value: value,
      contactItemId: id,
    });
    setIsOpenModal(true);
  };
  const handleCloseModalContact = useCallback(() => setIsOpenModal(false), []);

  const createContact = useCallback(
    (data: ContactItemInterface) => {
      handleCloseModalContact();
      dispatch(
        createContactThunk({
          type: title.toLowerCase(),
          contact: data,
        })
      );
    },
    [handleCloseModalContact, dispatch, title]
  );

  const updateContact = useCallback(
    (data: ContactItemInterface) => {
      handleCloseModalContact();
      dispatch(
        updateContactThunk({
          id: formValues.contactItemId,
          type: title.toLowerCase(),
          contact: data,
        })
      );
    },
    [handleCloseModalContact, dispatch, formValues.contactItemId, title]
  );

  const deleteContact = (contactId: number) => {
    dispatch(deleteContactThunk({ id: contactId, type: title.toLowerCase() }));
    setDeleteModal(0);
  };

  const initialValues = useMemo(
    () => ({
      isPrimary: false,
      employeeId: +employeeId,
      type: 'work',
      value: formValues.value,
    }),
    [employeeId, formValues.value]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CONTACT_SCHEMA(title)}
      onSubmit={(values, formikHelpers) => {
        isEdit ? updateContact(values as ContactItemInterface) : createContact(values as ContactItemInterface);
        setIsEdit(false);
        formikHelpers.resetForm();
        handleCloseModalContact();
      }}
      enableReinitialize
    >
      {({ handleSubmit, resetForm }) => (
        <div>
          <div className={styles.blockWrap}>
            <div className={styles.item}>
              <div className={isEditRole ? cx(styles.label, 'mt-1') : cx(styles.notEditLabel, styles.removeMargin)}>
                {title}(s)
              </div>
              <div className={styles.value} ref={contactFormRef}>
                {contacts?.length
                  ? contacts.map((contactItem, index) => {
                      return (
                        <ContactItem
                          key={contactItem.id}
                          employeeId={employeeId}
                          title={title}
                          contactItem={contactItem}
                          index={index}
                          contactsLength={contacts?.length}
                          isEditRole={isEditRole}
                          handleOpenModalContact={handleOpenModalContact}
                          setIsEdit={setIsEdit}
                          onDelete={event => {
                            event.preventDefault();
                            setDeleteModal(contactItem.id);
                          }}
                        />
                      );
                    })
                  : isEditRole && (
                      <div className={cx(stylesCurrent.wrapContacts, stylesCurrent.wrapContactsButtonAdd)}>
                        <button
                          onClick={() => handleOpenModalContact('', 0)}
                          className={stylesCurrent.addContacts}
                          type="button"
                        />
                      </div>
                    )}
              </div>
            </div>
          </div>
          {isOpenModal && (
            <ContactsModal
              handleClose={() => {
                resetForm();
                handleCloseModalContact();
              }}
              title={title}
              handleSubmit={handleSubmit}
            />
          )}
          {deleteModal !== 0 && (
            <DeleteModal
              title={title}
              itemToDelete={`this ${title.toLowerCase()}`}
              onClose={() => setDeleteModal(0)}
              onDelete={() => deleteContact(deleteModal)}
            />
          )}
        </div>
      )}
    </Formik>
  );
};

export default Contacts;
