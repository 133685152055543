import httpService, { HttpService } from './httpService';
import { RequestParams } from 'utils/mapParams';

export class ProjectsService {
  constructor(private _httpService: HttpService) {}

  async getProjectsList(params: Partial<RequestParams>) {
    const res = this._httpService.get('/projects', { queryStringParameters: params });
    return res;
  }
}

const projectsService = new ProjectsService(httpService);

export default projectsService;
