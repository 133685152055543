import PageTitle from '../../Layout/AppMain/PageTitle';
import RateCard from '../../components/BDM/RateCard/RateCard';
import { rateCardIcon } from 'constants/icons';

const RateCardPage = () => (
  <>
    <PageTitle heading="Rate Card" icon={rateCardIcon} />
    <RateCard />
  </>
);

export default RateCardPage;
