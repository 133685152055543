import { createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from '../configureStore';
import { ISSUE_TYPES } from '../../constants/issues.constants';
import { IssueDto } from '../../models/dto/issues.dto';
import issueService from '../../services/issueService';
import IssueAdapter from '../../models/adapters/issue.adapter';
import { format } from 'date-fns';

interface IssuesState {
  loading: boolean;
  issuesObject: {
    [key: string]: IssueDto[];
  } | null;
  error: Error | null;
}

const initialState: IssuesState = {
  loading: false,
  issuesObject: {},
  error: null,
};

export const issuesSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    getIssuesListRequest: state => {
      return {
        ...state,
        loading: true,
        issuesObject: null,
        error: null,
      };
    },
    getIssuesListSuccess: (state, action) => {
      return {
        ...state,
        issuesObject: action.payload,
        loading: false,
        error: null,
      };
    },
    getIssuesListFailure: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        loading: false,
        issuesObject: null,
        error: action.payload,
      };
    },
  },
});

export const { getIssuesListRequest, getIssuesListSuccess, getIssuesListFailure } = issuesSlice.actions;
export default issuesSlice.reducer;

type PropsT = {
  users: string[];
  fromDate: string;
  toDate: string;
};

export const getProfileVsdIssues =
  ({ users, fromDate, toDate, ...props }: PropsT) =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(getIssuesListRequest());
    try {
      const allIssues = await issueService.getIssuesList(ISSUE_TYPES.vsd, users, {
        ...props,
        fromDate,
        toDate,
      });
      const futureIssues = await issueService.getIssuesList(ISSUE_TYPES.vsd, users, {
        ...props,
        fromDate: format(new Date(), 'yyyy-MM-dd'),
        toDate,
      });

      dispatch(
        getIssuesListSuccess({
          issues: IssueAdapter.mapIssuesObjectDtoToIssuesObjectModel([allIssues]),
          totalVsd: IssueAdapter.mapTotalVsdDtoToTotalVsd([futureIssues]),
        })
      );
    } catch (err) {
      dispatch(getIssuesListFailure(err as Error));
    }
  };
