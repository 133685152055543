import { useState, useMemo } from 'react';

import { sortData } from 'utils/sortTable';

export const useSort = ({ data, defaultSortKey = '', defaultSortOrder = null }) => {
  const [sortKey, setSortKey] = useState(defaultSortKey);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);

  const [sortType, setSortType] = useState();

  const handleSortingChange = (value, type) => {
    sortKey !== value && setSortOrder('asc');
    setSortKey(value);
    setSortType(type);
    if (sortKey === value) {
      sortOrder === null && setSortOrder('asc');
      sortOrder === 'asc' && setSortOrder('desc');
      sortOrder === 'desc' && setSortOrder(null);
    }
  };

  const sortedData = useMemo(() => {
    return sortData(data, sortKey, sortOrder, sortType);
  }, [sortOrder, sortKey, data]);

  return { sortedData, sortKey, sortOrder, onSortingChange: handleSortingChange };
};
