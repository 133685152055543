import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateWorklogThunk } from '../../store/VpDashboard/vpDashboard.thunk';
import { AppState } from 'store/configureStore';
import { DecisionButtons } from 'models/interfaces/vpDashboard.interface';

import { approveIcon, rejectIcon } from 'constants/icons';

import styles from '../buttons.module.scss';

const VpDashboardButtons: FC<DecisionButtons> = ({ id, approveCallback, setCount }) => {
  const dispatch = useDispatch();

  const { approvedWorklogs } = useSelector((state: AppState) => state.vpDashboardReducer);

  const approvedId = approvedWorklogs.includes(id, 0);
  const isDisabledButton = approvedId;

  const handleIncrement = () => {
    setCount && setCount(prevCount => prevCount + 1);
  };

  const onApprove = (id: number) => {
    if (!approvedId) {
      dispatch(updateWorklogThunk({ id, status: 'approve', callback: approveCallback }));
      handleIncrement();
    }
  };

  const onReject = (id: number) => {
    if (!approvedId) {
      dispatch(updateWorklogThunk({ id, status: 'reject', callback: approveCallback }));
      handleIncrement();
    }
  };

  return (
    <div className={styles.buttonsWrap}>
      <button className={styles.approve} onClick={() => onApprove(id)} disabled={isDisabledButton}>
        {approveIcon} Approve
      </button>
      <button className={styles.reject} onClick={() => onReject(id)} disabled={isDisabledButton}>
        {rejectIcon} Reject
      </button>
    </div>
  );
};
export default VpDashboardButtons;
