import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { Card, CardBody } from 'reactstrap';
import AbsenceFooterTable from './AbsenceFooterTable';
import Legend from './Legend';
import Loader from 'common/Loader';
import RefreshButton from 'common/RefreshButton';
import TeamFilter from 'common/TeamFilter/TeamFilter';
import Datepicker from 'components/Datepicker/Datepicker';

import { getVsdData } from 'store/vsd/vsd.thunk';
import { AppState } from 'store/configureStore';

import { useQuery } from 'hooks/queryHook';
import { DATE_PICKER } from 'constants/common';
import { RequestParams } from 'utils/mapParams';
import { FormattedVsdFooterItem } from 'models/interfaces/vsd.interface';

import styles from './VsdPage.module.scss';
import './vsd.scss';

const AbsenceCalendar = () => {
  const dispatch = useDispatch();

  const { data, loading } = useSelector((state: AppState) => state.vsdReducer);

  const { fromDate, toDate, team, setFromToDate, setTeam } = useQuery();

  const getData = useCallback(() => {
    if (!fromDate || !toDate) {
      setFromToDate(
        format(startOfMonth(new Date()), DATE_PICKER.dateFormatToPayload),
        format(endOfMonth(new Date()), DATE_PICKER.dateFormatToPayload)
      );
    } else {
      const params: Partial<RequestParams> = {
        fromDate: fromDate,
        toDate: toDate,
      };

      if (team) {
        params.team = +team;
      }

      dispatch(getVsdData(params));
    }
  }, [team, dispatch, fromDate, toDate]);

  useEffect(() => {
    getData();
  }, [dispatch, getData]);

  const onDateChange = (date: Date) => {
    setFromToDate(
      format(startOfMonth(date), DATE_PICKER.dateFormatToPayload),
      format(endOfMonth(date), DATE_PICKER.dateFormatToPayload)
    );
  };

  return (
    <>
      <div className={styles.calendarToolbar}>
        <RefreshButton onClick={() => getData()} />
      </div>
      <Card className="main-card mb-3">
        <CardBody>
          <div className="filters-block">
            <TeamFilter team={team} setTeam={setTeam} />

            <div className="dropdown-filter">
              <div className="label-wrapper">Date</div>
              <Datepicker
                selected={fromDate ? new Date(fromDate) : startOfMonth(new Date())}
                dateFormat={DATE_PICKER.dateFormatMonth}
                showMonthYearPicker
                onChange={onDateChange}
              />
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                firstDay={1}
                contentHeight="auto"
                initialDate={fromDate || new Date()}
                nextDayThreshold={'00:00:00'}
                events={data?.issues || []}
                eventClick={info => {
                  info.jsEvent.preventDefault();
                  if (info.event.url) {
                    window.open(info.event.url, '_blank');
                  }
                }}
                headerToolbar={{
                  left: '',
                  center: '',
                  right: '',
                }}
                weekends
              />

              <Legend />

              <div className={styles.footerWrap}>
                {data?.footer?.map((item: FormattedVsdFooterItem) => (
                  <Fragment key={item.title}>
                    <AbsenceFooterTable item={item} />
                  </Fragment>
                ))}
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default AbsenceCalendar;
