import { IssuesRR, ResourceRequestsIssuesDto } from '../dto/resourceRequests.dto';
import ResourceRequestDealDetailsModel from '../resourceRequestDealDetails.model';

class ResourceRequestAdapter {
  static mapResourceRequestDtoToDealDetails(issues: IssuesRR): ResourceRequestDealDetailsModel[] {
    const resourceRequests = Object.entries(issues.items).reduce(
      (acc: Array<ResourceRequestsIssuesDto>, current: Array<any>) => [...acc, ...current[1].issues],
      []
    );

    return resourceRequests.map(resourceRequest => new ResourceRequestDealDetailsModel(resourceRequest));
  }
}

export default ResourceRequestAdapter;
