import { WorklogsData } from 'models/interfaces/vpDashboard.interface';

export const GET_WORKLOGS_REQUEST = 'GET_WORKLOGS_REQUEST';
export const GET_WORKLOGS_SUCCESS = 'GET_WORKLOGS_SUCCESS';
export const GET_WORKLOGS_FAILURE = 'GET_WORKLOGS_FAILURE';

export const UPDATE_WORKLOG_REQUEST = 'UPDATE_WORKLOG_REQUEST';
export const UPDATE_WORKLOG_SUCCESS = 'UPDATE_WORKLOG_SUCCESS';
export const UPDATE_WORKLOG_FAILURE = 'UPDATE_WORKLOG_FAILURE';

export const getWorklogsRequest = () => {
  return { type: GET_WORKLOGS_REQUEST };
};
export const getWorklogsSuccess = (data: WorklogsData) => {
  return { type: GET_WORKLOGS_SUCCESS, payload: data };
};
export const getWorklogsFailure = (error: unknown) => {
  return { type: GET_WORKLOGS_FAILURE, payload: error };
};

export const updateWorklogRequest = (id: number) => {
  return { type: UPDATE_WORKLOG_REQUEST, payload: id };
};
export const updateWorklogSuccess = (id: number) => {
  return { type: UPDATE_WORKLOG_SUCCESS, payload: id };
};
export const updateWorklogFailure = (error: unknown) => {
  return { type: UPDATE_WORKLOG_FAILURE, payload: error };
};
