import React from 'react';
import { Link } from 'react-router-dom';

import { pageNotFoundError } from 'constants/icons';

import styles from './notFoundStyle.module.scss';

const PageNotFound = () => {
  return (
    <div className={styles.wrapper}>
      {pageNotFoundError}

      <div className={styles.heading}>Oops! We couldn't find what you were looking for</div>
      <div className={styles.subheading}>
        Don't worry, it's not your fault. It looks like the page you were trying to access has either been removed,
        relocated or never existed in the first place.
      </div>

      <Link to="/dashboard/" className={styles.buttonLink}>
        Go to Homepage
      </Link>
    </div>
  );
};

export default PageNotFound;
