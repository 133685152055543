import { CalendarDayInterface } from '../models/interfaces/calendar-day.interface';
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDaysInMonth,
  getMonth,
  getTime,
  isAfter,
  startOfMonth,
} from 'date-fns';
import { DATE_PICKER } from 'constants/common';

export const getDaysOfInterval = (startDate: any, endDate: any) =>
  eachDayOfInterval({
    start: startDate ? new Date(startDate) : new Date(),
    end: endDate ? new Date(endDate) : new Date(),
  });

export const formatDate = (date: any): string => format(new Date(date), 'yyyy-MM-dd');

export const formatDatetoString = (date: any): string => format(new Date(date), 'MMM dd, yyyy EEEE');

export const formatDateToDayName = (date: any): string => format(new Date(date), 'EEE');

export const secondsToHours = (seconds: number): string => {
  let str = '';
  const hours = Math.floor(seconds / 60 / 60);
  const minutes = Math.floor(seconds / 60) - hours * 60;
  if (hours !== 0) str += `${hours}h `;
  if (minutes !== 0) str += `${minutes}m`;
  if (minutes === 0 && hours === 0) str += '0';
  return str;
};

export const getStartWeekDay = (date: any): number => {
  const res = new Date(date.getFullYear(), date.getMonth(), 1);
  return res.getDay() || 7;
};

export const isWeekendDay = (i: number): boolean => {
  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const weekDay = i % weekDays.length;
  return weekDay === 5 || weekDay === 6;
};

export const isHoliday = (date: string): boolean => {
  const holidayList = [
    '2021-01-01',
    '2021-01-07',
    '2021-03-08',
    '2021-05-03',
    '2021-05-04',
    '2021-05-10',
    '2021-06-28',
    '2021-08-24',
    '2021-10-14',
    '2021-12-31',
    '2022-01-03',
    '2022-01-07',
  ];
  return holidayList.some(holiday => date === holiday);
};

export const getDatesBetweenDateRange = (startDate: string, endDate: string, month: number) => {
  const date = [];

  while (getTime(new Date(startDate)) <= getTime(new Date(endDate))) {
    date.push(new Date(startDate));
    startDate = format(addDays(new Date(startDate), 1), 'yyyy-MM-dd');
  }

  return date.filter(day => getMonth(day) === month);
};

export const isCurrentMonth = (date: string, issueDate: string): boolean => {
  return getMonth(new Date(date)) === getMonth(new Date(issueDate));
};

export const getDatesRange = (fromDate: string, toDate: string) => {
  const dateArray = [];
  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);

  while (format(startDate, DATE_PICKER.dateFormatToPayload) <= format(endDate, DATE_PICKER.dateFormatToPayload)) {
    dateArray.push(format(startDate, DATE_PICKER.dateFormatToPayload));
    startDate.setDate(startDate.getDate() + 1);
  }

  return dateArray;
};

export const createMonthDatesWithProperties = (selectedDate: string, holidays: string[]): CalendarDayInterface[] => {
  const startWeekDay = getStartWeekDay(selectedDate);

  return new Array(getDaysInMonth(new Date(selectedDate))).fill(null).map((_, i) => {
    const date = format(addDays(startOfMonth(new Date(selectedDate)), 1), 'yyyy-MM-dd');
    const isWeekendRes = isWeekendDay(startWeekDay + i - 1);
    const isHolidayRes = holidays?.some(holiday => date === holiday);
    const isCurrentDay = format(new Date(), 'yyyy-MM-dd') === date;

    let nextDay = '';
    const splitedDate = date.split('-');
    splitedDate.splice(2, 1, (i + 2).toString());
    nextDay = splitedDate.join('-');
    const isCurrentDayNext = format(new Date(), 'yyyy-MM-dd') === nextDay;

    return {
      date,
      isHoliday: isWeekendRes || isHolidayRes,
      isCurrentDay,
      isDayBeforeCurrent: isCurrentDayNext,
    };
  });
};

export const isDayBeforeCurrent = (date: string): boolean => {
  const _date = new Date(date);
  _date.setDate(_date.getDate() + 1);
  return isAfter(new Date(), _date);
};

export const getStyleRowFromDismissalDate = (date?: string): string => {
  if (!!date) {
    const currentDate = new Date(date);
    const month3LateDate = new Date().setMonth(new Date().getMonth() + 3);
    const monthLateDate = new Date().setMonth(new Date().getMonth() + 1);

    if (currentDate.getTime() > month3LateDate) return 'greenRow';
    if (currentDate.getTime() < month3LateDate && currentDate.getTime() > monthLateDate) return 'yellowRow';
    return 'redRow';
  }

  return 'redRow';
};

export const getStyleRowFromEndDate = (expiration?: string) => {
  const currentDeal = expiration;

  if (!!currentDeal) {
    const currentDate = new Date(currentDeal);
    const month3LateDate = new Date().setMonth(new Date().getMonth() + 1);
    const monthLateDate = new Date().setMonth(new Date().getMonth());

    if (currentDate.getTime() > month3LateDate) return 'greenRow';
    if (currentDate.getTime() < month3LateDate && currentDate.getTime() > monthLateDate) return 'yellowRow';
    return 'redRow';
  }

  return 'redRow';
};

export const getLocalDateFromString = (date: string): Date => {
  const [year, month, day]: string[] = date.split('-');
  return new Date(+year, +month - 1, +day);
};

export const getStartAndEndDateOfPreviousMonth = () => {
  const currentDate = new Date();
  const previousMonthDate = currentDate.setMonth(currentDate.getMonth() - 1);

  const previousMonthStartDate = format(startOfMonth(new Date(previousMonthDate)), DATE_PICKER.dateFormatToPayload);
  const previousMonthEndDate = format(endOfMonth(new Date(previousMonthDate)), DATE_PICKER.dateFormatToPayload);

  return [previousMonthStartDate, previousMonthEndDate];
};

export const getPreviousMonthName = () => {
  const currentDate = new Date();
  const previousMonthDate = currentDate.setMonth(currentDate.getMonth() - 1);
  const previousMonthName = format(previousMonthDate, DATE_PICKER.dateFormatFullMonth);

  return previousMonthName;
};
