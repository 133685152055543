import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import cx from 'classnames';

import { secondsToHours } from 'utils/dates';
import { ItemsOutInterface } from '../../../models/dto/bdm.dto';

import styles from '../../tableStyles.module.scss';

type TProps = { data: Array<ItemsOutInterface> };

const WorkforseHoursTable: React.FC<TProps> = ({ data }) => (
  <div className={styles.tableWrapper}>
    <Table responsive hover className={cx(styles.tableContainer, 'align-middle mb-0 custom-table text-left')}>
      <thead>
        <tr>
          <th className={styles.firstCellWidth}>#</th>
          <th>User</th>
          <th>Team</th>
          <th>Available hours</th>
          <th>Monthly workload hours</th>
        </tr>
      </thead>

      <tbody>
        {data?.map((item: ItemsOutInterface, index: number) => {
          return (
            <tr key={index} className={styles.removeBorder}>
              <td>{index + 1}</td>
              <td>
                <Link to={`/dashboard/profile/${item.employeeId}`}>{item.fullName}</Link>
              </td>
              <td>{item.team.title}</td>
              <td>{secondsToHours(item.available)}</td>
              <td>{secondsToHours(item.working)}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  </div>
);

export default WorkforseHoursTable;
