import { COLORS } from 'config/theme';
import { TeamWithBudget, BudgetDataT, TeamBudgeting, EmployeeBudget } from 'models/interfaces/budgeting.interface';
import TeamModel from 'models/team.model';
import { BudgetingInfo } from 'store/budgeting';

export const getCoreTeams = (teams: TeamModel[], teamsToDisplay?: string): TeamModel[] => {
  if (!teamsToDisplay) {
    return teams.filter((team: TeamModel) => !team.parentId);
  } else {
    const teamsArr = teamsToDisplay.split(',');

    if (teamsArr.length === 1) {
      const selectedTeam = teams.find(team => team.id === +teamsToDisplay);
      return selectedTeam ? [selectedTeam] : [];
    } else {
      return teams.filter(team => teamsArr.includes(String(team.id)) && !teamsArr.includes(String(team.parentId)));
    }
  }
};

const sortBudgetItemsByEmployee = (budgetItems: BudgetDataT[]): EmployeeBudget[] => {
  const regularItems: EmployeeBudget[] = [];
  let teamLeadItem: EmployeeBudget | null = null;

  budgetItems.forEach(item => {
    if (item.isTeamLead) {
      teamLeadItem = {
        employee: item.employee,
        estimatedCost: item.estimatedCost,
        factualCost: item.factualCost,
        employeeBudget: [item],
      };
    } else if (item.employee) {
      const existingEmployeeIndex = regularItems.findIndex(
        regularItem => regularItem.employee?.id === item.employee?.id
      );

      if (existingEmployeeIndex !== -1) {
        regularItems[existingEmployeeIndex].employeeBudget.push(item);

        if (item.estimatedCost) {
          regularItems[existingEmployeeIndex].estimatedCost = item.estimatedCost;
        }
        if (item.factualCost) {
          regularItems[existingEmployeeIndex].factualCost = item.factualCost;
        }
      } else {
        regularItems.push({
          employee: item.employee,
          estimatedCost: item.estimatedCost,
          factualCost: item.factualCost,
          employeeBudget: [item],
        });
      }
    } else {
      regularItems.push({
        employee: null,
        estimatedCost: item.estimatedCost,
        factualCost: item.factualCost,
        employeeBudget: [item],
      });
    }
  });

  if (teamLeadItem) {
    regularItems.push(teamLeadItem);
  }

  return regularItems;
};

const addBudgetToTeam = (currentTeam: TeamWithBudget, budgetItemsMap: Map<number, BudgetDataT[]>): TeamBudgeting => {
  const team: TeamBudgeting = {
    ...currentTeam,
    budget: currentTeam.budget || [],
    hasBudgetData: false,
  };

  const teamItems = budgetItemsMap.get(team.id);
  if (teamItems) {
    const budgetItems = sortBudgetItemsByEmployee(teamItems);
    team.budget.push(...budgetItems);
  }

  team.children = team.children
    ?.map(child => addBudgetToTeam(child, budgetItemsMap))
    .filter(child => child.hasBudgetData || child.budget.length > 0);
  team.children?.sort((a, b) => (a.children?.length ? 1 : 0) - (b.children?.length ? 1 : 0));

  team.hasBudgetData = team.budget.length > 0 || team.children?.some(child => child.hasBudgetData);

  return team;
};

export const addBudgetingDataToTeams = (teams: TeamWithBudget[] = [], budget: BudgetDataT[]): TeamBudgeting[] => {
  const budgetItemsMap = new Map<number, BudgetDataT[]>();

  budget.forEach(item => {
    const teamId = item.team.id;
    if (!budgetItemsMap.has(teamId)) {
      budgetItemsMap.set(teamId, []);
    }
    budgetItemsMap.get(teamId)?.push(item);
  });

  return teams.map(team => addBudgetToTeam(team, budgetItemsMap)).filter(team => team.hasBudgetData);
};

export const getBudgetTotals = (budgetData: BudgetingInfo | null) => {
  return [
    {
      title: 'Estimated Budget',
      value: `${budgetData?.estimatedBudget || 0}$`,
      color: COLORS.darkGray,
    },
    {
      title: 'Factual Budget',
      value: `${budgetData?.factualBudget || 0}$`,
      color: COLORS.teal,
    },
    {
      title: 'Estimated Hours',
      value: `${budgetData?.estimatedHours || 0}h`,
      color: COLORS.purple,
    },
    {
      title: 'Factual Hours',
      value: `${budgetData?.factualHours || 0}h`,
      color: COLORS.blue,
    },
    {
      title: 'Estimated Cost',
      value: `${budgetData?.estimatedCost || 0}$`,
      color: COLORS.orange,
    },
    {
      title: 'Factual Cost',
      value: `${budgetData?.factualCost || 0}$`,
      color: COLORS.mdGray,
    },
    {
      title: 'Diff in $',
      value: `${budgetData?.totalDiff}$`,
      color: COLORS.green,
    },
    {
      title: 'Diff in %',
      value: `${budgetData?.totalPercentDiff}%`,
      color: COLORS.darkOrange,
    },
  ];
};
