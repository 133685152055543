import { eachMonthOfInterval, format, endOfMonth, startOfMonth, addMonths } from 'date-fns';
import { DATE_PICKER } from 'constants/common';
import { COLORS, HOVER_COLORS } from 'config/theme';
import { Month, PirDuration, ProjectAndPIRs } from 'models/interfaces/projectCalendar.interface';

export const NOT_ACTIVE_STATUSES = ['Start Failed', 'Rejected', 'Stopped', 'Finished', 'Closed'];

export const PIR_ACTIVE_STATUSES = [
  'Draft',
  'Requested',
  'Fin Check',
  'Delivery Check',
  'Legal Paperwork',
  'Client Sign',
  'Start Approved',
  'Started',
  'Prolonged',
  'Project Closure Request',
  'Resource Releasing',
  'Invoicing',
  'Deliverables Transferring',
  'Closure Legal Paperwork',
  'Cleaning Up',
  'Documentation',
  'Testimonials Requesting',
];

export const PIR_STATUSES = [
  'All Active',
  'Draft',
  'Requested',
  'Fin Check',
  'Delivery Check',
  'Legal Paperwork',
  'Client Sign',
  'Start Approved',
  'Start Failed',
  'Rejected',
  'Started',
  'Prolonged',
  'Project Closure Request',
  'Resource Releasing',
  'Invoicing',
  'Deliverables Transferring',
  'Closure Legal Paperwork',
  'Cleaning Up',
  'Documentation',
  'Testimonials Requesting',
  'Stopped',
  'Finished',
  'Closed',
];

export const isItemWithinMonth = ({ startOfMonth, endOfMonth, itemStartDate, itemEndDate }: PirDuration): boolean => {
  if (
    (startOfMonth >= itemStartDate && startOfMonth <= itemEndDate) ||
    (endOfMonth >= itemStartDate && endOfMonth <= itemEndDate) ||
    (itemStartDate >= startOfMonth && itemEndDate <= endOfMonth) ||
    (itemStartDate <= startOfMonth && itemEndDate >= endOfMonth)
  ) {
    return true;
  } else {
    return false;
  }
};

export const getMonthsList = (fromDate: string, toDate: string) => {
  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);

  const monthsArray = eachMonthOfInterval({ start: startDate, end: endDate });

  const months: Month[] = monthsArray.map(monthStartDate => {
    const monthName = format(monthStartDate, 'MMMM');
    const monthEndDate = endOfMonth(monthStartDate);

    const firstDateOfMonth = format(monthStartDate, DATE_PICKER.dateFormatToPayload);
    const lastDateOfMonth = format(monthEndDate, DATE_PICKER.dateFormatToPayload);

    return { name: monthName, startDate: firstDateOfMonth, endDate: lastDateOfMonth };
  });

  return months;
};

export const changeProjectsOrder = (projects: ProjectAndPIRs[]): Required<ProjectAndPIRs>[] => {
  const filteredProjects = projects.reduce((acc: Required<ProjectAndPIRs>[], item: ProjectAndPIRs) => {
    if (item?.project) {
      acc.push(item as Required<ProjectAndPIRs>);
    } else {
      acc.unshift({
        project: { id: 0, name: 'No project', projectKey: '', projectLink: '' },
        projectInitRequests: item.projectInitRequests,
      });
    }

    return acc;
  }, []);

  return filteredProjects;
};

export const getPirStatusColor = (status: string) => {
  if (['Rejected', 'Start Failed', 'Closed'].includes(status)) {
    return COLORS.red;
  } else if (['Draft', 'Stopped'].includes(status)) {
    return COLORS.gray;
  } else if (['Approved', 'Start Approved', 'Finished'].includes(status)) {
    return COLORS.green;
  } else if (['Requested', 'Started', 'Prolonged'].includes(status)) {
    return COLORS.blue;
  } else {
    return COLORS.orange;
  }
};

export const getResourceRequestStatusColor = (status: string) => {
  const colors: Record<string, string> = {
    Approved: COLORS.green,
    Draft: COLORS.gray,
    'Fin Check': COLORS.darkGray,
    'In Review': COLORS.orange,
    Purchased: COLORS.purple,
    Rejected: COLORS.red,
    Requested: COLORS.blue,
    'Waiting for confirmation': COLORS.teal,
  };

  return colors[status];
};

export const getFirstAndLastDay = () => {
  const currentDate = new Date();
  const firstDayOfPreviousMonth = startOfMonth(addMonths(currentDate, -1));
  const lastDayOfNextMonth = endOfMonth(addMonths(currentDate, 1));

  return [format(firstDayOfPreviousMonth, 'yyyy-MM-dd'), format(lastDayOfNextMonth, 'yyyy-MM-dd')];
};

export const getNextPossibleStatuses = (status: string): string[] => {
  const nextStatuses: Record<string, string[]> = {
    draft: ['Fin Check', 'Rejected'],
    'fin check': ['Requested', 'Rejected'],
    requested: ['In Review', 'Rejected'],
    'in review': ['Approved', 'Rejected'],
    approved: ['Waiting for confirmation', 'Rejected', 'Blocked'],
    'waiting for confirmation': ['Approved', 'Purchased'],
    purchased: ['Approved'],
    rejected: ['Draft'],
    blocked: ['Requested'],
  };

  return nextStatuses[status] || [];
};

export const getNextStatusBgColor = (status: string): string => {
  const statusColors: Record<string, string> = {
    draft: HOVER_COLORS.gray,
    'fin check': HOVER_COLORS.gray,
    requested: HOVER_COLORS.blue,
    'in review': HOVER_COLORS.orange,
    approved: HOVER_COLORS.green,
    'waiting for confirmation': HOVER_COLORS.teal,
    purchased: HOVER_COLORS.purple,
    rejected: HOVER_COLORS.red,
    blocked: HOVER_COLORS.blue,
  };

  return statusColors[status] || HOVER_COLORS.blue;
};
