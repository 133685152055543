import React from 'react';

import PageTitle from 'Layout/AppMain/PageTitle';
import ExpenseCategories from 'components/CompanyExpenses/ExpenseCategories/ExpenseCategories';

import { companyExpensesIcon } from 'constants/icons';

const ExpenseCategoriesPage = () => {
  return (
    <>
      <PageTitle heading="Company Expenses" icon={companyExpensesIcon} />
      <ExpenseCategories />
    </>
  );
};

export default ExpenseCategoriesPage;
