import React, { FC } from 'react';
import cx from 'classnames';
import { STATUSES } from './constants';

import styles from './VsdPage.module.scss';

const Legend: FC = () => {
  return (
    <div className="d-flex mt-3 mb-4">
      {STATUSES.map((status: string, index: number) => {
        return (
          <div className="d-flex align-items-center mr-2" key={index}>
            <span className={cx(styles.circle, styles[status.toLowerCase()])} />
            {status}
          </div>
        );
      })}
    </div>
  );
};

export default Legend;
