import React from 'react';
import cx from 'classnames';
import { Col, Row, Table } from 'reactstrap';

import { secondsToHours } from '../../utils/dates';
import TeamLeadWorkforceModel from '../../models/teamLead.model';

import styles from '../tableStyles.module.scss';

type TProps = { data: TeamLeadWorkforceModel };

const TeamLeadWorkforceFooter: React.FC<TProps> = ({ data: { footer } }) => (
  <div className="mt-3">
    <Row>
      <Col xs={12} md={6}>
        <div className={styles.tableWrapper}>
          <Table responsive hover className={cx(styles.footerTableContainer, 'align-middle mb-0 text-truncate')}>
            <tbody>
              <tr className={styles.removeTopBorder}>
                <td className={styles.rowWrapper}>Total cost</td>
                <td style={{ fontWeight: '700' }}>$ {footer.totalCost}</td>
              </tr>

              <tr>
                <td className={styles.rowWrapper}>Available hours</td>
                <td>{secondsToHours(footer.totalWorkingTime)}</td>
              </tr>

              <tr>
                <td className={styles.rowWrapper}>Added</td>
                <td>{footer.numberOfAdded}</td>
              </tr>

              <tr>
                <td className={styles.rowWrapper}>Dismissed</td>
                <td>{footer.numberOfDismissed}</td>
              </tr>

              <tr className={styles.greenRow}>
                <td>Added Cost</td>
                <td>{footer.totalAddedCost}</td>
              </tr>

              <tr className={styles.redRow}>
                <td>Dismissed Cost</td>
                <td>{footer.totalDismissedCost}</td>
              </tr>

              <tr>
                <td className={styles.rowWrapper}>Blended rate</td>
                <td>$ {footer.blendedRate}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  </div>
);

export default TeamLeadWorkforceFooter;
