import PageTitle from 'Layout/AppMain/PageTitle';
import CompensationOrders from 'components/CompensationOrders/CompensationOrders';

import { workforceCostsIcon } from 'constants/icons';

const CompensationOrdersPage = () => {
  return (
    <>
      <PageTitle heading="Compensation Orders" icon={workforceCostsIcon} />
      <CompensationOrders />
    </>
  );
};

export default CompensationOrdersPage;
