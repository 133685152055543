import { createSlice } from '@reduxjs/toolkit';
import { getCompanyExpenses, getCompanyExpensesById } from './companyExpenses.thunk';
import { CompanyExpensesState } from 'models/interfaces/companyExpenses.interface';

const initialState: CompanyExpensesState = {
  expenses: [],
  totalPlanned: null,
  currentExpense: null,
  pages: 0,
  total: 0,
  loadingModal: false,
  loading: false,
  error: null,
};

const companyExpensesSlice = createSlice({
  name: 'companyExpenses',
  initialState,
  reducers: {
    clearCurrentExpense(state) {
      return {
        ...state,
        currentExpense: null,
      };
    },
  },
  extraReducers: {
    [getCompanyExpenses.pending.toString()]: state => {
      state.expenses = [];
      state.loading = true;
      state.error = null;
    },
    [getCompanyExpenses.fulfilled.toString()]: (state, { payload }) => {
      state.expenses = payload.items;
      state.totalPlanned = payload.totals.planned;
      state.pages = payload.pages;
      state.total = payload.total;
      state.loading = false;
    },
    [getCompanyExpenses.rejected.toString()]: state => {
      state.loading = false;
      state.error = null;
    },
    [getCompanyExpensesById.pending.toString()]: state => {
      state.loadingModal = true;
      state.currentExpense = null;
      state.error = null;
    },
    [getCompanyExpensesById.fulfilled.toString()]: (state, { payload }) => {
      state.loadingModal = false;
      state.currentExpense = payload;
    },
    [getCompanyExpensesById.rejected.toString()]: (state, { payload }) => {
      state.loadingModal = false;
      state.error = payload;
    },
  },
});

export const { clearCurrentExpense } = companyExpensesSlice.actions;

export default companyExpensesSlice.reducer;
