import React from 'react';
import cx from 'classnames';

import { Table } from 'reactstrap';
import SortButton from 'common/SortButton/SortButton';

import { useSort } from 'hooks/sortHook';
import sortHeaderDto from 'models/dto/sort.dto';
import WorkingYearModel from 'models/workingdays.model';

import styles from '../tableStyles.module.scss';

type TProps = {
  data: WorkingYearModel[];
  currentYear: number;
};

const MONTH = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const WorkingYearTable: React.FC<TProps> = ({ data, currentYear }) => {
  const { sortedData, sortKey, sortOrder, onSortingChange } = useSort({ data });

  const WORKING_YEAR_TABLE_HEADERS: sortHeaderDto[] = [
    { id: 1, title: `${currentYear}`, value: 'month', sortType: 'numeric' },
    { id: 2, title: 'Working days', value: 'workingDays', sortType: 'numeric' },
    { id: 3, title: 'Working hours', value: 'workingTime', sortType: 'numeric' },
    { id: 4, title: 'Non-working days', value: 'nonWorkingDays', sortType: 'numeric' },
  ];

  return (
    <div className={styles.tableWrapper}>
      <Table responsive hover className={cx(styles.tableContainer, 'align-middle mb-0 custom-table')}>
        <thead>
          <tr>
            <th>#</th>
            {WORKING_YEAR_TABLE_HEADERS?.map((item, index) => (
              <th key={index}>
                <SortButton
                  onClick={() => onSortingChange(item.value, item.sortType)}
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  sortField={item.value}
                  sortTitle={item.title}
                />
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {sortedData.map((workingYear: WorkingYearModel, index: number) => {
            return (
              <React.Fragment key={index}>
                <tr className={styles.removeBorder}>
                  <td>{index + 1}</td>
                  <td className={styles.tdWidth}>{MONTH[workingYear.month - 1]}</td>
                  <td className={styles.tdWidth}>{workingYear.workingDays}</td>
                  <td className={styles.tdWidth}>{workingYear.workingTime}</td>
                  <td className={styles.tdWidth}>{workingYear.nonWorkingDays}</td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default WorkingYearTable;
