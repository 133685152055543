import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { Action, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from './configureStore';
import StorageService from '../services/storageService';
import AccountService from '../services/accountService';
import AccountModel from '../models/account.model';
import { Role } from 'constants/roles';

export const SET_USER = 'USER/SET_USER';
export const CHANGE_USER_ROLE = 'USER/CHANGE_USER_ROLE';
export const LOGOUT_USER = 'USER/LOGOUT_USER';
export const SESSION_IS_AUTHENTICATED_KEY = 'SESSION_IS_AUTHENTICATED_KEY';
export const CHECK_SESSION_REQUEST = 'CHECK_SESSION_REQUEST';
export const CHECK_SESSION_SUCCESS = 'CHECK_SESSION_SUCCESS';
export const CHECK_SESSION_FAILURE = 'CHECK_SESSION_FAILURE';

const accountStorage = new StorageService(sessionStorage, SESSION_IS_AUTHENTICATED_KEY);
const accountService = new AccountService(Auth, accountStorage);

interface AccountInterface {
  user: AccountModel | null;
  isAuthorized: boolean;
  loaded: boolean;
  loading: boolean;
  error: Error | null;
}

const initialState: AccountInterface = {
  user: null,
  isAuthorized: false,
  loaded: false,
  loading: true,
  error: null,
};

export default function userReducer(state: AccountInterface = initialState, action: PayloadAction<any>) {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        user: action.payload,
        isAuthorized: true,
      };
    }
    case CHANGE_USER_ROLE: {
      return {
        ...state,
        user: {
          ...state.user,
          role: action.payload,
        },
      };
    }
    case LOGOUT_USER: {
      return {
        ...state,
        user: null,
        isAuthorized: false,
        loading: false,
      };
    }
    case CHECK_SESSION_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case CHECK_SESSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case CHECK_SESSION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
}

export function setUser(user: CognitoUser): PayloadAction<AccountModel> {
  return {
    type: SET_USER,
    payload: accountService.getUserInfo(user),
  };
}

export function changeUserRole(role: Role): PayloadAction<Role> {
  return {
    type: CHANGE_USER_ROLE,
    payload: role,
  };
}

export function logOutUser(): Action {
  accountService.logOutUser();
  return {
    type: LOGOUT_USER,
  };
}

export const checkSessionThunk = () => async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
  try {
    dispatch({ type: CHECK_SESSION_REQUEST, payload: {} });
    const cognitoUser = await accountService.getUser();
    if (cognitoUser) {
      dispatch(setUser(cognitoUser));
    } else {
      dispatch({ type: CHECK_SESSION_FAILURE, payload: new Error('No cognito user') });
    }
  } catch (err) {
    dispatch({ type: CHECK_SESSION_FAILURE, payload: err });
  }
};
