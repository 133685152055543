import React from 'react';
import { format } from 'date-fns';
import cx from 'classnames';

import { Table } from 'reactstrap';

import { DATE_PICKER } from 'constants/common';
import ActionButtons from 'common/Buttons/ActionButtons';
import SortButton from 'common/SortButton/SortButton';

import { useSort } from 'hooks/sortHook';
import sortHeaderDto from 'models/dto/sort.dto';
import HolidaysModel from '../../models/holidays.model';

import styles from '../tableStyles.module.scss';

type TProps = {
  data: HolidaysModel[];
  canEdit: boolean;
  headers: sortHeaderDto[];
  handleClickOnEdit: (id: number) => void;
  handleClickOnDelete: (id: number) => void;
};

const HolidaysTable: React.FC<TProps> = ({ data, canEdit, headers, handleClickOnEdit, handleClickOnDelete }) => {
  const { sortedData, sortKey, sortOrder, onSortingChange } = useSort({ data });

  return (
    <div className={styles.tableWrapper}>
      <Table responsive hover className={cx(styles.tableContainer, 'align-middle mb-0 custom-table')}>
        <thead>
          <tr>
            <th className={styles.firstCellWidth}>#</th>
            {headers?.map((item, index) => (
              <th key={index}>
                <SortButton
                  onClick={() => onSortingChange(item.value, item.sortType)}
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  sortField={item.value}
                  sortTitle={item.title}
                />
              </th>
            ))}
            {canEdit ? <th>Actions</th> : null}
          </tr>
        </thead>

        <tbody>
          {sortedData.map((holiday: HolidaysModel, index: number) => (
            <React.Fragment key={index}>
              <tr className={styles.removeBorder}>
                <td>{index + 1}</td>
                <td>{format(new Date(holiday.date), DATE_PICKER.dateFormatHoliday)}</td>
                <td>{holiday.duration}</td>
                <td>{holiday.title}</td>
                {canEdit ? (
                  <td>
                    <ActionButtons
                      handleClickOnEdit={() => handleClickOnEdit(holiday.id)}
                      handleClickOnDelete={() => handleClickOnDelete(holiday.id)}
                    />
                  </td>
                ) : null}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default HolidaysTable;
