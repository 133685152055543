import { createAsyncThunk } from '@reduxjs/toolkit';
import projectInitRequestsService from 'services/projectInitRequestsService';
import { RequestParams } from 'utils/mapParams';

export const getProjectInitRequests = createAsyncThunk(
  'projectInitRequests/getProjectInitRequests',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const res = await projectInitRequestsService.getProjectInitRequests(params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
