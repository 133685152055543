import httpService, { HttpService } from './httpService';
import { RequestParams } from '../utils/mapParams';

export class ProjectCalendarService {
  constructor(private _httpService: HttpService) {}

  async getProjectCalendar(params: Partial<RequestParams>) {
    const res = await this._httpService.get('/projects-calendar', {
      queryStringParameters: params,
    });
    return res;
  }
}

const projectCalendarService = new ProjectCalendarService(httpService);

export default projectCalendarService;
