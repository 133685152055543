import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';

import { EMPLOYEE_POSITION } from '../../../constants/employee';
import { Role } from 'constants/roles';
import { AppState } from 'store/configureStore';
import { getCVthunk } from 'store/CV';

import { ProfileHeaderT } from 'models/interfaces/employeeProfileProps';

import styles from './profileHeader.module.scss';

const ProfileHeaderInfo: FC<ProfileHeaderT> = ({ user, userId }) => {
  const dispatch = useDispatch();
  const { role } = useSelector((state: AppState) => state.account?.user);
  const { CVid } = useSelector((state: AppState) => state.cvReducer);

  const titleText = useMemo(() => {
    if (user?.isTechlead) return EMPLOYEE_POSITION.techLead;
    else if (user?.isTeamlead) return EMPLOYEE_POSITION.teamLead;
    else return user?.title;
  }, [user]);

  const isShowSalesGrade = [Role.sales, Role.vp, Role.delivery, Role.leadgen, Role.finance];
  const isShowCV = [Role.sales, Role.vp, Role.delivery, Role.leadgen, Role.finance, Role.teamlead];

  useEffect(() => {
    if (user?.emailPublic) {
      dispatch(getCVthunk(user.emailPublic));
    }
  }, [userId]);

  return (
    <div className={styles.textBlock}>
      <div>
        <div className={styles.name}>{user?.fullName}</div>
        <div className={styles.gradeWrap}>
          {titleText && <div className={styles.jobTitle}>{titleText}</div>}
          {user?.grade && <div className={styles[user?.grade.toLowerCase()]}>{user?.grade}</div>}
        </div>
      </div>

      {isShowCV.includes(role) && CVid && (
        <div className={styles.gradeWrap}>
          <a href={`https://cv.itomy.ch/api/cvs/dev/${CVid}?format=pdf`} target="_blank">
            <div className={styles.title}>CV</div>
          </a>
        </div>
      )}

      <div className={styles.teamWrap}>
        {user?.departmentTitle && (
          <div className={styles.wrap}>
            <div className={styles.label}>Department</div>
            <div className={styles.value}>{user?.departmentTitle}</div>
          </div>
        )}

        {user?.team?.title && (
          <div className={styles.wrap}>
            <div className={styles.label}>Team</div>
            <Link to={`/dashboard/team_profile/${user?.team?.id}`}>
              <div className={styles.value}>{user?.team?.title}</div>
            </Link>
          </div>
        )}

        {user?.salesGrade && isShowSalesGrade.includes(role) && (
          <div className={styles.wrap}>
            <div className={styles.label}>Sales Grade</div>
            <div className={styles.value}>{user?.salesGrade?.title}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileHeaderInfo;
