import PageTitle from '../../Layout/AppMain/PageTitle';
import Holidays from '../../components/Holidays/Holidays';
import { holidaysPageIcon } from 'constants/icons';

const HolidaysPage = () => {
  return (
    <>
      <PageTitle heading="Holidays" icon={holidaysPageIcon} />
      <Holidays />
    </>
  );
};

export default HolidaysPage;
