import { format } from 'date-fns';
import { DATE_PICKER } from '../constants/common';

export default function formatDatesFields(values: any, fields: string[]) {
  let formattedFields = {};
  for (const key in values) {
    if (fields.includes(key)) {
      formattedFields = {
        ...formattedFields,
        [key]: values[key] ? format(new Date(values[key]), DATE_PICKER.dateFormatToPayload) : null,
      };
    } else {
      formattedFields = {
        ...formattedFields,
        [key]: values[key],
      };
    }
  }
  return formattedFields;
}
