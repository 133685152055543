import { MainNavInterface } from '../models/interfaces/mainNavInterface';
import { Role } from '../constants/roles';

export const generateMenu = (userType: Role, menu: MainNavInterface[]): MainNavInterface[] => {
  return menu.reduce((res: MainNavInterface[], item: MainNavInterface): MainNavInterface[] => {
    if (userType && item.permissions.includes(userType)) {
      res.push({
        ...item,
        content: item?.content?.filter(subItem => subItem.permissions.includes(userType)),
      });
    }
    return res;
  }, []);
};
