import httpService, { HttpService } from './httpService';
import { PersonItemInterface } from '../models/dto/employee.dto';

type CreatePersonT = {
  person: PersonItemInterface;
};

type UpdatePersonT = {
  id: number | undefined;
  person: PersonItemInterface;
};

type DeletePersonT = {
  id: number | undefined;
};

export class ProfilePersonService {
  constructor(private _httpService: HttpService) {}

  async createPerson({ person }: CreatePersonT): Promise<PersonItemInterface> {
    return await this._httpService.post(`/persons`, {
      body: person,
    });
  }

  async updatePerson({ id, person }: UpdatePersonT): Promise<PersonItemInterface> {
    return await this._httpService.put(`/persons/${id}`, {
      body: person,
    });
  }

  async deletePerson({ id }: DeletePersonT): Promise<PersonItemInterface> {
    return await this._httpService.delete(`/persons/${id}`);
  }

  
}

export default new ProfilePersonService(httpService);
