import { RequestParams } from 'utils/mapParams';
import httpService, { HttpService } from './httpService';

export class ResourceRequestsService {
  constructor(private _httpService: HttpService) {}

  async getResourceRequests(params: Partial<RequestParams>) {
    const res = this._httpService.get('/resource-requests', { queryStringParameters: params });
    return res;
  }
}

const resourceRequestsService = new ResourceRequestsService(httpService);

export default resourceRequestsService;
