import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, startOfMonth } from 'date-fns';

import { Card, CardBody } from 'reactstrap';
import TeamLeadWorkforceTable from './TeamLeadWorkforceTable';
import TeamLeadWorkforceFooter from './TeamLeadWorkforceFooter';
import Datepicker from '../../components/Datepicker/Datepicker';
import Loader from '../../common/Loader';
import TeamFilter from 'common/TeamFilter/TeamFilter';

import { teamLeadWorkforce } from '../../store/employees/mappedEmployeesTeamLead.selector';
import { getEmployees, EVENT_EMPLOYEES_ACTION_TYPE } from '../../store/eventsEmployees';
import { AppState } from '../../store/configureStore';
import { RequestParams } from 'utils/mapParams';

import { Role } from '../../constants/roles';
import { DATE_PICKER } from '../../constants/common';
import { useQuery } from 'hooks/queryHook';

const actionType = {
  request: EVENT_EMPLOYEES_ACTION_TYPE.TEAM_LEAD_REQUEST,
  success: EVENT_EMPLOYEES_ACTION_TYPE.TEAM_LEAD_SUCCESS,
  failure: EVENT_EMPLOYEES_ACTION_TYPE.TEAM_LEAD_FAILURE,
};

const TeamLeadWorkforce: React.FC = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state: AppState) => state.eventsEmployees);
  const employees = useSelector(teamLeadWorkforce);
  const { user } = useSelector((state: AppState) => state.account);

  const { date, team, setDate, setTeam } = useQuery();

  const getData = useCallback(() => {
    if (!date) {
      setDate(format(startOfMonth(new Date()), DATE_PICKER.dateFormatToPayload));
    } else {
      const params: Partial<RequestParams> = {
        date: date,
        expand: 'finance',
      };

      if (team) {
        params.team = +team;
      }

      dispatch(getEmployees(params, actionType));
    }
  }, [dispatch, date, team, user.role, user.teams]);

  useEffect(() => {
    if (user.role === Role.teamlead && user.teams?.split(',')?.length >= 1 && !team) {
      setTeam(user.teams?.split(',')?.[0]);
    } else {
      getData();
    }
  }, [dispatch, getData]);

  const onDateChange = (date: Date) => {
    setDate(format(startOfMonth(date), DATE_PICKER.dateFormatToPayload));
  };

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className="bp-header">
          <div className="filters-block">
            <div className="dropdown-filter">
              <p className="label-wrapper">Date</p>
              <Datepicker
                selected={date ? new Date(date) : startOfMonth(new Date())}
                dateFormat={DATE_PICKER.dateFormatMonth}
                showMonthYearPicker
                onChange={(date: Date) => onDateChange(date)}
              />
            </div>

            {user.role === Role.vp && <TeamFilter team={team} setTeam={setTeam} />}

            {user.role === Role.teamlead && user.teams?.split(',')?.length > 1 && (
              <TeamFilter team={team} setTeam={setTeam} teamLeadTeams={user.teams} />
            )}
          </div>
        </div>

        {!!employees?.employees?.length && !loading ? (
          <>
            <TeamLeadWorkforceTable data={employees} />
            <TeamLeadWorkforceFooter data={employees} />
          </>
        ) : loading ? (
          <Loader />
        ) : (
          <div>Team profile is empty</div>
        )}
      </CardBody>
    </Card>
  );
};
export default TeamLeadWorkforce;
