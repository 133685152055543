import React, { FC, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import cx from 'classnames';

import { Table } from 'reactstrap';
import ProjectRow from './ProjectRow';
import Loader from 'common/Loader';
import MonthNavigationButtons from './MonthNavigationButtons';
import TodayLine from './TodayLine';

import { Month, ProjectAndPIRs, ProjectCalendarTableProps } from 'models/interfaces/projectCalendar.interface';

import tableStyles from '../../tableStyles.module.scss';
import styles from '../projectCalendarStyles.module.scss';

const ProjectCalendarTable: FC<ProjectCalendarTableProps> = ({
  projects,
  monthsList,
  hiddenProjects,
  setHiddenProjects,
  onMonthChange,
  openModal,
  canCreateRR,
  loading,
}) => {
  const cellRef = useRef<HTMLTableCellElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  const [linePosition, setLinePosition] = useState(0);
  const [tableWidth, setTableWidth] = useState(0);
  const [mouseHoverPosition, setMouseHoverPosition] = useState({ x: 0, y: 0 });

  const updateCurrentDayLinePosition = () => {
    const firstTableCellWidth = 250;
    let cellWidth = 0;

    if (cellRef.current) {
      cellWidth = cellRef.current.offsetWidth;
    }

    if (tableRef.current) {
      setTableWidth(tableRef.current.offsetWidth);
    }

    const isCurrentQuarterMonthIndex = monthsList.findIndex(
      (month: Month) => month.name === format(new Date(), 'MMMM')
    );

    if (isCurrentQuarterMonthIndex !== -1) {
      const currentDay: number = new Date().getDate();
      const lastDayOfCurrentMonth: number = new Date(monthsList[isCurrentQuarterMonthIndex].endDate).getDate();

      const cellsBeforeCurrentMonthWidth = cellWidth * isCurrentQuarterMonthIndex;

      const position =
        (cellWidth / lastDayOfCurrentMonth) * currentDay + firstTableCellWidth + cellsBeforeCurrentMonthWidth;

      setLinePosition(position);
    }
  };

  useEffect(() => {
    const table = tableRef.current;

    if (table) {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          if (entry.target === table) {
            updateCurrentDayLinePosition();
          }
        }
      });

      resizeObserver.observe(table);

      return () => {
        resizeObserver.unobserve(table);
      };
    }
  }, [cellRef.current]);

  const isCurrentQuarterMonth = monthsList.find((month: Month) => month.name === format(new Date(), 'MMMM'));

  return (
    <>
      <div
        ref={tableRef}
        className={cx(tableStyles.tableWrapper, styles.fixedHeader, 'mb-1')}
        style={{ overflow: 'unset', position: 'relative' }}
      >
        {loading && (
          <div className={styles.tableLoader}>
            <Loader />
          </div>
        )}

        <Table className={cx(tableStyles.tableContainer, styles.wrapTable, 'align-middle mb-0 custom-table text-left')}>
          <thead>
            {isCurrentQuarterMonth && (
              <TodayLine
                linePosition={linePosition}
                mouseHoverPosition={mouseHoverPosition}
                setMouseHoverPosition={setMouseHoverPosition}
              />
            )}

            <tr className={cx('text-center', styles.thBorderWrap)}>
              <th style={{ width: '250px' }}></th>

              {monthsList.map((month: Month, index: number) => (
                <th
                  key={month.name}
                  style={{ width: `${(tableWidth - 250) / 3}px` }}
                  ref={index === 0 && isCurrentQuarterMonth ? cellRef : null}
                >
                  {index === 0 && <MonthNavigationButtons onMonthChange={onMonthChange} />}
                  {month.name}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {projects?.map((project: Required<ProjectAndPIRs>, index: number) => (
              <React.Fragment key={index}>
                <ProjectRow
                  project={project}
                  monthsList={monthsList}
                  hiddenProjects={hiddenProjects}
                  setHiddenProjects={setHiddenProjects}
                  openModal={openModal}
                  canCreateRR={canCreateRR}
                />
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ProjectCalendarTable;
