import { CURRENCY, OBLIGATION_TYPE } from '../constants/obligations.constants';
import { ExpenseTypeInterface, ItemObligationInterface } from './interfaces/obligation.interface';

export default class ObligationModel {
  approvedBy?: number;
  employeeId: number;
  amount: string;
  currency: string;
  type: string;
  effectiveDate?: Date;
  expirationDate?: Date;
  expenseTypes: ExpenseTypeInterface[];
  id: number;
  syncedAt?: string;
  paymentDelay?: number;
  paymentFrequency?: number;
  confirmationDay: number;

  constructor(obligation: ItemObligationInterface) {
    this.approvedBy = obligation?.approvedBy;
    this.employeeId = obligation?.employeeId;
    this.amount = obligation?.amount || '';
    this.currency =
      obligation?.type.substr(0, 3) === 'kpi' && !obligation?.currency ? '%' : obligation?.currency || CURRENCY.usd;
    this.type = obligation?.type || OBLIGATION_TYPE.compensationFixed;
    this.effectiveDate = obligation?.effectiveDate ? new Date(obligation.effectiveDate) : undefined;
    this.expirationDate = obligation?.expirationDate ? new Date(obligation.expirationDate) : undefined;
    this.expenseTypes = obligation?.expenseTypes || [];
    this.id = obligation.id;
    this.syncedAt = obligation?.syncedAt;
    this.paymentDelay = obligation?.paymentDelay;
    this.paymentFrequency = obligation?.paymentFrequency;
    this.confirmationDay = obligation?.confirmationDay;
  }
}
