import { createAsyncThunk } from '@reduxjs/toolkit';
import projectCalendarService from 'services/projectCalendarService';
import { RequestParams } from 'utils/mapParams';

export const getProjectCalendar = createAsyncThunk(
  'projectCalendar/getProjectCalendar',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const res = await projectCalendarService.getProjectCalendar(params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
