import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, startOfMonth } from 'date-fns';

import { Alert, Card, CardBody } from 'reactstrap';
import { DropdownList } from 'react-widgets';

import Datepicker from 'components/Datepicker/Datepicker';
import EmployeesTable from 'components/WorkforceCosts/EmployeesTable';
import WorkforceCostsFooter from 'components/WorkforceCosts/WorkforceCostsFooter';
import TeamFilter from 'common/TeamFilter/TeamFilter';
import Loader from 'common/Loader';

import { AppState } from 'store/configureStore';
import { EVENT_EMPLOYEES_ACTION_TYPE, getEmployees, EmployeeInfoT } from 'store/eventsEmployees';
import EmployeeWorkforceModel from 'models/employeeWorkforce.model';
import { RequestParams } from 'utils/mapParams';

import { useQuery } from 'hooks/queryHook';
import { TOP_PERMISSIONS } from 'constants/roles';
import { WORKFORCE_COSTS_TABLE_HEADERS } from 'constants/peopleTableHeaders';
import { DATE_PICKER } from 'constants/common';

const actionType = {
  request: EVENT_EMPLOYEES_ACTION_TYPE.WORKFORCE_COSTS_REQUEST,
  success: EVENT_EMPLOYEES_ACTION_TYPE.WORKFORCE_COSTS_SUCCESS,
  failure: EVENT_EMPLOYEES_ACTION_TYPE.WORKFORCE_COSTS_FAILURE,
};

const WorkforceCosts: FC = () => {
  const dispatch = useDispatch();

  const role = useSelector((state: AppState) => state.account.user.role);

  const { allWorkforceEmployees, loading, error } = useSelector((state: AppState) => state.eventsEmployees);

  const { date, team, costs, setDate, setTeam, setCosts } = useQuery();

  const costCenter = ['All', 'Direct', 'Indirect'];

  const getEmployeesData = useCallback(() => {
    if (!date) {
      setDate(format(startOfMonth(new Date()), DATE_PICKER.dateFormatToPayload));
    } else {
      const params: Partial<RequestParams> = {
        date: date,
        expand: 'finance,sales,private',
      };

      if (team) {
        params.team = +team;
      }
      if (costs) {
        params.costs = costs;
      }

      dispatch(getEmployees(params, actionType));
    }
  }, [dispatch, date, team, costs]);

  useEffect(() => {
    getEmployeesData();
  }, [dispatch, getEmployeesData]);

  const onDateChange = (date: Date) => {
    setDate(format(startOfMonth(date), DATE_PICKER.dateFormatToPayload));
  };

  const onCostsChange = (value: string) => {
    setCosts(value === 'All' ? '' : value.toLowerCase());
  };

  const employeesData: EmployeeWorkforceModel[] = allWorkforceEmployees?.employees.map((item: EmployeeInfoT) => {
    return new EmployeeWorkforceModel({
      address: item.privateInfo?.address,
      dismissalDate: item.dismissalDate,
      email: item.email,
      hasStartedThisMonth: item.hasStartedThisMonth,
      hasUnapprovedObligations: item.hasUnapprovedObligations,
      id: item.id,
      isEngineeringlead: item.isEngineeringlead,
      isTeamlead: item.isTeamlead,
      isTechlead: item.isTechlead,
      isDirectCost: item.isDirectCost,
      location: item.location,
      position: item.position,
      salaryPrediction: item.salaryPrediction,
      salesGrade: item.salesGrade?.title,
      startDate: item.startDate,
      team: item.team?.title,
      timeByMonth: item.timeByMonth,
      title: item.title,
    });
  });

  const workforceEmployeesTable = useMemo((): JSX.Element | null => {
    if (role) {
      return TOP_PERMISSIONS.includes(role) ? (
        <EmployeesTable data={employeesData || []} headers={WORKFORCE_COSTS_TABLE_HEADERS} isSalesGrade={true} />
      ) : (
        <Alert text="Error. You don't have access!" isHiddenCloseButton />
      );
    }
    return null;
  }, [role, allWorkforceEmployees, loading, error, costs]);

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className="bp-header">
          <div className="filters-block">
            <div className="dropdown-filter">
              <p className="label-wrapper">Date</p>
              <Datepicker
                selected={date ? new Date(date) : startOfMonth(new Date())}
                dateFormat={DATE_PICKER.dateFormatMonth}
                showMonthYearPicker
                onChange={onDateChange}
              />
            </div>

            <TeamFilter team={team} setTeam={setTeam} />

            <div className="dropdown-filter">
              <p className="label-wrapper">Cost Center</p>
              <DropdownList value={costs} data={costCenter} placeholder="All" onChange={onCostsChange} busy={error} />
            </div>
          </div>
        </div>

        {allWorkforceEmployees?.employees.length ? (
          <>
            {workforceEmployeesTable}
            <WorkforceCostsFooter data={allWorkforceEmployees} />
          </>
        ) : loading ? (
          <Loader />
        ) : (
          <div>Workforce costs are empty</div>
        )}
      </CardBody>
    </Card>
  );
};

export default WorkforceCosts;
