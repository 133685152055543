import { createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from '../configureStore';
import ExpenseTypeModel from '../../models/expenseType.model';
import expenseTypeService from '../../services/expenseTypeService';

interface ExpenseTypeState {
  loading: boolean;
  expenseTypes: ExpenseTypeModel[] | null;
  error: Error | null;
}

const initialState: ExpenseTypeState = {
  loading: false,
  expenseTypes: null,
  error: null,
};

export const expenseTypesSlice = createSlice({
  name: 'expense type',
  initialState,
  reducers: {
    getExpenseTypesRequest: (state: ExpenseTypeState) => {
      return {
        ...state,
        loading: true,
        expenseTypes: null,
        error: null,
      };
    },
    getExpenseTypesSuccess: (state: ExpenseTypeState, action: PayloadAction<ExpenseTypeModel[]>) => {
      return {
        ...state,
        loading: false,
        expenseTypes: action.payload,
        error: null,
      };
    },
    getExpenseTypesFailure: (state: ExpenseTypeState, action: PayloadAction<Error>) => {
      return {
        ...state,
        loading: false,
        expenseTypes: null,
        error: action.payload,
      };
    },
  },
});

export const { getExpenseTypesRequest, getExpenseTypesSuccess, getExpenseTypesFailure } = expenseTypesSlice.actions;

export const getExpenseTypesThunk = () => async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
  dispatch(getExpenseTypesRequest());
  try {
    const expenseTypes: ExpenseTypeModel[] = await expenseTypeService.getExpenseTypesList();
    dispatch(getExpenseTypesSuccess(expenseTypes));
  } catch (err) {
    dispatch(getExpenseTypesFailure(err as Error));
  }
};

export default expenseTypesSlice.reducer;
