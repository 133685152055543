import httpService, { HttpService } from './httpService';
import { ContactItemInterface, PersonItemInterface } from '../models/dto/employee.dto';
import profilePersonService from './profilePersonService';

type CreateContactT = {
  type: string;
  contact: ContactItemInterface;
};

type UpdateContactT = {
  id: number | undefined;
  type: string;
  contact: ContactItemInterface;
};

type DeleteContactT = {
  id: number | undefined;
  type: string;
};

export class ProfileContactService {
  constructor(private _httpService: HttpService) {}

  async createContact({ type, contact }: CreateContactT) {
    if (type === 'children') {
      const person: PersonItemInterface = {
        employeeId: contact.employeeId,
        name: contact.value,
        type: 'child'
      }
      return await profilePersonService.createPerson({person});
    } else {
      return await this._httpService.post(`/contacts/${type}`, {
        body: contact,
      });
    }
  }

  async updateContact({ id, type, contact }: UpdateContactT){
    if (type === 'children') {
      const person: PersonItemInterface = {
        employeeId: contact.employeeId,
        name: contact.value,
        type: 'child'
      }
      return await profilePersonService.updatePerson({id,person});
    } else {
      return await this._httpService.put(`/contacts/${type}/${id}`, {
        body: contact,
      });
    }
  }

  async deleteContact({ id, type }: DeleteContactT) {
    if (type === 'children') {
      return await profilePersonService.deletePerson({id});
    } else {
      return await this._httpService.delete(`/contacts/${type}/${id}`);
    }
  }

  
}

export default new ProfileContactService(httpService);
