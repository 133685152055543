import React, { FC } from 'react';
import { useField } from 'formik';
import InputMask from 'react-input-mask';

import styles from '../Formik.module.scss';

const clearPhone = (value: string) => value.replace(/[^0-9+]/g, '');

type FormikInputMaskT = {
  className?: string;
  label?: string;
  placeholder?: string;
  name: string;
  disabled?: boolean;
  mask: string;
};

const FormikInputMask: FC<FormikInputMaskT> = ({ className, label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { error } = meta;

  return (
    <div className={className}>
      {label && <p>{label}</p>}
      <InputMask
        {...field}
        {...props}
        onChange={event => {
          helpers.setValue(clearPhone(event.target.value));
        }}
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default FormikInputMask;
