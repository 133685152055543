import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import cx from 'classnames';
import get from 'lodash.get';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import { DropdownList } from 'react-widgets';
import DatePicker from 'react-datepicker';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Card, CardBody } from 'reactstrap';
import ButtonsSave from './ButtonsSave';
import Loader from 'common/Loader';

import { getTeamTypes } from 'store/teamTypes/teamTypes.thunk';
import { AppState } from 'store/configureStore';

import TeamModel from 'models/team.model';
import { MemberInterface } from 'models/member.model';
import { EmployeeDto } from 'models/dto/employee.dto';
import { TeamTypeT } from 'store/teamTypes';
import { DatePickerT } from 'components/Datepicker/Datepicker';

import { getValue, getFullName, getParentTeams } from 'helpers/companyStructureHeplers';
import { calendar } from 'constants/icons';

import styles from './teamEditing.module.scss';
import './multiselectCheckboxes.scss';

type PropsT = {
  team?: TeamModel;
  employees: EmployeeDto[];
  teamEmployees?: EmployeeDto[];
  onClose(): void;
  buttonName: string;
  teamLead?: EmployeeDto;
  onSubmit(values: TeamModel | Omit<TeamModel, 'id'>): void;
  teams: TeamModel[];
};

const EDITING_SCHEMA = Yup.object({
  title: Yup.string().required('Required field').max(128, 'Maximum 128 characters'),
  typeId: Yup.number().required('Required field'),
  email: Yup.string().email('incorrect email address').max(128, 'Maximum 128 characters'),
  isAvailableForPlanning: Yup.bool(),
  openDate: Yup.string().nullable(),
  closeDate: Yup.string().nullable(),
  parentId: Yup.number().nullable(),
  teamleadId: Yup.mixed(),
  members: Yup.array().of(Yup.object()),
  children: Yup.array().of(Yup.object()),
});

export const TeamEditing: FC<PropsT> = ({
  team,
  employees,
  teamEmployees,
  onClose,
  buttonName,
  teamLead,
  onSubmit,
  teams,
}) => {
  const dispatch = useDispatch();
  const closeDateRef = useRef<DatePickerT>();
  const openDateRef = useRef<DatePickerT>();

  const { teamTypes, loading }: { teamTypes: TeamTypeT[]; loading: boolean } = useSelector(
    (state: AppState) => state.teamTypesReducer
  );

  useEffect(() => {
    dispatch(getTeamTypes());
  }, []);

  const formik = useFormik({
    initialValues: {
      title: get(team, 'title') || '',
      typeId: team?.type?.id || '',
      email: get(team, 'email') || '',
      isAvailableForPlanning: get(team, 'isAvailableForPlanning', false),
      openDate: get(team, 'openDate') ? new Date(get(team, 'openDate')!) : undefined,
      closeDate: get(team, 'closeDate') ? new Date(get(team, 'closeDate')!) : undefined,
      parentId: get(team, 'parentId') || '',
      teamleadId: get(team, 'teamleadId') || undefined,
      members: teamEmployees || [],
      children: team?.children || [],
      teamLead: teamLead || undefined,
      confluencePageId: get(team, 'confluencePageId') || undefined,
      confluenceTeamleadsGroup: get(team, 'confluenceTeamleadsGroup') || undefined,
    },
    validationSchema: EDITING_SCHEMA,
    onSubmit: values => {
      const openDate = values.openDate ? format(new Date(values.openDate), 'yyyy-MM-dd') : values.openDate;
      const closeDate = values.closeDate ? format(new Date(values.closeDate), 'yyyy-MM-dd') : values.closeDate;
      const email = values.email ? values.email : null;

      const submitValues: Omit<TeamModel, 'id'> = {
        ...values,
        email,
        typeId: +values.typeId,
        openDate,
        closeDate,
        confluencePageId: values.confluencePageId || null,
        confluenceTeamleadsGroup: values.confluenceTeamleadsGroup || null,
        parentId: +values.parentId || null,
      };

      if (team?.id) {
        onSubmit({ ...submitValues, id: team.id });
      } else {
        onSubmit(submitValues);
      }
    },
  });

  const membersValue: MemberInterface<EmployeeDto>[] = useMemo(
    () => getValue(formik.values.members, getFullName),
    [formik.values.members]
  );
  const membersOptions: MemberInterface<EmployeeDto>[] = useMemo(() => getValue(employees, getFullName), [employees]);

  const childTeamsValue: MemberInterface<TeamModel>[] = useMemo(
    () => getValue(formik.values.children, (value: TeamModel) => get(value, 'title')),
    [formik.values.children]
  );
  const childTeamsOptions: MemberInterface<TeamModel>[] = useMemo(() => {
    if (team) {
      return getValue(
        teams.filter(item => ![team.id, ...getParentTeams(team?.parent)].includes(item.id)),
        (value: TeamModel) => get(value, 'title')
      );
    } else {
      return getValue(teams, (value: TeamModel) => get(value, 'title'));
    }
  }, [team]);

  const currentTeamType = teamTypes.find(item => item.id === team?.type?.id);

  const managerTitle = useMemo(() => {
    if (formik.values.typeId) {
      const selectedTeamType = teamTypes.find(type => type.id === formik.values.typeId);
      return selectedTeamType?.managerTitle || 'Team Lead';
    } else {
      return 'Team Lead';
    }
  }, [formik.values.typeId]);

  const isShowError = (field: string) => get(formik.errors, field) && get(formik.touched, field);

  const setFormikValue = <T,>(field: string, value: T) => formik.setFieldValue(field, value);
  const removeMember = (id: number) =>
    setFormikValue(
      'members',
      formik.values.members.filter(item => item.id !== id)
    );

  const removeChildTeam = (id: number) =>
    setFormikValue(
      'children',
      formik.values.children.filter(item => item.id !== id)
    );

  const [isRemoveTL, setIsRemoveTL] = useState(false);
  const [currTL, setCurrTL] = useState(teamLead);

  const changeTeamLead = (value: EmployeeDto | null) => {
    if (!value) {
      setFormikValue('teamleadId', null);
      setIsRemoveTL(true);
    } else {
      setFormikValue('teamleadId', value.id);
      setIsRemoveTL(false);
      setCurrTL(value);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Card className="main-card mb-3" style={{ position: 'static' }}>
      <CardBody>
        <form className={styles.formWrap} onSubmit={formik.handleSubmit}>
          <ButtonsSave btnRed="cancel" btnBlue={buttonName} close={onClose} />

          <div className={styles.generalInfo}>
            <div className={styles.generalInfoItem}>
              <span className={styles.label}>Team Name</span>
              <div className={styles.inputWrap}>
                <input
                  type="text"
                  name="title"
                  placeholder="Team name"
                  className={cx(styles.formInput, { [styles.hasError]: isShowError('title') })}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                {isShowError('title') ? <div className={styles.errorMsg}>{formik.errors.title}</div> : null}
              </div>
            </div>
            <div className={styles.generalInfoItem}>
              <span className={styles.label}>Team Type</span>
              <div className={styles.inputWrap}>
                <DropdownList
                  containerClassName={cx(styles.selectInput, { [styles.hasError]: isShowError('typeId') })}
                  data={teamTypes}
                  defaultValue={currentTeamType}
                  textField="title"
                  onChange={value => setFormikValue('typeId', value.id)}
                  placeholder="Select team type"
                />
                {isShowError('typeId') ? <div className={styles.errorMsg}>{formik.errors.typeId}</div> : null}
              </div>
            </div>
            <div className={styles.generalInfoItem}>
              <span className={styles.label}>Team Email</span>
              <div className={styles.inputWrap}>
                <input
                  type="email"
                  name="email"
                  placeholder="Team email"
                  className={cx(styles.formInput, { [styles.hasError]: isShowError('email') })}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {isShowError('email') ? <div className={styles.errorMsg}>{formik.errors.email}</div> : null}
              </div>
            </div>
            <div className={styles.checkboxWrap}>
              <input
                type="checkbox"
                name="isAvailableForPlanning"
                onChange={formik.handleChange}
                checked={formik.values.isAvailableForPlanning}
                className={styles.checkbox}
              />
              <span className={styles.checkboxLabel}>Is Team available for billable hours planning</span>
            </div>
            <div className={styles.generalInfoItem}>
              <span className={styles.label}>Open Date</span>
              <div className={styles.inputWrap}>
                <div className={styles.datePickerWrap}>
                  <DatePicker
                    ref={openDateRef}
                    name="openDate"
                    className={cx(styles.formInput, styles.datePicker)}
                    onChange={(value: Date) => setFormikValue('openDate', value)}
                    onBlur={formik.handleBlur}
                    dateFormat="yyyy/MM/dd"
                    showYearDropdown
                    selected={formik.values.openDate}
                    placeholderText="yyyy/mm/dd"
                    autoComplete="off"
                  />
                  <div className="calendarIcon" onClick={() => openDateRef.current?.setOpen(true)}>
                    {calendar}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.generalInfoItem}>
              <span className={styles.label}>Close Date</span>
              <div className={styles.inputWrap}>
                <div className={styles.datePickerWrap}>
                  <DatePicker
                    ref={closeDateRef}
                    name="closeDate"
                    className={cx(styles.formInput, styles.datePicker)}
                    onChange={(value: Date) => setFormikValue('closeDate', value)}
                    onBlur={formik.handleBlur}
                    dateFormat="yyyy/MM/dd"
                    showYearDropdown
                    selected={formik.values.closeDate}
                    minDate={formik.values.openDate}
                    placeholderText="yyyy/mm/dd"
                    autoComplete="off"
                  />
                  <div className="calendarIcon" onClick={() => closeDateRef.current?.setOpen(true)}>
                    {calendar}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.generalInfoItem}>
              <span className={styles.label}>Parent Team</span>
              <div className={styles.inputWrap}>
                <DropdownList
                  containerClassName={cx(styles.selectInput, { [styles.hasError]: isShowError('parentId') })}
                  data={teams}
                  value={teams?.find(item => item.id === formik.values.parentId)}
                  textField="title"
                  onChange={value => setFormikValue('parentId', value.id)}
                  filter="contains"
                  placeholder="Select parent team"
                />
                <FontAwesomeIcon
                  onClick={() => setFormikValue('parentId', null)}
                  icon={faTimes}
                  className={styles.isRemoveTL}
                />
                {isShowError('parentId') ? <div className={styles.errorMsg}>{formik.errors.parentId}</div> : null}
              </div>
            </div>
            <div className={styles.generalInfoItem}>
              <span className={styles.label}>{managerTitle}</span>
              <div className={styles.inputWrap}>
                <DropdownList
                  containerClassName={styles.selectInput}
                  defaultValue={teamLead}
                  filter="contains"
                  data={employees}
                  placeholder={`Select ${managerTitle}`}
                  textField={item => (item ? getFullName(item) : 'lastName')}
                  onChange={employee => {
                    changeTeamLead(employee);
                  }}
                  value={isRemoveTL ? false : currTL}
                />
                <FontAwesomeIcon onClick={() => changeTeamLead(null)} icon={faTimes} className={styles.isRemoveTL} />
              </div>
            </div>
            <div className={styles.generalInfoItem}>
              <span className={styles.label}>Confluence TL group</span>
              <div className={styles.inputWrap}>
                <input
                  type="text"
                  name="confluenceTeamleadsGroup"
                  placeholder="Confluence team leads group"
                  className={cx(styles.formInput, { [styles.hasError]: isShowError('confluenceTeamleadsGroup') })}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confluenceTeamleadsGroup}
                />
                {isShowError('confluenceTeamleadsGroup') ? (
                  <div className={styles.errorMsg}>{formik.errors.confluenceTeamleadsGroup}</div>
                ) : null}
              </div>
            </div>
            <div className={styles.generalInfoItem}>
              <span className={styles.label}>Confluence Page ID</span>
              <div className={styles.inputWrap}>
                <input
                  type="text"
                  name="confluencePageId"
                  placeholder="Confluence Page ID"
                  className={cx(styles.formInput, { [styles.hasError]: isShowError('confluencePageId') })}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confluencePageId}
                />
                {isShowError('confluencePageId') ? (
                  <div className={styles.errorMsg}>{formik.errors.confluencePageId}</div>
                ) : null}
              </div>
            </div>
          </div>

          <div>
            <div className={styles.membersWrap}>
              <span className={cx(styles.label, 'mt-2 mr-2')}>Team Members</span>
              <div className={styles.selectWrap}>
                <ReactMultiSelectCheckboxes
                  value={membersValue}
                  options={membersOptions}
                  onChange={(value: MemberInterface<EmployeeDto>[]) =>
                    setFormikValue(
                      'members',
                      value.map(item => ({ ...item.data }))
                    )
                  }
                />
                <div className={styles.membersContainer}>
                  {formik.values.members &&
                    formik.values.members.map(member => (
                      <div className={styles.membersItem} key={member.id}>
                        {getFullName(member)}
                        <span className={styles.removeMember} onClick={() => removeMember(member.id)}>
                          &#10005;
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className={cx(styles.membersWrap, 'mt-5')}>
              <span className={cx(styles.label, 'mt-2 mr-2')}>Child Teams</span>
              <div className={styles.selectWrap}>
                <ReactMultiSelectCheckboxes
                  value={childTeamsValue}
                  options={childTeamsOptions}
                  onChange={(value: MemberInterface<TeamModel>[]) =>
                    setFormikValue(
                      'children',
                      value.map(item => ({ ...item.data }))
                    )
                  }
                />
                <div className={styles.membersContainer}>
                  {formik.values.children &&
                    formik.values.children.map(childTeam => (
                      <div className={styles.membersItem} key={childTeam.id}>
                        {childTeam.title}
                        <span className={styles.removeMember} onClick={() => removeChildTeam(childTeam.id)}>
                          &#10005;
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};
