import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import FormikDropdown from 'common/Formik/FormikDropdown';

import { AppState } from 'store/configureStore';
import { getEmployeeListThunk } from 'store/employees';

import { getFullName } from 'helpers/companyStructureHeplers';
import { currentDay } from 'helpers/formatTime';
import EmployeeListModel from 'models/employeeList.model';
import EmployeeModel from 'models/employee.model';

import styles from '../../../modal.module.scss';

type PropsType = {
  onSubmit: (values: { employee: EmployeeModel | null }) => void;
  onClose: () => void;
};

const ADD_EMPLOYEE_SCHEMA = Yup.object({
  employee: Yup.object().nullable().required('Field required'),
});

const CreateEmployeePRModal: React.FC<PropsType> = ({ onSubmit, onClose }) => {
  const dispatch = useDispatch();

  const { employees }: EmployeeListModel = useSelector((state: AppState) => state.employeesReducer);

  useEffect(() => {
    dispatch(getEmployeeListThunk({ date: currentDay() }));
  }, []);

  return (
    <Formik
      initialValues={{
        employee: null,
      }}
      validationSchema={ADD_EMPLOYEE_SCHEMA}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {() => (
        <Form>
          <div className="filters-block">
            <div className="dropdown-filter" style={{ maxWidth: '375px' }}>
              <p className="label-wrapper">Employee</p>
              <FormikDropdown
                name="employee"
                placeholder="Search and select employee from list"
                data={employees}
                textField={getFullName}
                busy={!employees}
                addFilter={true}
              />
            </div>
          </div>

          <div className={styles.buttonWrapper}>
            <button onClick={onClose} className={styles.blueLineButton} type="button">
              Cancel
            </button>
            <button className={styles.blueButton} type="submit">
              Add
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default CreateEmployeePRModal;
