import { ItemsOutInterface } from './dto/bdm.dto';

class AvailableHoursModel {
  items: ItemsOutInterface[];
  totals: {
    working: number;
    available: number;
  };

  constructor(data: AvailableHoursModel) {
    this.items = data.items;
    this.totals = data.totals;
  }
}

export default AvailableHoursModel;
