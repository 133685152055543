import PageTitle from '../../Layout/AppMain/PageTitle';
import TeamBudget from 'components/TeamBudget/TeamBudget';
import { teamBudgetIcon } from 'constants/icons';

const TeamBudgetPage = () => {
  return (
    <>
      <PageTitle heading="Team Budget" icon={teamBudgetIcon} />
      <TeamBudget />
    </>
  );
};

export default TeamBudgetPage;
