import { AppState } from '../configureStore';
import { createSelector } from 'reselect';

import EmployeeExtendsVsdModel from '../../models/employeeExtendsVsdModel';
import IssueModel from '../../models/issue.model';
import { currentDay } from '../../helpers/formatTime';
import { ISSUE_STATUS } from '../../constants/issues.constants';
import { EmployeeProfileModel } from '../../models/employeeProfile.model';

const employeeState = (state: AppState) => state.employeeReducer.employee;
const issuesState = (state: AppState) => state.issuesReducer.issuesObject;

export const mappedProfile = createSelector(
  employeeState,
  issuesState,
  (employee: EmployeeProfileModel, issuesObject): EmployeeExtendsVsdModel | undefined => {
    if (!employee) return undefined;

    const vsd = issuesObject?.issues?.[employee.atlassianId];

    const closedVsd = vsd?.filter(
      (item: IssueModel) => item.endDate <= currentDay() && item.status === ISSUE_STATUS.closed
    );

    const plannedVsd = vsd?.filter(
      (item: IssueModel) =>
        item.endDate > currentDay() && item.endDate <= currentDay(3) && item.status !== ISSUE_STATUS.closed
    );

    const totalPlannedVsd = issuesObject?.totalVsd?.totalPlaned;

    return employee ? new EmployeeExtendsVsdModel(employee, closedVsd, plannedVsd, totalPlannedVsd) : undefined;
  }
);
