import { ItemDealGroupsOutInterface } from './dto/bdm.dto';

class DealGroupsModel {
  groupName: string;
  items: ItemDealGroupsOutInterface[];

  constructor(data: DealGroupsModel) {
    this.groupName = data.groupName;
    this.items = data.items;
  }
}

export default DealGroupsModel;
