import { EmployeeDto } from 'models/dto/employee.dto';
import { MemberInterface } from 'models/member.model';
import TeamModel from 'models/team.model';

export const getValue = <T extends { id: number }>(value: T[], callback: (item: T) => string): MemberInterface<T>[] =>
  value.map((item: T) => ({
    label: callback(item),
    value: item.id,
    data: { ...item },
  }));

export const getFullName = (item: Partial<EmployeeDto>) => (item ? `${item.firstName} ${item.lastName}` : '');

export const getMembers = (teamMembers?: EmployeeDto[], teamId?: TeamModel['id']) => {
  const updatedMembers: { id: number; employee: Partial<EmployeeDto> }[] = [];

  teamMembers?.forEach(member => {
    const data = {
      id: member.id,
      employee: { teamId: teamId },
    };
    updatedMembers.push(data);
  });

  return updatedMembers;
};

export const getParentTeams = (parent?: TeamModel | null) => {
  const parentTeams = [];
  let current = parent;

  while (current) {
    parentTeams.push(current.id);
    current = current.parent;
  }

  return parentTeams;
};

export const sortTeamsData = (teams: TeamModel[]) => {
  let teamWithManyNestedTeams: TeamModel['id'] | null = null;

  const rootTeams = teams?.filter((team: TeamModel) => !team.parentId);
  const sortedTeams = rootTeams?.map((sortedTeam: TeamModel) => {
    let hasChildWithChildren = false;
    const sortedChildTeams = sortedTeam?.children?.reduce((acc: TeamModel[], child: TeamModel) => {
      if (child.children?.length) {
        if (!teamWithManyNestedTeams) {
          const childWithChildTeams = child.children?.length;
          if (childWithChildTeams) {
            teamWithManyNestedTeams = sortedTeam.id;
          }
        }
        hasChildWithChildren = true;
        acc.push(child);
      } else if (hasChildWithChildren) {
        acc.unshift(child);
      } else {
        acc.push(child);
      }

      return acc;
    }, []);
    sortedTeam.children = sortedChildTeams;
    return sortedTeam;
  });

  if (teamWithManyNestedTeams) {
    const teamsAndChildren = sortedTeams.filter(team => team.id !== teamWithManyNestedTeams);
    const teamWithChildren = sortedTeams.find(team => team.id === teamWithManyNestedTeams);
    return { teamWithChildren, sortedTeams: teamsAndChildren };
  }

  return { teamWithChildren: null, sortedTeams };
};

export const getChildTeamsIds = (data: TeamModel[]) => {
  let ids: TeamModel['id'][] = [];

  const collectIds = (items: TeamModel[] | TeamModel) => {
    if (Array.isArray(items)) {
      items.forEach(item => collectIds(item));
    } else if (items) {
      ids.push(items.id);

      if (items.children) {
        collectIds(items.children);
      }
    }
  };

  collectIds(data);
  return ids;
};
