import React, { Fragment } from 'react';

import PageTitle from '../../Layout/AppMain/PageTitle';
import TeamProfile from 'components/CompanyStructure/TeamProfile/TeamProfile';
import { teamProfileIcon } from 'constants/icons';

const TeamProfilePage = () => {
  return (
    <Fragment>
      <PageTitle heading="Team Profile" icon={teamProfileIcon} />
      <TeamProfile />
    </Fragment>
  );
};

export default TeamProfilePage;
