import { createAsyncThunk } from '@reduxjs/toolkit';
import { CategoryInitialState } from 'models/interfaces/companyExpenses.interface';
import expenseCategoriesService from 'services/expenseCategoriesService';
import { NotificationType } from 'services/notificationService';
import { enqueueSnackbar } from 'store/notifications';

export const getExpensesCategories = createAsyncThunk(
  'expensesCategories/getExpensesCategories',
  async (params: { page: number; size: number; enabled?: boolean }, thunkAPI) => {
    try {
      const res = await expenseCategoriesService.getExpenseCategories(params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const getExpenseCategoryById = createAsyncThunk(
  'expenseCategories/getExpenseCategoryById',
  async (id: number, thunkAPI) => {
    try {
      const res = await expenseCategoriesService.getExpenseCategoryById(id);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const createExpenseCategory = createAsyncThunk(
  'expenseCategories/createExpenseCategory',
  async (params: CategoryInitialState, thunkAPI) => {
    try {
      const res = await expenseCategoriesService.createExpenseCategory(params);
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Expense Category created successfully!',
            title: 'Expense Category',
          },
        })
      );
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const updateExpenseCategory = createAsyncThunk(
  'expenseCategories/updateExpenseCategory',
  async (params: { id: number; value: CategoryInitialState; isEnabled: boolean }, thunkAPI) => {
    try {
      const res = await expenseCategoriesService.updateExpenseCategory(params);
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Expense Category updated successfully!',
            title: 'Expense Category',
          },
        })
      );
      return params;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const disableExpenseCategory = createAsyncThunk(
  'expenseCategories/disableExpenseCategory',
  async (params: { id: number; isEnabled: boolean }, thunkAPI) => {
    try {
      const res = await expenseCategoriesService.disableExpenseCategory(params);
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Expense Category updated successfully!',
            title: 'Expense Category',
          },
        })
      );
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const deleteExpenseCategory = createAsyncThunk(
  'expenseCategories/deleteExpenseCategory',
  async (id: number, thunkAPI) => {
    try {
      const errorHandler = (err: any, handler: (err: Error) => void) => {
        if (
          err.response.data.message ===
          `[BadRequest] You can't remove the 'Category Name' because it is linked to one or more expenses`
        ) {
          throw err;
        } else handler(err);
      };

      await expenseCategoriesService.deleteExpenseCategory(id, errorHandler);
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Expense Category deleted successfully!',
            title: 'Expense Category',
          },
        })
      );
      return id;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err);
    }
  }
);
