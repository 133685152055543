import PageTitle from '../../Layout/AppMain/PageTitle';
import OvertimeRequests from 'components/OvertimeRequestDashboard/OvertimeRequests';
import { overtimeRequestIcon } from 'constants/icons';

const OvertimeRequestsPage = () => {
  return (
    <>
      <PageTitle heading="Overtime Requests" icon={overtimeRequestIcon} />
      <OvertimeRequests />
    </>
  );
};

export default OvertimeRequestsPage;
