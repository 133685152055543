import { HolidayDto } from '../dto/holidays.dto';
import HolidaysModel from '../holidays.model';
import { formatDurationHoliday } from '../../utils/formatDatas';

export default class HolidaysAdapter {
  static mapHolidaysDtoToHolidaysModel(holidays: HolidayDto[]): HolidaysModel[] {
    return holidays.map(
      holiday =>
        new HolidaysModel({
          id: holiday.id,
          title: holiday.title,
          duration: formatDurationHoliday(holiday.workingTimeSeconds),
          date: holiday.date,
        })
    );
  }

  static mapHolidayDtoToHolidayModel(holiday: HolidayDto): HolidaysModel {
    return new HolidaysModel({
      id: holiday.id,
      title: holiday.title,
      duration: formatDurationHoliday(holiday.workingTimeSeconds),
      date: holiday.date,
    });
  }
}
