import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfYear, format, startOfYear } from 'date-fns';
import { createPortal } from 'react-dom';
import cx from 'classnames';

import { Card, CardBody } from 'reactstrap';
import PerformanceReviewTable from './PerformanceReviewTable';
import ChangeStatusPRModal from './Modals/ChangeStatusPRModal';
import CreatePRModal from './Modals/CreatePRModal';
import Loader from 'common/Loader';

import {
  changeStatusPerformanceReview,
  createPerformanceReview,
  deletePerformanceReview,
  getPerformanceReviews,
  updatePerformanceReview,
} from 'store/performanceReview/performanceReview.thunk';
import { setStatePerfomanceRequest } from 'store/performanceReview';
import { AppState } from '../../../store/configureStore';

import { DATE_PICKER } from 'constants/common';
import { Role } from '../../../constants/roles';
import {
  CommandPerformanceReview,
  PERFORMANCE_REVIEW_TABLE_HEADERS,
  StatusPerformanceReview,
} from 'constants/performanceReview';
import { arrowLeft, arrowRight, plus } from 'constants/icons';
import { InitialCreatePRDto } from 'models/dto/performanceReview.dto';
import { useQuery } from 'hooks/queryHook';

import styles from '../../tableStyles.module.scss';

const PerformanceReview: React.FC = () => {
  const dispatch = useDispatch();

  const { role } = useSelector((state: AppState) => state.account.user);
  const { performanceReviews, performanceReview, loading, openChangeStatusPR } = useSelector(
    (state: AppState) => state.performanceReviewReducer
  );

  const accessEdit = useMemo(() => role === Role.hr || role === Role.vp, [role]);

  const { fromDate, toDate, setFromToDate } = useQuery();

  const [open, setOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState(
    fromDate ? new Date(fromDate).getFullYear() : new Date().getFullYear()
  );

  const getData = useCallback(() => {
    if (!fromDate || !toDate) {
      setFromToDate(
        format(startOfYear(new Date()), DATE_PICKER.dateFormatToPayload),
        format(endOfYear(new Date()), DATE_PICKER.dateFormatToPayload)
      );
    } else {
      dispatch(getPerformanceReviews({ fromDate, toDate }));
    }
  }, [fromDate, toDate, dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onChangeYear = (direction: string) => {
    const currentDate = new Date(fromDate);
    const selectedDate = direction === 'next' ? currentDate.getFullYear() + 1 : currentDate.getFullYear() - 1;

    const selectedYearDate = currentDate.setFullYear(selectedDate);
    const selectedYearStartDate = format(startOfYear(new Date(selectedYearDate)), DATE_PICKER.dateFormatToPayload);
    const selectedYearEndDate = format(endOfYear(new Date(selectedYearDate)), DATE_PICKER.dateFormatToPayload);

    setCurrentYear(new Date(selectedYearDate).getFullYear());
    setFromToDate(selectedYearStartDate, selectedYearEndDate);
  };

  const onSavePR = useCallback(
    (values: InitialCreatePRDto) => {
      const data = {
        ...values,
        startDate: format(new Date(values.startDate), DATE_PICKER.dateFormatToPayload),
      };

      if (!!performanceReview) {
        dispatch(
          updatePerformanceReview({
            id: performanceReview.id,
            values: data,
          })
        );
      } else {
        dispatch(createPerformanceReview(data));
      }
      setOpen(false);
    },
    [dispatch, performanceReview]
  );

  const onChangeStatusPR = useCallback(() => {
    if (!!performanceReview && !!openChangeStatusPR) {
      if (
        openChangeStatusPR === CommandPerformanceReview.complete ||
        openChangeStatusPR === CommandPerformanceReview.start
      ) {
        dispatch(
          changeStatusPerformanceReview({
            id: performanceReview.id,
            command:
              openChangeStatusPR === CommandPerformanceReview.complete
                ? CommandPerformanceReview.complete
                : CommandPerformanceReview.start,
            status:
              openChangeStatusPR === CommandPerformanceReview.complete
                ? StatusPerformanceReview.completed
                : StatusPerformanceReview.active,
          })
        );
      } else if (openChangeStatusPR === CommandPerformanceReview.delete) {
        dispatch(deletePerformanceReview(performanceReview.id));
      }
      onCloseChangeStatusPR();
    }
  }, [performanceReview, openChangeStatusPR, dispatch]);

  const onCloseChangeStatusPR = () => {
    dispatch(setStatePerfomanceRequest({ type: 'openChangeStatusPR', data: null }));
    dispatch(setStatePerfomanceRequest({ type: 'performanceReview', data: null }));
  };

  const titleSavePR = useMemo(() => (!!performanceReview ? `Edit PR` : 'Create PR'), [performanceReview]);

  const titleChangeStatusPR = useMemo(() => {
    switch (openChangeStatusPR) {
      case CommandPerformanceReview.start:
        return 'Are you sure you want to start this PR?';
      case CommandPerformanceReview.complete:
        return 'Are you sure you want to complete this PR?';
      case CommandPerformanceReview.delete:
        return 'Are you sure you want to delete this PR?';
      default:
        return '';
    }
  }, [openChangeStatusPR]);

  const buttonChangeStatusPR = useMemo(() => {
    switch (openChangeStatusPR) {
      case CommandPerformanceReview.start:
        return 'Start';
      case CommandPerformanceReview.complete:
        return 'Complete';
      case CommandPerformanceReview.delete:
        return 'Delete';
      default:
        return '';
    }
  }, [openChangeStatusPR]);

  return (
    <>
      <div className={styles.buttonWrap}>
        {accessEdit && (
          <button className={cx(styles.button, styles.topButton)} onClick={() => setOpen(true)}>
            {plus} Create PR
          </button>
        )}
      </div>

      <Card className="main-card mb-3">
        <CardBody>
          <div className="mb-3 d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center">
              <div className={styles.arrow} onClick={() => onChangeYear('prev')}>
                {arrowLeft}
              </div>

              <div className={styles.currentYear}>{currentYear}</div>

              <div className={styles.arrow} onClick={() => onChangeYear('next')}>
                {arrowRight}
              </div>
            </div>
          </div>

          <div>{loading && <Loader />}</div>

          {!!performanceReviews?.length && !loading ? (
            <PerformanceReviewTable
              data={performanceReviews}
              accessEdit={accessEdit}
              onOpenEdit={() => setOpen(true)}
              headers={PERFORMANCE_REVIEW_TABLE_HEADERS}
            />
          ) : (
            !loading && <div>Performance Review Cycles are empty</div>
          )}
        </CardBody>
      </Card>

      {open &&
        createPortal(
          <CreatePRModal title={titleSavePR} onClose={() => setOpen(false)} handleSubmit={onSavePR} />,
          document.body
        )}

      {!!openChangeStatusPR &&
        createPortal(
          <ChangeStatusPRModal
            title={titleChangeStatusPR}
            onClose={onCloseChangeStatusPR}
            onChange={onChangeStatusPR}
            submitButton={buttonChangeStatusPR}
          />,
          document.body
        )}
    </>
  );
};

export default PerformanceReview;
