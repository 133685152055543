import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { formatStage, formatPipeline } from './../../../utils/functions';
import cx from 'classnames';

import { DropdownList } from 'react-widgets';
import { Card, CardBody } from 'reactstrap';

import DealsTable from './DealsTable';
import Datepicker from '../../Datepicker/Datepicker';
import Pagination from 'components/Pagination/Pagination';
import Loader from '../../../common/Loader';

import { getDealsList, getDealStages, getDealPipelines } from '../../../store/bdm/bdm.thunk';
import { AppState } from '../../../store/configureStore';
import { CommonRequestParams } from 'models/dto/params.dto';

import { DATE_PICKER } from '../../../constants/common';
import { useQuery } from 'hooks/queryHook';

import styles from '../../tableStyles.module.scss';

const Deals: React.FC = () => {
  const dispatch = useDispatch();

  const { deals, tempPipelines, tempStages, loading, pages, total } = useSelector(
    (state: AppState) => state.bdmReducer
  );
  const pipelines = tempPipelines ? tempPipelines : [];
  const stages = tempStages ? tempStages : [];

  const {
    page,
    pageSize,
    fromDate,
    toDate,
    pipeline,
    stage,
    setPage,
    setPageSize,
    setDateRange,
    setPipeline,
    setStage,
  } = useQuery();

  const stageValue = stages?.find((item: string) => formatStage(item) === stage);

  const getDeals = useCallback(() => {
    if (!fromDate || !toDate) {
      setDateRange(
        format(startOfMonth(new Date()), DATE_PICKER.dateFormatToPayload),
        format(endOfMonth(new Date()), DATE_PICKER.dateFormatToPayload)
      );
    } else {
      const params: CommonRequestParams = {
        fromDate: fromDate,
        toDate: toDate,
        page: +page,
        size: +pageSize,
      };

      if (pipeline) {
        params.pipeline = pipeline;
      }
      if (stage) {
        params.stage = stage;
      }

      dispatch(getDealsList(params));
    }
  }, [dispatch, fromDate, toDate, pipeline, stage, pageSize, page]);

  useEffect(() => {
    getDeals();
  }, [getDeals, dispatch]);

  useEffect(() => {
    dispatch(getDealStages({ pipeline: formatPipeline(pipeline) }));
  }, [dispatch, pipeline]);

  useEffect(() => {
    !tempPipelines?.length && dispatch(getDealPipelines());
  }, [dispatch, tempPipelines]);

  const setCurrentPageHandle = (value: number) => {
    if (value !== +page) {
      setPage(value);
    }
  };

  const setCountPerPageHandler = (value: number) => {
    setPageSize(value);
  };

  const changeStage = (value: string) => {
    setStage(value ? formatStage(value) : '');
  };

  const changePipeline = (value: string) => {
    setPipeline(value ? formatPipeline(value) : '');
  };

  const onDateChange = (date: Date) => {
    setDateRange(
      format(startOfMonth(date), DATE_PICKER.dateFormatToPayload),
      format(endOfMonth(date), DATE_PICKER.dateFormatToPayload)
    );
  };

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className="bp-header">
          <div className="filters-block">
            <div className="dropdown-filter">
              <div className="label-wrapper">Date</div>
              <Datepicker
                selected={fromDate ? new Date(fromDate) : startOfMonth(new Date())}
                dateFormat={DATE_PICKER.dateFormatMonth}
                showMonthYearPicker
                onChange={onDateChange}
              />
            </div>

            {pipelines.length > 0 ? (
              <div className="dropdown-filter">
                <div className="label-wrapper">Pipeline</div>
                <DropdownList
                  value={pipeline}
                  data={pipelines}
                  placeholder="All"
                  textField="Pipeline"
                  filter="contains"
                  onChange={changePipeline}
                />
              </div>
            ) : null}

            {stages.length > 0 ? (
              <div className="dropdown-filter">
                <div className="label-wrapper">Stage</div>
                <DropdownList
                  value={stageValue}
                  data={stages}
                  placeholder="All"
                  textField="Stage"
                  filter="contains"
                  onChange={changeStage}
                  disabled={!pipeline}
                />
              </div>
            ) : null}
          </div>
        </div>

        {!!deals?.length && !loading ? (
          <div className={cx(styles.tableWrapper, 'mb-1')} style={{ overflow: 'unset' }}>
            <DealsTable data={deals} currentPage={+page} perPage={+pageSize} />

            <div className={styles.marginBottomTable}>
              <Pagination
                pages={pages}
                perPage={+pageSize}
                totalCount={total}
                setCurrentPage={setCurrentPageHandle}
                currentPage={+page}
                setCountPerPage={setCountPerPageHandler}
                count={0}
              />
            </div>
          </div>
        ) : (
          (loading && <Loader />) || (!loading && <div>Deals are empty</div>)
        )}
      </CardBody>
    </Card>
  );
};

export default Deals;
