import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMonth, format, startOfMonth } from 'date-fns';

import { Card, CardBody } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import BudgetTable from './BudgetTable';
import Totals from 'common/Totals/Totals';
import Datepicker from 'components/Datepicker/Datepicker';
import TeamFilter from 'common/TeamFilter/TeamFilter';
import Loader from 'common/Loader';

import { getTeamById } from 'store/teams/teams.thunk';
import { getTeamsBudgeting } from 'store/budgeting/budgeting.thunk';
import { AppState } from 'store/configureStore';
import { RequestParams } from 'utils/mapParams';
import { BudgetingInfo } from 'store/budgeting';

import { addBudgetingDataToTeams, getBudgetTotals, getCoreTeams } from './utils';
import { BILLING_SOURCES, DATE_PICKER } from 'constants/common';
import { useQuery } from 'hooks/queryHook';
import { Role } from 'constants/roles';

const TeamBudget = () => {
  const dispatch = useDispatch();

  const { teams, team: teamLeadTeam, loading } = useSelector((state: AppState) => state.teamsReducer);
  const { budgetData, loading: loadingBudget }: { budgetData: BudgetingInfo | null; loading: boolean } = useSelector(
    (state: AppState) => state.budgetingReducer
  );
  const { user } = useSelector((state: AppState) => state.account);

  const { fromDate, toDate, team, billingSource, setFromToDate, setTeam, setBillingSource } = useQuery();

  const getData = useCallback(() => {
    if (!fromDate || !toDate) {
      setFromToDate(
        format(startOfMonth(new Date()), DATE_PICKER.dateFormatToPayload),
        format(endOfMonth(new Date()), DATE_PICKER.dateFormatToPayload)
      );
    } else {
      const params: Partial<RequestParams> = {
        fromDate: fromDate,
        toDate: toDate,
      };

      if (billingSource) {
        params.billingSource = billingSource;
      }
      if (team) {
        params.team = +team;
      }

      dispatch(getTeamsBudgeting(params));
    }
  }, [fromDate, toDate, team, billingSource]);

  const teamLeadOfMultipleTeams = user.role === Role.teamlead && user.teams?.split(',')?.length > 1;
  const oneTeamTeamLead = user.role !== Role.vp && user.role !== Role.finance && !teamLeadOfMultipleTeams && user.teams;

  useEffect(() => {
    if (oneTeamTeamLead) {
      dispatch(getTeamById({ id: user.teams, searchParams: { expand: 'type,parent,children' } }));
    }
    getData();
  }, [getData]);

  const teamsWithBudget = useMemo(
    () =>
      addBudgetingDataToTeams(
        oneTeamTeamLead && teamLeadTeam
          ? [teamLeadTeam]
          : getCoreTeams(teams || [], team ? team : teamLeadOfMultipleTeams ? user.teams : null),
        budgetData?.items || []
      ),
    [teams, budgetData]
  );

  const onDateChange = (date: Date) => {
    setFromToDate(
      format(startOfMonth(date), DATE_PICKER.dateFormatToPayload),
      format(endOfMonth(date), DATE_PICKER.dateFormatToPayload)
    );
  };

  const onBillingSourceChange = (value: string) => {
    setBillingSource(value === 'All' ? '' : value.toLowerCase());
  };

  const billingSourceValue = BILLING_SOURCES?.find((item: string) => item.toLowerCase() === billingSource);

  const budgetTotals = getBudgetTotals(budgetData);

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className="bp-header">
          <div className="filters-block">
            <div className="dropdown-filter">
              <div className="label-wrapper">Date</div>
              <Datepicker
                selected={!!fromDate ? new Date(fromDate) : startOfMonth(new Date())}
                dateFormat={DATE_PICKER.dateFormatMonth}
                showMonthYearPicker
                onChange={onDateChange}
              />
            </div>

            {!oneTeamTeamLead && (
              <TeamFilter
                team={team}
                setTeam={setTeam}
                teamLeadTeams={user.role === Role.teamlead ? user.teams : ''}
                planning
                addAllOptionForTL
              />
            )}

            <div className="dropdown-filter">
              <div className="label-wrapper">Billing Source</div>
              <DropdownList
                value={billingSourceValue}
                data={['All', ...BILLING_SOURCES]}
                textField="title"
                placeholder="All"
                onChange={onBillingSourceChange}
              />
            </div>
          </div>
        </div>

        {loading || loadingBudget || !budgetData ? (
          <Loader />
        ) : teamsWithBudget?.length ? (
          <>
            <Totals data={budgetTotals || []} className="mb-3" />
            <BudgetTable teams={teamsWithBudget || []} />
          </>
        ) : (
          'Team budget is empty'
        )}
      </CardBody>
    </Card>
  );
};

export default TeamBudget;
