import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { Form, Formik } from 'formik';

import ProfileHeader from './ProfileHeader/ProfileHeader';
import ProfilePublic from './ProfilePublic';
import ProfileEditButtons from './ProfileEditButtons';

import { AppState } from 'store/configureStore';
import { updateEmployeeByIdThunk } from 'store/employee';
import { updateEmployeeByIdT } from 'services/employeeService';

import { DATE_FORMAT_FIELDS, PROFILE_PUBLIC_SCHEMA } from './constants';
import { edit } from '../../constants/icons';
import formatDatesFields from 'helpers/formatDatesFields';
import { formatDropdownPayload } from 'utils/functions';
import { getLocalDateFromString } from 'utils/dates';
import { ProfileProps, ProfilePublicInitialValuesT } from 'models/interfaces/employeeProfileProps';

import styles from './Profile.module.scss';
import stylesButton from '../tableStyles.module.scss';

const ProfilePublicInfo: FC<ProfileProps> = ({
  user,
  employeeId,
  isEditRole,
  isEditAvailability,
  userId,
  isEditHrManager,
  isEditMentor,
}) => {
  const dispatch = useDispatch();

  const [isEditMode, setEditMode] = useState(false);

  const { salesGrades } = useSelector((state: AppState) => state.profileReducer);

  const initialValues: ProfilePublicInitialValuesT = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    title: user?.title,
    grade: user?.grade,
    teamId: user?.team?.id,
    birthday: user?.birthday && getLocalDateFromString(user.birthday),
    startDate: user?.startDate && new Date(user.startDate),
    trialEndDate: user?.trialEndDate && new Date(user.trialEndDate),
    location: user?.location,
    city: user?.city,
    dismissalDate: user?.dismissalDate && new Date(user.dismissalDate),
    isDirectCost: user?.isDirectCost,
    availability: user?.availability,
    isTechlead: user?.isTechlead || false,
    isIntern: user?.isIntern || false,
    salesGradeId: user?.salesGrade?.title,
  };

  if (isEditHrManager) {
    initialValues.hrId = user?.hr?.id;
  }
  if (isEditMentor) {
    initialValues.mentorId = user?.mentor?.id;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PROFILE_PUBLIC_SCHEMA}
      onSubmit={values => {
        const formattedValues: any = formatDatesFields(
          { ...values, salesGradeId: formatDropdownPayload(salesGrades, values.salesGradeId) },
          DATE_FORMAT_FIELDS
        );
        dispatch(updateEmployeeByIdThunk({ id: employeeId, employeeData: formattedValues } as updateEmployeeByIdT));
        setEditMode(false);
      }}
    >
      {({ values, resetForm, setFieldValue }) => (
        <div
          className={cx(
            styles.wrapInfoProfile,
            !isEditMode && styles.wrapInfoProfileStatic,
            !isEditMode && 'wrap-info-profile-edit'
          )}
        >
          {isEditRole && !isEditMode && (
            <div className={cx('btn-wrap', styles.btnWrap)}>
              <button className={cx(stylesButton.button, stylesButton.topButton)} onClick={() => setEditMode(true)}>
                {edit} Edit
              </button>
            </div>
          )}

          <Form>
            {isEditMode && (
              <div className={cx(styles.editButton)}>
                <ProfileEditButtons resetForm={resetForm} setEditMode={setEditMode} />
              </div>
            )}

            <div className={styles.blockPadding}>
              <ProfileHeader user={user} isEditMode={isEditMode} userId={userId} />
              <ProfilePublic
                user={user}
                setFieldValue={setFieldValue}
                startDate={values?.startDate}
                values={values}
                isEditMode={isEditMode}
                isEditAvailability={isEditAvailability}
                isEditHrManager={isEditHrManager}
                isEditMentor={isEditMentor}
              />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default ProfilePublicInfo;
