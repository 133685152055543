import { createAsyncThunk } from '@reduxjs/toolkit';
import budgetingService from 'services/budgetingService';
import { RequestParams } from 'utils/mapParams';

export const getTeamsBudgeting = createAsyncThunk(
  'budgeting/getTeamsBudgeting',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const availableHours = await budgetingService.getBudgeting(params);
      return availableHours;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
