import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTeamById } from './teams.thunk';
import TeamModel from '../../models/team.model';

interface TeamsState {
  loading: boolean;
  teams: TeamModel[] | null;
  team: TeamModel | null;
  error: Error | null;
}

const initialState: TeamsState = {
  loading: false,
  teams: null,
  team: null,
  error: null,
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    getTeamsListRequest: (state: TeamsState) => {
      return {
        ...state,
        loading: true,
        teams: null,
        error: null,
      };
    },
    getTeamsListSuccess: (state: TeamsState, action: PayloadAction<TeamModel[]>) => {
      return {
        ...state,
        teams: action.payload,
        loading: false,
        error: null,
      };
    },
    getTeamsListFailure: (state: TeamsState, action: PayloadAction<Error>) => {
      return {
        ...state,
        loading: false,
        teams: null,
        error: action.payload,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTeamById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTeamById.fulfilled, (state, action: PayloadAction<TeamModel>) => {
        state.team = action.payload;
        state.loading = false;
      })
      .addCase(getTeamById.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { getTeamsListRequest, getTeamsListSuccess, getTeamsListFailure } = teamsSlice.actions;

export default teamsSlice.reducer;
