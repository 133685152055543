export enum ISSUE_TYPES {
  rr = 'rr',
  ovr = 'ovr',
  vsd = 'vsd',
  shift = 'shift',
  dayOff = 'day off',
  downtime = 'downtime',
  extraDayOff = 'extra day off',
  dayOffShift = 'day off/shift',
}

export enum ISSUE_STATUS {
  approved = 'approved',
  hold = 'waiting for confirmation',
  closed = 'closed',
}

export enum ISSUE_FULL_TIME_HOURS {
  shift = 'S',
  dayOff = 'V',
  downtime = 'D',
}
