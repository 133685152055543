import IssueModel from './issue.model';
import { EmployeeProfileModel } from './employeeProfile.model';

export default class EmployeeExtendsVsdModel extends EmployeeProfileModel {
  closedVsd: IssueModel[] | undefined;
  plannedVsd: IssueModel[] | undefined;
  totalPlannedVsd: number | undefined;

  constructor(
    data: EmployeeProfileModel & Partial<EmployeeExtendsVsdModel>,
    closedVsd: IssueModel[],
    plannedVsd: IssueModel[],
    totalPlannedVsd: number
  ) {
    super(data);
    this.closedVsd = closedVsd;
    this.plannedVsd = plannedVsd;
    this.totalPlannedVsd = totalPlannedVsd;
  }
}
