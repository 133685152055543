import React, { Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @ts-ignore
import { Slider } from 'react-burgers';

import AppMobileMenu from '../AppMobileMenu';

import { setEnableClosedSidebar } from '../../store/themeOptions';
import { AppState } from '../../store/configureStore';

type isSidebar = {
  enableClosedSidebar: boolean;
};

const HeaderLogo = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const { enableClosedSidebar }: isSidebar = useSelector((state: AppState) => state.themeOptions);

  const toggleEnableClosedSidebar = useCallback(() => {
    dispatch(setEnableClosedSidebar(!enableClosedSidebar));
  }, [dispatch, enableClosedSidebar]);

  return (
    <Fragment>
      <div className="app-header__logo">
        <div className="logo-src" />
        <div className="header__pane ml-auto">
          <div onClick={toggleEnableClosedSidebar}>
            <Slider
              width={26}
              lineHeight={2}
              lineSpacing={5}
              color="#6c757d"
              active={active}
              onClick={() => setActive(!active)}
            />
          </div>
        </div>
      </div>
      <AppMobileMenu />
    </Fragment>
  );
};

export default HeaderLogo;
