import { createAsyncThunk } from '@reduxjs/toolkit';
import resourceRequestsBulkService from 'services/resourceRequestServiceBulkService';
import { NotificationType } from 'services/notificationService';
import { enqueueSnackbar } from 'store/notifications';
import { CreateRrT } from 'models/interfaces/createRR.interface';

export const createResourceRequestsBulk = createAsyncThunk(
  'resourceRequestsBulk/createResourceRequestsBulk',
  async (params: { values: CreateRrT[]; callback: () => void }, thunkAPI) => {
    const { values, callback } = params;
    try {
      const res = await resourceRequestsBulkService.createResourceRequestsBulk(values);
      callback();
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Resource Requests created successfully!',
            title: 'Resource Requests',
          },
        })
      );
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
