import { createAsyncThunk } from '@reduxjs/toolkit';
import compensationOrdersService from 'services/compensationOrdersService';
import { enqueueSnackbar } from 'store/notifications';
import { NotificationType } from 'services/notificationService';
import { CompensationOrderCommand, CompensationOrder, OrdersRequestParams } from 'models/compensationOrders.interface';

export const getCompensationOrders = createAsyncThunk(
  'compensationOrders/getCompensationOrders',
  async (params: OrdersRequestParams, thunkAPI) => {
    try {
      const res = await compensationOrdersService.getCompensationOrders(params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const changeCompensationOrderByCommand = createAsyncThunk(
  'compensationOrders/changeCompensationOrderByCommand',
  async (params: { id: number; command: CompensationOrderCommand }, thunkAPI) => {
    try {
      const res = await compensationOrdersService.changeCompensationOrderByCommand(params.id, params.command);
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: `Compensation order ${
              params.command === 'resend'
                ? 'resent'
                : params.command === 'recalculate'
                ? 'recalculated'
                : `${params.command}ed`
            } successfully!`,
            title: 'Compensation Orders',
          },
        })
      );
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const editCompensationOrder = createAsyncThunk(
  'compensationOrders/editCompensationOrder',
  async (params: { id: number; value: Partial<CompensationOrder> }, thunkAPI) => {
    try {
      await compensationOrdersService.editCompensationOrder(params.id, params.value);
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: `Compensation order edited successfully!`,
            title: 'Compensation Orders',
          },
        })
      );
      return params;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
