import { OwnerCloserPMInterface } from './dto/bdm.dto';

class DealsModel {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  rate: number;
  owner: OwnerCloserPMInterface;
  closer: OwnerCloserPMInterface;

  constructor(data: DealsModel) {
    this.id = data.id;
    this.name = data.name;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.rate = data.rate;
    this.owner = data.owner;
    this.closer = data.closer;
  }
}

export default DealsModel;
