import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import get from 'lodash.get';

import { Table } from 'reactstrap';
import VpDashboardButtons from './VpDashboardButtons';

import { secondsToHours } from '../../utils/dates';
import { getFullName } from '../../helpers/companyStructureHeplers';
import { WorklogIssuesTable, WorklogTableData } from 'models/interfaces/vpDashboard.interface';

import styles from '../tableStyles.module.scss';

const VpDashboardTable: FC<WorklogIssuesTable> = ({
  worklogData,
  isApproveRole,
  currentPage,
  perPage,
  userId,
  role,
  approveCallback,
  setCount,
}) => {
  const coefForNumberFirstColumn = useMemo(() => perPage * currentPage - perPage + 1, [currentPage, perPage]);

  return (
    <Table responsive hover className={cx(styles.tableContainer, 'align-middle mb-0 custom-table text-left')}>
      <thead>
        <tr>
          <th className={styles.firstCellWidth}>#</th>
          <th>Employee</th>
          <th>Issue</th>
          <th>Date</th>
          <th>Time Spent</th>
          {isApproveRole && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {worklogData.map((item: WorklogTableData, index: number) => {
          const approveForTL = get(item, 'employee.id') !== +userId && role === 'teamlead';
          return (
            <tr key={item.id}>
              <td>{index + coefForNumberFirstColumn}</td>
              <td>{item.employee ? getFullName(item.employee) : get(item, 'worklog.author')}</td>
              <td>
                <a href={item.worklog.issueLink} target="_blank">
                  {item.worklog.issueKey}
                </a>
              </td>
              <td>{item.worklog.startedAt}</td>
              <td>{secondsToHours(item.timeSpent)}</td>
              {(role === 'vp' || approveForTL) && (
                <td>
                  <VpDashboardButtons id={item.id} approveCallback={approveCallback} setCount={setCount} />
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
export default VpDashboardTable;
