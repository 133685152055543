import React from 'react';
import { Input } from 'reactstrap';
import { search as searchIcon } from 'constants/icons';
import styles from './styles.module.scss';

type SearchProps = {
  searchValue: string;
  setSearchValue: (value: string) => void;
  updateSearchValue: (event: React.FormEvent) => void;
  label?: string;
};

const Search = ({ searchValue, setSearchValue, updateSearchValue, label = 'Search' }: SearchProps) => {
  return (
    <div className="dropdown-filter">
      <p className="label-wrapper">{label}</p>
      <form className={styles.searchKeyWrap} onSubmit={updateSearchValue}>
        <Input
          type="text"
          name="search"
          placeholder="Search"
          value={searchValue}
          onChange={event => setSearchValue(event?.target.value)}
        />
        <button className={styles.buttonSearch} onClick={updateSearchValue}>
          {searchIcon}
        </button>
      </form>
    </div>
  );
};

export default Search;
