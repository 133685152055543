import React, { FC } from 'react';
import cx from 'classnames';

import { Table } from 'reactstrap';

import { secondsToHours } from '../../utils/dates';
import { RRFooterProps } from 'models/interfaces/resourceRequest.interface';

import styles from '../tableStyles.module.scss';

const ResourceRequestFooter: FC<RRFooterProps> = ({ totals }) => (
  <div className={cx(styles.footerTableWrap, 'mt-3')}>
    <Table responsive hover className="custom-table align-middle mb-0">
      <thead>
        <tr className={styles.removeRow}>
          <th />
          <th>Planned</th>
          <th>Factual</th>
          <th>Pending</th>
          <th>Rejected</th>
          <th>Estimated</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={cx(styles.greenRow, styles.footerCellWidth)}>Total Billable Hours</td>
          <td>{secondsToHours(totals?.billableHours?.planned)}</td>
          <td>{secondsToHours(totals?.billableHours?.factual)}</td>
          <td>{secondsToHours(totals?.billableHours?.pending)}</td>
          <td>{secondsToHours(totals?.billableHours?.rejected)}</td>
          <td>{secondsToHours(totals?.billableHours?.estimated)}</td>
        </tr>
        <tr>
          <td className={cx(styles.redRow, styles.footerCellWidth)}>Total Non Billable Hours</td>
          <td>{secondsToHours(totals?.nonBillableHours?.planned)}</td>
          <td>{secondsToHours(totals?.nonBillableHours?.factual)}</td>
          <td>{secondsToHours(totals?.nonBillableHours?.pending)}</td>
          <td>{secondsToHours(totals?.nonBillableHours?.rejected)}</td>
          <td>{secondsToHours(totals?.nonBillableHours?.estimated)}</td>
        </tr>
      </tbody>
    </Table>
  </div>
);

export default ResourceRequestFooter;
