import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

import Loader from '../../common/Loader';
import ProfilePublicInfo from './ProfilePublicInfo';
import ProfilePrivate from './ProfilePrivate';
import VsdInformation from './VsdInformation';
import SalaryHistory from './Obligations/SalaryHistory';
import WorklogIssuesHistory from './WorklogIssuesHistory';
import Mentees from './Mentees/Mentees';

import { AppState } from '../../store/configureStore';
import { mappedProfile } from '../../store/Profile/mappedProfile.selector';
import { getEmployeeByIdWithVsd } from '../../store/Profile/profile.thunk';
import { clearEmployee } from '../../store/employee';

import { ALL_EXPANDS_EMPLOYEE, ALL_VP_EXPANDS_EMPLOYEE, FIRST_DAY_COUNT_VSD } from '../../constants/employee';
import { PROFILE_TABS_HEADERS } from './constants';
import { ALL_PERMISSIONS, Role } from '../../constants/roles';
import { currentDay } from '../../helpers/formatTime';
import {
  ParamsT,
  ProfilePrivateT,
  ProfileProps,
  SalaryHistoryT,
  VsdInformationT,
  WorklogTabProps,
} from 'models/interfaces/employeeProfileProps';

import styles from './Profile.module.scss';

const renderTabs = [
  {
    id: 1,
    component: ({
      user,
      userId,
      employeeId,
      isEditRole,
      isEditAvailability,
      isEditHrManager,
      isEditMentor,
    }: ProfileProps) => (
      <ProfilePublicInfo
        user={user}
        employeeId={employeeId}
        userId={userId}
        isEditAvailability={isEditAvailability}
        isEditRole={isEditRole}
        isEditHrManager={isEditHrManager}
        isEditMentor={isEditMentor}
      />
    ),
  },
  {
    id: 2,
    component: ({ user }: VsdInformationT) => <VsdInformation user={user} />,
  },
  {
    id: 3,
    component: ({ user, isEditRole, employeeId }: ProfilePrivateT) => (
      <ProfilePrivate user={user} isEditRole={isEditRole} employeeId={employeeId} />
    ),
  },
  {
    id: 4,
    component: ({ employeeId, userId }: SalaryHistoryT) => <SalaryHistory employeeId={employeeId} userId={userId} />,
  },
  {
    id: 5,
    component: ({ role, userId, employeeId }: WorklogTabProps) => (
      <WorklogIssuesHistory role={role} userId={userId} employeeId={employeeId} />
    ),
  },
  {
    id: 5,
    component: ({ employeeId }: { employeeId: string }) => <Mentees employeeId={employeeId} />,
  },
];

const ProfileForm = () => {
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(0);

  const { id: paramsId } = useParams<ParamsT>();

  const { role, userId, teams } = useSelector((state: AppState) => state.account?.user);

  const { loading } = useSelector((state: AppState) => state.employeeReducer);

  const employeeId: string = paramsId ? paramsId : userId;

  const user = useSelector(mappedProfile);
  const isTeamLeadOfUser = role === Role.teamlead && teams?.split(',')?.includes(String(user?.team?.id));

  const isShowSalesGrade = useMemo(
    () =>
      role === Role.sales ||
      role === Role.vp ||
      role === Role.delivery ||
      role === Role.leadgen ||
      role === Role.finance,
    [role, paramsId, userId, user]
  );

  const isShowPrivateInfo = useMemo(
    () => role === Role.hr || role === Role.vp || isTeamLeadOfUser || (role === Role.user && user?.id === +userId),
    [role, user, userId]
  );

  const isShowWorklogIssues = useMemo(
    () => (ALL_PERMISSIONS && String(user?.id) === userId) || role === Role.teamlead || role === Role.vp,
    [role, paramsId, userId, user]
  );

  const isEditRole = useMemo(() => role === Role.hr || role === Role.vp || isTeamLeadOfUser, [role, user, userId]);

  const isEditAvailability = useMemo(() => role === Role.hr || role === Role.vp, [role]);
  const isEditHrManager = useMemo(() => role === Role.vp, [role]);
  const isEditMentor = useMemo(() => role === Role.vp || isTeamLeadOfUser, [role, user]);

  const accessSalaryBlock = useMemo(() => {
    return (
      role === Role.vp ||
      role === Role.finance ||
      role === Role.hr ||
      (role === Role.teamlead && (isTeamLeadOfUser || user?.id === +userId)) ||
      ((role === Role.user ||
        role === Role.leadgen ||
        role === Role.sales ||
        role === Role.pm ||
        role === Role.delivery) &&
        !paramsId)
    );
  }, [role, paramsId, userId, user]);

  const tabPermissions = [
    ALL_PERMISSIONS,
    ALL_PERMISSIONS,
    isShowPrivateInfo,
    accessSalaryBlock,
    isShowWorklogIssues,
    ALL_PERMISSIONS,
  ];

  useEffect(() => {
    setCurrentTab(0);
    dispatch(
      getEmployeeByIdWithVsd(
        employeeId,
        isShowSalesGrade ? ALL_VP_EXPANDS_EMPLOYEE : ALL_EXPANDS_EMPLOYEE,
        FIRST_DAY_COUNT_VSD,
        currentDay(3)
      )
    );
  }, [dispatch, employeeId]);

  useEffect(() => {
    return () => {
      dispatch(clearEmployee());
    };
  }, [dispatch]);

  if (loading) {
    return (
      <div className="ml-3">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {user?.id ? (
        <div className={cx(styles.wrapInfoProfile, 'wrap-info-profile')}>
          {!!user && (
            <div className={styles.tabs}>
              {PROFILE_TABS_HEADERS.map(
                (tabHeader, index) =>
                  tabPermissions[index] && (
                    <div
                      key={tabHeader.id}
                      className={currentTab === tabHeader.id ? styles.activeTab : styles.tab}
                      onClick={() => setCurrentTab(tabHeader.id)}
                    >
                      {tabHeader.title}
                    </div>
                  )
              )}
            </div>
          )}

          <div className={styles.block}>
            {!!user &&
              renderTabs[currentTab].component({
                user,
                userId,
                employeeId,
                role,
                isEditRole,
                isEditAvailability,
                isEditHrManager,
                isEditMentor,
              })}
          </div>
        </div>
      ) : (
        <div>Error. Please try again.</div>
      )}
    </>
  );
};

export default ProfileForm;
