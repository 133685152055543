import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/Loader';
import VpDashboardTable from 'components/VpDashboard/VpDashboardTable';

import { getWorklogsApprovalList } from 'store/VpDashboard/vpDashboard.thunk';
import { AppState } from 'store/configureStore';
import { Role } from '../../constants/roles';
import { WorklogTabProps } from 'models/interfaces/employeeProfileProps';
import { ParamsT } from 'models/interfaces/vpDashboard.interface';

import styles from '../tableStyles.module.scss';
import profileStyles from './Profile.module.scss';

const WorklogIssuesHistory: FC<WorklogTabProps> = ({ role, userId, employeeId }) => {
  const dispatch = useDispatch();

  const { loading, worklogsApproval } = useSelector((state: AppState) => state.vpDashboardReducer);

  useEffect(() => {
    const params: ParamsT = {
      employee: employeeId,
    };
    dispatch(getWorklogsApprovalList(params));
  }, [userId]);

  const isApproveRole = role === Role.vp || role === Role.teamlead;

  if (loading) {
    return (
      <div className={profileStyles.blockPadding}>
        <div className={profileStyles.blockWrap}>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.container)}>
        <div className={profileStyles.blockWrap}>
          {worklogsApproval && worklogsApproval.length ? (
            <div className={styles.tableWrapper}>
              <VpDashboardTable
                worklogData={worklogsApproval}
                isApproveRole={isApproveRole}
                currentPage={1}
                perPage={50}
                userId={userId}
                role={role}
              />
            </div>
          ) : (
            <div>Worklog approval requests are empty</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorklogIssuesHistory;
