import { AssignedPerson, EmployeeDto } from '../models/dto/employee.dto';
import { secondsToHours } from './dates';
import { ProjectT } from 'models/interfaces/projects.interface';

export const formatEmployees = (employees: EmployeeDto[]) => {
  return [
    {
      id: 0,
      firstName: 'All',
      lastName: '',
    },
    ...employees,
  ];
};

export const formatAssignees = (employees: EmployeeDto[]): AssignedPerson[] => {
  return employees.reduce(
    (acc: AssignedPerson[], employee: EmployeeDto) => {
      acc.push({
        id: employee.id,
        firstName: employee.firstName,
        lastName: employee.lastName,
      });
      return acc;
    },
    [
      {
        id: null,
        firstName: 'Unassigned',
        lastName: '',
      },
    ]
  );
};

export const formatProjects = (projects: ProjectT[]) => {
  return [{ name: 'All', projectKey: '' }, ...projects];
};

export const formatPipelines = (pipelines: string[]) => {
  return ['All', ...pipelines];
};

export const formatStages = (stages: string[]) => {
  return ['All', ...stages];
};

export const formatDurationHoliday = (seconds: number) => {
  if (seconds) {
    return secondsToHours(seconds);
  }

  return 'Day off';
};
