import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { createPortal } from 'react-dom';

import Loader from 'common/Loader';

import { resetPerfomanceReview } from 'store/performanceReview';
import { AppState } from 'store/configureStore';

import styles from '../../../modal.module.scss';

type PropsType = {
  title: string;
  onClose: () => void;
  blockStyle?: {};
};

const CreatePRModal: React.FC<PropsType> = ({ children, title, onClose, blockStyle }) => {
  const dispatch = useDispatch();

  const { loadingModal } = useSelector((state: AppState) => state.performanceReviewReducer);

  useEffect(() => {
    return () => {
      dispatch(resetPerfomanceReview());
    };
  }, [dispatch]);

  return createPortal(
    <div className={styles.modal}>
      <div className={styles.backDrop} onClick={onClose}></div>
      <div className={cx(styles.block, 'text-left')} style={blockStyle}>
        <button className={styles.close} onClick={onClose} />
        <div className={styles.header}>{title}</div>
        <div className="ml-3">{loadingModal && <Loader />}</div>

        <>{children}</>
      </div>
    </div>,
    document.body
  );
};

export default CreatePRModal;
