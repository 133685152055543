import httpService, { HttpService } from './httpService';
import ExpenseTypeDto from '../models/dto/expenseType.dto';
import ExpenseTypeAdapter from '../models/adapters/expenseType.adapter';

export class ExpenseTypeService {
  constructor(private _httpService: HttpService) {}

  async getExpenseTypesList() {
    const res = await this._httpService.get(`/expense-types`);
    return res.map((expenseType: ExpenseTypeDto) => ExpenseTypeAdapter.mapExpenseTypeDtoToExpenseType(expenseType));
  }
}

export default new ExpenseTypeService(httpService);
