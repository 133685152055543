import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { Tooltip } from 'reactstrap';
import ItemDuration from './ItemDuration';
import ResourceRequestRow from './ResourceRequestRow';
import Loader from 'common/Loader';
import ColoredCircle from 'components/ColoredCircle/ColoredCircle';

import { getResourceRequests } from 'store/resourceRequests/resourceRequests.thunk';
import { decrementOpenedRowsCounter, incrementOpenedRowsCounter } from 'store/projectCalendar';
import { AppState } from 'store/configureStore';

import { PirRowProps, Month } from 'models/interfaces/projectCalendar.interface';
import { TableItemsRR } from 'models/interfaces/resourceRequest.interface';
import { RequestParams } from 'utils/mapParams';
import { useQuery } from 'hooks/queryHook';
import { arrowRight, bluePlus } from 'constants/icons';
import { getPirStatusColor, isItemWithinMonth } from '../constants';
import { COLORS } from 'config/theme';

import styles from '../projectCalendarStyles.module.scss';
import '../tooltip.scss';

const ProjectPirRow = ({ pir, monthsList, openModal, canCreateRR }: PirRowProps) => {
  const dispatch = useDispatch();

  const { dataWithId, loading } = useSelector((state: AppState) => state.resourceRequestsReducer);

  const [hoveredCell, setHoveredCell] = useState<null | number>(null);
  const [selected, setSelected] = useState(false);

  const { fromDate, toDate } = useQuery();

  const getRRsForPir = () => {
    const params: Partial<RequestParams> = {
      fromDate: pir.periodStartDate < fromDate ? fromDate : pir.periodStartDate,
      toDate: pir.periodEndDate > toDate ? toDate : pir.periodEndDate,
      pir: pir.issueKey,
      expand: 'workforce,team',
      status: 'rejected',
      statusExcluded: true,
      orderBy: 'workforce',
    };

    dispatch(getResourceRequests({ params, id: pir.id }));
  };

  const showResourceRequests = () => {
    dispatch(!selected ? incrementOpenedRowsCounter() : decrementOpenedRowsCounter());
    setSelected(!selected);

    if (!selected) {
      getRRsForPir();
    }
  };

  useEffect(() => {
    if (selected && pir.periodEndDate >= fromDate && pir.periodStartDate <= toDate) {
      getRRsForPir();
    }
  }, [fromDate, toDate]);

  let isFirstCellWithFill: number;

  const statusColor = useMemo(() => {
    return getPirStatusColor(pir.status);
  }, [pir]);

  const resourceRequestsData = dataWithId[pir.id];

  return (
    <>
      <tr className={styles.wrapPir}>
        <td
          className={cx(styles.project, styles.pirText)}
          style={{ width: '250px', maxWidth: '250px' }}
          onMouseLeave={() => setHoveredCell(null)}
        >
          <a target="_blank" href={pir.pirLink}>
            <div
              className={cx(
                styles.summary,
                pir.linkedProjectKey
                  ? canCreateRR
                    ? styles.widthWithRR
                    : styles.widthWithRRNotCreate
                  : styles.widthWithoutRR
              )}
              id={`pir_${pir.id}`}
              onMouseEnter={() => setHoveredCell(pir.id)}
            >
              {`${pir.issueKey} - ${pir?.summary ? pir.summary : ''}`}
            </div>
          </a>

          {pir.linkedProjectKey && (
            <>
              {canCreateRR && (
                <button className={styles.plusButton} onClick={() => openModal(pir)}>
                  {bluePlus}
                </button>
              )}

              <span
                onClick={showResourceRequests}
                className={selected ? cx(styles.projectArrow, styles.arrowOpen) : styles.projectArrow}
              >
                {arrowRight}
              </span>
            </>
          )}

          <Tooltip placement="right-start" isOpen={hoveredCell === pir.id} target={`pir_${String(pir.id)}`}>
            <div>
              Status: <strong>{pir.status}</strong>
              <span className="ml-2">
                <ColoredCircle color={statusColor} />
              </span>
            </div>
            <div>
              Date: from <strong>{pir.periodStartDate}</strong> to <strong>{pir.periodEndDate}</strong>
            </div>

            {pir.hubspotLink && (
              <div>
                Hubspot:{' '}
                <strong>
                  <a href={pir.hubspotLink} target="_blank">
                    Link
                  </a>
                </strong>
              </div>
            )}
          </Tooltip>
        </td>

        {monthsList.map((month: Month, index: number) => {
          const isPirWithinCurrentMonth = isItemWithinMonth({
            startOfMonth: month.startDate,
            endOfMonth: month.endDate,
            itemStartDate: pir.periodStartDate,
            itemEndDate: pir.periodEndDate,
          });

          if (isFirstCellWithFill === undefined && isPirWithinCurrentMonth) {
            isFirstCellWithFill = index;
          }

          return (
            <td
              key={month.name}
              onClick={pir.linkedProjectKey ? showResourceRequests : undefined}
              style={{ padding: 0 }}
            >
              {isPirWithinCurrentMonth && (
                <ItemDuration
                  month={month}
                  item={pir}
                  isFirstCellWithFill={isFirstCellWithFill === index}
                  status={pir.status}
                  color={statusColor}
                  isPir={true}
                  isLastMontInList={index + 1 === monthsList.length}
                />
              )}
            </td>
          );
        })}
      </tr>

      {selected &&
        (resourceRequestsData?.length ? (
          resourceRequestsData.map((resourceRequest: TableItemsRR) => (
            <React.Fragment key={resourceRequest.id}>
              <ResourceRequestRow
                resourceRequest={resourceRequest}
                monthsList={monthsList}
                canCreateRR={canCreateRR}
                parentPirId={pir.id}
              />
            </React.Fragment>
          ))
        ) : loading && !dataWithId[pir.id] ? (
          <tr className={styles.resourceRequestRow}>
            <td>
              <Loader />
            </td>
          </tr>
        ) : (
          <tr className={styles.resourceRequestRow}>
            <td className={styles.red}>
              <ColoredCircle color={COLORS.red} /> Resource not provided
            </td>

            {monthsList.map((month: Month) => (
              <td key={month.name}></td>
            ))}
          </tr>
        ))}
    </>
  );
};

export default ProjectPirRow;
