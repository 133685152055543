import React from 'react';

import PageTitle from 'Layout/AppMain/PageTitle';
import CompanyExpenses from 'components/CompanyExpenses/CompanyExpenses';

import { companyExpensesIcon } from 'constants/icons';

const CompanyExpensesPage = () => {
  return (
    <>
      <PageTitle heading="Company Expenses" icon={companyExpensesIcon} />
      <CompanyExpenses />
    </>
  );
};

export default CompanyExpensesPage;
