import React, { FC, useRef, useState } from 'react';
import { useField } from 'formik';
import { Multiselect } from 'react-widgets';
import { FieldConfig } from 'formik/dist/Field';
import { useClickOutside } from 'hooks/useClickOutside';
import { Tooltip } from 'reactstrap';

type FormikMultiselectT = {
  label?: string;
  className: string;
  errorClassName?: string;
  data: any[];
  placeholder?: string;
  textField: string;
  disabled?: boolean | any[];
  onSubmit?: () => void;
  setCurrentlyEditing?: (value: string) => void;
} & FieldConfig;

const FormikMultiselect: FC<FormikMultiselectT> = ({
  className,
  errorClassName,
  label,
  disabled,
  onSubmit,
  setCurrentlyEditing,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { error, touched } = meta;

  const elementRef = useRef(null);
  const [openTooltip, setOpenTooltip] = useState(false);

  if (onSubmit && setCurrentlyEditing) {
    const handleSubmit = () => {
      if (JSON.stringify(field.value) !== JSON.stringify(meta.initialValue)) {
        onSubmit();
      }
      setCurrentlyEditing('');
    };

    useClickOutside(elementRef, handleSubmit);
  }

  return (
    <div
      className={className}
      style={{ position: 'relative' }}
      ref={elementRef}
      onMouseEnter={() => setOpenTooltip(true)}
      onMouseLeave={() => setOpenTooltip(false)}
    >
      {label && <p>{label}</p>}
      <Multiselect
        {...props}
        {...field}
        disabled={disabled}
        onChange={value => {
          helpers.setValue(value);
        }}
      />

      {error && touched ? (
        errorClassName ? (
          <Tooltip placement="bottom" isOpen={openTooltip} target={elementRef}>
            <div>{error}</div>
          </Tooltip>
        ) : null
      ) : null}
    </div>
  );
};

export default FormikMultiselect;
