import DealsModel from './deals.model';

class DealsFullInfo {
  total: number;
  pages: number;
  items: DealsModel[];

  constructor(data: DealsFullInfo) {
    this.total = data.total;
    this.pages = data.pages;
    this.items = data.items;
  }
}

export default DealsFullInfo;
