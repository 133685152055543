import React, { Dispatch, RefObject, SetStateAction, useCallback, useRef } from 'react';
import { useField } from 'formik';
import { FieldConfig } from 'formik/dist/Field';
import { DropdownList } from 'react-widgets';

import { useClickOutside } from 'hooks/useClickOutside';
import { closeIcon } from 'constants/icons';

import stylesModal from '../../../components/modal.module.scss';
import styles from '../Formik.module.scss';

type FormikDropdownT<T> = {
  placeholder?: string;
  label?: string;
  className?: string;
  closeIconClassName?: string;
  data: T[];
  textField?: string | ((item: { firstName: string; lastName: string }) => string);
  defaultValue?: T;
  dataKey?: string;
  busy?: boolean;
  disabled?: boolean;
  containerClassName?: string;
  dropUp?: boolean;
  addFilter?: boolean;
  handleSubmit?: () => void;
  handleChange?: (value: T) => void;
  onDropdownSearch?: (event: KeyboardEvent) => void;
  setEditMode?: Dispatch<SetStateAction<number | boolean>>;
  clearValue?: boolean;
  elementRef?: RefObject<HTMLDivElement> | null;
} & FieldConfig;

const FormikDropdown = <T,>({
  className,
  closeIconClassName,
  dataKey,
  addFilter,
  label,
  handleSubmit,
  handleChange,
  onDropdownSearch,
  setEditMode,
  clearValue,
  elementRef,
  ...props
}: FormikDropdownT<T>) => {
  const [field, meta, helpers] = useField(props.name);
  const { error, touched } = meta;

  const fieldRef = useRef<HTMLDivElement>(null);

  if (handleSubmit && setEditMode) {
    const onInputBlur = useCallback(() => {
      field.value !== meta.initialValue ? handleSubmit() : setEditMode(false);
    }, [field.value, meta.initialValue]);

    useClickOutside(fieldRef, onInputBlur, elementRef);
  }

  const onChange = (value: any) => {
    if (handleChange) {
      handleChange(value);
    }
    if (dataKey) {
      helpers.setValue(value[dataKey]);
    } else {
      helpers.setValue(value);
    }
  };

  return (
    <div ref={fieldRef} className={className} style={{ position: 'relative' }}>
      {label && <p>{label}</p>}

      {dataKey ? (
        <DropdownList
          {...props}
          filter={addFilter ? 'contains' : false}
          value={props?.data.find((el: any) => {
            return el[dataKey] == field.value;
          })}
          onChange={onChange}
          onKeyDown={onDropdownSearch}
        />
      ) : (
        <DropdownList
          filter={addFilter ? 'contains' : false}
          {...props}
          {...field}
          onChange={onChange}
          containerClassName={error && touched ? stylesModal.fieldError : ''}
          onKeyDown={onDropdownSearch}
        />
      )}

      {clearValue && (
        <div
          className={closeIconClassName ? closeIconClassName : styles.closeIcon}
          onClick={() => helpers.setValue(null)}
        >
          {closeIcon}
        </div>
      )}

      {error && touched ? <div className={styles.dropdownError}>{error}</div> : null}
    </div>
  );
};

export default FormikDropdown;
