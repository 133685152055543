import React, { FC } from 'react';
import cx from 'classnames';

import IssueModel from '../../models/issue.model';

import styles from './modal.module.scss';

type VsdModalT = {
  title: string;
  status: string;
  vsd: IssueModel[] | undefined;
  handleClosedVsd(): void;
  name: string | undefined;
};

const VsdModal: FC<VsdModalT> = ({ title, status, handleClosedVsd, vsd = [], name }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.block}>
        <button className={styles.close} onClick={handleClosedVsd} />
        <h4 className={cx('text-center', styles.name)}>{name}</h4>
        <h3 className={cx('text-center', styles.title)}>VSD {title}</h3>
        <div className={styles.itemWrap}>
          {vsd?.length ? (
            vsd?.map((item: IssueModel) => (
              <div className={styles.item} key={item.id}>
                <div className={styles.value}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.key}
                  </a>
                  <span className={styles.paymentType}>{item.paymentType}</span>
                </div>
                <div className={styles.date}>
                  {item.startDate} &mdash; {item.endDate}
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">User don't have {status} VSD &#9785;</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VsdModal;
