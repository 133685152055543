import { API, Hub } from 'aws-amplify';
import { ApiInterface } from '../models/interfaces/api-service.interface';
import notificationService, { NotificationService, NotificationType } from './notificationService';

const baseUrl = 'helloAPI';

export class HttpService {
  constructor(
    private _baseUrl: string,
    private _api: ApiInterface,
    private _hub: typeof Hub,
    private _notificationService: NotificationService
  ) {
    this.actionListener();
  }

  public async get(url: string, options?: any): Promise<any> {
    return await this._api.get(this._baseUrl, url, options).catch(error => this.handleError(error));
  }

  public post(url: string, options?: any): Promise<any> {
    return this._api.post(this._baseUrl, url, options).catch(error => this.handleError(error));
  }

  public put(url: string, options?: any): Promise<any> {
    return this._api.put(this._baseUrl, url, options).catch(error => this.handleError(error));
  }

  public patch(url: string, options?: any): Promise<any> {
    return this._api.patch(this._baseUrl, url, options).catch(error => this.handleError(error));
  }

  public delete(url: string, options?: any): Promise<any> {
    return this._api
      .del(this._baseUrl, url, options)
      .catch(error =>
        options && options.errorHandler ? options.errorHandler(error, this.handleError) : this.handleError(error)
      );
  }

  private handleError(error: Error | any) {
    if (error?.response?.data?.message) {
      this._notificationService.showNotification({
        variant: NotificationType.error,
        body: JSON.stringify(error?.response?.data?.message),
      });
    } else if (error?.message) {
      this._notificationService.showNotification({
        variant: NotificationType.error,
        body: JSON.stringify(error?.message),
      });
    }
    throw error;
  }

  private actionListener() {
    this._hub.listen('auth', ({ payload: { data, event } }) => {
      if (data?.message) {
        this._notificationService.showNotification({
          variant: NotificationType.error,
          body: data?.message || '',
          title: event,
        });
      }
    });
  }
}

const httpService = new HttpService(baseUrl, API, Hub, notificationService);

export default httpService;
