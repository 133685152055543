import PageTitle from '../../Layout/AppMain/PageTitle';
import PerformanceReview from '../../components/HR/PerformanceReview/PerformanceReview';
import { performanceReviewIcon } from 'constants/icons';

const PerformanceReviewPage = () => (
  <>
    <PageTitle heading="Performance Review" icon={performanceReviewIcon} />
    <PerformanceReview />
  </>
);

export default PerformanceReviewPage;
