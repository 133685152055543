import React, { FC, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import cx from 'classnames';

import { Table } from 'reactstrap';
import FormikDatePicker from 'common/Formik/FormikDatePicker';
import FormikInput from 'common/Formik/FormikInput';
import FormikDropdownList from 'common/Formik/FormikDropdownList';
import FormikMultiselect from 'common/Formik/FormikMultiselect';

import { updateExpirationDate, updateObligation } from 'store/obligation/obligation.thunk';
import { AppState } from 'store/configureStore';

import { ObligationTableProps, ObligationValues } from 'models/interfaces/obligation.interface';
import {
  CURRENCIES,
  CURRENCY,
  DATE_FIELDS,
  OBLIGATION_SCHEMA,
  OBLIGATION_TYPES,
  PAYMENT_FREQUENCY,
  PAYMENT_DELAY,
  CONFIRMATION_DAYS,
} from 'constants/obligations.constants';
import cleanFormEmptyFields from 'helpers/cleanFormEmptyFields';
import formatDatesFields from 'helpers/formatDatesFields';
import { getDisabledExpenseTypes } from './helpers';
import { DATE_PICKER } from 'constants/common';

import styles from './obligation.module.scss';
import './obligationInputStyle.scss';

const ObligationTable: FC<ObligationTableProps> = ({ obligation, employeeId, addObligation, isEditRole }) => {
  const dispatch = useDispatch();
  const dateRef = useRef(null);

  const { expenseTypes } = useSelector((state: AppState) => state.expenseTypeReducer);

  const [currentlyEditing, setCurrentlyEditing] = useState('');

  const canBeEdited = !addObligation && isEditRole && !obligation.approvedBy;

  const initialValues: ObligationValues = useMemo(() => {
    return {
      approvedBy: obligation?.approvedBy || null,
      employeeId: obligation?.employeeId || +employeeId,
      amount: obligation?.amount || '',
      currency: obligation?.currency || '',
      type: obligation?.type || '',
      effectiveDate: obligation?.effectiveDate,
      expirationDate: obligation?.expirationDate,
      expenseTypes: obligation?.expenseTypes || [],
      id: obligation?.id || null,
      paymentDelay: obligation?.paymentDelay || obligation?.paymentDelay === 0 ? obligation.paymentDelay : '',
      paymentFrequency: obligation?.paymentFrequency || null,
      confirmationDay: obligation?.confirmationDay || null,
    };
  }, [obligation]);

  const onSubmit = (values: ObligationValues) => {
    const clearValues = cleanFormEmptyFields(values);
    const formattedValues: any = formatDatesFields(clearValues, DATE_FIELDS);
    if (formattedValues.currency === CURRENCY.percent || formattedValues.currency === '%') {
      delete formattedValues.currency;
    }
    formattedValues.amount = String(formattedValues.amount);

    if (!obligation?.approvedBy) {
      dispatch(
        updateObligation({
          ...formattedValues,
          expenseTypes: formattedValues.expenseTypes.map((item: { id: number }): number => item.id),
          ...{ id: obligation?.id },
        })
      );
    } else if (values.expirationDate || values.expirationDate === null) {
      dispatch(
        updateExpirationDate({
          id: obligation.id,
          expirationDate: values.expirationDate
            ? format(new Date(values.expirationDate), DATE_PICKER.dateFormatToPayload)
            : values.expirationDate,
        })
      );
    }
  };

  return (
    <div className={styles.tableWrap}>
      <Table className={cx('align-middle mb-0 custom-table text-left')} style={{ overflow: 'visible' }}>
        <tbody>
          <tr className={styles.removeTopBorder}>
            <td className={styles.greyCell}>Amount</td>
            <td>
              {currentlyEditing === 'amount' ? (
                <Formik initialValues={initialValues} validationSchema={OBLIGATION_SCHEMA} onSubmit={onSubmit}>
                  {({ handleSubmit }) => (
                    <Form>
                      <FormikInput
                        name="amount"
                        type="number"
                        placeholder="Enter amount"
                        className={styles.inputWrap}
                        errorClassName={styles.error}
                        onSubmit={handleSubmit}
                        setCurrentlyEditing={setCurrentlyEditing}
                      />
                    </Form>
                  )}
                </Formik>
              ) : (
                <div
                  onClick={() => canBeEdited && setCurrentlyEditing('amount')}
                  className={cx(styles.cellTextMargin, canBeEdited ? styles.cellTextHover : '')}
                  style={{ fontWeight: '700' }}
                >
                  {obligation.amount}
                </div>
              )}
            </td>
            <td className={styles.greyCell}>Obligation type</td>
            <td>
              {currentlyEditing === 'type' ? (
                <Formik initialValues={initialValues} validationSchema={OBLIGATION_SCHEMA} onSubmit={onSubmit}>
                  {({ handleSubmit }) => (
                    <Form>
                      <FormikDropdownList
                        name="type"
                        data={OBLIGATION_TYPES}
                        placeholder="Select obligation type"
                        className="obligationDropdownWrap dropdownPadding"
                        errorClassName={styles.error}
                        onSubmit={handleSubmit}
                        setCurrentlyEditing={setCurrentlyEditing}
                      />
                    </Form>
                  )}
                </Formik>
              ) : (
                <div
                  onClick={() => canBeEdited && setCurrentlyEditing('type')}
                  className={cx(styles.cellTextMargin, canBeEdited ? styles.cellTextHover : '')}
                >
                  {obligation.type}
                </div>
              )}
            </td>
          </tr>

          <tr>
            <td className={styles.greyCell}>Expense type</td>
            <td>
              {currentlyEditing === 'expenseTypes' ? (
                <Formik initialValues={initialValues} validationSchema={OBLIGATION_SCHEMA} onSubmit={onSubmit}>
                  {({ handleSubmit, values }) => (
                    <Form>
                      <FormikMultiselect
                        name="expenseTypes"
                        data={expenseTypes || []}
                        placeholder="Select expense type"
                        className="obligationDropdownWrap multiselectPadding"
                        errorClassName={styles.error}
                        textField="title"
                        onSubmit={handleSubmit}
                        disabled={getDisabledExpenseTypes(values.expenseTypes, expenseTypes)}
                        setCurrentlyEditing={setCurrentlyEditing}
                      />
                    </Form>
                  )}
                </Formik>
              ) : (
                <div
                  onClick={() => canBeEdited && setCurrentlyEditing('expenseTypes')}
                  className={cx(styles.typesWrapper, canBeEdited ? styles.cellTextHover : '')}
                >
                  {obligation.expenseTypes?.length ? (
                    obligation.expenseTypes.map(type => (
                      <span key={type.id} className={styles.expenseType}>
                        {type.title}
                      </span>
                    ))
                  ) : (
                    <span className={styles.emptyCell}></span>
                  )}
                </div>
              )}
            </td>
            <td className={styles.greyCell}>Currency</td>
            <td>
              {currentlyEditing === 'currency' ? (
                <Formik initialValues={initialValues} validationSchema={OBLIGATION_SCHEMA} onSubmit={onSubmit}>
                  {({ handleSubmit }) => (
                    <Form>
                      <FormikDropdownList
                        name="currency"
                        data={CURRENCIES}
                        placeholder="Select currency"
                        className="obligationDropdownWrap dropdownPadding"
                        errorClassName={styles.error}
                        onSubmit={handleSubmit}
                        setCurrentlyEditing={setCurrentlyEditing}
                      />
                    </Form>
                  )}
                </Formik>
              ) : (
                <div
                  onClick={() => canBeEdited && setCurrentlyEditing('currency')}
                  className={cx(styles.cellTextMargin, canBeEdited ? styles.cellTextHover : '')}
                >
                  {obligation.currency}
                </div>
              )}
            </td>
          </tr>

          <tr ref={dateRef}>
            <td className={styles.greyCell}>Effective Date</td>
            <td>
              {currentlyEditing === 'effectiveDate' ? (
                <Formik initialValues={initialValues} validationSchema={OBLIGATION_SCHEMA} onSubmit={onSubmit}>
                  {({ handleSubmit }) => (
                    <Form>
                      <FormikDatePicker
                        name="effectiveDate"
                        dateFormat={DATE_PICKER.dateFormatToPayload}
                        placeholderText={'YYYY-MM-DD'}
                        className={styles.inputWrap}
                        errorClassName={styles.error}
                        onSubmit={handleSubmit}
                        setCurrentlyEditing={setCurrentlyEditing}
                        fieldRef={dateRef}
                        fixedHeight={true}
                      />
                    </Form>
                  )}
                </Formik>
              ) : (
                <div
                  onClick={() => canBeEdited && setCurrentlyEditing('effectiveDate')}
                  className={cx(styles.cellTextMargin, canBeEdited ? styles.cellTextHover : '')}
                >
                  {obligation.effectiveDate
                    ? format(new Date(obligation.effectiveDate), DATE_PICKER.dateFormatToPayload)
                    : ''}
                </div>
              )}
            </td>
            <td className={styles.greyCell}>Expiration Date</td>
            <td>
              {currentlyEditing === 'expirationDate' ? (
                <Formik initialValues={initialValues} validationSchema={OBLIGATION_SCHEMA} onSubmit={onSubmit}>
                  {({ handleSubmit }) => (
                    <Form>
                      <FormikDatePicker
                        name="expirationDate"
                        dateFormat={DATE_PICKER.dateFormatToPayload}
                        placeholderText={'YYYY-MM-DD'}
                        className={styles.inputWrap}
                        errorClassName={styles.error}
                        onSubmit={handleSubmit}
                        setCurrentlyEditing={setCurrentlyEditing}
                        fieldRef={dateRef}
                        fixedHeight={true}
                      />
                    </Form>
                  )}
                </Formik>
              ) : (
                <div
                  onClick={() => isEditRole && !addObligation && setCurrentlyEditing('expirationDate')}
                  className={cx(styles.cellTextMargin, isEditRole && !addObligation ? styles.cellTextHover : '')}
                >
                  {obligation.expirationDate ? (
                    format(new Date(obligation.expirationDate), DATE_PICKER.dateFormatToPayload)
                  ) : (
                    <span className={styles.emptyCell}></span>
                  )}
                </div>
              )}
            </td>
          </tr>

          <tr className={styles.lastDoubleCell}>
            <td className={styles.greyCell}>Payment Delay</td>
            <td>
              {currentlyEditing === 'paymentDelay' ? (
                <Formik initialValues={initialValues} validationSchema={OBLIGATION_SCHEMA} onSubmit={onSubmit}>
                  {({ handleSubmit }) => (
                    <Form>
                      <FormikDropdownList
                        name="paymentDelay"
                        data={PAYMENT_DELAY}
                        placeholder="Select payment delay"
                        className="obligationDropdownWrap dropdownPadding"
                        errorClassName={styles.error}
                        onSubmit={handleSubmit}
                        setCurrentlyEditing={setCurrentlyEditing}
                      />
                    </Form>
                  )}
                </Formik>
              ) : (
                <div
                  onClick={() => canBeEdited && setCurrentlyEditing('paymentDelay')}
                  className={cx(styles.cellTextMargin, canBeEdited ? styles.cellTextHover : '')}
                >
                  {obligation.paymentDelay || obligation.paymentDelay === 0 ? (
                    obligation.paymentDelay
                  ) : (
                    <span className={styles.emptyCell}></span>
                  )}
                </div>
              )}
            </td>
            <td className={styles.greyCell}>Payment Frequency</td>
            <td>
              {currentlyEditing === 'paymentFrequency' ? (
                <Formik initialValues={initialValues} validationSchema={OBLIGATION_SCHEMA} onSubmit={onSubmit}>
                  {({ handleSubmit }) => (
                    <Form>
                      <FormikDropdownList
                        name="paymentFrequency"
                        data={PAYMENT_FREQUENCY}
                        placeholder="Select payment frequency"
                        className="obligationDropdownWrap dropdownPadding"
                        errorClassName={styles.error}
                        onSubmit={handleSubmit}
                        setCurrentlyEditing={setCurrentlyEditing}
                      />
                    </Form>
                  )}
                </Formik>
              ) : (
                <div
                  onClick={() => canBeEdited && setCurrentlyEditing('paymentFrequency')}
                  className={cx(styles.cellTextMargin, canBeEdited ? styles.cellTextHover : '')}
                >
                  {obligation.paymentFrequency ? (
                    obligation.paymentFrequency
                  ) : (
                    <span className={styles.emptyCell}></span>
                  )}
                </div>
              )}
            </td>
          </tr>

          <tr>
            <td className={styles.greyCell}>Confirmation Day</td>
            <td>
              {currentlyEditing === 'confirmationDay' ? (
                <Formik initialValues={initialValues} validationSchema={OBLIGATION_SCHEMA} onSubmit={onSubmit}>
                  {({ handleSubmit }) => (
                    <Form>
                      <FormikDropdownList
                        name="confirmationDay"
                        data={CONFIRMATION_DAYS}
                        placeholder="Select confirmation day"
                        className="obligationDropdownWrap dropdownPadding"
                        errorClassName={styles.error}
                        onSubmit={handleSubmit}
                        setCurrentlyEditing={setCurrentlyEditing}
                      />
                    </Form>
                  )}
                </Formik>
              ) : (
                <div
                  onClick={() => canBeEdited && setCurrentlyEditing('confirmationDay')}
                  className={cx(styles.cellTextMargin, canBeEdited ? styles.cellTextHover : '')}
                >
                  {obligation.confirmationDay ? obligation.confirmationDay : <span className={styles.emptyCell}></span>}
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ObligationTable;
