import IssueModel from '../issue.model';
import { IssueDto, IssuesDto } from '../dto/issues.dto';
import IssuesObjectModel from '../issuesObjectModel';
import TotalVsdModal from '../totalVsd.modal';

class IssueAdapter {
  static mapIssueDtoToIssue(issue: IssueDto): IssueModel {
    return new IssueModel({
      id: issue.id,
      key: issue.key,
      startDate: issue.startDate,
      endDate: issue.endDate,
      relativeDuration: issue.relativeDuration,
      estimatedBillableHours: issue.estimatedBillableHours,
      originalTimeEstimate: issue.originalTimeEstimate,
      factualBillableHours: issue.factualBillableHours,
      timeSpent: issue.timeSpent,
      projectKey: issue.projectKey,
      paymentType: issue.paymentType,
      linkedProject: issue.linkedProject,
      type: issue.type,
      timePerDay: issue.timePerDay,
      link: issue.link,
      status: issue.status,
    });
  }

  static mapTotalVsdDtoToTotalVsd(issuesGroups: IssuesDto[]): TotalVsdModal {
    const issuesObject = issuesGroups?.find(item => {
      return item?.footer?.totalVsd && item?.footer?.totalVsd;
    });
    const totalVsd = issuesObject?.footer?.totalVsd;
    return new TotalVsdModal({
      totalPlaned: totalVsd?.totalPlaned,
      billablePlaned: totalVsd?.billablePlaned,
    });
  }

  static mapIssuesObjectDtoToIssuesObjectModel(issuesGroups: IssuesDto[]): IssuesObjectModel {
    return issuesGroups?.reduce((object: IssuesObjectModel, issuesGroup: IssuesDto) => {
      const res: IssuesObjectModel = object;
      Object.keys(issuesGroup.items).forEach((issueKey: string) => {
        if (issuesGroup?.items[issueKey]?.issues?.length) {
          res[issueKey as string] = [
            ...(res[issueKey] || []),
            ...issuesGroup.items[issueKey].issues.map((issue: IssueDto) => IssueAdapter.mapIssueDtoToIssue(issue)),
          ];
        }
      });
      return res;
    }, {});
  }
}

export default IssueAdapter;
