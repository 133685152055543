import React from 'react';
import cx from 'classnames';
import { Col, Row, Table } from 'reactstrap';

import { secondsToHours } from 'utils/dates';
import { ItemsOutInterface } from '../../../models/dto/bdm.dto';

import styles from '../../tableStyles.module.scss';

type TProps = { data: ItemsOutInterface };

const WorkforseHoursFooter: React.FC<TProps> = ({ data: { working, available } }) => {
  return (
    <div className="mt-3">
      <Row>
        <Col md={6} lg={6} xl={6}>
          <div className={styles.tableWrapper}>
            <Table responsive hover className={cx(styles.footerTableContainer, 'align-middle mb-0 text-truncate')}>
              <tbody>
                <tr className={styles.removeTopBorder}>
                  <td className={styles.rowWrapper}>Total working</td>
                  <td>{secondsToHours(working)}</td>
                </tr>

                <tr>
                  <td className={styles.rowWrapper}>Total available</td>
                  <td>{secondsToHours(available)}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WorkforseHoursFooter;
