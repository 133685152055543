import PageTitle from '../../Layout/AppMain/PageTitle';
import { hrAssigneesIcon } from 'constants/icons';
import Assignees from 'components/HR/Assignees/Assignees';

const AssigneesPage = () => {
  return (
    <>
      <PageTitle heading="Assignees" icon={hrAssigneesIcon} />
      <Assignees />
    </>
  );
};

export default AssigneesPage;
