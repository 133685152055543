import React, { useMemo, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { Form, Formik } from 'formik';

import ProfileEditButtons from './ProfileEditButtons';
import Contacts from './ProfileContacts/Contacts';
import FormikCheckBox from '../../common/Formik/FormikCheckBox';
import FormikInput from '../../common/Formik/FormikInput';

import { ProfilePrivateInitialValuesT, ProfilePrivateT } from 'models/interfaces/employeeProfileProps';
import { updateEmployeeByIdThunk } from 'store/employee';
import { updateEmployeeByIdT } from 'services/employeeService';

import cleanFormEmptyFields from 'helpers/cleanFormEmptyFields';
import { CONTACTS_TITLE, PROFILE_PRIVATE_SCHEMA } from './constants';
import { edit } from 'constants/icons';

import styles from './Profile.module.scss';
import stylesButton from '../tableStyles.module.scss';

const ProfilePrivate: FC<ProfilePrivateT> = ({ user, isEditRole, employeeId }) => {
  const dispatch = useDispatch();

  const [isEditMode, setEditMode] = useState(false);

  const hasChildren: string = useMemo(() => (user?.privateInfo?.hasChildren ? 'yes' : 'no'), [user]);
  const isVacationPaid: string = useMemo(() => (user?.privateInfo?.isVacationPaid ? 'yes' : 'no'), [user]);

  const initialValues: ProfilePrivateInitialValuesT = {
    wearSize: user?.privateInfo?.wearSize,
    address: user?.privateInfo?.address,
    englishLevel: user?.englishLevel,
    emergencyContact: user?.privateInfo?.emergencyContact,
    isVacationPaid: user?.privateInfo?.isVacationPaid,
    hasChildren: user?.privateInfo?.hasChildren,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PROFILE_PRIVATE_SCHEMA}
      onSubmit={values => {
        const clearValues = cleanFormEmptyFields(values);
        dispatch(updateEmployeeByIdThunk({ id: employeeId, employeeData: clearValues } as updateEmployeeByIdT));
        setEditMode(false);
      }}
    >
      {({ resetForm }) => (
        <div
          className={cx(
            styles.wrapInfoProfile,
            !isEditMode && styles.wrapInfoProfileStatic,
            !isEditMode && 'wrap-info-profile-edit'
          )}
        >
          {isEditRole && !isEditMode && (
            <div className={cx('btn-wrap', styles.btnWrap)}>
              <button className={cx(stylesButton.button, stylesButton.topButton)} onClick={() => setEditMode(true)}>
                {edit} Edit
              </button>
            </div>
          )}

          <Form>
            {isEditMode && (
              <div className={cx(styles.editButton)}>
                <ProfileEditButtons resetForm={resetForm} setEditMode={setEditMode} />
              </div>
            )}

            <div className={styles.blockPadding}>
              <div className={cx(styles.flex)}>
                <div className={!isEditMode ? styles.privateBlockWrap : cx(styles.privateBlockWrap, styles.blockEdit)}>
                  <div className={isEditRole ? styles.removeMargin : styles.onlyContact}>
                    <Contacts
                      contacts={user?.phones}
                      title={CONTACTS_TITLE.phone}
                      isEditRole={isEditRole}
                      employeeId={employeeId}
                    />
                  </div>

                  <FormikInput
                    label="Address"
                    placeholder={isEditMode ? 'Employee address' : ''}
                    name="address"
                    className={styles.item}
                    disabled={!isEditMode}
                  />

                  <div className={isEditRole ? styles.removeMargin : styles.onlyContact}>
                    <Contacts
                      contacts={user?.emails}
                      title={CONTACTS_TITLE.email}
                      isEditRole={isEditRole}
                      employeeId={employeeId}
                    />
                  </div>

                  <FormikInput
                    label="Emergency contact"
                    placeholder={isEditMode ? 'Emergency contact' : ''}
                    name="emergencyContact"
                    className={styles.item}
                    disabled={!isEditMode}
                  />
                </div>
                <div className={!isEditMode ? styles.privateBlockWrap : cx(styles.privateBlockWrap, styles.blockEdit)}>
                  {isEditMode ? (
                    <FormikCheckBox label="Children" name="hasChildren" className={styles.item} />
                  ) : (
                    <div className={styles.item}>
                      <p>Children</p>
                      <div className={cx(styles.value, styles.labelCheckbox)}>{hasChildren}</div>
                    </div>
                  )}

                  <div className={isEditRole ? styles.removeMargin : styles.onlyContact}>
                    <Contacts
                      contacts={user?.childrens}
                      title={CONTACTS_TITLE.children}
                      isEditRole={isEditRole}
                      employeeId={employeeId}
                    />
                  </div>

                  <FormikInput
                    label="Wear size"
                    placeholder={isEditMode ? 'Enter size' : ''}
                    name="wearSize"
                    className={styles.item}
                    disabled={!isEditMode}
                  />

                  <FormikInput
                    label="English level"
                    placeholder={isEditMode ? 'Enter level' : ''}
                    name="englishLevel"
                    className={styles.item}
                    disabled={!isEditMode}
                  />

                  {isEditMode ? (
                    <FormikCheckBox label="Is vacation paid" name="isVacationPaid" className={styles.item} />
                  ) : (
                    <div className={styles.item}>
                      <p>Is vacation paid</p>
                      <div className={cx(styles.value, styles.labelCheckbox)}>{isVacationPaid}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default ProfilePrivate;
