import { createSlice } from '@reduxjs/toolkit';
import {
  approveOrCancelObligation,
  createObligation,
  deleteObligation,
  getObligations,
  updateExpirationDate,
  updateObligation,
} from './obligation.thunk';
import ObligationModel from '../../models/obligation.model';

interface ObligationsState {
  loading: boolean;
  obligations: ObligationModel[] | null;
  isUpdatedObligation: boolean;
  error: Error | null;
}

const initialState: ObligationsState = {
  loading: false,
  obligations: null,
  isUpdatedObligation: false,
  error: null,
};

export const obligationsSlice = createSlice({
  name: 'obligation',
  initialState,
  reducers: {
    clearObligations: () => {
      return initialState;
    },
  },
  extraReducers: {
    // get obligations
    [getObligations.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [getObligations.fulfilled.toString()]: (state, { payload }) => {
      state.obligations = payload;
      state.loading = false;
    },
    [getObligations.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // create obligation
    [createObligation.pending.toString()]: state => {
      state.isUpdatedObligation = true;
      state.error = null;
    },
    [createObligation.fulfilled.toString()]: (state, { payload }) => {
      if (state.obligations?.length) {
        state.obligations = [payload, ...state.obligations];
      } else {
        state.obligations = [payload];
      }
      state.isUpdatedObligation = false;
    },
    [createObligation.rejected.toString()]: (state, { payload }) => {
      state.isUpdatedObligation = false;
      state.error = payload;
    },
    // edit obligation
    [updateObligation.pending.toString()]: state => {
      state.isUpdatedObligation = true;
      state.error = null;
    },
    [updateObligation.fulfilled.toString()]: (state, { payload }) => {
      if (state.obligations !== null) {
        const updatedObligationIndex = state.obligations.findIndex(
          (obligation: ObligationModel) => obligation.id === payload.id
        );
        state.obligations[updatedObligationIndex] = payload;
      }
      state.isUpdatedObligation = false;
    },
    [updateObligation.rejected.toString()]: (state, { payload }) => {
      state.isUpdatedObligation = false;
      state.error = payload;
    },
    // edit expiration date
    [updateExpirationDate.pending.toString()]: state => {
      state.isUpdatedObligation = true;
      state.error = null;
    },
    [updateExpirationDate.fulfilled.toString()]: (state, { payload }) => {
      if (state.obligations !== null) {
        const updatedObligationIndex = state.obligations.findIndex(
          (obligation: ObligationModel) => obligation.id === payload.id
        );
        state.obligations[updatedObligationIndex] = {
          ...state.obligations[updatedObligationIndex],
          expirationDate: payload.expirationDate,
        };
      }
      state.isUpdatedObligation = false;
    },
    [updateExpirationDate.rejected.toString()]: (state, { payload }) => {
      state.isUpdatedObligation = false;
      state.error = payload;
    },
    // approve or cancel obligation
    [approveOrCancelObligation.pending.toString()]: state => {
      state.isUpdatedObligation = true;
      state.error = null;
    },
    [approveOrCancelObligation.fulfilled.toString()]: (state, { payload }) => {
      state.isUpdatedObligation = false;

      if (state.obligations !== null) {
        const updatedObligationIndex = state.obligations.findIndex(
          (obligation: ObligationModel) => obligation.id === payload.id
        );
        if (payload.command === 'approve') {
          state.obligations[updatedObligationIndex] = {
            ...state.obligations[updatedObligationIndex],
            approvedBy: payload.approvedBy,
          };
        } else {
          state.obligations = state.obligations?.filter(obligation => obligation.id !== payload.id);
        }
      }
    },
    [approveOrCancelObligation.rejected.toString()]: (state, { payload }) => {
      state.isUpdatedObligation = false;
      state.error = payload;
    },
    // delete obligation
    [deleteObligation.pending.toString()]: state => {
      state.isUpdatedObligation = true;
      state.error = null;
    },
    [deleteObligation.fulfilled.toString()]: (state, { payload }) => {
      state.isUpdatedObligation = false;

      if (state.obligations !== null) {
        state.obligations = state.obligations.filter(obligation => obligation.id !== payload);
      }
    },
    [deleteObligation.rejected.toString()]: (state, { payload }) => {
      state.isUpdatedObligation = false;
      state.error = payload;
    },
  },
});

export const { clearObligations } = obligationsSlice.actions;

export default obligationsSlice.reducer;
