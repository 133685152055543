import { createSlice } from '@reduxjs/toolkit';
import { getProjectsList } from './projects.thunk';
import { ProjectInitialState } from 'models/interfaces/projects.interface';

const initialState: ProjectInitialState = {
  projects: null,
  loading: false,
  error: null,
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: {
    [getProjectsList.pending.toString()]: state => {
      state.projects = null;
      state.loading = true;
      state.error = null;
    },
    [getProjectsList.fulfilled.toString()]: (state, { payload }) => {
      state.projects = payload.items;
      state.loading = false;
    },
    [getProjectsList.rejected.toString()]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export default projectsSlice.reducer;
