import React, { ReactElement } from 'react';

export const generateAvatar = (firstname: string, lastname: string): ReactElement => {
  const nameLetter = firstname.split('')[0];
  const surnameLetter = lastname.split('')[0];
  return (
    <div className="employee-avatar">
      <span>{`${nameLetter} ${surnameLetter}`}</span>
    </div>
  );
};
