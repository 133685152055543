import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getResourceRequests, getResourceRequestsIssues } from './resourceRequests.thunk';
import { RRsInitialState } from 'models/interfaces/resourceRequest.interface';
import { UpdateRR } from 'models/interfaces/updateRR.interface';

const initialState: RRsInitialState = {
  resourceRequests: [],
  dataWithId: {},
  rrForDeals: [],
  totals: null,
  pages: 0,
  totalCount: 0,
  loading: false,
  error: null,
};

const resourceRequestsSlice = createSlice({
  name: 'resourceRequests',
  initialState,
  reducers: {
    clearDataWithId: () => {
      return {
        ...initialState,
      };
    },
    updateDataWithId: (state, action: PayloadAction<Required<UpdateRR>>) => {
      const updatedData = state.dataWithId[action.payload.id].map(item => {
        if (action.payload.requestBody.issueKeys.includes(item.issueKey)) {
          return {
            ...item,
            status: action.payload.requestBody.status,
          };
        }
        return item;
      });

      return {
        ...state,
        dataWithId: {
          ...state.dataWithId,
          [action.payload.id]: updatedData,
        },
      };
    },
  },
  extraReducers: {
    [getResourceRequests.pending.toString()]: state => {
      state.resourceRequests = [];
      state.loading = true;
      state.error = null;
    },
    [getResourceRequests.fulfilled.toString()]: (state, action) => {
      state.loading = false;

      if (action.meta.arg?.id) {
        state.dataWithId = {
          ...state.dataWithId,
          [action.meta.arg.id]: action.payload.items,
        };
      } else {
        state.resourceRequests = action.payload.items;
        state.totals = action.payload.totals;
        state.pages = action.payload.pages;
        state.totalCount = action.payload.total;
      }
    },
    [getResourceRequests.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // RRs for Deal Details page
    [getResourceRequestsIssues.pending.toString()]: state => {
      state.loading = true;
      state.rrForDeals = [];
    },
    [getResourceRequestsIssues.fulfilled.toString()]: (state, { payload }) => {
      state.loading = false;
      state.rrForDeals = payload;
    },
    [getResourceRequestsIssues.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { clearDataWithId, updateDataWithId } = resourceRequestsSlice.actions;

export default resourceRequestsSlice.reducer;
