import { createSlice } from '@reduxjs/toolkit';
import { getTeamTypes } from './teamTypes.thunk';

export type TeamTypeT = {
  id: number;
  title: string;
  managerTitle: string;
};

type TeamTypesInitialState = {
  teamTypes: TeamTypeT[];
  loading: boolean;
  error: null | Error;
};

const initialState: TeamTypesInitialState = {
  teamTypes: [],
  loading: false,
  error: null,
};

const teamTypesSlice = createSlice({
  name: 'teamTypes',
  initialState,
  reducers: {},
  extraReducers: {
    [getTeamTypes.pending.toString()]: state => {
      state.teamTypes = [];
      state.loading = true;
      state.error = null;
    },
    [getTeamTypes.fulfilled.toString()]: (state, { payload }) => {
      state.teamTypes = payload;
      state.loading = false;
    },
    [getTeamTypes.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default teamTypesSlice.reducer;
