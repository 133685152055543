import * as Yup from 'yup';

export enum OBLIGATION_TYPE {
  compensationFixed = 'compensation_fixed',
  compensationTm = 'compensation_tm',
  compensation = 'compensation',
  kpi_bdm = 'kpi_bdm',
  kpi_dm = 'kpi_dm',
  kpiTeamlead = 'kpi_teamlead',
  kpiTechlead = 'kpi_techlead',
  kpiLeadgen = 'kpi_leadgen',
  kpiHrs = 'kpi_hrs',
  kpiRecruiters = 'kpi_recruiters',
  kpiPm = 'kpi_pm',
  kpiBonus = 'bonus',
  kpiPLM = 'kpi_pl_m',
  kpiPLQ = 'kpi_pl_q',
  kpiSmart = 'kpi_smart',
}

export const OBLIGATION_TYPES: string[] = [
  OBLIGATION_TYPE.compensationFixed,
  OBLIGATION_TYPE.compensationTm,
  OBLIGATION_TYPE.compensation,
  OBLIGATION_TYPE.kpi_bdm,
  OBLIGATION_TYPE.kpi_dm,
  OBLIGATION_TYPE.kpiTeamlead,
  OBLIGATION_TYPE.kpiTechlead,
  OBLIGATION_TYPE.kpiLeadgen,
  OBLIGATION_TYPE.kpiHrs,
  OBLIGATION_TYPE.kpiRecruiters,
  OBLIGATION_TYPE.kpiPm,
  OBLIGATION_TYPE.kpiBonus,
  OBLIGATION_TYPE.kpiPLM,
  OBLIGATION_TYPE.kpiPLQ,
  OBLIGATION_TYPE.kpiSmart,
];

export enum CURRENCY {
  usd = 'USD',
  uah = 'UAH',
  percent = '%',
}

export const CURRENCIES: string[] = [CURRENCY.usd, CURRENCY.uah, CURRENCY.percent];
export const DATE_FIELDS = ['effectiveDate', 'expirationDate'];

export const PAYMENT_DELAY = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const PAYMENT_FREQUENCY = [1, 3, 6, 12];

export const CONFIRMATION_DAYS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export const OBLIGATION_SCHEMA = Yup.object({
  amount: Yup.string().required(`'Amount' is a required field`),
  currency: Yup.string(),
  type: Yup.string().required(`'Obligation type' is a required field`),
  effectiveDate: Yup.date().nullable().required(`'Effective date' is a required field`),
  expirationDate: Yup.date().nullable(),
  expenseTypes: Yup.array(),
  paymentDelay: Yup.string().required(`'Payment term' is a required field`),
  paymentFrequency: Yup.string().nullable().required(`'Payment frequency' is a required field`),
  confirmationDay: Yup.string().nullable().required(`'Confirmation day' is a required field`),
});
