import React, { FC } from 'react';
import { useField } from 'formik';
import { FieldConfig } from 'formik/dist/Field';
import { Input } from 'reactstrap';
import cx from 'classnames';

import styles from '../Formik.module.scss';
import stylesCurrent from './FormikCheckBox.module.scss';

type FormikCheckBoxT = {
  className?: string;
  label?: string;
  placeholder?: string;
  checked?: boolean;
  onChange?: () => void;
} & FieldConfig;

const FormikCheckBox: FC<FormikCheckBoxT> = ({ className, label, ...props }) => {
  const [field, meta] = useField(props);
  const { error, value } = meta;

  return (
    <div className={cx(className, stylesCurrent.wrapCheckbox)}>
      {label && <p>{label}</p>}
      <div className={stylesCurrent.wrapInput}>
        <Input {...field} {...props} type="checkbox" checked={value} />
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default FormikCheckBox;
