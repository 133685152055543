import { PayloadAction } from '@reduxjs/toolkit';
import {
  GET_WORKLOGS_REQUEST,
  GET_WORKLOGS_SUCCESS,
  GET_WORKLOGS_FAILURE,
  UPDATE_WORKLOG_REQUEST,
  UPDATE_WORKLOG_SUCCESS,
  UPDATE_WORKLOG_FAILURE,
} from './vpDashboard.action';
import { WorklogState, WorklogsData } from 'models/interfaces/vpDashboard.interface';

const initialState: WorklogState = {
  loading: false,
  loadingUpdate: false,
  approvedWorklogs: [],
  error: null,
  worklogsApproval: [],
  pages: 0,
  totalCount: 0,
};

export default function vpDashboardReducer(state = initialState, action: PayloadAction<WorklogsData>) {
  switch (action.type) {
    case GET_WORKLOGS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case GET_WORKLOGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        worklogsApproval: action.payload.worklogs,
        totalCount: action.payload.total,
        pages: action.payload.pages,
      };
    }
    case GET_WORKLOGS_FAILURE: {
      return {
        ...state,
        loading: false,
        worklogsApproval: null,
        totalCount: 0,
        error: action.payload,
      };
    }
    case UPDATE_WORKLOG_REQUEST: {
      return {
        ...state,
        approvedWorklogs: state.approvedWorklogs.concat(action.payload as unknown as number),
        loadingUpdate: true,
        error: null,
      };
    }
    case UPDATE_WORKLOG_SUCCESS: {
      return {
        ...state,
        loadingUpdate: false,
        worklogsApproval: state.worklogsApproval.filter(worklogsApproval => {
          return worklogsApproval.id !== (action.payload as unknown as number);
        }),
      };
    }
    case UPDATE_WORKLOG_FAILURE: {
      return {
        ...state,
        loadingUpdate: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
