import httpService, { HttpService } from './httpService';
import ObligationDto from '../models/dto/obligation.dto';
import ObligationAdapter from '../models/adapters/obligation.adapter';
import { ExpectationInterface, ItemObligationInterface } from '../models/interfaces/obligation.interface';

export class ObligationService {
  constructor(private _httpService: HttpService) {}

  async getObligationList(params: { employee: string; active?: boolean }) {
    const res = await this._httpService.get('/obligations?', {
      queryStringParameters: params,
    });
    return res.map((obligationType: ObligationDto) => ObligationAdapter.mapObligationDtoToObligation(obligationType));
  }

  async createObligation(body: ItemObligationInterface) {
    return await this._httpService.post(`/obligations`, {
      body,
    });
  }

  async updateObligation({ id, syncedAt, ...body }: ItemObligationInterface) {
    return await this._httpService.put(`/obligations/${id}`, {
      body,
    });
  }

  async updateObligationApproval(params: { id: number; command: string }) {
    const options = {
      headers: {
        'content-type': 'application/json; charset=UTF-8',
      },
    };
    return await this._httpService.post(`/obligations/${params.id}/${params.command}`, options);
  }

  async updateObligationCancelApproval(id: number) {
    const options = {
      headers: {
        'content-type': 'application/json; charset=UTF-8',
      },
    };
    return await this._httpService.post(`/obligations/${id}/cancel`, options);
  }

  async deleteObligation(id: number) {
    await this._httpService.delete(`/obligations/${id}`);
  }

  async syncObligation(id: number) {
    return await this._httpService.post(`/obligations/${id}/sync`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async updateExpiration({ id, expirationDate }: ExpectationInterface) {
    return await this._httpService.patch(`/obligations/${id}`, {
      body: { expirationDate },
    });
  }
}

export default new ObligationService(httpService);
