import { Role } from '../constants/roles';

class AccountModel {
  userName: string;
  role: Role;
  availableRoles: Role[];
  email: string;
  firstName: string;
  lastName: string;
  teams: string;
  userId: string;

  constructor(data: AccountModel) {
    this.userName = data.userName;
    this.role = data.role;
    this.availableRoles = data.availableRoles;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.teams = data.teams;
    this.userId = data.userId;
  }
}

export default AccountModel;
