import { FC } from 'react';
import cx from 'classnames';

import { Col, Row, Table } from 'reactstrap';
import { secondsToHours } from 'utils/dates';

import styles from '../tableStyles.module.scss';
import { EmployeeInfoT } from 'store/eventsEmployees';

interface WorkforceCostsFooter {
  blendedRate: number;
  blendedRateExcludingTeamLead: number;
  employees: EmployeeInfoT[];
  numberOfAdded: number;
  numberOfDismissed: number;
  teamLeadsCost: number;
  totalAddedCost: number;
  totalCost: number;
  totalDismissedCost: number;
  totalTeamLeadHours: number;
  totalUnchangedCost: number;
  totalWorkingTime: number;
}

interface FooterProps {
  data: WorkforceCostsFooter;
}

const WorkforceCostsFooter: FC<FooterProps> = ({ data }) => {
  return (
    <div className="mt-3">
      <Row>
        <Col md={6} lg={6} xl={6}>
          <div className={styles.tableWrapper}>
            <Table responsive hover className={cx(styles.footerTableContainer, 'align-middle mb-0 text-truncate')}>
              <tbody>
                <tr className={styles.removeTopBorder}>
                  <td className={styles.rowWrapper}>Total Cost</td>
                  <td style={{ fontWeight: '700' }}>
                    ${data.totalCost} ({secondsToHours(data.totalWorkingTime)})
                  </td>
                </tr>

                <tr className={styles.greenRow}>
                  <td>Added</td>
                  <td>
                    ${data.totalAddedCost} ({data.numberOfAdded})
                  </td>
                </tr>

                <tr className={styles.redRow}>
                  <td>Dismissed</td>
                  <td>
                    ${data.totalDismissedCost} ({data.numberOfDismissed}){' '}
                  </td>
                </tr>

                <tr>
                  <td className={styles.rowWrapper}>Unchanged</td>
                  <td>${data.totalUnchangedCost}</td>
                </tr>

                <tr>
                  <td className={styles.rowWrapper}>Blended rate</td>
                  <td>${data.blendedRate}</td>
                </tr>

                <tr>
                  <td className={styles.rowWrapper}>Blended rate (excluding lead)</td>
                  <td>${data.blendedRateExcludingTeamLead}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WorkforceCostsFooter;
