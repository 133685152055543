import { TotalDto } from './dto/bdm.dto';

class TotalModel {
  id: number;
  bdm: string;
  bdmId: number;
  totalApprovedHours: number;
  approvedAmount: number;
  nonApprovedHours: number;
  nonApprovedAmount: number;

  constructor(data: TotalDto) {
    this.id = data.id;
    this.bdm = data.bdm;
    this.bdmId = data.bdmId;
    this.totalApprovedHours = data.totalApprovedHours;
    this.approvedAmount = data.approvedAmount;
    this.nonApprovedHours = data.nonApprovedHours;
    this.nonApprovedAmount = data.nonApprovedAmount;
  }
}

export default TotalModel;
