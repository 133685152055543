import PageTitle from 'Layout/AppMain/PageTitle';
import HrEmployees from 'components/HR/Employees/HrEmployees';
import { employeesPageIcon } from 'constants/icons';

const EmployeesPage = () => {
  return (
    <>
      <PageTitle heading="Employees" icon={employeesPageIcon} />
      <HrEmployees />
    </>
  );
};

export default EmployeesPage;
