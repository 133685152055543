import AccountDto from '../dto/account.dto';
import AccountModel from '../account.model';
import { Role } from '../../constants/roles';

class AccountAdapter {
  static mapAccountDtoToAccount(account: AccountDto, role: Role | null): AccountModel {
    return new AccountModel({
      userName: account['cognito:username'],
      role: role ? role : Role[account['custom:role']?.split(',')?.[0] as Role] || 'user',
      availableRoles: (account['custom:role']?.split(',') as Role[]) || [],
      email: account.email,
      firstName: account.given_name,
      lastName: account.family_name,
      teams: account.teams,
      userId: account.userId,
    });
  }
}

export default AccountAdapter;
