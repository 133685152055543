import PageTitle from '../../Layout/AppMain/PageTitle';
import Deals from '../../components/BDM/Deals/Deals';
import { dealsIcon } from 'constants/icons';

const DealsPage = () => (
  <>
    <PageTitle heading="Deals" icon={dealsIcon} />
    <Deals />
  </>
);

export default DealsPage;
