import React, { FC, useRef, useState } from 'react';
import { useField } from 'formik';
import { Input, Tooltip } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import { useClickOutside } from 'hooks/useClickOutside';

import styles from '../Formik.module.scss';
import stylesModal from '../../../components/modal.module.scss';

type FormikInputT = {
  className?: string;
  errorClassName?: string;
  label?: string;
  placeholder?: string;
  name: string;
  disabled?: boolean;
  type?: InputType;
  inputNumerProps?: any;
  onSubmit?: () => void;
  setCurrentlyEditing?: (value: string) => void;
};
const FormikInput: FC<FormikInputT> = ({
  className,
  errorClassName,
  label,
  inputNumerProps,
  onSubmit,
  setCurrentlyEditing,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { error, touched } = meta;

  const elementRef = useRef(null);
  const [openTooltip, setOpenTooltip] = useState(false);

  if (onSubmit && setCurrentlyEditing) {
    const handleSubmit = () => {
      if (field.value !== meta.initialValue) {
        onSubmit();
      }
      setCurrentlyEditing('');
    };

    useClickOutside(elementRef, handleSubmit);
  }

  return (
    <div
      className={className}
      style={{ position: 'relative' }}
      ref={elementRef}
      onMouseEnter={() => setOpenTooltip(true)}
      onMouseLeave={() => setOpenTooltip(false)}
    >
      {label && <p className="label-wrapper">{label}</p>}
      <Input
        {...field}
        {...props}
        {...inputNumerProps}
        className={error && touched ? (errorClassName ? errorClassName : stylesModal.error) : ''}
      />

      {error && touched ? (
        errorClassName ? (
          <Tooltip placement="bottom" isOpen={openTooltip} target={elementRef}>
            <div>{error}</div>
          </Tooltip>
        ) : (
          <div className={styles.inputError}>{error}</div>
        )
      ) : null}
    </div>
  );
};

export default FormikInput;
