class EmployeeHRModel {
  address?: string;
  birthday?: string;
  confluencePrivatePage?: string;
  dismissalDate?: string;
  email: string;
  englishLevel?: string;
  fullName: string;
  grade?: string;
  hasStartedThisMonth: boolean;
  hasUnapprovedObligations: boolean;
  id: number;
  isEngineeringlead: boolean;
  isTeamlead: boolean;
  isTechlead: boolean;
  location?: string;
  nextPerformanceReviewDate?: string;
  position?: number;
  salaryPrediction: number;
  startDate: string;
  team: string;
  title: string;
  trialEndDate: string;

  constructor(data: EmployeeHRModel) {
    this.address = data.address;
    this.birthday = data.birthday;
    this.confluencePrivatePage = data.confluencePrivatePage;
    this.dismissalDate = data.dismissalDate;
    this.email = data.email;
    this.englishLevel = data.englishLevel;
    this.fullName = data.fullName;
    this.grade = data.grade;
    this.hasStartedThisMonth = data.hasStartedThisMonth;
    this.hasUnapprovedObligations = data.hasUnapprovedObligations;
    this.id = data.id;
    this.isEngineeringlead = data.isEngineeringlead;
    this.isTeamlead = data.isTeamlead;
    this.isTechlead = data.isTechlead;
    this.location = data.location;
    this.nextPerformanceReviewDate = data.nextPerformanceReviewDate;
    this.position = data.position;
    this.salaryPrediction = data.salaryPrediction;
    this.startDate = data.startDate;
    this.team = data.team;
    this.title = data.title;
    this.trialEndDate = data.trialEndDate;
  }
}

export default EmployeeHRModel;
