import { createAsyncThunk } from '@reduxjs/toolkit';

import employeeService, { updateEmployeeByIdT } from 'services/employeeService';
import employeesService from 'services/employeesService';
import { NotificationType } from 'services/notificationService';
import { enqueueSnackbar } from 'store/notifications';

import { AssigneesRequestParams } from 'models/interfaces/assignees.interface';
import { RequestParams } from 'utils/mapParams';

interface GetEmployeesParams {
  id?: number;
  params: Partial<RequestParams>;
}

export const getEmployees = createAsyncThunk(
  'employees/getEmployees',
  async (requestParams: GetEmployeesParams, thunkAPI) => {
    try {
      const res = await employeesService.getEmployeesData({ ...requestParams.params });
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const updateEmployeeById = createAsyncThunk(
  'employees/updateEmployeeById',
  async (params: updateEmployeeByIdT, thunkAPI) => {
    try {
      const res = await employeeService.editEmployeeById({ id: params.id, employeeData: params.employeeData });

      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Employee updated successfully!',
            title: 'Employee',
          },
        })
      );

      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const updateEmployees = createAsyncThunk(
  'employees/updateEmployees',
  async (employeesData: AssigneesRequestParams, thunkAPI) => {
    try {
      const res = await employeesService.editEmployeesData(employeesData);

      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Employees updated successfully!',
            title: 'Employees',
          },
        })
      );

      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
