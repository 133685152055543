import React, { FC, useMemo, useState } from 'react';
import cx from 'classnames';

import { secondsToDays } from '../../helpers/formatTime';
import VsdModal from './VsdModal';
import { ISSUE_STATUS } from '../../constants/issues.constants';
import { VSD_TITLE } from './constants';
import { VsdInformationT } from 'models/interfaces/employeeProfileProps';

import styles from './Profile.module.scss';

const VsdInformation: FC<VsdInformationT> = ({ user }) => {
  const [isOpenModalVsd, setIsOpenModalVsd] = useState('');

  const handleSpentShowVsd = () => {
    setIsOpenModalVsd(VSD_TITLE.history);
  };
  const handleShowPlannedVsd = () => {
    setIsOpenModalVsd(VSD_TITLE.planned);
  };
  const handleClosedVsd = () => {
    setIsOpenModalVsd('');
  };
  const vsdModalProps = useMemo(
    () =>
      isOpenModalVsd === VSD_TITLE.history
        ? {
            title: VSD_TITLE.history,
            status: ISSUE_STATUS.closed,
            vsd: user?.closedVsd,
          }
        : { title: VSD_TITLE.planned, status: ISSUE_STATUS.approved, vsd: user?.plannedVsd },
    [isOpenModalVsd, user]
  );

  const fullPlannedTime = useMemo(() => {
    return user?.plannedVsd?.reduce((time, currentTime) => (time += currentTime?.relativeDuration), 0);
  }, [user]);

  return (
    <div className={styles.blockPadding}>
      <div className={styles.blockWrap}>
        <div className={cx(styles.item, styles.vsdItems)}>
          <div className={styles.label}>Available VSD</div>
          <div className={styles.value}>{secondsToDays(user?.vsdStats?.available)}</div>
        </div>
        <div className={cx(styles.item, styles.vsdItems)}>
          <div className={styles.label}>Accumulated VSD</div>
          <div className={styles.value}>{secondsToDays(user?.vsdStats?.accumulated)}</div>
        </div>
        <div className={cx(styles.item, styles.vsdItems)}>
          <div className={styles.label}>Spent VSD</div>
          <div className={styles.value}>
            <span>{secondsToDays(user?.vsdStats?.spent)}</span>
            {user && (
              <button
                onClick={handleSpentShowVsd}
                disabled={!user?.closedVsd?.length}
                type="button"
                className={styles.vsdButton}
              >
                See history
              </button>
            )}
          </div>
        </div>
        <div className={cx(styles.item, styles.vsdItems)}>
          <div className={styles.label}>Planned VSD</div>
          <div className={styles.value}>
            <span>{fullPlannedTime ? secondsToDays(fullPlannedTime) : 0}</span>
            {user && (
              <button
                onClick={handleShowPlannedVsd}
                disabled={!user?.plannedVsd?.length}
                type="button"
                className={styles.vsdButton}
              >
                See planned
              </button>
            )}
          </div>
        </div>
      </div>
      {isOpenModalVsd && <VsdModal handleClosedVsd={handleClosedVsd} name={user?.fullName} {...vsdModalProps} />}
    </div>
  );
};

export default VsdInformation;
