import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody } from 'reactstrap';
import AssigneesTable from './AssigneesTable';
import Loader from 'common/Loader';
import TeamFilter from 'common/TeamFilter/TeamFilter';

import { getEmployees } from 'store/allEmployees/allEmployees.thunk';
import { AppState } from 'store/configureStore';
import TeamModel from 'models/team.model';

import { useQuery } from 'hooks/queryHook';
import { formatAssignees } from 'utils/formatDatas';
import { RequestParams } from 'utils/mapParams';
import { Role } from 'constants/roles';
import EmployeeAdapter from 'models/adapters/employee.adapter';
import { EmployeeDto } from 'models/dto/employee.dto';

const Assignees = () => {
  const dispatch = useDispatch();

  const { role } = useSelector((state: AppState) => state.account?.user);
  const { employees, hrTeamEmployees, loading } = useSelector((state: AppState) => state.allEmployeesReducer);
  const { loading: teamsLoading, teams } = useSelector((state: AppState) => state.teamsReducer);

  const hrAssignees = EmployeeAdapter.mapEmployeeDtoToHrAssignees(employees);
  const hrList = hrTeamEmployees && formatAssignees(hrTeamEmployees);

  const { team, setTeamData } = useQuery();

  const isEditRole = role === Role.vp;

  const getData = useCallback(() => {
    const params: Partial<RequestParams> = { expand: 'hr' };

    if (team) {
      params.team = +team;
    }
    dispatch(getEmployees({ params }));
  }, [team]);

  const fetchData = async () => {
    if (!hrTeamEmployees.length && teams) {
      const hrTeam = teams.find((team: TeamModel) => team.title.includes('HR'));
      sessionStorage.setItem('hrTeamId', hrTeam.id);

      await dispatch(getEmployees({ params: { team: hrTeam.id } }) as unknown as Promise<EmployeeDto[]>);
    }
    getData();
  };

  useEffect(() => {
    if (teams) {
      fetchData();
    }
  }, [getData, teams]);

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className="bp-header">
          <div className="filters-block">
            <TeamFilter team={team} setTeam={setTeamData} />
          </div>
        </div>
        {hrAssignees.length ? (
          <AssigneesTable data={hrAssignees} hrTeamEmployees={hrList || []} isEditRole={isEditRole} team={team} />
        ) : loading || teamsLoading ? (
          <Loader />
        ) : (
          teams && 'Assignees are empty'
        )}
      </CardBody>
    </Card>
  );
};

export default Assignees;
