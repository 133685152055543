import TeamModel from '../team.model';
import TeamDto from '../dto/team.dto';

export default class TeamAdapter {
  static mapTeamDtoToTeam(team: TeamDto): TeamModel {
    return new TeamModel({
      id: team.id,
      title: team.title,
      email: team.email,
      isAvailableForPlanning: team.isAvailableForPlanning,
      closeDate: team.closeDate,
      department: team.department,
      teamleadId: team.teamleadId,
      parentId: team.parentId,
      children: team.children,
      typeId: team.typeId,
      type: team.type,
    });
  }
}
