const alphabeticCollator = new Intl.Collator(undefined, {});

export const sortData = (tableData, sortKey, sortOrder, sortType) => {
  if (!sortOrder || !sortType) {
    return tableData;
  }

  const sorted = [...tableData].sort((a, b) => {
    if (!a[sortKey] || a[sortKey] === '-') return 1;
    if (!b[sortKey] || b[sortKey] === '-') return -1;

    if (sortType === 'numeric' && sortOrder === 'asc') {
      return a[sortKey] - b[sortKey];
    }

    if (sortType === 'numeric' && sortOrder === 'desc') {
      return b[sortKey] - a[sortKey];
    }

    if (sortType === 'string' && sortOrder === 'asc') {
      return alphabeticCollator.compare(a[sortKey], b[sortKey]);
    }

    if (sortType === 'string' && sortOrder === 'desc') {
      return alphabeticCollator.compare(b[sortKey], a[sortKey]);
    }
  });

  return sorted;
};
