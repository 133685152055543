import { AppState } from '../configureStore';
import { createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';

import { ContactItemInterface } from '../../models/dto/employee.dto';
import profileContactService from '../../services/profileContactService';
import { enqueueSnackbar } from '../notifications';
import { NotificationType } from '../../services/notificationService';
import { createEmployeeContactThunk, updateEmployeeContactThunk, deleteEmployeeContactThunk } from '../employee';

interface ProfileContactsState {
  loading: boolean;
  contact: ContactItemInterface | null;
  error: Error | null;
}

const initialState: ProfileContactsState = {
  loading: false,
  contact: null,
  error: null,
};

export const profileContactsSlice = createSlice({
  name: 'profile employee',
  initialState,
  reducers: {
    createContactRequest: (state: ProfileContactsState) => {
      return {
        ...state,
        loading: true,
        contact: null,
        error: null,
      };
    },
    createContactSuccess: (state: ProfileContactsState, action) => {
      return {
        ...state,
        loading: false,
        contact: action.payload,
        error: null,
      };
    },
    createContactFailure: (state: ProfileContactsState, action: PayloadAction<Error>) => {
      return {
        ...state,
        loading: false,
        contact: null,
        error: action.payload,
      };
    },
    updateContactRequest: (state: ProfileContactsState) => {
      return {
        ...state,
        loading: true,
        contact: null,
        error: null,
      };
    },
    updateContactSuccess: (state: ProfileContactsState, action) => {
      return {
        ...state,
        loading: false,
        contact: action.payload,
        error: null,
      };
    },
    updateContactFailure: (state: ProfileContactsState, action: PayloadAction<Error>) => {
      return {
        ...state,
        loading: false,
        contact: null,
        error: action.payload,
      };
    },
    deleteContactRequest: (state: ProfileContactsState) => {
      return {
        ...state,
        loading: true,
        contact: null,
        error: null,
      };
    },
    deleteContactSuccess: (state: ProfileContactsState, action) => {
      return {
        ...state,
        loading: false,
        contact: action.payload,
        error: null,
      };
    },
    deleteContactFailure: (state: ProfileContactsState, action: PayloadAction<Error>) => {
      return {
        ...state,
        loading: false,
        contact: null,
        error: action.payload,
      };
    },
  },
});

export const {
  createContactRequest,
  createContactSuccess,
  createContactFailure,
  updateContactRequest,
  updateContactSuccess,
  updateContactFailure,
  deleteContactRequest,
  deleteContactSuccess,
  deleteContactFailure,
} = profileContactsSlice.actions;

export default profileContactsSlice.reducer;

type contactThunkT = {
  type: string;
};

type createContactThunkT = contactThunkT & { contact: ContactItemInterface };

export const createContactThunk =
  ({ type, contact }: createContactThunkT) =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(createContactRequest());
    try {
      const contactItem: ContactItemInterface = await profileContactService.createContact({ type, contact });
      dispatch(createContactSuccess(contactItem));
      dispatch(createEmployeeContactThunk(contactItem, type));
      dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: `Profile ${type} create successfully!`,
            title: 'Profile',
          },
        })
      );
    } catch (err) {
      dispatch(createContactFailure(err as Error));
    }
  };

type updateContactThunkT = contactThunkT & {
  id: number | undefined;
  contact: ContactItemInterface;
};

export const updateContactThunk =
  ({ id, type, contact }: updateContactThunkT) =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(updateContactRequest());
    try {
      const contactItem: ContactItemInterface = await profileContactService.updateContact({
        id,
        type,
        contact,
      });
      dispatch(updateContactSuccess(contactItem));
      dispatch(updateEmployeeContactThunk(contactItem, type));
      dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: `Profile ${type} update successfully!`,
            title: 'Profile',
          },
        })
      );
    } catch (err) {
      dispatch(updateContactFailure(err as Error));
    }
  };

export type deleteContactThunkT = contactThunkT & { id: number | undefined };

export const deleteContactThunk =
  ({ id, type }: deleteContactThunkT) =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(deleteContactRequest());
    try {
      const contactItem: ContactItemInterface = await profileContactService.deleteContact({ id, type });
      dispatch(deleteContactSuccess(contactItem));
      dispatch(deleteEmployeeContactThunk(id!, type));
      dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: `Profile ${type} delete successfully!`,
            title: 'Profile',
          },
        })
      );
    } catch (err) {
      dispatch(deleteContactFailure(err as Error));
    }
  };
