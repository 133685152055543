import React from 'react';
import cx from 'classnames';

import styles from './workloadStyle.module.scss';

const LegendBlock: React.FC = () => {
  return (
    <div className={styles.legendBlock}>
      <div className={styles.legendItem}>
        <div className={cx(styles.roundMark, styles.correctLog)}></div>
        <div>Equal to working day</div>
      </div>

      <div className={styles.legendItem}>
        <div className={cx(styles.roundMark, styles.loggedLess)}></div>
        <div>Less than working day</div>
      </div>

      <div className={styles.legendItem}>
        <div className={cx(styles.roundMark, styles.loggedMore)}></div>
        <div>More than working day</div>
      </div>

      <div className={styles.legendItem}>
        <div className={cx(styles.roundMark, styles.downtime)}></div>
        <div>Downtime</div>
      </div>

      <div className={styles.legendItem}>
        <div className={cx(styles.roundMark, styles.shift)}></div>
        <div>Shift</div>
      </div>

      <div className={styles.legendItem}>
        <div className={cx(styles.roundMark, styles.tealBorder)}></div>
        <div>Shifted</div>
      </div>

      <div className={styles.legendItem}>
        <div className={cx(styles.roundMark, styles.dayOff)}></div>
        <div>VSD</div>
      </div>
    </div>
  );
};

export default LegendBlock;
