import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import NavigationHistory from 'components/NavigationHistory/NavigationHistory';

class PageTitle extends Component {
  randomize(myArray) {
    return myArray[Math.floor(Math.random() * myArray.length)];
  }

  render() {
    let {
      enablePageTitleSubheading,

      heading,
      icon,
      subheading,
    } = this.props;

    return (
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className="page-title-icon">{icon}</div>
            <div>
              {heading}
              <div
                className={cx('page-title-subheading', {
                  'd-none': !enablePageTitleSubheading,
                })}
              >
                {subheading}
              </div>
            </div>
          </div>
        </div>
        <NavigationHistory />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  enablePageTitleIcon: state.themeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.themeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
