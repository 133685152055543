import React, { FC } from 'react';
import { Table } from 'reactstrap';
import cx from 'classnames';

import { secondsToHours } from '../../utils/dates';
import { ORTableItem, ORTableProps } from 'models/interfaces/overtimeRequests.interface';
import { PAYMENT_TYPE } from 'models/enums/payment-type.enum';

import styles from '../tableStyles.module.scss';
import orStyles from './orStyles.module.scss';

const OvertimeRequestTable: FC<ORTableProps> = ({ issues }) => {
  return (
    <div className={cx(styles.tableWrapper, 'mb-3')}>
      <Table responsive hover className={cx(styles.tableContainer, 'align-middle mb-0 custom-table text-left')}>
        <thead>
          <tr className={orStyles.headCell}>
            <th style={{ width: '40px' }}>#</th>
            <th>Ticket key</th>
            <th className={orStyles.headCellWide}>Resource</th>
            <th className={orStyles.headCellWide}>Project</th>
            <th>Coef</th>
            <th>Est hours</th>
            <th>Logged hours</th>
            <th>Start date</th>
            <th>End date</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {issues.map((item: ORTableItem, index: number) => {
            return (
              <tr key={index} className={styles.removeBorder}>
                <td>{index + 1}</td>
                <td>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.key}
                  </a>
                </td>
                <td>{item.resourceName}</td>
                <td>
                  <a href={item.linkedProjectLink} target="_blank" rel="noopener noreferrer">
                    {item.linkedProject}
                  </a>
                </td>
                <td>{item.coefficient}</td>
                <td>{secondsToHours(item.originalTimeEstimate)}</td>
                <td>{secondsToHours(item.timeSpent)}</td>
                <td>{item.startDate}</td>
                <td>{item.endDate}</td>
                <td className={item.paymentType === PAYMENT_TYPE.billable ? styles.greenRow : styles.redRow}>
                  {item.paymentType}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default OvertimeRequestTable;
