import React, { useMemo, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { getEmployees } from 'store/allEmployees/allEmployees.thunk';
import { AppState } from 'store/configureStore';

import FormikDatePicker from '../../common/Formik/FormikDatePicker';
import FormikInput from '../../common/Formik/FormikInput';
import FormikCheckBox from '../../common/Formik/FormikCheckBox';
import FormikDropdown from 'common/Formik/FormikDropdown';

import { DATE_PICKER } from '../../constants/common';
import { getFullName } from 'helpers/companyStructureHeplers';
import { currentDay } from 'helpers/formatTime';
import { formatAssignees } from 'utils/formatDatas';
import { EmployeeDto } from 'models/dto/employee.dto';
import { ProfilePublicT } from 'models/interfaces/employeeProfileProps';

import styles from './Profile.module.scss';

const ProfilePublic: FC<ProfilePublicT> = ({
  startDate,
  user,
  isEditMode,
  isEditAvailability,
  isEditHrManager,
  isEditMentor,
  setFieldValue,
  values,
}) => {
  const dispatch = useDispatch();

  const { employees } = useSelector((state: AppState) => state.allEmployeesReducer);

  useEffect(() => {
    dispatch(getEmployees({ params: { date: currentDay() } }));
  }, []);

  const mentorsList = useMemo(() => formatAssignees(employees), [employees]);
  const hrList = useMemo(() => {
    const hrEmployees = employees.filter((employee: EmployeeDto) => employee.team?.title?.includes('HR'));
    return formatAssignees(hrEmployees);
  }, [employees]);

  const isDirectCost: string = useMemo(() => (user?.isDirectCost ? 'yes' : 'no'), [user]);
  const isTechlead: string = useMemo(() => (user?.isTechlead ? 'yes' : 'no'), [user]);
  const isIntern: string = useMemo(() => (user?.isIntern ? 'yes' : 'no'), [user]);

  return (
    <>
      <div
        className={
          !isEditMode
            ? cx('row justify-content-between m-0', styles.rowWrap)
            : cx('row justify-content-between m-0', styles.rowWrap, styles.blockEdit)
        }
      >
        <FormikDatePicker
          name="birthday"
          label="Date of birth"
          dateFormat={DATE_PICKER.dateFormatToYear}
          placeholderText={isEditMode ? DATE_PICKER.placeholder : ''}
          className={styles.item}
          showYearDropdown
          disabled={!isEditMode}
        />
        <FormikDatePicker
          name="startDate"
          label="Trial start"
          dateFormat={DATE_PICKER.dateFormatToYear}
          placeholderText={isEditMode ? DATE_PICKER.placeholder : ''}
          className={styles.item}
          showYearDropdown
          disabled={!isEditMode}
        />
        <FormikInput
          label="Location"
          placeholder={isEditMode ? 'Employee location' : ''}
          name="location"
          className={styles.item}
          disabled={!isEditMode}
        />
        <FormikDatePicker
          name="trialEndDate"
          label="Trial end"
          dateFormat={DATE_PICKER.dateFormatToYear}
          placeholderText={isEditMode ? DATE_PICKER.placeholder : ''}
          className={styles.item}
          showYearDropdown
          disabled={!isEditMode}
          minDate={startDate}
        />
        <FormikInput
          label="City"
          placeholder={isEditMode ? 'Employee city' : ''}
          name="city"
          className={styles.item}
          disabled={!isEditMode}
        />
        <FormikDatePicker
          name="dismissalDate"
          label="Dismissal Date"
          dateFormat={DATE_PICKER.dateFormatToYear}
          placeholderText={isEditMode ? DATE_PICKER.placeholder : ''}
          className={styles.item}
          showYearDropdown
          disabled={!isEditMode}
          minDate={startDate}
        />
        {isEditMode ? (
          <FormikCheckBox label="Direct Cost" name="isDirectCost" className={styles.item} />
        ) : (
          <div className={styles.item}>
            <p>Direct Cost</p>
            <div className={cx(styles.value, styles.labelCheckbox)}>{isDirectCost}</div>
          </div>
        )}
        {isEditMode && isEditMentor ? (
          <FormikDropdown
            label="Mentor"
            name="mentorId"
            placeholder="Select Mentor"
            className={cx(styles.item, 'dropdownWithSearch')}
            data={mentorsList ? mentorsList : []}
            defaultValue={user?.mentor ? user?.mentor : mentorsList[0]}
            textField={item => getFullName(item)}
            dataKey="id"
            disabled={!isEditMode}
            addFilter={true}
          />
        ) : (
          <div className={styles.item}>
            <p className="label-wrapper">Mentor</p>
            {user?.mentor ? (
              <Link to={`/dashboard/profile/${user?.mentor?.id}`}>
                <div className={cx(styles.value, 'mt-2')}>{`${user?.mentor?.firstName} ${user?.mentor?.lastName}`}</div>
              </Link>
            ) : (
              ''
            )}
          </div>
        )}
        {isEditAvailability && (
          <FormikInput
            label="Availability"
            placeholder={isEditMode ? 'Employee availability' : ''}
            name="availability"
            type="number"
            inputNumerProps={{ max: 1, min: 0, step: 'any' }}
            className={cx(styles.item, styles.availabilityInput)}
            disabled={!isEditMode}
          />
        )}
        {isEditMode && isEditHrManager ? (
          <FormikDropdown
            label="HR Manager"
            name="hrId"
            placeholder="Select HR Manager"
            className={cx(styles.item, 'dropdownWithSearch')}
            data={hrList ? hrList : []}
            defaultValue={user?.hr ? user?.hr : hrList[0]}
            textField={item => getFullName(item)}
            dataKey="id"
            disabled={!isEditMode}
          />
        ) : (
          <div className={styles.item}>
            <p className="label-wrapper">HR Manager</p>
            {user?.hr ? (
              <Link to={`/dashboard/profile/${user?.hr?.id}`}>
                <div className={cx(styles.value, 'mt-2')}>{`${user?.hr?.firstName} ${user?.hr?.lastName}`}</div>
              </Link>
            ) : (
              ''
            )}
          </div>
        )}
        {user?.emailPublic && (
          <div className={styles.item}>
            <p className="label-wrapper">Corporate email</p>
            <div className={cx(styles.value, 'mt-2')}>
              <a href={`mailto:${user.emailPublic}`}>{user.emailPublic}</a>
            </div>
          </div>
        )}
      </div>
      <div className={!isEditMode ? styles.rowWrap : cx(styles.rowWrap, styles.blockEdit)}>
        <div className={!isEditMode ? 'w-100' : styles.blockEdit}>
          {isEditMode ? (
            <FormikCheckBox
              label="Is tech lead"
              name="isTechlead"
              className={styles.item}
              onChange={() => {
                setFieldValue('isTechlead', !values.isTechlead);
                setFieldValue('isIntern', false);
              }}
            />
          ) : (
            <div className={styles.item}>
              <p className={styles.label}>Is tech lead</p>
              <div className={cx(styles.value, styles.labelCheckbox)}>{isTechlead}</div>
            </div>
          )}

          {isEditMode ? (
            <FormikCheckBox
              label="Is intern"
              name="isIntern"
              className={styles.downItem}
              onChange={() => {
                setFieldValue('isTechlead', false);
                setFieldValue('isIntern', !values.isIntern);
              }}
            />
          ) : (
            <div className={styles.downItem}>
              <p className={cx(styles.label)}>Is intern</p>
              <div className={cx(styles.value, styles.labelCheckbox)}>{isIntern}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfilePublic;
