import ExpenseTypeModel from '../expenseType.model';
import ExpenseTypeDto from '../dto/expenseType.dto';

export default class ExpenseTypeAdapter {
  static mapExpenseTypeDtoToExpenseType(expenseType: ExpenseTypeDto): ExpenseTypeModel {
    return new ExpenseTypeModel({
      id: expenseType.id,
      isConstant: expenseType.isConstant,
      title: expenseType.title,
    });
  }
}
