import { createAsyncThunk } from '@reduxjs/toolkit';
import teamTypesService from 'services/teamTypesService';

export const getTeamTypes = createAsyncThunk('teamTypes', async (_, thunkApi) => {
  try {
    const res = await teamTypesService.getTeamTypes();
    return res;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkApi.rejectWithValue(err.message.original);
  }
});
