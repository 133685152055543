import React from 'react';
import { Table } from 'reactstrap';
import cx from 'classnames';

import SortButton from 'common/SortButton/SortButton';

import TotalModel from 'models/totals.model';
import sortHeaderDto from 'models/dto/sort.dto';
import { useSort } from 'hooks/sortHook';

import styles from '../../tableStyles.module.scss';

type TProps = { data: TotalModel[]; headers: sortHeaderDto[] };

const TotalsTable: React.FC<TProps> = ({ data = [], headers }) => {
  const { sortedData, sortKey, sortOrder, onSortingChange } = useSort({ data: data });

  return (
    <div className={styles.tableWrapper}>
      <Table
        responsive
        hover
        className={cx(styles.tableContainer, styles.minWidth1000, 'align-middle mb-3 custom-table text-left')}
      >
        <thead>
          <tr>
            <th className={styles.firstCellWidth}>#</th>
            {headers?.map((item: any, index: number) => (
              <th key={item.value} className={!!index ? undefined : styles.firstCell}>
                <SortButton
                  onClick={() => onSortingChange(item.value, item.sortType)}
                  sortKey={sortKey}
                  sortOrder={sortOrder}
                  sortField={item.value}
                  sortTitle={item.title}
                />
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {sortedData?.map((item: TotalModel, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.bdm || '-'}</td>
              <td>{item.totalApprovedHours || '-'}</td>
              <td>{item.approvedAmount || '-'}</td>
              <td>{item.nonApprovedHours || '-'}</td>
              <td>{item.nonApprovedAmount || '-'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TotalsTable;
