import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ChangeExpenseParams,
  CreateExpenseParams,
  DeleteExpenseParams,
} from 'models/interfaces/companyExpenses.interface';
import companyExpensesService from 'services/companyExpensesService';
import { NotificationType } from 'services/notificationService';
import { enqueueSnackbar } from 'store/notifications';
import { RequestParams } from 'utils/mapParams';

export const getCompanyExpenses = createAsyncThunk(
  'companyExpenses/getCompanyExpenses',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const res = await companyExpensesService.getCompanyExpenses(params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const getCompanyExpensesById = createAsyncThunk(
  'companyExpenses/getCompanyExpensesById',
  async (id: number, thunkAPI) => {
    try {
      const res = await companyExpensesService.getCompanyExpensesById(id);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const createCompanyExpenses = createAsyncThunk(
  'companyExpenses/createCompanyExpenses',
  async (params: CreateExpenseParams, thunkAPI) => {
    const { values, callback } = params;
    try {
      const res = await companyExpensesService.createCompanyExpenses(values);
      callback();
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Company Expense created successfully!',
            title: 'Company Expense',
          },
        })
      );
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const updateCompanyExpenses = createAsyncThunk(
  'companyExpenses/updateCompanyExpenses',
  async (params: ChangeExpenseParams, thunkAPI) => {
    const { id, values, callback } = params;
    try {
      const res = await companyExpensesService.updateCompanyExpenses({ id, values });
      callback();
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Company Expense updated successfully!',
            title: 'Company Expense',
          },
        })
      );
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const deleteCompanyExpenses = createAsyncThunk(
  'companyExpenses/deleteCompanyExpenses',
  async (params: DeleteExpenseParams, thunkAPI) => {
    const { id, callback } = params;
    try {
      await companyExpensesService.deleteCompanyExpenses(id);
      callback();
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Company Expense deleted successfully!',
            title: 'Company Expense',
          },
        })
      );
      return id;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
