import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import cx from 'classnames';

import { Card, CardBody } from 'reactstrap';

import PerformanceReviewDetailsTable from './PerformanceReviewDetailsTable';
import Pagination from 'components/Pagination/Pagination';
import Loader from 'common/Loader';
import CreateEmployeePRModal from './Modals/CreateEmployeePRModal';
import Modal from './Modals/Modal';
import DeleteModal from 'common/Modals/DeleteModal';
import TeamFilter from 'common/TeamFilter/TeamFilter';
import Search from 'common/Search/Search';

import {
  getPerformanceReviewDetails,
  createPerformanceReviewCycle,
  deletePerformanceReviewCycle,
  getPerformanceReviewById,
} from 'store/performanceReview/performanceReview.thunk';
import { clearState, setStatePerfomanceRequest } from 'store/performanceReview';
import { AppState } from 'store/configureStore';
import EmployeeModel from 'models/employee.model';
import { RequestParams } from 'utils/mapParams';

import { DATE_PICKER } from 'constants/common';
import { Role } from 'constants/roles';
import { plus } from 'constants/icons';
import { useQuery } from 'hooks/queryHook';

import styles from '../../tableStyles.module.scss';

const PerformanceReviewDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { role } = useSelector((state: AppState) => state.account.user);
  const { performanceReviewDetails, loading, pages, total, selectedCycle, selectedCycleEmployee } = useSelector(
    (state: AppState) => state.performanceReviewReducer
  );

  const [open, setOpen] = useState(false);

  const { page, pageSize, team, search, setPage, setPageSize, setTeamValue, setSearch } = useQuery();

  const [searchText, setSearchText] = useState(search ? search : '');

  const getData = useCallback(() => {
    const params: Partial<RequestParams> = {
      cycle: id,
      page: +page,
      size: +pageSize,
    };

    if (team) {
      params.team = +team;
    }
    if (search) {
      params.search = search;
    }

    dispatch(getPerformanceReviewDetails(params));
  }, [id, team, page, pageSize, search, dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    dispatch(getPerformanceReviewById(id));
    return () => {
      dispatch(clearState());
    };
  }, []);

  const accessEdit = selectedCycle?.status === 'Draft' && (role === Role.hr || role === Role.vp);

  const onCreateEmployee = (values: { employee: EmployeeModel | null }) => {
    if (values.employee?.id) {
      dispatch(
        createPerformanceReviewCycle({
          employeeId: values.employee.id,
          performanceReviewCycleId: +id,
        })
      );
    }

    setSearchText('');
    setSearch('');
    setOpen(false);
  };

  const setCurrentPageHandle = (value: number) => value !== page && setPage(value, searchText);
  const setCountPerPageHandler = (value: number) => setPageSize(value, searchText);

  const onDeleteModalClose = () => {
    dispatch(setStatePerfomanceRequest({ type: 'selectedCycleEmployee', data: null }));
  };

  const onDeleteEmployee = () => {
    dispatch(deletePerformanceReviewCycle(selectedCycleEmployee?.id));
    onDeleteModalClose();
  };

  const onChangeSearch = (event: React.FormEvent) => {
    setSearch(searchText.trim());
    event.preventDefault();
  };

  return (
    <>
      <div className={styles.buttonWrap}>
        {accessEdit && (
          <button className={cx(styles.button, styles.topButton)} onClick={() => setOpen(true)}>
            {plus} Add employee
          </button>
        )}
      </div>

      <Card className="main-card mb-3">
        <CardBody>
          <div className="bp-header">
            <div className="filters-block">
              <Search searchValue={searchText} setSearchValue={setSearchText} updateSearchValue={onChangeSearch} />

              <TeamFilter team={team} setTeam={setTeamValue} planning={true} searchData={searchText} />

              <div className={styles.dateWrap}>
                <div className={cx('label-wrapper', styles.labelMargin)}>Start Date</div>
                <span>
                  {!!selectedCycle?.startDate &&
                    format(new Date(selectedCycle?.startDate), DATE_PICKER.dateFormatMonthAndDay)}
                </span>
              </div>

              <div>
                <div className={cx('label-wrapper', styles.labelMargin)}>End Date</div>
                <span>
                  {!!selectedCycle?.endDate &&
                    format(new Date(selectedCycle?.endDate), DATE_PICKER.dateFormatMonthAndDay)}
                </span>
              </div>
            </div>
          </div>

          <div>{loading && <Loader />}</div>

          {performanceReviewDetails?.items?.length ? (
            <div className={cx(styles.tableWrapper, 'mb-3')} style={{ overflow: 'unset' }}>
              <PerformanceReviewDetailsTable
                data={performanceReviewDetails?.items}
                accessEdit={accessEdit}
                currentPage={+page}
                perPage={+pageSize}
              />

              <div className={styles.marginBottomTable}>
                <Pagination
                  pages={pages}
                  perPage={+pageSize}
                  totalCount={total}
                  setCurrentPage={setCurrentPageHandle}
                  currentPage={+page}
                  setCountPerPage={setCountPerPageHandler}
                  count={0}
                />
              </div>
            </div>
          ) : (
            !loading && <div>Performance Review Cycles are empty</div>
          )}
        </CardBody>
      </Card>

      {open && (
        <Modal title={'Add employee'} onClose={() => setOpen(false)} blockStyle={{ overflow: 'unset' }}>
          <CreateEmployeePRModal onSubmit={onCreateEmployee} onClose={() => setOpen(false)} />
        </Modal>
      )}

      {!!selectedCycleEmployee && (
        <DeleteModal
          title={'employee'}
          itemToDelete={`${selectedCycleEmployee.employee.firstName} ${selectedCycleEmployee.employee.lastName} from PR list`}
          onClose={onDeleteModalClose}
          onDelete={onDeleteEmployee}
        />
      )}
    </>
  );
};

export default PerformanceReviewDetails;
