export default class HolidaysModel {
  id: number;
  title: string;
  duration: string;
  date: string;

  constructor(data: HolidaysModel) {
    this.id = data.id;
    this.title = data.title;
    this.duration = data.duration;
    this.date = data.date;
  }
}
