import httpService, { HttpService } from './httpService';
import { RequestParams } from '../utils/mapParams';
import { InitialCreatePRDto, InitialCreateCyclePRDto } from 'models/dto/performanceReview.dto';

export class PerformanceReviewService {
  constructor(private _httpService: HttpService) {}

  async getPerformanceReviews(params: Partial<RequestParams>) {
    const res = await this._httpService.get('/performance-review-cycles', {
      queryStringParameters: params,
    });
    return res;
  }

  async getPerformanceReviewById(id: string) {
    const res = await this._httpService.get(`/performance-review-cycles/${id}`);
    return res;
  }

  async createPerformanceReview(values: InitialCreatePRDto) {
    const res = await this._httpService.post('/performance-review-cycles', {
      body: values,
    });
    return res;
  }

  async getPerformanceReviewDetails(params: Partial<RequestParams>) {
    const res = await this._httpService.get('/performance-reviews?', { queryStringParameters: params });
    return res;
  }
  async createPerformanceReviewCycle(values: InitialCreateCyclePRDto) {
    const res = await this._httpService.post('/performance-reviews', {
      body: values,
    });
    return res;
  }
  async changeStatusPerformanceReview(values: { id: number; command: string }) {
    const res = await this._httpService.post(`/performance-review-cycles/${values.id}/${values.command}`, {
      headers: { 'content-type': 'application/json; charset=UTF-8' },
    });
    return res;
  }

  async deletePerformanceReviewCycle(id: number) {
    await this._httpService.delete(`/performance-reviews/${id}`);
    return id;
  }
  async deletePerformanceReview(id: number) {
    const res = await this._httpService.delete(`/performance-review-cycles/${id}`);
    return res;
  }

  async updatePerformanceReview(params: { id: number; values: InitialCreatePRDto }) {
    const res = await this._httpService.put(`/performance-review-cycles/${params.id}`, {
      body: params.values,
    });
    return res;
  }
}

const performanceReviewService = new PerformanceReviewService(httpService);

export default performanceReviewService;
