import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/configureStore';
import cx from 'classnames';

import FormikInput from '../../../common/Formik/FormikInput';
import FormikDropdown from '../../../common/Formik/FormikDropdown';

import { getTeamsListThunk } from 'store/teams/teams.thunk';
import { getSalesGradesThunk } from 'store/Profile/profile.thunk';

import { formatDropdown } from '../../../utils/functions';
import { Role } from 'constants/roles';
import { GRADE_DROPDOWN, SALES_GRADE_DROPDOWN } from '../constants';
import { ProfileHeaderT } from 'models/interfaces/employeeProfileProps';

import stylesCurrent from './profileHeader.module.scss';

const ProfileHeaderEdit: FC<ProfileHeaderT> = ({ user }) => {
  const dispatch = useDispatch();

  const { teams } = useSelector((state: AppState) => state.teamsReducer);
  const { salesGrades } = useSelector((state: AppState) => state.profileReducer);
  const { role } = useSelector((state: AppState) => state.account?.user);

  const isEditSalesGrade = useMemo(() => role === Role.vp, [role]);

  const team = useMemo(
    () =>
      user?.team || {
        id: 0,
        title: 'Select Team',
      },
    [user]
  );

  useEffect(() => {
    if (!teams) dispatch(getTeamsListThunk());
  }, [dispatch]);

  useEffect(() => {
    if (isEditSalesGrade) {
      dispatch(getSalesGradesThunk());
    }
  }, [dispatch]);

  return (
    <div className={stylesCurrent.textWrap}>
      <FormikInput
        label="First Name"
        placeholder="Enter first name"
        name="firstName"
        className={stylesCurrent.inputWrap}
      />
      <FormikInput
        label="Last Name"
        placeholder="Enter last name"
        name="lastName"
        className={stylesCurrent.inputWrap}
      />
      <FormikInput
        label="Title"
        placeholder="Enter title"
        name="title"
        className={cx(stylesCurrent.inputWrap, stylesCurrent.longInputWrap)}
      />
      <FormikDropdown
        label="Grade"
        name="grade"
        placeholder="Select Grade"
        className={stylesCurrent.inputWrap}
        data={GRADE_DROPDOWN}
      />
      <FormikDropdown
        label="Team"
        name="teamId"
        placeholder="Select Team"
        className={cx(stylesCurrent.inputWrap, stylesCurrent.longInputWrap)}
        data={teams ? teams : []}
        defaultValue={team}
        textField="title"
        dataKey="id"
        busy={!teams}
      />
      {isEditSalesGrade && (
        <div style={{ width: '100%' }}>
          <FormikDropdown
            label="Sales Grade"
            name="salesGradeId"
            placeholder="Select Sales Grade"
            className={stylesCurrent.inputWrap}
            data={formatDropdown(salesGrades) || SALES_GRADE_DROPDOWN}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileHeaderEdit;
