import React, { FC } from 'react';
import cx from 'classnames';

import { Col, Row, Table } from 'reactstrap';
import { ExpensesFooterProps } from 'models/interfaces/companyExpenses.interface';

import styles from '../tableStyles.module.scss';

const CompanyExpensesFooter: FC<ExpensesFooterProps> = ({ totalPlanned }) => {
  return (
    <div className="mt-3">
      <Row>
        <Col md={6} lg={6} xl={6}>
          <div className={styles.tableWrapper}>
            <Table responsive hover className={cx(styles.footerTableContainer, 'align-middle mb-0 text-truncate')}>
              <tbody>
                <tr className={styles.removeTopBorder}>
                  <td className={styles.rowWrapper}>Total Planned Expenses</td>
                  <td>$ {totalPlanned?.amount}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyExpensesFooter;
