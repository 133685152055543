import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { editIcon, flag, play, remove } from 'constants/icons';
import { StatusPerformanceReview } from 'constants/performanceReview';
import { PerformanceReviewDto } from 'models/dto/performanceReview.dto';
import { setStatePerfomanceRequest } from 'store/performanceReview';

import styles from './performanceReview.module.scss';

type PropsType = {
  item: PerformanceReviewDto;
  onOpenEdit: () => void;
  loading: boolean;
};

const ActionButtons: React.FC<PropsType> = ({ item, onOpenEdit, loading = false }) => {
  const dispatch = useDispatch();

  const onChangeStatus = useCallback(
    (status: string) => {
      dispatch(setStatePerfomanceRequest({ type: 'openChangeStatusPR', data: status }));
      dispatch(setStatePerfomanceRequest({ type: 'performanceReview', data: item }));
    },
    [item, dispatch]
  );

  const onOpenEditPR = useCallback(() => {
    onOpenEdit();
    dispatch(setStatePerfomanceRequest({ type: 'performanceReview', data: item }));
  }, [item, dispatch, onOpenEdit]);

  return (
    <div className={styles.buttonsWrap}>
      {item.status === StatusPerformanceReview.active && (
        <button
          className={cx(styles.complete, { [styles.disabled]: loading })}
          onClick={() => onChangeStatus('complete')}
          disabled={loading}
        >
          {flag} Complete
        </button>
      )}

      {item.status === StatusPerformanceReview.draft && (
        <>
          <button
            className={cx(styles.success, { [styles.disabled]: loading })}
            onClick={() => onChangeStatus('start')}
            disabled={loading}
          >
            {play} Start
          </button>

          <button className={cx(styles.edit, { [styles.disabled]: loading })} onClick={onOpenEditPR} disabled={loading}>
            {editIcon} Edit
          </button>

          <button
            className={cx(styles.delete, { [styles.disabled]: loading })}
            onClick={() => onChangeStatus('delete')}
            disabled={loading}
          >
            {remove} Delete
          </button>
        </>
      )}
    </div>
  );
};

export default ActionButtons;
