import { FC, useCallback } from 'react';
import cx from 'classnames';
import { Table } from 'reactstrap';

import SortButton from 'common/SortButton/SortButton';
import EmployeeTitle from 'common/EmployeeTitle/EmployeeTitle';
import ColoredCircle from '../ColoredCircle/ColoredCircle';

import { useSort } from 'hooks/sortHook';

import { secondsToHours } from 'utils/dates';
import { COLORS } from 'config/theme';
import sortHeaderDto from 'models/dto/sort.dto';
import EmployeeWorkforceModel from 'models/employeeWorkforce.model';

import styles from '../tableStyles.module.scss';

interface PropsT {
  data: EmployeeWorkforceModel[];
  headers: sortHeaderDto[];
  isSalesGrade: boolean;
}

const EmployeesTable: FC<PropsT> = ({ data, headers, isSalesGrade }) => {
  const { sortedData, sortKey, sortOrder, onSortingChange } = useSort({ data: data });

  const renderList = useCallback(() => {
    if (sortedData) {
      return sortedData.map((item: EmployeeWorkforceModel, index: number) => {
        return (
          <tr
            style={
              item.dismissalDate
                ? { color: 'var(--danger)' }
                : item.hasStartedThisMonth
                ? { color: 'var(--success)' }
                : {}
            }
            key={item.email}
          >
            <td>{index + 1}</td>
            <td>
              {item.dismissalDate ? <span>--</span> : ''}
              {item.hasStartedThisMonth ? <span>++</span> : ''}
              <a
                style={
                  item.dismissalDate
                    ? { color: 'var(--danger)' }
                    : item.hasStartedThisMonth
                    ? { color: 'var(--success)' }
                    : {}
                }
                href={`#/dashboard/profile/${item.id}`}
              >
                {item.email}
              </a>
              <EmployeeTitle employee={item} />
            </td>
            <td>{item.isDirectCost ? 'direct' : 'indirect'}</td>
            <td>{item.startDate}</td>
            <td>{item.dismissalDate}</td>
            <td>
              <a href={`#/dashboard/obligation/${item.id}`}>
                {item.salaryPrediction}
                {item.hasUnapprovedObligations && (
                  <span className="ml-2">
                    <ColoredCircle color={COLORS.red} />
                  </span>
                )}
              </a>
            </td>
            <td>{secondsToHours(item.timeByMonth)}</td>
            <td>{item.team}</td>
            <td>{item.title}</td>
            {isSalesGrade && <td>{item.salesGrade}</td>}
            <td>{item.location}</td>
            <td>{item.address}</td>
          </tr>
        );
      });
    } else return null;
  }, [sortedData]);
  const renderTable = () => {
    if (sortedData?.length) {
      return (
        <div className={styles.tableWrapper}>
          <Table
            responsive
            hover
            className={cx(styles.tableContainer, 'align-middle mb-0 custom-table text-truncate text-left')}
          >
            <thead>
              <tr>
                <th>#</th>
                {headers?.map(item => (
                  <th key={item.value}>
                    <SortButton
                      onClick={() => onSortingChange(item.value, item.sortType)}
                      sortKey={sortKey}
                      sortOrder={sortOrder}
                      sortField={item.value}
                      sortTitle={item.title}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{renderList()}</tbody>
          </Table>
        </div>
      );
    } else return null;
  };

  return renderTable();
};

export default EmployeesTable;
