import {
  AssignedPerson,
  ContactItemInterface,
  PrivateInfoInterface,
  SalesGradeInterface,
  TeamInterface,
  VsdStatsInterface,
} from './dto/employee.dto';
import EmployeeModel from './employee.model';

export class EmployeeProfileModel extends EmployeeModel {
  grade: string;
  team: TeamInterface | null;
  departmentTitle: string;
  emailPublic: string;
  location: string;
  city?: string;
  birthday: string;
  startDate: string;
  trialEndDate: string;
  dismissalDate: string;
  privateInfo: PrivateInfoInterface | null;
  vsdStats: VsdStatsInterface | null;
  phones: ContactItemInterface[];
  emails: ContactItemInterface[];
  englishLevel: string;
  salesGrade?: SalesGradeInterface;
  salesGradeId?: {};
  childrens: ContactItemInterface[];
  mentor: AssignedPerson | null;
  hr: AssignedPerson | null;

  constructor(data: Omit<EmployeeProfileModel, 'fullName'>) {
    super(data);
    this.grade = data.grade;
    this.team = data.team;
    this.departmentTitle = data.departmentTitle;
    this.emailPublic = data.emailPublic;
    this.location = data.location;
    this.city = data.city;
    this.birthday = data.birthday;
    this.startDate = data.startDate;
    this.trialEndDate = data.trialEndDate;
    this.dismissalDate = data.dismissalDate;
    this.privateInfo = data.privateInfo;
    this.vsdStats = data.vsdStats;
    this.phones = data.phones;
    this.emails = data.emails;
    this.englishLevel = data.englishLevel;
    this.salesGrade = data.salesGrade;
    this.salesGradeId = data.salesGradeId;
    this.childrens = data.childrens;
    this.mentor = data.mentor;
    this.hr = data.hr;
  }
}
