import { useField } from 'formik';
import { FieldConfig } from 'formik/dist/Field';
import DatePicker from 'react-datepicker';

import stylesModal from '../../../components/modal.module.scss';
import styles from '../Formik.module.scss';

type FormikRangeDatePickerT = {
  dateFormat: string;
  placeholderText?: string;
  label?: string;
  className?: string;
  showYearDropdown?: boolean;
  minDate?: Date | string;
  maxDate?: Date | string;
  disabled?: boolean;
  onChange?: (value: Array<Date | null>) => void;
} & FieldConfig;

const FormikDateRangePicker = ({ className, label, onChange, ...props }: FormikRangeDatePickerT) => {
  const [field, meta, helpers] = useField(props);
  const { error, value, touched } = meta;

  const handleChange = (value: Array<Date | null>) => {
    if (onChange) {
      onChange(value);
    }
    helpers.setValue(value);
  };

  const [startDate, endDate] = field.value;

  return (
    <div className={className} style={{ position: 'relative' }}>
      {label && <p className="label-wrapper">{label}</p>}
      <DatePicker
        {...props}
        {...field}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        className={error && touched ? stylesModal.error : ''}
        selectsRange={true}
      />
      {error && touched ? <div className={styles.dateError}>{error}</div> : null}
    </div>
  );
};

export default FormikDateRangePicker;
