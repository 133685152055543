import PageTitle from '../../Layout/AppMain/PageTitle';
import ProjectCalendar from 'components/ProjectCalendar/ProjectCalendar';
import { holidaysPageIcon } from 'constants/icons';

const ProjectCalendarPage = () => {
  return (
    <>
      <PageTitle heading="Project Calendar" icon={holidaysPageIcon} />
      <ProjectCalendar />
    </>
  );
};

export default ProjectCalendarPage;
