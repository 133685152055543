import React, { FC } from 'react';

import { ActionButtonsProps } from 'models/interfaces/commonComponents.interface';
import { editIcon, remove } from 'constants/icons';

import styles from '../../components/HR/PerformanceReview/performanceReview.module.scss';

const ActionButtons: FC<ActionButtonsProps> = ({ handleClickOnEdit, handleClickOnDelete }) => {
  return (
    <div className={styles.buttonsWrap}>
      <button className={styles.edit} onClick={handleClickOnEdit}>
        {editIcon} Edit
      </button>

      <button className={styles.delete} onClick={handleClickOnDelete}>
        {remove} Delete
      </button>
    </div>
  );
};

export default ActionButtons;
