import PageTitle from '../../Layout/AppMain/PageTitle';
import Availability from '../../components/BDM/Availability/Availability';
import { availabilityIcon } from 'constants/icons';

const AvailabilityPage = () => {
  return (
    <>
      <PageTitle heading="Workforce Availability" icon={availabilityIcon} />
      <Availability />
    </>
  );
};

export default AvailabilityPage;
