import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Card, CardBody } from 'reactstrap';

import CompanyExpensesTable from './CompanyExpensesTable';
import CompanyExpensesFooter from './CompanyExpensesFooter';
import Loader from 'common/Loader';
import Pagination from 'components/Pagination/Pagination';
import Datepicker from 'components/Datepicker/Datepicker';
import CreateOrEditExpense from './Modals/CreateOrEditExpense';
import DeleteModal from '../../common/Modals/DeleteModal';

import { AppState } from 'store/configureStore';
import {
  createCompanyExpenses,
  deleteCompanyExpenses,
  getCompanyExpenses,
  updateCompanyExpenses,
} from 'store/companyExpenses/companyExpenses.thunk';
import { InitialExpenseValues } from 'models/interfaces/companyExpenses.interface';

import { useQuery } from 'hooks/queryHook';
import { formatCategories } from 'utils/functions';
import { DATE_PICKER } from 'constants/common';
import { plus } from 'constants/icons';
import { Role } from 'constants/roles';

import styles from '../tableStyles.module.scss';

const CompanyExpenses = () => {
  const dispatch = useDispatch();

  const { expenses, totalPlanned, pages, total, loading } = useSelector(
    (state: AppState) => state.companyExpensesReducer
  );
  const { expenseCategories } = useSelector((state: AppState) => state.expenseCategoriesReducer);
  const { role } = useSelector((state: AppState) => state.account?.user);

  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [expenseId, setExpenseId] = useState(0);

  const isEditRole = useMemo(() => role === Role.hr || role === Role.vp, [role]);

  const { page, pageSize, fromDate, toDate, setPage, setPageSize, setDateRange } = useQuery();

  const getExpensesData = useCallback(() => {
    if (!fromDate || !toDate) {
      setDateRange(
        format(startOfMonth(new Date()), DATE_PICKER.dateFormatToPayload),
        format(endOfMonth(new Date()), DATE_PICKER.dateFormatToPayload)
      );
    } else {
      const params = {
        fromDate: fromDate,
        toDate: toDate,
        page: page ? +page : 1,
        size: pageSize ? +pageSize : 50,
      };
      dispatch(getCompanyExpenses(params));
    }
  }, [pageSize, page, fromDate, toDate]);

  useEffect(() => {
    getExpensesData();
  }, [getExpensesData]);

  const setCurrentPageHandler = (value: number) => {
    if (value !== +page) {
      setPage(value);
    }
  };

  const setCountPerPageHandler = (value: number) => {
    setPageSize(value);
  };

  const onDateChange = (date: Date) => {
    setDateRange(
      format(startOfMonth(date), DATE_PICKER.dateFormatToPayload),
      format(endOfMonth(date), DATE_PICKER.dateFormatToPayload)
    );
  };

  // open modal windows
  const handleClickOnAdd = () => {
    setExpenseId(0);
    setModalTitle('Add New Expense');
    setOpenModal(true);
  };

  const handleClickOnEdit = (id: number) => {
    setExpenseId(id);
    setModalTitle('Edit Expense');
    setOpenModal(true);
  };

  const handleClickOnDelete = (id: number) => {
    setOpenDelete(true);
    setExpenseId(id);
  };

  // close modal windows
  const onDeleteModalClose = () => {
    setOpenDelete(false);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  // submit functions for modal windows
  const onSubmitExpense = (values: InitialExpenseValues) => {
    const categoryId = values.categoryId?.name ? values.categoryId.name : values.categoryId;

    const data = {
      amount: String(values.amount),
      currency: values.currency,
      description: values?.description,
      categoryId: formatCategories(expenseCategories, categoryId as string),
      effectiveDate: format(new Date(values.effectiveDate), DATE_PICKER.dateFormatToPayload),
      expirationDate: !!values.expirationDate
        ? format(new Date(values.expirationDate), DATE_PICKER.dateFormatToPayload)
        : null,
      recurringPeriod: values.repeat ? values?.recurringPeriod : null,
    };

    expenseId !== 0
      ? dispatch(updateCompanyExpenses({ id: expenseId, values: data, callback: getExpensesData }))
      : dispatch(createCompanyExpenses({ values: data, callback: getExpensesData }));

    setOpenModal(false);
  };

  const onDeleteExpense = (id: number) => {
    dispatch(deleteCompanyExpenses({ id, callback: getExpensesData }));
    setOpenDelete(false);
  };

  return (
    <>
      <Card className="main-card mb-3">
        <CardBody>
          <div className="bp-header">
            <div className="filters-block">
              <div className="dropdown-filter">
                <div className="label-wrapper">Date</div>
                <Datepicker
                  selected={!!fromDate ? new Date(fromDate) : startOfMonth(new Date())}
                  dateFormat={DATE_PICKER.dateFormatMonth}
                  showMonthYearPicker
                  onChange={onDateChange}
                />
              </div>
            </div>

            <div className={styles.buttonWrap}>
              <Link className={styles.lineButton} to="/dashboard/company_expense_categories?page=1&size=50">
                Expense Categories
              </Link>

              {isEditRole && (
                <button className={cx(styles.button, styles.addButton)} onClick={handleClickOnAdd}>
                  {plus} Add New Expense
                </button>
              )}
            </div>
          </div>

          {expenses?.length ? (
            <>
              <div className={cx(styles.tableWrapper, 'mb-1')} style={{ overflow: 'unset' }}>
                <CompanyExpensesTable
                  data={expenses || []}
                  isEditRole={isEditRole}
                  handleClickOnEdit={handleClickOnEdit}
                  handleClickOnDelete={handleClickOnDelete}
                  currentPage={+page}
                  perPage={+pageSize}
                />

                <div className={styles.marginBottomTable}>
                  <Pagination
                    pages={pages}
                    perPage={+pageSize}
                    totalCount={total}
                    setCurrentPage={setCurrentPageHandler}
                    currentPage={+page}
                    setCountPerPage={setCountPerPageHandler}
                    count={0}
                  />
                </div>
              </div>
              <CompanyExpensesFooter totalPlanned={totalPlanned} />
            </>
          ) : loading ? (
            <Loader />
          ) : (
            'Company expenses are empty'
          )}

          {openModal && (
            <CreateOrEditExpense
              title={modalTitle}
              id={expenseId}
              onCloseModal={onCloseModal}
              onSubmitExpense={onSubmitExpense}
            />
          )}

          {openDelete && (
            <DeleteModal
              title={'Expense'}
              itemToDelete={'this expense'}
              onClose={onDeleteModalClose}
              onDelete={() => onDeleteExpense(expenseId)}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default CompanyExpenses;
