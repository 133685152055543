import { CommonRequestParams } from 'models/dto/params.dto';
import { RequestParams } from './mapParams';

export const generateSortingUrl = (data: CommonRequestParams | Partial<RequestParams>) => {
  return Object.entries(data)
    .map(([k, v]) => {
      return `${k}=${v}`;
    })
    .join('&');
};
