import { PAYMENT_TYPE } from 'models/enums/payment-type.enum';
import { ORTableItem, OvertimeRequestsData, OvertimeRequestsT } from 'models/interfaces/overtimeRequests.interface';

export const transformOvertimeRequests = (data: OvertimeRequestsData): OvertimeRequestsT | null => {
  if (!data) return null;

  let billableTotalEstimate = 0;
  let billableTotalLogged = 0;
  let nonBillableTotalEstimate = 0;
  let nonBillableTotalLogged = 0;

  const arrOfIssues: ORTableItem[] = [];
  Object.values(data.items).forEach(item => arrOfIssues.push(...item.issues));

  arrOfIssues.forEach((item: ORTableItem) => {
    if (item.paymentType === PAYMENT_TYPE.billable) {
      billableTotalEstimate += item.originalTimeEstimate;
      billableTotalLogged += item.timeSpent;
    }
    if (item.paymentType === PAYMENT_TYPE.nonBillable) {
      nonBillableTotalEstimate += item.originalTimeEstimate;
      nonBillableTotalLogged += item.timeSpent;
    }
  });

  return {
    issues: arrOfIssues,
    footer: {
      billableTotalEstimate,
      billableTotalLogged,
      nonBillableTotalEstimate,
      nonBillableTotalLogged,
    },
  };
};
