import PageTitle from '../../Layout/AppMain/PageTitle';
import Totals from '../../components/BDM/Totals/Totals';
import { totalsPageIcon } from 'constants/icons';

const TotalsPage = () => (
  <>
    <PageTitle heading="Totals" icon={totalsPageIcon} />
    <Totals />
  </>
);

export default TotalsPage;
