import { Link } from 'react-router-dom';
import cx from 'classnames';
import { TeamManagerProps } from 'models/interfaces/companyStructure.interface';
import styles from './companyStructure.module.scss';

const TeamManager = ({ team, employees, className }: TeamManagerProps) => {
  const teamManager = employees.find(employee => employee.id === team.teamleadId);
  const teamManagerTitle = team.type?.managerTitle
    ?.split(' ')
    .map((str: string) => str[0])
    .join('');

  return teamManager ? (
    <div className={cx(styles.team, className)}>
      {teamManagerTitle}
      <span className={styles.circle} />
      <Link to={`/dashboard/profile/${team.teamleadId}`}>
        {teamManager.firstName} {teamManager.lastName}
      </Link>
    </div>
  ) : null;
};

export default TeamManager;
