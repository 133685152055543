import { store } from '../App';
import { enqueueSnackbar } from '../store/notifications';

export enum NotificationType {
  default = 'default',
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}

interface NotificationInterface {
  title?: string;
  body: string;
  variant: NotificationType;
}

export class NotificationService {
  showNotification(options: NotificationInterface) {
    store.dispatch(
      enqueueSnackbar({
        options: {
          key: new Date().getTime() + Math.random(),
          ...options,
        },
      })
    );
  }
}

export default new NotificationService();
