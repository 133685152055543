import { createAsyncThunk } from '@reduxjs/toolkit';
import { BodyHolidayRequestDto } from '../../models/dto/holidays.dto';
import holidaysService from '../../services/holidaysService';

export const getHolidays = createAsyncThunk('holidays/getHolidays', async (params: any, thunkAPI) => {
  try {
    const res = await holidaysService.getHolidays(params);
    return res;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const getHolidayById = createAsyncThunk('holidays/getHolidayById', async (id: number, thunkAPI) => {
  try {
    const res = await holidaysService.getHolidayById(id);
    return res;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const createHoliday = createAsyncThunk(
  'holidays/createHoliday',
  async (values: BodyHolidayRequestDto, thunkAPI) => {
    try {
      const res = await holidaysService.createHoliday(values);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const editHoliday = createAsyncThunk(
  'holidays/editHoliday',
  async (data: { id: number; values: BodyHolidayRequestDto }, thunkAPI) => {
    try {
      const res = await holidaysService.editHoliday(data);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const deleteHoliday = createAsyncThunk('holidays/deleteHoliday', async (id: number, thunkAPI) => {
  try {
    await holidaysService.deleteHoliday(id);
    return id;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});
