import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Hub } from 'aws-amplify';
import { useDispatch } from 'react-redux';

import PageNotFound from 'pages/PageNotFound/PageNotFound';
import { logOutUser, checkSessionThunk } from '../../store/account';

type PrivateRouteT = {
  children: JSX.Element;
  isAuthorized: boolean;
  canActivate: boolean;
};

const PrivateRoute = ({ children, isAuthorized, canActivate }: PrivateRouteT) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const verifiedKey = sessionStorage.getItem('SESSION_IS_AUTHENTICATED_KEY');
    if (!isAuthorized && !verifiedKey)
      history.push({ pathname: '/login', state: { from: `${location.pathname}${location.search || ''}` } });
  }, [history, location, isAuthorized]);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          if (isAuthorized) return;
          dispatch(checkSessionThunk());
          break;
        case 'oAuthSignOut':
        case 'signOut':
          dispatch(logOutUser());
          break;
        default:
          console.log('Sign in failure', event, data);
          break;
      }
    });
  }, [dispatch, isAuthorized]);

  if (canActivate && isAuthorized) {
    return children;
  } else if (!canActivate && isAuthorized) {
    return <PageNotFound />;
  }

  return null;
};

export default PrivateRoute;
