export enum StatusPerformanceReview {
  active = 'Active',
  draft = 'Draft',
  completed = 'Completed',
}

export enum CommandPerformanceReview {
  start = 'start',
  complete = 'complete',
  delete = 'delete',
}

export const PERFORMANCE_REVIEW_TABLE_HEADERS = [
  { id: 1, title: 'Title', value: 'title', sortType: 'string' },
  { id: 2, title: 'Start Date', value: 'startDate', sortType: 'string' },
  { id: 3, title: 'End Date', value: 'endDate', sortType: 'string' },
  { id: 4, title: 'Status', value: 'status', sortType: 'string' },
];
