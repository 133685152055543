import React, { Fragment } from 'react';
import TeamLeadWorkforce from '../../components/TeamLead/TeamLeadWorkforce';

import PageTitle from '../../Layout/AppMain/PageTitle';
import { teamLeadTeamIcon } from 'constants/icons';

const TeamLeadWorkforcePage = () => {
  return (
    <Fragment>
      <PageTitle heading="Team Profile" icon={teamLeadTeamIcon} />
      <TeamLeadWorkforce />
    </Fragment>
  );
};

export default TeamLeadWorkforcePage;
