import PageTitle from '../../Layout/AppMain/PageTitle';
import ResourceRequests from 'components/ResourceRequestsDashboard/ResourceRequests';
import { resourceRequestsIcon } from '../../constants/icons';

const ResourceRequestsPage = () => {
  return (
    <>
      <PageTitle heading="Resource Requests" icon={resourceRequestsIcon} />
      <ResourceRequests />
    </>
  );
};

export default ResourceRequestsPage;
