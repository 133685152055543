import { useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import cx from 'classnames';

import ActionButtons from './ActionButtons';

import { SelectedWorkforceProps } from 'models/interfaces/createRR.interface';
import { AppState } from 'store/configureStore';
import TeamModel from 'models/team.model';
import { EmployeeDto } from 'models/dto/employee.dto';

import { arrowRight } from 'constants/icons';
import { DATE_PICKER } from 'constants/common';
import { getFullName } from 'helpers/companyStructureHeplers';

import styles from './styles.module.scss';

const SelectedWorkforce = ({ workforce, onDelete, onEdit }: SelectedWorkforceProps) => {
  const { teams, loading } = useSelector((state: AppState) => state.teamsReducer);
  const { dataWithId } = useSelector((state: AppState) => state.allEmployeesReducer);

  const [openDetails, setOpenDetails] = useState(false);

  const selectedTeam = teams.find((team: TeamModel) => team.id === workforce.teamId)?.title;
  const selectedWorkforce: EmployeeDto | null = dataWithId[workforce.teamId].find((employee: EmployeeDto) =>
    workforce.workforce ? employee.atlassianId === workforce.workforce : null
  );
  const [startDate, endDate] = workforce.dateRange;

  const data = [
    { label: 'Team', value: selectedTeam },
    { label: 'Service type', value: workforce.serviceType },
    {
      label: 'Datetime',
      value: `${format(startDate as Date, DATE_PICKER.dateFormatToStandard)} - ${format(
        endDate as Date,
        DATE_PICKER.dateFormatToStandard
      )}`,
    },
    { label: 'FTE', value: workforce.fte },
    { label: 'Workforce', value: selectedWorkforce ? getFullName(selectedWorkforce) : '' },
    { label: 'Billing source', value: workforce.billingSource },
    { label: 'Grade', value: workforce.grade },
    { label: 'Description', value: workforce.description },
  ];

  return (
    <div className={styles.selectedWorkforce}>
      <div className={styles.workforceInfo} onClick={() => setOpenDetails(!openDetails)}>
        <div>{`${selectedTeam}, ${workforce.fte}, ${format(
          startDate as Date,
          DATE_PICKER.dateFormatToStandard
        )} - ${format(endDate as Date, DATE_PICKER.dateFormatToStandard)}, ${workforce.billingSource || ''}`}</div>

        <span className={openDetails ? styles.arrow : cx(styles.arrow, styles.arrowOpen)}>{arrowRight}</span>
      </div>

      {openDetails && (
        <>
          <div className={styles.details}>
            {data.map(item => (
              <div key={item.label}>
                <div className="label-wrapper mb-0">{item.label}</div>
                <div>{item.value}</div>
              </div>
            ))}
          </div>

          <ActionButtons
            className={styles.itemActions}
            cancelText="Delete"
            submitText="Edit"
            onCancel={onDelete}
            onSubmit={onEdit}
          />
        </>
      )}
    </div>
  );
};

export default SelectedWorkforce;
