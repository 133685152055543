import React, { FC } from 'react';
import { useField } from 'formik';
import { RadioButtonsOptions } from 'models/interfaces/companyExpenses.interface';

interface RadioButtonsT {
  className: string;
  options: RadioButtonsOptions[];
  name: string;
}

const FormikRadioButtons: FC<RadioButtonsT> = ({ className, options, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <>
      {options.map((option: RadioButtonsOptions) => (
        <div className={className} key={option.key}>
          <input
            type="radio"
            {...field}
            {...props}
            checked={field.value === option.value}
            onChange={() => helpers.setValue(option.value)}
          />
          <label>{option.key}</label>
        </div>
      ))}
    </>
  );
};

export default FormikRadioButtons;
