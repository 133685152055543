import React from 'react';
import { Table } from 'reactstrap';
import cx from 'classnames';

import { ExecutorInterface } from '../../../models/dto/bdm.dto';

import styles from '../../tableStyles.module.scss';

type TProps = { data: Array<ExecutorInterface> };

const DealsTable: React.FC<TProps> = ({ data = [] }) => (
  <div className={styles.tableWrapper}>
    <Table
      responsive
      hover
      className={cx(styles.tableContainer, styles.minWidth1400, 'align-middle mb-0 custom-table text-left')}
    >
      <thead>
        <tr>
          <th className={styles.firstCellWidth}>#</th>
          <th>Name</th>
          <th>Team</th>
          <th>English Level</th>
          <th>Internal/Sales Grade</th>
        </tr>
      </thead>

      <tbody>
        {data?.map((item, index: number) => (
          <tr key={index} className={styles.removeBorder}>
            <td>{index + 1}</td>
            <td>{item?.firstName || item?.lastName ? `${item?.firstName || ''} ${item?.lastName || ''}` : '-'}</td>
            <td>{item?.team?.title || '-'}</td>
            <td>{item?.englishLevel || '-'}</td>
            <td>{`${item?.grade || '-'} / ${item?.salesGrade || '-'}`}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

export default DealsTable;
