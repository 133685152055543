import { ExecutorInterface, OwnerCloserPMInterface } from './dto/bdm.dto';

class dealDetails {
  id: string;
  name: string;
  status: string;
  owner: OwnerCloserPMInterface;
  closer: OwnerCloserPMInterface;
  amount: number;
  rate: number;
  workload: string;
  startDate: string;
  endDate: string;
  projectInitRequest: string;
  projectManager: OwnerCloserPMInterface;
  hubspotLink: string;
  executors: Array<ExecutorInterface[]>;

  constructor(data: dealDetails) {
    this.id = data.id;
    this.name = data.name;
    this.status = data.status;
    this.owner = data.owner;
    this.closer = data.closer;
    this.amount = data.amount;
    this.rate = data.rate;
    this.workload = data.workload;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.projectInitRequest = data.projectInitRequest;
    this.projectManager = data.projectManager;
    this.hubspotLink = data.hubspotLink;
    this.executors = data.executors;
  }
}

export default dealDetails;
