import { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import EmployeeTitle from 'common/EmployeeTitle/EmployeeTitle';
import { generateAvatar } from 'utils/generateUI';

import { arrowUp } from 'constants/icons';
import { TEAM_BUDGET_TABLE_HEADERS } from 'constants/peopleTableHeaders';
import { TeamBudgeting } from 'models/interfaces/budgeting.interface';

import styles from './teamBudgetStyles.module.scss';

const BudgetRow = ({ team }: { team: TeamBudgeting }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <tr
        onClick={() => setIsOpen(!isOpen)}
        className={cx(
          styles.teamTitleRow,
          styles.cellAccentLine,
          team.type ? styles[team.type?.title.toLowerCase()] : ''
        )}
      >
        <td>
          {team.children?.length || team.budget?.length ? (
            <span className={cx(styles.arrow, isOpen ? styles.arrowOpened : '')}>{arrowUp}</span>
          ) : null}
          {team.title}
        </td>
        {TEAM_BUDGET_TABLE_HEADERS.map(
          (header, index) => index + 1 !== TEAM_BUDGET_TABLE_HEADERS.length && <td key={header.id}></td>
        )}
      </tr>

      {isOpen && (
        <>
          {team.budget?.map((teamBudgetItem, index) =>
            teamBudgetItem.employeeBudget?.map((budgetRR, rowIndex) => (
              <tr
                key={`cell-${index}-${rowIndex}`}
                className={cx(
                  rowIndex === 0 ? styles.cellAccentLine : styles.mergedCellsAccentLine,
                  budgetRR.isTeamLead ? styles.teamLead : ''
                )}
              >
                {rowIndex === 0 && (
                  <td rowSpan={teamBudgetItem.employeeBudget.length}>
                    {teamBudgetItem.employee ? (
                      <div className={styles.employee}>
                        {generateAvatar(teamBudgetItem.employee.firstName, teamBudgetItem.employee.lastName)}
                        <Link to={`/dashboard/profile/${teamBudgetItem.employee.id}`}>
                          {`${teamBudgetItem.employee.firstName} ${teamBudgetItem.employee.lastName}`}
                        </Link>
                        {budgetRR.isTeamLead && (
                          <EmployeeTitle employee={{ ...teamBudgetItem.employee, isTeamlead: true }} />
                        )}
                      </div>
                    ) : null}
                  </td>
                )}
                <td>{budgetRR.grade}</td>
                <td>
                  {budgetRR.project ? (
                    <a href={budgetRR.resourceRequest.link} target="_blank">
                      {budgetRR.project}
                    </a>
                  ) : (
                    '-'
                  )}
                </td>
                <td>{budgetRR.source || '-'}</td>
                <td>{budgetRR.estimatedHours}</td>
                <td>{budgetRR.estimatedBudget}</td>
                {teamBudgetItem.estimatedCost ? (
                  rowIndex === 0 && (
                    <td rowSpan={teamBudgetItem.employeeBudget.length}>{teamBudgetItem.estimatedCost}</td>
                  )
                ) : (
                  <td>{teamBudgetItem.estimatedCost}</td>
                )}
                <td>{budgetRR.factualHours}</td>
                <td>{budgetRR.factualBudget}</td>
                {teamBudgetItem.factualCost ? (
                  rowIndex === 0 && <td rowSpan={teamBudgetItem.employeeBudget.length}>{teamBudgetItem.factualCost}</td>
                ) : (
                  <td>{teamBudgetItem.factualCost}</td>
                )}
              </tr>
            ))
          )}

          {team.children?.map(child => (
            <BudgetRow key={child.id} team={child} />
          ))}
        </>
      )}
    </>
  );
};

export default BudgetRow;
