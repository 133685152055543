import React, { FC } from 'react';
import cx from 'classnames';
import { createPortal } from 'react-dom';

import { error } from 'constants/icons';
import { ForbidModalProps } from 'models/interfaces/companyExpenses.interface';

import expenseStyles from '../../ExpensesStyle.module.scss';
import stylesModal from '../../../modal.module.scss';
import styles from '../../../tableStyles.module.scss';

const ForbidDeletingModal: FC<ForbidModalProps> = ({ title, onClose }) => {
  return createPortal(
    <div className={stylesModal.modal}>
      <div className={stylesModal.backDrop} onClick={onClose}></div>

      <div className={cx(stylesModal.block, 'text-center')}>
        <button className={stylesModal.close} onClick={onClose} />

        {error}

        <div className={cx(expenseStyles.warningText, 'mb-4')}>
          {`You can't remove the "${title}" category because it is linked to one or more expenses`}
        </div>

        <button className={cx(styles.button, styles.addButton, 'm-auto')} onClick={onClose}>
          Go back
        </button>
      </div>
    </div>,
    document.body
  );
};

export default ForbidDeletingModal;
