import React, { FC, useMemo } from 'react';
import cx from 'classnames';

import { Table } from 'reactstrap';
import ActionButtons from '../../common/Buttons/ActionButtons';

import { CompanyExpenseItem, ExpensesTableProps } from 'models/interfaces/companyExpenses.interface';

import styles from '../tableStyles.module.scss';

const CompanyExpensesTable: FC<ExpensesTableProps> = ({
  data,
  isEditRole,
  handleClickOnEdit,
  handleClickOnDelete,
  currentPage,
  perPage,
}) => {
  const coefForNumberFirstColumn = useMemo(() => perPage * currentPage - perPage + 1, [currentPage, perPage]);

  return (
    <Table responsive hover className={cx(styles.tableContainer, 'align-middle mb-0 custom-table text-left')}>
      <thead>
        <tr>
          <th>#</th>
          <th>Planned</th>
          <th>Currency</th>
          <th>Description</th>
          <th>Category</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Recurring Period</th>
          {isEditRole ? <th>Actions</th> : null}
        </tr>
      </thead>

      <tbody>
        {data?.map((item: CompanyExpenseItem, index: number) => (
          <tr key={index}>
            <td>{index + coefForNumberFirstColumn}</td>
            <td>{item?.amount}</td>
            <td>{item?.currency}</td>
            <td>{item?.description}</td>
            <td>{item?.category.name}</td>
            <td className={styles.dateRow}>{item?.effectiveDate}</td>
            <td className={styles.dateRow}>{item?.expirationDate}</td>
            {item?.recurringPeriod ? <td>{item?.recurringPeriod} months</td> : <td />}
            {isEditRole ? (
              <td>
                <ActionButtons
                  handleClickOnEdit={() => handleClickOnEdit(item.id)}
                  handleClickOnDelete={() => handleClickOnDelete(item.id)}
                />
              </td>
            ) : null}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CompanyExpensesTable;
