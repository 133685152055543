import PageTitle from '../../Layout/AppMain/PageTitle';
import OutstuffFunnel from '../../components/BDM/OutstuffFunnel/OutstuffFunnel';
import { outstaffFunnelIcon } from 'constants/icons';

const OutstuffFunnelPage = () => {
  return (
    <>
      <PageTitle heading="BDM Funnel" icon={outstaffFunnelIcon} />
      <OutstuffFunnel />
    </>
  );
};

export default OutstuffFunnelPage;
