import React, { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import EmployeeProjectsInfo from './EmployeeProjectsInfo';

import { secondsToHours } from 'utils/dates';
import { generateAvatar } from 'utils/generateUI';
import {
  formatProjectResourceRequests,
  formatResourceRequests,
  getBorderColor,
  getEmployeeTotalWorklogs,
  getHoverColor,
  getPlannedWorkload,
  getProjectsTotalRRs,
} from '../constants';
import {
  CalendarTdInfo,
  EmployeeInfoProps,
  EmployeeResourceRequests,
  PlannedWorkloadParams,
  ResourceRequestItem,
} from 'models/interfaces/workloadCalendar.interface';
import { employeeCellArrow } from 'constants/icons';

import styles from '../workloadStyle.module.scss';

const EmployeeInfoRow: FC<EmployeeInfoProps> = ({
  employee,
  expandTable,
  hoveredColumn,
  setHoveredColumn,
  dateRange,
}) => {
  const [openProjectsInfo, setOpenProjectsInfo] = useState(false);

  const resourceRequests = formatResourceRequests(employee.resourceRequests);

  const projects = useMemo(() => {
    return formatProjectResourceRequests(employee.resourceRequests);
  }, [employee]);

  const resourceRequestInfo = useMemo(() => {
    return getProjectsTotalRRs(resourceRequests);
  }, [employee]);

  const worklogsInfo = useMemo(() => {
    return getEmployeeTotalWorklogs(resourceRequests);
  }, [employee]);

  const totalLoggedTime = resourceRequests.reduce(
    (acc: number, item: ResourceRequestItem) => acc + item.totalLoggedTime,
    0
  );

  const params: PlannedWorkloadParams = {
    dateRange,
    absences: employee.absences,
    shifts: employee.shifts,
    resourceRequests: resourceRequestInfo,
    worklogs: worklogsInfo,
    startDate: employee.startDate,
    endDate: employee.dismissalDate,
    isProjectRow: false,
  };

  return (
    <>
      <tr
        className={cx(styles.employeeDetail, expandTable ? styles.tableCellsFixed : styles.firstCellFixed)}
        onClick={() => setOpenProjectsInfo(!openProjectsInfo)}
      >
        <td className={styles.employeeCell}>
          <div className={styles.employeeName}>
            <div className={styles.flex}>
              {generateAvatar(employee.firstName, employee.lastName)}

              <Link to={`/dashboard/profile/${employee.id}`}>
                {employee.firstName} {employee.lastName}
              </Link>
            </div>
            {!!employee.resourceRequests?.length && (
              <span className={openProjectsInfo ? styles.openedArrow : styles.closedArrow}>{employeeCellArrow}</span>
            )}
          </div>
        </td>

        {expandTable && (
          <>
            <td className={styles.employeeInfo}>{secondsToHours(employee.working)}</td>
            <td className={styles.employeeInfo}>{secondsToHours(employee.workloadBillable)}</td>
            <td className={styles.employeeInfo}>{secondsToHours(employee.workloadNonBillable)}</td>
            <td className={styles.employeeInfo}>{secondsToHours(employee.absence)}</td>
            <td className={styles.employeeInfo}>{secondsToHours(totalLoggedTime)}</td>
            <td className={styles.employeeInfo}>{secondsToHours(employee.available)}</td>
            <td className={styles.employeeInfo}>{secondsToHours(employee.availableRemaining)}</td>
          </>
        )}

        {getPlannedWorkload(params).map((item: CalendarTdInfo, index: number) => (
          <td
            key={index}
            className={cx(styles[item.issueType])}
            onMouseEnter={() => setHoveredColumn(index)}
            onMouseLeave={() => setHoveredColumn(null)}
            style={{
              background: index === hoveredColumn ? getHoverColor(item.issueType) : '',
              borderColor: getBorderColor(item),
            }}
          >
            {item.issue}
          </td>
        ))}
      </tr>

      {openProjectsInfo &&
        projects.map((project: EmployeeResourceRequests) => (
          <React.Fragment key={project.id}>
            <EmployeeProjectsInfo
              project={project}
              absences={employee.absences}
              shifts={employee.shifts}
              startDate={employee.startDate}
              endDate={employee.dismissalDate}
              expandTable={expandTable}
              dateRange={dateRange}
              hoveredColumn={hoveredColumn}
              setHoveredColumn={setHoveredColumn}
            />
          </React.Fragment>
        ))}
    </>
  );
};

export default EmployeeInfoRow;
