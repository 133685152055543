import cx from 'classnames';
import { createPortal } from 'react-dom';
import { ConfirmModalProps } from 'models/interfaces/commonComponents.interface';

import styles from '../../components/modal.module.scss';

const ConfirmModal = ({ title, confirmQuestion, confirmButtonText, onClose, onConfirm }: ConfirmModalProps) => {
  return createPortal(
    <div className={styles.modal}>
      <div className={styles.backDrop} onClick={onClose}></div>
      <div className={cx(styles.block, 'text-left')}>
        <button className={styles.close} onClick={onClose} />
        <div className={styles.header}>{title}</div>

        <>
          <button className={styles.close} onClick={onClose}></button>
          <div className={styles.title}>{confirmQuestion}</div>

          <div className={styles.buttonWrapper}>
            <button onClick={onClose} className={styles.blueLineButton}>
              Cancel
            </button>
            <button className={styles.blueButton} onClick={onConfirm}>
              {confirmButtonText}
            </button>
          </div>
        </>
      </div>
    </div>,
    document.body
  );
};

export default ConfirmModal;
