import httpService, { HttpService } from './httpService';
import { cleanEmptyParams, RequestParams } from '../utils/mapParams';

export class WorklogService {
  constructor(private _httpService: HttpService) {}

  public async getWorklogs(body: any, params: Partial<RequestParams>) {
    return await this._httpService.post('/worklogs', {
      queryStringParameters: cleanEmptyParams(params),
      body,
    });
  }
}

export default new WorklogService(httpService);
