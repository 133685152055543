import { PAYMENT_TYPE } from './enums/payment-type.enum';
import { ISSUE_STATUS } from '../constants/issues.constants';

export default class IssueModel {
  id: string | null;
  key: string;
  startDate: string;
  endDate: string;
  relativeDuration: number;
  estimatedBillableHours?: number;
  originalTimeEstimate?: number;
  factualBillableHours?: number;
  timeSpent?: number;
  projectKey: string;
  paymentType: PAYMENT_TYPE;
  linkedProject: string;
  type: string;
  timePerDay: number;
  link: string;
  status: ISSUE_STATUS;

  constructor(data: Partial<IssueModel>) {
    this.id = data.id || null;
    this.key = data.key || '';
    this.startDate = data.startDate || '';
    this.endDate = data.endDate || '';
    this.relativeDuration = data.relativeDuration || 0;
    this.projectKey = data.projectKey || '';
    this.paymentType = data.paymentType || PAYMENT_TYPE.nonBillable;
    this.estimatedBillableHours = data.estimatedBillableHours;
    this.linkedProject = data.linkedProject || '';
    this.originalTimeEstimate = data.originalTimeEstimate;
    this.factualBillableHours = data.factualBillableHours;
    this.timeSpent = data.timeSpent;
    this.type = data.type || '';
    this.timePerDay = data.timePerDay || 0;
    this.link = data.link || '';
    this.status = data.status || ISSUE_STATUS.hold;
  }
}
