export const formatSalesGrades = salesGrades => {
  const grades = [
    {
      id: 0,
      title: 'All',
    },
  ];
  salesGrades.forEach(grade => {
    grades.push({
      id: grade.id,
      title: grade.title,
    });
  });
  return grades;
};
