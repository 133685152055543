import httpService, { HttpService } from './httpService';
import EmployeeListModel from '../models/employeeList.model';
import EmployeeAdapter from '../models/adapters/employee.adapter';
import { AssigneesRequestParams } from 'models/interfaces/assignees.interface';
import { cleanEmptyParams, RequestParams } from '../utils/mapParams';

export class EmployeesService {
  constructor(private _httpService: HttpService) {}

  public async getEmployeesList(params: Partial<RequestParams>): Promise<EmployeeListModel> {
    const res = await this._httpService.get(`/employees`, {
      queryStringParameters: cleanEmptyParams(params),
    });
    return EmployeeAdapter.mapEmployeeListDtoToEmployeeList(res);
  }

  public async getEmployeesFullData(params: Partial<RequestParams>) {
    const res = await this._httpService.get(`/employees`, {
      queryStringParameters: cleanEmptyParams(params),
    });
    return res;
  }

  public async getEmployeesData(params: Partial<RequestParams>) {
    const res = await this._httpService.get(`/employees`, {
      queryStringParameters: params,
    });
    return res.employees;
  }

  public async editEmployeesData(employeesData: AssigneesRequestParams) {
    const res = await this._httpService.post(`/employees-bulk`, {
      body: employeesData,
    });
    return res;
  }
}

export default new EmployeesService(httpService);
