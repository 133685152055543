class EmployeeModel {
  id: number;
  firstName: string;
  lastName: string;
  availability: number;
  isTeamlead: boolean;
  isTechlead: boolean;
  isIntern: boolean;
  isDirectCost: boolean;
  timeByMonth: number;
  title: string;
  atlassianId: string;
  isTeamAvailableForPlanning?: boolean;

  constructor(data: Omit<EmployeeModel, 'fullName'>) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.title = data.title;
    this.availability = data.availability;
    this.isTeamlead = data.isTeamlead;
    this.isTechlead = data.isTechlead;
    this.isIntern = data.isIntern;
    this.isDirectCost = data.isDirectCost;
    this.timeByMonth = data.timeByMonth;
    this.atlassianId = data.atlassianId;
    this.isTeamAvailableForPlanning = data.isTeamAvailableForPlanning;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}

export default EmployeeModel;
