import httpService, { HttpService } from './httpService';
import { RequestParams } from '../utils/mapParams';
import { ExpenseValues } from 'models/interfaces/companyExpenses.interface';

export class CompanyExpensesService {
  constructor(private _httpService: HttpService) {}

  async getCompanyExpenses(params: Partial<RequestParams>) {
    const res = await this._httpService.get('/company-expenses', {
      queryStringParameters: params,
    });
    return res;
  }

  async getCompanyExpensesById(id: number) {
    const res = await this._httpService.get(`/company-expenses/${id}`);
    return res;
  }

  async createCompanyExpenses(values: ExpenseValues) {
    const res = await this._httpService.post('/company-expenses', {
      body: values,
    });
    return res;
  }

  async updateCompanyExpenses(params: { id: number; values: ExpenseValues }) {
    const res = await this._httpService.put(`/company-expenses/${params.id}`, {
      body: params.values,
    });
    return res;
  }

  async deleteCompanyExpenses(id: number) {
    const res = await this._httpService.delete(`/company-expenses/${id}`);
    return res;
  }
}

const companyExpensesService = new CompanyExpensesService(httpService);

export default companyExpensesService;
