import { API } from 'aws-amplify';

export const EVENTS_SYNCUSERS_REQUEST = 'SYNCUSERS/EVENTS_SYNCUSERS_REQUEST';
export const EVENTS_SYNCUSERS_SUCCESS = 'SYNCUSERS/EVENTS_SYNCUSERS_SUCCESS';
export const EVENTS_SYNCUSERS_FAILURE = 'SYNCUSERS/EVENTS_SYNCUSERS_FAILURE';

const initialState = {
  data: null,
  fetching: false,
  fetched: false,
  error: null,
};

export default function eventReducer(state = initialState, action) {
  switch (action.type) {
    case EVENTS_SYNCUSERS_REQUEST: {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case EVENTS_SYNCUSERS_SUCCESS: {
      return {
        ...state,
        fetched: true,
        fetching: false,
        data: action.data,
      };
    }
    case EVENTS_SYNCUSERS_FAILURE: {
      return {
        ...state,
        fetched: false,
        fetching: false,
        data: null,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}

export function getSyncUsers() {
  return function (dispatch) {
    dispatch({ type: EVENTS_SYNCUSERS_REQUEST });

    return API.post('helloAPI', '/employees/sync')
      .then(data => {
        dispatch({
          type: EVENTS_SYNCUSERS_SUCCESS,
          data: data,
        });
      })
      .catch(error => {
        dispatch({ type: EVENTS_SYNCUSERS_FAILURE, error });
        return Promise.reject(error);
      })
      .then();
  };
}
