import PageTitle from 'Layout/AppMain/PageTitle';
import WorkforceCosts from 'components/WorkforceCosts/WorkforceCosts';
import { workforceCostsIcon } from 'constants/icons';

const WorkforceCostsPage = () => {
  return (
    <>
      <PageTitle heading="Workforce Costs" icon={workforceCostsIcon} />
      <WorkforceCosts />
    </>
  );
};

export default WorkforceCostsPage;
