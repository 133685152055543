import { PerformanceReviewsDetailsItemDto } from './dto/performanceReview.dto';
import PerformanceReviewsDetailsEmployeeModel from './performanceReviewsDetailsEmployee.model';

class PerformanceReviewDetailsModel {
  id: number;
  employee: PerformanceReviewsDetailsEmployeeModel;
  employeeId: number;
  jiraEpicLink: string;
  googleMeetLink: string;
  confluencePageLink: string;
  performanceReviewCycleId: number;

  constructor(data: PerformanceReviewsDetailsItemDto) {
    this.id = data.id;
    this.employee = data.employee;
    this.employeeId = data.employee.id;
    this.jiraEpicLink = data.jiraEpicLink;
    this.googleMeetLink = data.googleMeetLink;
    this.confluencePageLink = data.confluencePageLink;
    this.performanceReviewCycleId = data.performanceReviewCycleId;
  }
}

export default PerformanceReviewDetailsModel;
