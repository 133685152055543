import React, { useState } from 'react';
import cx from 'classnames';
import { Table } from 'reactstrap';
import { format } from 'date-fns';

import DealGroupsModel from '../../../models/dealGroups.model';
import { DATE_PICKER } from '../../../constants/common';
import { arrowDown, arrowUp } from 'constants/icons';
import { capitalizeWords } from 'utils/functions';

import styles from '../../tableStyles.module.scss';
import stylesBdm from '../wrapper.module.scss';

type TProps = {
  data: DealGroupsModel;
  loadingUpdate?: boolean;
};

const OutstuffFunnelTable: React.FC<TProps> = ({ data }) => {
  const [openTable, setOpenTable] = useState(true);

  return (
    <>
      <div className={cx(styles.tableWrapper, 'mb-3')}>
        <div
          className={cx(
            stylesBdm.tableHeadingWrapper,
            data.groupName === 'deal closed' ? stylesBdm.closed : stylesBdm[data.groupName]
          )}
        >
          <button onClick={() => setOpenTable(!openTable)}>{openTable ? arrowDown : arrowUp}</button>
          <div className={stylesBdm.groupNameTable}>{data.groupName.split(' ').map(capitalizeWords).join(' ')}</div>
        </div>

        {openTable && (
          <>
            <Table
              responsive
              hover
              className={cx(styles.tableContainer, styles.minWidth1000, 'align-middle mb-3 custom-table text-left')}
            >
              <thead>
                <tr>
                  <th className={styles.firstCellWidth}>#</th>
                  <th className={stylesBdm.columnWidth}>Deal</th>
                  <th className={stylesBdm.columnWidth}>Stage</th>
                  <th className={stylesBdm.columnWidth}>Last Touch</th>
                  <th className={stylesBdm.columnWidth}>Workforce</th>
                  <th className={stylesBdm.columnWidth}>Create Date</th>
                  <th className={stylesBdm.columnWidth}>Closer</th>
                </tr>
              </thead>

              <tbody>
                {data?.items.map((item, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <a href={`#/dashboard/deal-details/${item.id}`}>{item.deal}</a>
                    </td>
                    <td>{item.stage}</td>
                    <td>{item.lastTouch}</td>
                    <td>
                      {item.resource?.length
                        ? item.resource.map((el, index) => (
                            <React.Fragment key={index}>
                              {index === item.resource?.length - 1 ? '' : ', '}
                            </React.Fragment>
                          ))
                        : '-'}
                    </td>
                    <td>{format(new Date(item.createdAt), DATE_PICKER.dateFormatToPayload)}</td>
                    <td>
                      <a href={`#/dashboard/deal-details/${item.id}`}>{item.closer.email}</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className={cx(stylesBdm.groupNameTable, stylesBdm.result)}>Result: {data?.items?.length}</div>
          </>
        )}
      </div>
    </>
  );
};
export default OutstuffFunnelTable;
