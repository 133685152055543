import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'react-notistack';
import { removeSnackbar as removeSnackbarAction } from '../../store/notifications';
import { AppState } from '../../store/configureStore';

const Notifier = (): null => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state: AppState) => state.notificationsReducer);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [displayed, setDispalyed] = useState<(string | number)[]>([]);

  useEffect(() => {
    const storeDisplayed = (id: string | number): void => {
      setDispalyed([...displayed, id]);
    };

    const removeDisplayed = (id: string | number): void => {
      setDispalyed([...displayed.filter(key => id !== key)]);
    };

    notifications.forEach(({ key, options = {}, dismissed = false }: NotificationT) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) return;

      enqueueSnackbar(options.title, {
        key,
        hide: 5000,
        autoHide: true,
        preventDuplicate: false,
        onClick: (event: { key: number }) => {
          dispatch(removeSnackbarAction({ key: event.key }));
          removeDisplayed(event.key);
        },
        ...options,
      });

      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, displayed]);

  return null;
};

export default Notifier;
