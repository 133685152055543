import { createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from '../configureStore';
import employeeService, { updateEmployeeByIdT } from '../../services/employeeService';
import { ALL_EXPANDS_EMPLOYEE, ALL_VP_EXPANDS_EMPLOYEE } from '../../constants/employee';
import { enqueueSnackbar } from '../notifications';
import { NotificationType } from '../../services/notificationService';
import { EmployeeProfileModel } from '../../models/employeeProfile.model';
import { ContactItemInterface } from './../../models/dto/employee.dto';
import { Role } from 'constants/roles';

interface EmployeeState {
  loading: boolean;
  employee: EmployeeProfileModel | null;
  error: Error | null;
  salesGrades: any[];
}

const initialState: EmployeeState = {
  loading: false,
  employee: null,
  error: null,
  salesGrades: [],
};

type EmployeeContact = {
  contact: ContactItemInterface;
  type: string;
};

type DeleteEmployeeContact = {
  id: number;
  type: string;
};

export const employeeSlice = createSlice({
  name: 'employee by id',
  initialState,
  reducers: {
    clearEmployee: state => {
      return {
        ...state,
        loading: false,
        employee: null,
        error: null,
      };
    },
    getEmployeeByIdRequest: state => {
      return {
        ...state,
        loading: true,
        employee: null,
        error: null,
      };
    },
    getEmployeeByIdSuccess: (state, action: PayloadAction<EmployeeProfileModel>) => {
      return {
        ...state,
        loading: false,
        employee: action.payload,
        error: null,
      };
    },
    getEmployeeByIdFailure: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        loading: false,
        employee: null,
        error: action.payload,
      };
    },
    updateEmployeeByIdRequest: state => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },
    updateEmployeeByIdSuccess: (state, action: PayloadAction<EmployeeProfileModel>) => {
      return {
        ...state,
        loading: false,
        employee: action.payload,
        error: null,
      };
    },
    updateEmployeeByIdFailure: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    updateEmployeeContact: state => {
      return {
        ...state,
        error: null,
      };
    },
    updateEmployeeContactSuccess: (state, action: PayloadAction<EmployeeContact>) => {
      if (state.employee) {
        if (action.payload.type === 'email') {
          (state.employee = {
              ...state.employee,
              emails: state.employee.emails.map(email =>
                email.id === action.payload.contact.id ? action.payload.contact : email
              ),
            })
        } else if (action.payload.type ===  'children') { 
          (state.employee = {
            ...state.employee,
            childrens: state.employee.childrens.map(children =>
              children.id === action.payload.contact.id ? {
                ...action.payload.contact,
                value: action.payload.contact.name ? action.payload.contact.name : action.payload.contact.value
              }: children
            ),
          })
        } else {
          (state.employee = {
            ...state.employee,
            phones: state.employee.phones.map(phone =>
              phone.id === action.payload.contact.id ? action.payload.contact : phone
            ),
          });
          }
      }
    },
    updateEmployeeContactFailure: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    createEmployeeContact: state => {
      return {
        ...state,
        error: null,
      };
    },
    createEmployeeContactSuccess: (state, action: PayloadAction<EmployeeContact>) => {
      if (state.employee) {
       if (action.payload.type === 'email') {
          (state.employee = {
              ...state.employee,
              emails: [action.payload.contact, ...state.employee.emails],
            })
        } else if (action.payload.type ===  'children') {
          (state.employee = {
            ...state.employee,
            childrens: [...state.employee.childrens,
            {
              ...action.payload.contact,
              value: action.payload.contact.name ? action.payload.contact.name : action.payload.contact.value
            }],
          });
        }
         else {
          (state.employee = {
              ...state.employee,
              phones: [action.payload.contact, ...state.employee.phones],
            });
        }
      }
    },
    createEmployeeContactFailure: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    deleteEmployeeContact: state => {
      return {
        ...state,
        error: null,
      };
    },
    deleteEmployeeContactSuccess: (state, action: PayloadAction<DeleteEmployeeContact>) => {
      if (state.employee) {
        if (action.payload.type === 'email') {
          (state.employee = {
              ...state.employee,
              emails: state.employee.emails.filter(email => email.id !== action.payload.id),
            })
          } else if (action.payload.type ===  'children') {
            (state.employee = {
              ...state.employee,
              childrens: state.employee.childrens.filter(children => children.id !== action.payload.id),
            })
          } else {
            (state.employee = {
              ...state.employee,
              phones: state.employee.phones.filter(phone => phone.id !== action.payload.id),
            });
          }
      }
    },
    deleteEmployeeContactFailure: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
  },
});

export const {
  clearEmployee,
  getEmployeeByIdRequest,
  getEmployeeByIdSuccess,
  getEmployeeByIdFailure,
  updateEmployeeByIdRequest,
  updateEmployeeByIdSuccess,
  updateEmployeeByIdFailure,
  updateEmployeeContact,
  updateEmployeeContactSuccess,
  updateEmployeeContactFailure,
  createEmployeeContact,
  createEmployeeContactSuccess,
  createEmployeeContactFailure,
  deleteEmployeeContact,
  deleteEmployeeContactSuccess,
  deleteEmployeeContactFailure,
} = employeeSlice.actions;

export default employeeSlice.reducer;

export const getEmployeeByIdThunk =
  (id: string, expand: string = '') =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(getEmployeeByIdRequest());
    try {
      const employee = await employeeService.getEmployeeById(id, expand);
      dispatch(getEmployeeByIdSuccess(employee));
    } catch (err) {
      dispatch(getEmployeeByIdFailure(err as Error));
    }
  };

export const updateEmployeeByIdThunk =
  ({ id, employeeData }: updateEmployeeByIdT) =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>, getState: any) => {
    dispatch(updateEmployeeByIdRequest());
    try {
      const employeeById = await employeeService.editEmployeeById({ id, employeeData });
      const { role } = getState()?.account.user;
      dispatch(updateEmployeeByIdSuccess(employeeById));
      dispatch(getEmployeeByIdThunk(id, role === Role.vp ? ALL_VP_EXPANDS_EMPLOYEE : ALL_EXPANDS_EMPLOYEE));
      dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Profile updated successfully!',
            title: 'Profile',
          },
        })
      );
    } catch (err) {
      dispatch(updateEmployeeByIdFailure(err as Error));
    }
  };

export const createEmployeeContactThunk =
  (contact: ContactItemInterface, type: string) =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(createEmployeeContact());
    try {
      dispatch(createEmployeeContactSuccess({ contact, type }));
    } catch (err) {
      dispatch(createEmployeeContactFailure(err as Error));
    }
  };

export const updateEmployeeContactThunk =
  (contact: ContactItemInterface, type: string) =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(updateEmployeeContact());
    try {
      dispatch(updateEmployeeContactSuccess({ contact, type }));
    } catch (err) {
      dispatch(updateEmployeeContactFailure(err as Error));
    }
  };

export const deleteEmployeeContactThunk =
  (id: number, type: string) => async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(deleteEmployeeContact());
    try {
      dispatch(deleteEmployeeContactSuccess({ id, type }));
    } catch (err) {
      dispatch(deleteEmployeeContactFailure(err as Error));
    }
  };
