import React, { Fragment } from 'react';

import PageTitle from '../../Layout/AppMain/PageTitle';
import WorkloadCalendar from 'components/WorkloadCalendar/WorkloadCalendar';
import { absenceCalendarIcon } from 'constants/icons';

const WorkloadCalendarPage = () => {
  return (
    <Fragment>
      <PageTitle heading="Workload Calendar" icon={absenceCalendarIcon} />
      <WorkloadCalendar />
    </Fragment>
  );
};

export default WorkloadCalendarPage;
