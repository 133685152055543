import React, { FC } from 'react';
import cx from 'classnames';

import { Button } from 'reactstrap';
import { cancel, save } from '../../constants/icons';

import styles from './Profile.module.scss';

type ProfileEditButtonsT = {
  setEditMode: (value: boolean) => void;
  resetForm(): void;
};

const ProfileEditButtons: FC<ProfileEditButtonsT> = ({ resetForm, setEditMode }) => {
  const handleCloseEdit = () => {
    setEditMode(false);
    resetForm();
  };

  return (
    <div className="text-right container-fluid d-flex justify-content-end">
      <div className={cx('btn-wrap', styles.btnWrap)}>
        <Button color="danger" outline onClick={handleCloseEdit}>
          {cancel}
          <span>cancel</span>
        </Button>

        <Button color="primary" id="save" type="submit">
          {save}
          <span>save</span>
        </Button>
      </div>
    </div>
  );
};

export default ProfileEditButtons;
