import { useSelector } from 'react-redux';

import PageTitle from '../../Layout/AppMain/PageTitle';
import ProfileForm from '../../components/ProfileDashboard/ProfileForm';

import { mappedProfile } from 'store/Profile/mappedProfile.selector';
import { profileIcon } from 'constants/icons';

const ProfilePage = () => {
  const user = useSelector(mappedProfile);

  return (
    <>
      <PageTitle heading={user ? `${user?.firstName} ${user?.lastName}` : 'Profile Dashboard'} icon={profileIcon} />
      <ProfileForm />
    </>
  );
};

export default ProfilePage;
