import React, { FC } from 'react';
import { Table } from 'reactstrap';
import cx from 'classnames';

import { secondsToHours } from '../../utils/dates';
import { FooterPropsOR } from 'models/interfaces/overtimeRequests.interface';

import styles from '../tableStyles.module.scss';

const OverTimeRequestFooter: FC<FooterPropsOR> = ({ footer }) => {
  return (
    <div className={cx(styles.footerTableWrap, 'mt-3')}>
      <Table responsive hover className="custom-table align-middle mb-0">
        <thead>
          <tr className={styles.removeRow}>
            <th />
            <th>Estimated</th>
            <th>Logged</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={cx(styles.greenRow, styles.footerCellWidth)}>Total Billable Overtime</td>
            <td>{secondsToHours(footer.billableTotalEstimate)}</td>
            <td>{secondsToHours(footer.billableTotalLogged)}</td>
          </tr>
          <tr>
            <td className={cx(styles.redRow, styles.footerCellWidth)}>Total Non Billable Overtime</td>
            <td>{secondsToHours(footer.nonBillableTotalEstimate)}</td>
            <td>{secondsToHours(footer.nonBillableTotalLogged)}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default OverTimeRequestFooter;
