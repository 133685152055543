import React from 'react';
import cx from 'classnames';

import ProjectPirRow from './ProjectPirRow';

import { arrowRight } from 'constants/icons';
import { ProjectPIR, ProjectRowProps, Month } from 'models/interfaces/projectCalendar.interface';

import styles from '../projectCalendarStyles.module.scss';

const ProjectRow = ({
  project,
  monthsList,
  hiddenProjects,
  setHiddenProjects,
  openModal,
  canCreateRR,
}: ProjectRowProps) => {
  const projectIsClosed = hiddenProjects.includes(project.project.id);

  return (
    <>
      <tr
        onClick={() => {
          projectIsClosed
            ? setHiddenProjects(hiddenProjects.filter((projectId: number) => projectId !== project.project.id))
            : setHiddenProjects([...hiddenProjects, project.project.id]);
        }}
        className={cx(styles.projectRowWrap)}
      >
        <td className={styles.project}>
          <span className={projectIsClosed ? styles.projectArrow : cx(styles.projectArrow, styles.arrowOpen)}>
            {arrowRight}
          </span>
          {project.project?.name}
        </td>

        {monthsList.map((month: Month) => (
          <td key={month.name} />
        ))}
      </tr>

      {!projectIsClosed &&
        project.projectInitRequests.map((pir: ProjectPIR) => (
          <React.Fragment key={pir.id}>
            <ProjectPirRow pir={pir} monthsList={monthsList} openModal={openModal} canCreateRR={canCreateRR} />
          </React.Fragment>
        ))}
    </>
  );
};

export default ProjectRow;
