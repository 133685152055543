import PageTitle from '../../Layout/AppMain/PageTitle';
import WorkforseHours from '../../components/BDM/WorkforseHours/WorkforseHours';
import { workforceHoursIcon } from 'constants/icons';

const WorkforseHoursPage = () => {
  return (
    <>
      <PageTitle heading="Available Hours" icon={workforceHoursIcon} />
      <WorkforseHours />
    </>
  );
};

export default WorkforseHoursPage;
