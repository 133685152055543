import { getMonth, secondsToHours } from 'date-fns';
import { WorkingYearDto } from 'models/dto/workingYear.dto';
import WorkingYearModel from '../workingdays.model';

export default class WorkingYearAdapter {
  static mapWorkingYearDtoToWorkingYearModel(items: WorkingYearDto[]): WorkingYearModel[] {
    return items.map(
      item =>
        new WorkingYearModel({
          month: getMonth(new Date(item.fromDate)) + 1,
          workingDays: item.workingDays,
          workingTime: secondsToHours(item.workingTime),
          nonWorkingDays: item.nonWorkingDays,
        })
    );
  }
}
