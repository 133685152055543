import React, { useMemo } from 'react';

import { COLORS } from '../../../config/theme';
import { StatusPerformanceReview } from 'constants/performanceReview';

import styles from './performanceReview.module.scss';

type PropsType = {
  status: string;
};

const Status: React.FC<PropsType> = ({ status }) => {
  const color = useMemo(() => {
    switch (status) {
      case StatusPerformanceReview.active:
        return COLORS.green;
      case StatusPerformanceReview.draft:
        return COLORS.gray;
      default:
        return COLORS.blue;
    }
  }, [status]);

  return (
    <div className={styles.wrapperStatus}>
      <div className={styles.statusMark} style={{ backgroundColor: color }} />
      <div style={{ color }}>{status}</div>
    </div>
  );
};

export default Status;
