import PageTitle from '../../Layout/AppMain/PageTitle';
import PerformanceReviewDetails from '../../components/HR/PerformanceReviewDetails/PerformanceReviewDetails';
import { performanceReviewIcon } from 'constants/icons';

const PerformanceReviewDetailsPage = () => (
  <>
    <PageTitle heading="Performance Review" icon={performanceReviewIcon} />
    <PerformanceReviewDetails />
  </>
);

export default PerformanceReviewDetailsPage;
