class EmployeeWorkforceModel {
  address?: string;
  dismissalDate?: string;
  email: string;
  hasStartedThisMonth: boolean;
  hasUnapprovedObligations: boolean;
  id: number;
  isEngineeringlead: boolean;
  isTeamlead: boolean;
  isTechlead: boolean;
  isDirectCost: boolean;
  location?: string;
  position?: number;
  salaryPrediction: number;
  salesGrade?: string;
  startDate: string;
  team: string;
  timeByMonth: number;
  title: string;

  constructor(data: EmployeeWorkforceModel) {
    this.address = data.address;
    this.dismissalDate = data.dismissalDate;
    this.email = data.email;
    this.hasStartedThisMonth = data.hasStartedThisMonth;
    this.hasUnapprovedObligations = data.hasUnapprovedObligations;
    this.id = data.id;
    this.isEngineeringlead = data.isEngineeringlead;
    this.isTeamlead = data.isTeamlead;
    this.isTechlead = data.isTechlead;
    this.isDirectCost = data.isDirectCost;
    this.location = data.location;
    this.position = data.position;
    this.salaryPrediction = data.salaryPrediction;
    this.salesGrade = data.salesGrade;
    this.startDate = data.startDate;
    this.team = data.team;
    this.timeByMonth = data.timeByMonth;
    this.title = data.title;
  }
}

export default EmployeeWorkforceModel;
