import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Field } from 'formik';
import { Button } from 'reactstrap';

import { AppState } from 'store/configureStore';
import { ContactItemInterface } from '../../../models/dto/employee.dto';
import { CONTACTS_LINK, CONTACTS_TITLE } from '../constants';

import { edit } from '../../../constants/icons';
import styleCurrent from './Contacts.module.scss';

type ContactItemT = {
  employeeId: string;
  title: string;
  contactsLength: number;
  contactItem: ContactItemInterface;
  index: number;
  isEditRole: boolean;
  handleOpenModalContact(value: string, id: number): void;
  setIsEdit(b: boolean): void;
  onDelete: (e: React.MouseEvent) => void;
};

const ContactItem: FC<ContactItemT> = ({
  title,
  contactsLength,
  contactItem,
  index,
  isEditRole,
  handleOpenModalContact,
  setIsEdit,
  onDelete,
}) => {
  const { loading } = useSelector((state: AppState) => state.profileContactsReducer);

  const link = title === CONTACTS_TITLE.phone ? CONTACTS_LINK.tel : CONTACTS_LINK.mailto;

  const handleEditContact = () => {
    setIsEdit(true);
    handleOpenModalContact(contactItem.value, contactItem.id);
  };

  return (
    <div className={cx('d-flex align-items-center justify-content-between', styleCurrent.wrapContacts)}>
      {title === CONTACTS_TITLE.children ? (
        <div className="mr-3">{contactItem.value}</div>
      ) : (
        <a href={`${link}:${contactItem.value}`} className="mr-3">
          {contactItem.value}
        </a>
      )}

      {isEditRole && (
        <div className={cx('d-flex align-items-center btn-wrap', styleCurrent.buttonWrap)}>
          <Field as="div" onClick={handleEditContact}>
            <Button color="primary" type="button">
              {edit}
              <span>edit</span>
            </Button>
          </Field>
          <div className={cx('ml-2 mt-1 d-flex justify-content-end', styleCurrent.wrapContactsButton)}>
            <button
              onClick={onDelete}
              disabled={loading}
              className={
                contactsLength > 1 || title === CONTACTS_TITLE.children
                  ? styleCurrent.removeContacts
                  : styleCurrent.hideDeleteButton
              }
            />
            {((contactsLength - 1 === index && contactsLength < 3) || title === CONTACTS_TITLE.children) && (
              <button
                onClick={() => handleOpenModalContact('', 0)}
                className={styleCurrent.addContacts}
                type="button"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactItem;
