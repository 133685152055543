import styles from './EmployeeTitle.module.scss';

const EmployeeTitle = ({ employee }) => {
  if (employee.isTeamlead) {
    return <span className={styles.teamlead}> TL</span>;
  } else if (employee.isTechlead) {
    return <span className={styles.techlead}> ThL</span>;
  } else if (employee.isEngineeringlead) {
    return <span className={styles.engineeringlead}> EL</span>;
  } else {
    return null;
  }
};

export default EmployeeTitle;
