import { createAsyncThunk } from '@reduxjs/toolkit';
import employeesService from 'services/employeesService';
import employeeService from 'services/employeeService';
import teamService from 'services/teamService';
import TeamModel from 'models/team.model';
import { enqueueSnackbar } from 'store/notifications';
import { NotificationType } from 'services/notificationService';
import { EmployeeDto } from 'models/dto/employee.dto';
import { getMembers } from 'helpers/companyStructureHeplers';

type CreateTeamProps = {
  team: Omit<TeamModel, 'id'>;
  newMembers?: EmployeeDto[];
  childTeams?: TeamModel[];
};

type EditTeamProfileProps = {
  id: string;
  teamInfo: Omit<TeamModel, 'id'>;
  isTeamEdit: boolean;
  callback: () => void;
  newMembers?: { id: number; employee: Partial<EmployeeDto> }[];
  removedMembers: { id: number; employee: Partial<EmployeeDto> }[];
  newChildTeams?: { id: number; team: Partial<TeamModel> }[];
  removedChildTeams: { id: number; team: Partial<TeamModel> }[];
};

export const getTeamInfo = createAsyncThunk(
  'people/getTeamInfo',
  async (params: { id: string; searchParams?: { expand: string } }, thunkAPI) => {
    try {
      const teamData = await teamService.getTeamsListById(params);
      const employeeData = await employeesService.getEmployeesFullData({});
      const res = { team: teamData, employees: employeeData.employees };
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

const editTeamEmployees = async (data: { id: number; employee: Partial<EmployeeDto> }[]) => {
  const employeeApiCalls = data?.map(item => {
    return employeeService.editEmployeeById({
      id: String(item.id),
      employeeData: { ...item.employee, id: undefined },
    });
  });
  await Promise.all(employeeApiCalls);
};

const editTeamChildTeams = async (data: { id: number; team: Partial<TeamModel> }[]) => {
  const teamApiCalls = data?.map(item => {
    return teamService.editTeamById({
      id: item.id,
      team: { parentId: item.team.parentId },
    });
  });
  await Promise.all(teamApiCalls);
};

export const editTeamProfile = createAsyncThunk(
  'people/updateTeamInfo',
  async (values: EditTeamProfileProps, thunkAPI) => {
    try {
      if (values.isTeamEdit) {
        await teamService.updateTeamsListById({ id: values.id, team: values.teamInfo });
      }
      if (values.newMembers) {
        await editTeamEmployees(values.newMembers);
      }

      if (values.removedMembers?.length) {
        await editTeamEmployees(values.removedMembers);
      }

      if (values.newChildTeams) {
        await editTeamChildTeams(values.newChildTeams);
      }

      if (values.removedChildTeams?.length) {
        await editTeamChildTeams(values.removedChildTeams);
      }

      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Team edited successfully!',
            title: 'Team',
          },
        })
      );
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    } finally {
      values.callback();
    }
  }
);

export const createTeam = createAsyncThunk('people/createTeam', async (values: CreateTeamProps, thunkAPI) => {
  try {
    const team = await teamService.createTeam(values.team);
    if (values.newMembers?.length) {
      const teamEmployees = getMembers(values.newMembers, team.id);
      await editTeamEmployees(teamEmployees);
    }

    if (values.childTeams) {
      const changeParentTeam = values.childTeams.map(item => ({
        id: item.id,
        parentId: team.id,
      }));
      const teamApiCalls = changeParentTeam.map(item => {
        return teamService.editTeamById({
          id: item.id,
          team: { parentId: item.parentId },
        });
      });
      await Promise.all(teamApiCalls);
    }

    thunkAPI.dispatch(
      enqueueSnackbar({
        options: {
          key: new Date().getTime() + Math.random(),
          variant: NotificationType.success,
          body: 'Team created successfully!',
          title: 'Team',
        },
      })
    );
    return team;
  } catch (error: any) {
    if (!error.message) {
      throw error;
    }
    return error;
  }
});

export const deleteTeam = createAsyncThunk(
  'people/deleteTeam',
  async (params: { id: number; callback?: () => void }, thunkAPI) => {
    try {
      await teamService.deleteTeam(params.id);
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Team deleted successfully!',
            title: 'Team',
          },
        })
      );
      if (params.callback) {
        params.callback();
      }
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
