import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfYear, format, startOfYear } from 'date-fns';
import cx from 'classnames';

import HolidaysTable from './HolidaysTable';
import WorkingYearTable from './WorkingYearTable';
import EditModal from './EditModal';
import DeleteModal from 'common/Modals/DeleteModal';
import Loader from '../../common/Loader';
import { DATE_PICKER } from '../../constants/common';

import { getHolidays, createHoliday, editHoliday, deleteHoliday } from '../../store/holidays/holidays.thunk';
import { getWorkingYearList } from '../../store/workingYear/workingYear.thunk';
import { InitialHolidayDto } from '../../models/dto/holidays.dto';
import { AppState } from '../../store/configureStore';

import { Role } from '../../constants/roles';
import { HOLIDAYS_TABLE_HEADERS } from 'constants/holidays';
import { arrowLeft, arrowRight, plus } from 'constants/icons';
import { setWorkingTimeSecondsHoliday } from '../../utils/functions';
import { useQuery } from 'hooks/queryHook';

import styles from '../tableStyles.module.scss';

const Holidays: React.FC = () => {
  const dispatch = useDispatch();

  const { holidaysList, loading, isUpdated } = useSelector((state: AppState) => state.holidaysReducer);
  const { workingYearList } = useSelector((state: AppState) => state.workingYearReducer);
  const role = useSelector((state: AppState) => state.account.user.role);

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [holidayId, setHolidayId] = useState(0);
  const [type, setType] = useState('Add holiday');

  const canEdit = role === Role.vp || role === Role.hr;

  const { fromDate, toDate, setFromToDate } = useQuery();

  const [currentYear, setCurrentYear] = useState(
    fromDate ? new Date(fromDate).getFullYear() : new Date().getFullYear()
  );
  const [currentTab, setCurrentTab] = useState(1);

  const getData = useCallback(() => {
    if (!fromDate || !toDate) {
      setFromToDate(
        format(startOfYear(new Date()), DATE_PICKER.dateFormatToPayload),
        format(endOfYear(new Date()), DATE_PICKER.dateFormatToPayload)
      );
    } else {
      dispatch(getHolidays({ fromDate, toDate }));
      dispatch(getWorkingYearList({ fromDate, toDate }));
    }
  }, [fromDate, toDate, dispatch]);

  useEffect(() => {
    getData();
  }, [dispatch, getData]);

  useEffect(() => {
    isUpdated && setOpen(false);
  }, [isUpdated]);

  const onChangeYear = (direction: string) => {
    const currentDate = new Date(fromDate);
    const selectedDate = direction === 'next' ? currentDate.getFullYear() + 1 : currentDate.getFullYear() - 1;

    const selectedYearDate = currentDate.setFullYear(selectedDate);
    const selectedYearStartDate = format(startOfYear(new Date(selectedYearDate)), DATE_PICKER.dateFormatToPayload);
    const selectedYearEndDate = format(endOfYear(new Date(selectedYearDate)), DATE_PICKER.dateFormatToPayload);

    setCurrentYear(new Date(selectedYearDate).getFullYear());
    setFromToDate(selectedYearStartDate, selectedYearEndDate);
  };

  // open modal windows
  const handleClickOnAdd = () => {
    setHolidayId(0);
    setType('Add holiday');
    setOpen(true);
  };

  const handleClickOnEdit = (id: number) => {
    setHolidayId(id);
    setType('Edit holiday');
    setOpen(true);
  };

  const handleClickOnDelete = (id: number) => {
    setOpenDelete(true);
    setHolidayId(id);
  };

  // submit functions for modal windows
  const onSubmit = (values: InitialHolidayDto) => {
    const data = {
      date: format(new Date(values.date), DATE_PICKER.dateFormatToPayload),
      title: values.title,
      workingTimeSeconds: setWorkingTimeSecondsHoliday(values.duration) || 0,
    };
    holidayId !== 0 ? dispatch(editHoliday({ id: holidayId, values: data })) : dispatch(createHoliday(data));

    setOpen(false);
  };

  const onDeleteHoliday = (id: number) => {
    dispatch(deleteHoliday(id));
    setOpenDelete(false);
  };

  return (
    <>
      <div className={styles.buttonWrap}>
        {canEdit && (
          <button className={cx(styles.button, styles.topButton)} onClick={handleClickOnAdd}>
            {plus} Add holiday
          </button>
        )}
      </div>

      <div className={styles.tabs}>
        <div className={currentTab === 1 ? styles.activeTab : styles.tab} onClick={() => setCurrentTab(1)}>
          Holidays
        </div>
        <div className={currentTab === 2 ? styles.activeTab : styles.tab} onClick={() => setCurrentTab(2)}>
          Working Year
        </div>
      </div>

      <div className={cx(styles.tWrapper, 'container-fluid mb-3')}>
        <div className="mb-3 d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className={styles.arrow} onClick={() => onChangeYear('prev')}>
                {arrowLeft}
              </div>

              <div className={styles.currentYear}>{currentYear}</div>

              <div className={styles.arrow} onClick={() => onChangeYear('next')}>
                {arrowRight}
              </div>
            </div>
          </div>
        </div>

        <div className={currentTab === 1 ? styles.container : styles.hideContent}>
          {!!holidaysList.length && !loading ? (
            <HolidaysTable
              data={holidaysList}
              canEdit={canEdit}
              headers={HOLIDAYS_TABLE_HEADERS}
              handleClickOnEdit={handleClickOnEdit}
              handleClickOnDelete={handleClickOnDelete}
            />
          ) : loading ? (
            <Loader />
          ) : (
            <div>Holidays are empty</div>
          )}
        </div>

        {open && (
          <EditModal
            title={type}
            handleClose={() => setOpen(false)}
            handleSubmit={onSubmit}
            submitButton={holidayId === 0 ? 'Add' : 'Save'}
            holidayId={holidayId}
          />
        )}

        {openDelete && (
          <DeleteModal
            title={'holiday'}
            itemToDelete={'this holiday'}
            onClose={() => setOpenDelete(false)}
            onDelete={() => onDeleteHoliday(holidayId)}
          />
        )}

        <div className={currentTab === 2 ? styles.container : styles.hideContent}>
          {!!workingYearList?.length ? (
            <WorkingYearTable data={workingYearList} currentYear={currentYear} />
          ) : loading ? (
            <Loader />
          ) : (
            <div>Working Year is empty</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Holidays;
