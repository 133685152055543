import { createAsyncThunk } from '@reduxjs/toolkit';
import PerformanceReviewAdapter from 'models/adapters/performanceReview.adapter';
import { InitialCreatePRDto, InitialCreateCyclePRDto } from 'models/dto/performanceReview.dto';
import { NotificationType } from 'services/notificationService';
import performanceReviewService from 'services/performanceReviewService';
import { enqueueSnackbar } from 'store/notifications';
import { RequestParams } from 'utils/mapParams';

export const getPerformanceReviews = createAsyncThunk(
  'hr/getPerformanceReviews',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const res = await performanceReviewService.getPerformanceReviews(params);
      return PerformanceReviewAdapter.mapPerformanceReviewDtoToPerformanceReview(res);
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const getPerformanceReviewById = createAsyncThunk(
  'hr/getPerformanceReviewById',
  async (id: string, thunkAPI) => {
    try {
      const res = await performanceReviewService.getPerformanceReviewById(id);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const getPerformanceReviewDetails = createAsyncThunk(
  'hr/getPerformanceReviewDetails',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const res = await performanceReviewService.getPerformanceReviewDetails(params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const createPerformanceReview = createAsyncThunk(
  'hr/createPerformanceReview',
  async (values: InitialCreatePRDto, thunkAPI) => {
    try {
      const res = await performanceReviewService.createPerformanceReview(values);
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Performance Review Cycle created successfully!',
            title: 'Performance Review Cycle',
          },
        })
      );
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const changeStatusPerformanceReview = createAsyncThunk(
  'hr/changeStatusPerformanceReview',
  async (values: { id: number; command: string; status: string }, thunkAPI) => {
    try {
      const res = await performanceReviewService.changeStatusPerformanceReview(values);
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Performance Review Cycle status changed successfully!',
            title: 'Performance Review Cycle',
          },
        })
      );
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const deletePerformanceReview = createAsyncThunk('hr/deletePerformanceReview', async (id: number, thunkAPI) => {
  try {
    await performanceReviewService.deletePerformanceReview(id);
    thunkAPI.dispatch(
      enqueueSnackbar({
        options: {
          key: new Date().getTime() + Math.random(),
          variant: NotificationType.success,
          body: 'Performance Review Cycle deleted successfully!',
          title: 'Performance Review Cycle',
        },
      })
    );
    return id;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const deleteEmployeePR = createAsyncThunk('hr/deleteEmployeePR', async (id: number, thunkAPI) => {
  try {
    thunkAPI.dispatch(
      enqueueSnackbar({
        options: {
          key: new Date().getTime() + Math.random(),
          variant: NotificationType.success,
          body: 'Employee deleted successfully!',
          title: 'Performance Review',
        },
      })
    );
    return id;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const createPerformanceReviewCycle = createAsyncThunk(
  'hr/createPerformanceReviewCycle',
  async (values: InitialCreateCyclePRDto, thunkAPI) => {
    try {
      const res = await performanceReviewService.createPerformanceReviewCycle(values);
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Performance Review created successfully!',
            title: 'Performance Review',
          },
        })
      );
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const deletePerformanceReviewCycle = createAsyncThunk(
  'hr/deletePerformanceReviewCycle',
  async (id: number, thunkAPI) => {
    try {
      await performanceReviewService.deletePerformanceReviewCycle(id);
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Performance Review deleted successfully!',
            title: 'Performance Review',
          },
        })
      );
      return id;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const updatePerformanceReview = createAsyncThunk(
  'hr/updatePerformanceReview',
  async (params: { id: number; values: InitialCreatePRDto }, thunkAPI) => {
    try {
      const res = await performanceReviewService.updatePerformanceReview(params);
      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Performance Review updated successfully!',
            title: 'Performance Review',
          },
        })
      );
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
