import { createSlice } from '@reduxjs/toolkit';
import { getEmployees, updateEmployeeById, updateEmployees } from './allEmployees.thunk';
import { EmployeeDto } from 'models/dto/employee.dto';

interface EmployeesInitialState {
  employees: EmployeeDto[];
  allEmployeesList: EmployeeDto[];
  hrTeamEmployees: EmployeeDto[];
  dataWithId: EmployeeDto[];
  loading: boolean;
  error: null | string;
}

const initialState: EmployeesInitialState = {
  employees: [],
  allEmployeesList: [],
  hrTeamEmployees: [],
  dataWithId: [],
  loading: false,
  error: null,
};

const allEmployeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: {
    [getEmployees.pending.toString()]: state => {
      state.employees = [];
      state.loading = true;
      state.error = null;
    },
    [getEmployees.fulfilled.toString()]: (state, action) => {
      if (action.meta.arg?.id) {
        state.dataWithId = {
          ...state.dataWithId,
          [action.meta.arg.id]: action.payload,
        };
      } else {
        const hrTeamId = sessionStorage.getItem('hrTeamId');

        if (hrTeamId && action.meta.arg.params.team === +hrTeamId) {
          state.hrTeamEmployees = action.payload;
        }
        if (!action.meta.arg.params.team) {
          state.allEmployeesList = action.payload;
        }
        state.employees = action.payload;
      }

      state.loading = false;
      state.error = null;
    },
    [getEmployees.rejected.toString()]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateEmployeeById.pending.toString()]: state => {
      state;
    },
    [updateEmployeeById.fulfilled.toString()]: (state, action) => {
      const currentEmployeeIndex = state.employees?.findIndex(employee => employee.id === +action.meta.arg.id);

      let assignedEmployee;

      if (action.meta.arg.edit === 'hr') {
        assignedEmployee = state.hrTeamEmployees?.find(employee => employee.id === action.meta.arg.employeeData.hrId);
      } else {
        assignedEmployee = state.allEmployeesList?.find(
          employee => employee.id === action.meta.arg.employeeData.mentorId
        );
      }

      state.employees[currentEmployeeIndex] = {
        ...state.employees[currentEmployeeIndex],
        [action.meta.arg.edit]: {
          id: assignedEmployee?.id || null,
          firstName: assignedEmployee?.firstName || '',
          lastName: assignedEmployee?.lastName || '',
        },
      };
    },
    [updateEmployeeById.rejected.toString()]: (state, action) => {
      state.error = action.payload;
    },
    [updateEmployees.pending.toString()]: state => {
      state;
    },
    [updateEmployees.fulfilled.toString()]: (state, action) => {
      const currentHr = state.hrTeamEmployees?.find(employee => employee.id === action.meta.arg.hrId);
      state.employees = state.employees.map(employee => ({
        ...employee,
        hr: {
          id: currentHr?.id || null,
          firstName: currentHr?.firstName || '',
          lastName: currentHr?.lastName || '',
        },
      }));
    },
    [updateEmployees.rejected.toString()]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default allEmployeesSlice.reducer;
