import httpService, { HttpService } from './httpService';
import { BDMAdapter } from 'models/adapters/bdm.adapter';
import { AvailabilityPageDto } from 'models/dto/bdm.dto';
import { RequestParams } from 'utils/mapParams';

export class WorkforceService {
  constructor(private _httpService: HttpService) {}

  public async getWorkforce(params: Partial<RequestParams>): Promise<AvailabilityPageDto> {
    const { pages, total, items } = await this._httpService.get(`/workforce`, {
      queryStringParameters: params,
    });

    return { pages, total, items: BDMAdapter.mapAvailablityDtoToModel(items || []) };
  }
}

export default new WorkforceService(httpService);
