import { createSlice } from '@reduxjs/toolkit';
import { getCompensation } from './compensation.thunk';

interface CompensationInitialState {
  compensation: null | { currency: string; amount: string };
  loading: boolean;
  error: null | Error;
}

const initialState: CompensationInitialState = {
  compensation: null,
  loading: false,
  error: null,
};

const compensationSlice = createSlice({
  name: 'companyExpenses',
  initialState,
  reducers: {
    clearCurrentExpense(state) {
      return {
        ...state,
        currentExpense: null,
      };
    },
  },
  extraReducers: {
    [getCompensation.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [getCompensation.fulfilled.toString()]: (state, { payload }) => {
      state.compensation = payload;
      state.loading = false;
    },
    [getCompensation.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default compensationSlice.reducer;
