import React, { FC } from 'react';
import cx from 'classnames';

import styles from '../../tableStyles.module.scss';

type PropsT = {
  btnRed: string;
  btnBlue: string;
  close(): void;
};

const ButtonsSave: FC<PropsT> = ({ btnRed, btnBlue, close }) => {
  return (
    <div className={cx(styles.buttonWrap, styles.topButton)}>
      <button className={styles.lineButton} onClick={close} type="button" style={{ textTransform: 'capitalize' }}>
        {btnRed}
      </button>
      <button className={styles.button} type="submit" style={{ textTransform: 'capitalize' }}>
        {btnBlue}
      </button>
    </div>
  );
};

export default ButtonsSave;
