import httpService, { HttpService } from './httpService';
import { CompensationOrder, CompensationOrderCommand, OrdersRequestParams } from 'models/compensationOrders.interface';

export class CompensationOrdersService {
  constructor(private _httpService: HttpService) {}

  async getCompensationOrders(params: OrdersRequestParams) {
    const res = await this._httpService.get('/compensation-orders', {
      queryStringParameters: params,
    });
    return res;
  }

  async changeCompensationOrderByCommand(id: number, command: CompensationOrderCommand) {
    const res = await this._httpService.post(`/compensation-orders/${id}/${command}`, {
      headers: {
        'content-type': 'application/json;',
      },
    });
    return res;
  }

  async editCompensationOrder(id: number, body: Partial<CompensationOrder>) {
    const res = await this._httpService.patch(`/compensation-orders/${id}`, {
      body,
    });
    return res;
  }
}

const compensationOrdersService = new CompensationOrdersService(httpService);

export default compensationOrdersService;
