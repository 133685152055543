import React, { useCallback, useMemo, useRef, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import MetisMenu from 'react-metismenu';

import { AppState } from '../../store/configureStore';
import { setEnableMobileMenu } from '../../store/themeOptions';

import { generateMenu } from '../../utils/generateMenu';
import { MAIN_MENU } from '../../constants/mainMenu';

const Nav: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { enableMobileMenu, role } = useSelector((state: AppState) => {
    return {
      enableMobileMenu: state.themeOptions?.enableMobileMenu,
      role: state.account.user?.role,
    };
  });

  const filteredMenu = useMemo(() => generateMenu(role, MAIN_MENU), [role]);
  const toggleMobileSidebar = useCallback(() => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
    sessionStorage.removeItem('navigation');
  }, [dispatch, enableMobileMenu]);

  const active: { current: any } = useRef(null);

  useEffect(() => {
    active.current.changeActiveLinkFromLocation();
  }, [history.location.pathname]);

  return (
    <div className={cx('vertical-nav-menu')}>
      <>
        <h5 className="app-sidebar__heading">Menu</h5>
        <MetisMenu
          ref={active}
          content={filteredMenu}
          onSelected={toggleMobileSidebar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </>
    </div>
  );
};

export default Nav;
