import React, { FC, Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endOfMonth, format, startOfMonth } from 'date-fns';

import { Card, CardBody } from 'reactstrap';
import Loader from 'common/Loader';
import TeamFilter from 'common/TeamFilter/TeamFilter';
import Datepicker from 'components/Datepicker/Datepicker';
import OvertimeRequestTable from './OvertimeRequestTable';
import OverTimeRequestFooter from './OvertimeRequestFooter';

import { getOvertimeRequests } from 'store/overtimeRequests/overtimeRequests.thunk';
import { AppState } from 'store/configureStore';

import { useQuery } from 'hooks/queryHook';
import { DATE_PICKER } from 'constants/common';
import { RequestParams } from 'utils/mapParams';

const OvertimeRequests: FC = () => {
  const dispatch = useDispatch();

  const { overtimeData, loading } = useSelector((state: AppState) => state.overtimeRequestsReducer);

  const { fromDate, toDate, team, setFromToDate, setTeam } = useQuery();

  const getData = useCallback(() => {
    if (!fromDate || !toDate) {
      setFromToDate(
        format(startOfMonth(new Date()), DATE_PICKER.dateFormatToPayload),
        format(endOfMonth(new Date()), DATE_PICKER.dateFormatToPayload)
      );
      return;
    }

    const params: Partial<RequestParams> = {
      fromDate: fromDate,
      toDate: toDate,
    };

    if (team) {
      params.team = +team;
    }

    dispatch(getOvertimeRequests(params));
  }, [dispatch, fromDate, toDate, team]);

  useEffect(() => {
    getData();
  }, [dispatch, getData]);

  const onDateChange = (date: Date) => {
    setFromToDate(
      format(startOfMonth(date), DATE_PICKER.dateFormatToPayload),
      format(endOfMonth(date), DATE_PICKER.dateFormatToPayload)
    );
  };

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className="bp-header">
          <div className="filters-block">
            <TeamFilter team={team} setTeam={setTeam} planning={true} />

            <div className="dropdown-filter">
              <div className="label-wrapper">Date</div>
              <Datepicker
                selected={fromDate ? new Date(fromDate) : startOfMonth(new Date())}
                dateFormat={DATE_PICKER.dateFormatMonth}
                showMonthYearPicker
                onChange={(date: Date) => onDateChange(date)}
              />
            </div>
          </div>
        </div>

        {overtimeData?.issues?.length ? (
          <Fragment>
            <OvertimeRequestTable issues={overtimeData.issues} />
            <OverTimeRequestFooter footer={overtimeData.footer} />
          </Fragment>
        ) : loading ? (
          <Loader />
        ) : (
          'Overtime Requests are empty'
        )}
      </CardBody>
    </Card>
  );
};

export default OvertimeRequests;
