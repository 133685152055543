import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { Card, CardBody } from 'reactstrap';

import ExpenseCategoriesTable from './ExpenseCategoriesTable';
import Pagination from 'components/Pagination/Pagination';
import Loader from 'common/Loader';
import DeleteModal from '../../../common/Modals/DeleteModal';
import AddOrEditCategory from './Modals/AddOrEditCategory';
import ForbidDeletingModal from './Modals/ForbidDeletingModal';

import { AppState } from 'store/configureStore';
import {
  createExpenseCategory,
  deleteExpenseCategory,
  getExpensesCategories,
  updateExpenseCategory,
} from 'store/companyExpensesCategories/expensesCategories.thunk';
import { clearError } from 'store/companyExpensesCategories';
import { CategoryInitialState, ExpenseCategoryT } from 'models/interfaces/companyExpenses.interface';

import { useQuery } from 'hooks/queryHook';
import { Role } from 'constants/roles';
import { plus } from 'constants/icons';

import styles from '../../tableStyles.module.scss';

const ExpenseCategories = () => {
  const dispatch = useDispatch();

  const { expenseCategories, pages, total, loading, error } = useSelector(
    (state: AppState) => state.expenseCategoriesReducer
  );
  const { role } = useSelector((state: AppState) => state.account?.user);

  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openForbidDelete, setOpenForbidDelete] = useState(false);

  const [categoryId, setCategoryId] = useState(0);
  const [isEnabled, setIsEnabled] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const isEditRole = useMemo(() => role === Role.vp || role === Role.finance, [role]);

  const { page, pageSize, setPage, setPageSize } = useQuery();

  const getCategoriesData = useCallback(() => {
    dispatch(
      getExpensesCategories({
        page: +page,
        size: +pageSize,
      })
    );
  }, [page, pageSize]);

  useEffect(() => {
    getCategoriesData();
  }, [getCategoriesData]);

  const setCurrentPageHandler = (value: number) => {
    if (value !== +page) {
      setPage(value);
    }
  };

  const setCountPerPageHandler = (value: number) => {
    setPageSize(value);
  };

  // open modal windows
  const handleClickOnAdd = () => {
    setCategoryId(0);
    setModalTitle('Add Expense Category');
    setOpenModal(true);
  };

  const handleClickOnEdit = (id: number, enabled: boolean) => {
    setCategoryId(id);
    setIsEnabled(enabled);
    setModalTitle('Edit Expense Category');
    setOpenModal(true);
  };

  const handleClickOnDelete = (id: number) => {
    const title = expenseCategories.find((category: ExpenseCategoryT) => category.id === id);
    setModalTitle(title.name);
    setOpenDelete(true);
    setCategoryId(id);
  };

  // submit functions for modal windows
  const onSubmitCategory = (value: CategoryInitialState) => {
    categoryId !== 0
      ? dispatch(updateExpenseCategory({ id: categoryId, value, isEnabled }))
      : dispatch(createExpenseCategory(value));

    setOpenModal(false);
  };

  const onDeleteCategory = (id: number) => {
    dispatch(deleteExpenseCategory(id));
    setOpenDelete(false);
  };

  // close ForbidDeletingModal
  const onCloseForbidDeleting = () => {
    dispatch(clearError());
    setOpenForbidDelete(false);
  };

  if (error === `[BadRequest] You can't remove the 'Category Name' because it is linked to one or more expenses`) {
    if (!openForbidDelete) {
      setOpenForbidDelete(true);
    }
  }

  return (
    <>
      {isEditRole && (
        <button className={cx(styles.button, styles.topButton)} onClick={handleClickOnAdd}>
          {plus} Add Expense Category
        </button>
      )}

      <Card className="main-card mb-3">
        <CardBody>
          {expenseCategories?.length ? (
            <div className={cx(styles.tableWrapper, 'mb-1')} style={{ overflow: 'unset' }}>
              <ExpenseCategoriesTable
                data={expenseCategories || []}
                isEditRole={isEditRole}
                currentPage={+page}
                perPage={+pageSize}
                handleClickOnEdit={handleClickOnEdit}
                handleClickOnDelete={handleClickOnDelete}
              />

              <div className={styles.marginBottomTable}>
                <Pagination
                  pages={pages}
                  perPage={+pageSize}
                  totalCount={total}
                  setCurrentPage={setCurrentPageHandler}
                  currentPage={+page}
                  setCountPerPage={setCountPerPageHandler}
                  count={0}
                />
              </div>
            </div>
          ) : loading ? (
            <Loader />
          ) : (
            'Expense categories are empty'
          )}
        </CardBody>
      </Card>

      {openModal && (
        <AddOrEditCategory
          title={modalTitle}
          id={categoryId}
          onSubmit={onSubmitCategory}
          onCloseModal={() => setOpenModal(false)}
        />
      )}

      {openDelete && (
        <DeleteModal
          title={'Category'}
          itemToDelete={`${modalTitle} category`}
          onClose={() => setOpenDelete(false)}
          onDelete={() => onDeleteCategory(categoryId)}
        />
      )}

      {openForbidDelete && <ForbidDeletingModal title={modalTitle} onClose={onCloseForbidDeleting} />}
    </>
  );
};

export default ExpenseCategories;
