import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface CVT {
  CVid: string;
  loading: boolean;
  errors: Error | null;
}

export const getCVthunk = createAsyncThunk('CV/getCVid', async (email: string) => {
  const { data } = await axios.get(`https://cv.itomy.ch/api/cvs/dev/?email=${email} `);

  return data;
});

const initialState: CVT = {
  CVid: '',
  loading: false,
  errors: null,
};

export const cvSlice = createSlice({
  name: 'CV',
  initialState,
  reducers: {},
  extraReducers: {
    [getCVthunk.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [getCVthunk.fulfilled.toString()]: (state, { payload }) => {
      state.CVid = payload.cvs[0]?.cvs[0].id;
      state.loading = false;
    },
    [getCVthunk.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.errors = payload;
    },
  },
});

export default cvSlice.reducer;
