export const stageOptions = [
  { id: undefined, title: 'all stages' },
  { id: 'connected', title: 'connected' },
  { id: 'needs_identified', title: 'needs identified' },
  { id: 'cv_sent', title: 'cv sent' },
  { id: 'interview_scheduled', title: 'interview scheduled' },
  { id: 'interview_debriefed', title: 'interview debriefed' },
  { id: 'proposal_sent', title: 'proposal sent' },
  { id: 'deal_closed', title: 'deal closed' },
  { id: 'active', title: 'active' },
  { id: 'dead_deal', title: 'dead deal' },
];

export const durationHolidayOptions = ['Day off', '7h', '6h', '5h', '4h', '3h', '2h', '1h'];
