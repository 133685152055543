import { CalendarDayDto } from '../dto/calendar.dto';
import { ScheduleDayInterface } from '../interfaces/schedule-day.interface';
import { ISSUE_TYPES } from '../../constants/issues.constants';

const FULL_DAY = 24 * 3600 * 1000;

export class CalendarAdapter {
  static mapCalendarDayDtoToScheduleDay(day: CalendarDayDto): ScheduleDayInterface {
    const currentDate = new Date();
    const date = new Date(day.date);
    const currentDateStamp = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    ).getTime();
    const dateStamp = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
    const isCurrentDay = currentDateStamp === dateStamp;
    const isDayBeforeCurrent = currentDateStamp === dateStamp + FULL_DAY;
    return {
      date: day.date,
      isCurrentDay,
      isDayBeforeCurrent,
      isHoliday: !day.workingTimeSeconds || !!day.holiday,
      isAvailableAtWeekend: false,
      hours: 0,
      type: ISSUE_TYPES.downtime,
    };
  }
}
