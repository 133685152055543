import PageTitle from '../../Layout/AppMain/PageTitle';
import CompanyStructure from 'components/CompanyStructure/CompanyStructure';
import { companyStructureIcon } from 'constants/icons';

const CompanyStructurePage = () => {
  return (
    <>
      <PageTitle heading="Company Structure" icon={companyStructureIcon} />
      <CompanyStructure />
    </>
  );
};

export default CompanyStructurePage;
