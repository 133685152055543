import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody } from 'reactstrap';
import MentorsAssignmentTable from './MentorsAssignmentTable';
import TeamFilter from 'common/TeamFilter/TeamFilter';
import Loader from 'common/Loader';

import { getEmployees } from 'store/allEmployees/allEmployees.thunk';
import { AppState } from 'store/configureStore';

import { useQuery } from 'hooks/queryHook';
import EmployeeAdapter from 'models/adapters/employee.adapter';
import { EmployeeDto } from 'models/dto/employee.dto';
import { RequestParams } from 'utils/mapParams';
import { formatAssignees } from 'utils/formatDatas';
import { Role } from '../../constants/roles';

const MentorsAssignment: FC = () => {
  const dispatch = useDispatch();

  const { employees, allEmployeesList, loading } = useSelector((state: AppState) => state.allEmployeesReducer);
  const { user } = useSelector((state: AppState) => state.account);

  const employeesWithMentors = EmployeeAdapter.mapEmployeeDtoToMentors(employees);
  const formattedEmployeesList = allEmployeesList && formatAssignees(allEmployeesList);

  const { team, setTeamData } = useQuery();

  const getData = useCallback(() => {
    const params: Partial<RequestParams> = { expand: 'mentor' };

    if (team) {
      params.team = +team;
    }
    dispatch(getEmployees({ params }));
  }, [team]);

  const fetchData = async () => {
    if (!allEmployeesList.length) {
      await dispatch(getEmployees({ params: { expand: 'mentor' } }) as unknown as Promise<EmployeeDto[]>);
    }
    getData();
  };

  useEffect(() => {
    if (user.role === Role.teamlead && user.teams?.split(',')?.length >= 1 && !team) {
      setTeamData(user.teams?.split(',')?.[0]);
    } else {
      fetchData();
    }
  }, [getData]);

  return (
    <Card className="main-card mb-3">
      <CardBody>
        {(user.role === Role.vp || (user.role === Role.teamlead && user.teams?.split(',')?.length > 1)) && (
          <div className="bp-header">
            <div className="filters-block">
              <TeamFilter
                team={team}
                setTeam={setTeamData}
                teamLeadTeams={user.role === Role.teamlead ? user.teams : ''}
              />
            </div>
          </div>
        )}

        {employeesWithMentors?.length ? (
          <MentorsAssignmentTable data={employeesWithMentors} dropdownData={formattedEmployeesList} />
        ) : loading ? (
          <Loader />
        ) : (
          'Mentors assignees are empty'
        )}
      </CardBody>
    </Card>
  );
};

export default MentorsAssignment;
