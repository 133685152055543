import * as Yup from 'yup';

export const PROFILE_TABS_HEADERS = [
  { id: 0, title: 'Public Information' },
  { id: 1, title: 'VSD Information' },
  { id: 2, title: 'Private Information' },
  { id: 3, title: 'Obligation Information' },
  { id: 4, title: 'Worklog Issues' },
  { id: 5, title: 'Mentees' },
];

export const GRADE_DROPDOWN = ['Trainee', 'Junior', 'Middle', 'Senior'];
export const SALES_GRADE_DROPDOWN = ['junior', 'middle', 'senior', 'techlead', 'architect'];

export const DATE_FORMAT_FIELDS = ['startDate', 'birthday', 'trialEndDate', 'dismissalDate'];

export const PROFILE_PUBLIC_SCHEMA = Yup.object({
  salesGradeId: Yup.string(),
  isRemote: Yup.boolean(),
  birthday: Yup.string(),
  lastName: Yup.string().required('Field required'),
  dismissalDate: Yup.string().nullable(),
  availability: Yup.number().required('Field required').positive().max(1, 'Value should be less or equal to 1'),
  title: Yup.string(),
  isTechlead: Yup.boolean(),
  personalityTestResults: Yup.string(),
  firstName: Yup.string().required('Field required'),
  trialEndDate: Yup.string(),
  grade: Yup.string(),
  location: Yup.string(),
  city: Yup.string(),
  startDate: Yup.string().required('Field required'),
  email: Yup.string(),
  teamId: Yup.number(),
});

export const PROFILE_PRIVATE_SCHEMA = Yup.object({
  isVacationPaid: Yup.boolean(),
  englishLevel: Yup.string(),
  address: Yup.string(),
  wearSize: Yup.string(),
  hasChildren: Yup.boolean(),
  emergencyContact: Yup.string(),
});

export const CONTACT_SCHEMA = (title: string) =>
  Yup.object({
    isPrimary: Yup.boolean(),
    employeeId: Yup.number().required('Field required'),
    type: Yup.string().required('Field required'),
    value:
      title === 'Email'
        ? Yup.string().email().required('Field required')
        : title === 'Children'
        ? Yup.string().required('Field required')
        : Yup.number().required('Field required'),
  });

export enum VSD_TITLE {
  history = 'history',
  planned = 'planned',
}

export enum CONTACTS_TITLE {
  email = 'Email',
  phone = 'Phone',
  children = 'Children',
}

export enum CONTACTS_LINK {
  mailto = 'mailto',
  tel = 'tel',
}
