import React, { Fragment } from 'react';

import PageTitle from '../../Layout/AppMain/PageTitle';
import ExternalInterviewTable from '../../components/ExternalInterviewTable/ExternalInterviewTable';
import { externalInterviewIcon } from 'constants/icons';

const ExternalInterviewPage = () => {
  return (
    <Fragment>
      <PageTitle heading="External Interview" icon={externalInterviewIcon} />
      <ExternalInterviewTable />
    </Fragment>
  );
};

export default ExternalInterviewPage;
