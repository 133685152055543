import { createSlice } from '@reduxjs/toolkit';
import {
  createExpenseCategory,
  deleteExpenseCategory,
  disableExpenseCategory,
  getExpenseCategoryById,
  getExpensesCategories,
  updateExpenseCategory,
} from './expensesCategories.thunk';
import { ExpenseCategoriesState } from 'models/interfaces/companyExpenses.interface';

const initialState: ExpenseCategoriesState = {
  expenseCategories: [],
  currentCategory: null,
  pages: 0,
  total: 0,
  loading: false,
  loadingModal: false,
  error: null,
};

const expenseCategoriesSlice = createSlice({
  name: 'companyExpenses',
  initialState,
  reducers: {
    clearCurrentCategory(state) {
      return {
        ...state,
        currentCategory: null,
      };
    },
    clearError(state) {
      return {
        ...state,
        error: null,
      };
    },
  },
  extraReducers: {
    [getExpensesCategories.pending.toString()]: state => {
      state.expenseCategories = [];
      state.loading = true;
      state.error = null;
    },
    [getExpensesCategories.fulfilled.toString()]: (state, { payload }) => {
      state.expenseCategories = payload.items;
      state.pages = payload.pages;
      state.total = payload.total;
      state.loading = false;
    },
    [getExpensesCategories.rejected.toString()]: state => {
      state.loading = false;
      state.error = null;
    },
    [getExpenseCategoryById.pending.toString()]: state => {
      state.loadingModal = true;
      state.currentCategory = null;
      state.error = null;
    },
    [getExpenseCategoryById.fulfilled.toString()]: (state, { payload }) => {
      state.loadingModal = false;
      state.currentCategory = payload;
    },
    [getExpenseCategoryById.rejected.toString()]: (state, { payload }) => {
      state.loadingModal = false;
      state.error = payload;
    },
    [createExpenseCategory.pending.toString()]: state => {
      state.loadingModal = true;
      state.error = null;
    },
    [createExpenseCategory.fulfilled.toString()]: (state, { payload }) => {
      state.expenseCategories = [...state.expenseCategories, { ...payload }];
      state.loadingModal = false;
    },
    [createExpenseCategory.rejected.toString()]: (state, { payload }) => {
      state.loadingModal = false;
      state.error = payload;
    },
    [updateExpenseCategory.pending.toString()]: state => {
      state.loadingModal = true;
      state.error = null;
    },
    [updateExpenseCategory.fulfilled.toString()]: (state, { payload }) => {
      const currentIndex = state.expenseCategories?.findIndex(category => category.id === payload.id);
      state.expenseCategories[currentIndex] = {
        id: payload.id,
        name: payload.value.name,
        isEnabled: payload.isEnabled,
      };
      state.loadingModal = false;
    },
    [updateExpenseCategory.rejected.toString()]: (state, { payload }) => {
      state.loadingModal = false;
      state.error = payload;
    },
    [disableExpenseCategory.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [disableExpenseCategory.fulfilled.toString()]: (state, { payload }) => {
      state.loading = false;

      const currentIndex = state.expenseCategories?.findIndex(category => category.id === payload.id);
      state.expenseCategories[currentIndex] = {
        id: payload.id,
        name: state.expenseCategories[currentIndex].name,
        isEnabled: payload.isEnabled,
      };
    },
    [disableExpenseCategory.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deleteExpenseCategory.pending.toString()]: state => {
      state.loadingModal = true;
      state.error = null;
    },
    [deleteExpenseCategory.fulfilled.toString()]: (state, { payload }) => {
      state.expenseCategories = state.expenseCategories.filter(category => category.id !== payload);
      state.loadingModal = false;
    },
    [deleteExpenseCategory.rejected.toString()]: (state, { payload }) => {
      state.loadingModal = false;
      state.error = payload.response.data.message;
    },
  },
});

export const { clearCurrentCategory, clearError } = expenseCategoriesSlice.actions;

export default expenseCategoriesSlice.reducer;
