import React, { FC, useLayoutEffect, useState } from 'react';
import { format } from 'date-fns';
import cx from 'classnames';

import { Col, Row, Table } from 'reactstrap';
import TeamTableRow from './TeamTableRow';

import { formatDateToDayName } from 'utils/dates';
import { arrowLeft } from 'constants/icons';
import { CalendarTableProps, WorkloadTeamItem } from 'models/interfaces/workloadCalendar.interface';
import { CalendarDayInterface } from 'models/interfaces/calendar-day.interface';

import styles from '../workloadStyle.module.scss';
import tableStyles from '../../tableStyles.module.scss';

const WorkloadCalendarTable: FC<CalendarTableProps> = ({ data, dateRange, setLoadingTable }) => {
  const [expandTable, setExpandTable] = useState(false);
  const [hoveredColumn, setHoveredColumn] = useState<number | null>(null);

  useLayoutEffect(() => {
    setLoadingTable(false);
  }, []);

  return (
    <Row className="flex-nowrap">
      <Col className="overflow-hidden">
        <div className={cx(tableStyles.tableWrapper, styles.headRow)}>
          <button
            onClick={() => setExpandTable(!expandTable)}
            className={cx(styles.expandButton, expandTable ? styles.open : styles.closed)}
          >
            {arrowLeft}
          </button>

          <Table responsive hover className={cx(styles.container, 'align-middle mb-0 custom-table text-left')}>
            <thead>
              <tr className={cx(styles.theadCell, expandTable ? styles.tableCellsFixed : styles.firstCellFixed)}>
                <th className={styles.firstCell}>Employee</th>

                {expandTable && (
                  <>
                    <th className={cx(styles.totalCell, styles.hoursCell)}>Hours</th>
                    <th className={styles.totalCell}>
                      B <br /> RR
                    </th>
                    <th className={styles.totalCell}>
                      N-B
                      <br />
                      RR
                    </th>
                    <th className={styles.totalCell}>VSD</th>
                    <th className={styles.totalCell}>Log</th>
                    <th className={styles.totalCell}>
                      Total <br /> DT
                    </th>
                    <th className={styles.totalCell}>
                      {' '}
                      Rem <br /> DT
                    </th>
                  </>
                )}

                {dateRange?.map((date: CalendarDayInterface, index: number) => (
                  <th
                    key={index}
                    className={styles.calendarCell}
                    onMouseEnter={() => setHoveredColumn(index)}
                    onMouseLeave={() => setHoveredColumn(null)}
                    style={{
                      background: index === hoveredColumn ? '#DCE3FF' : date.isHoliday ? '#E6E7ED' : '',
                      border: date.isCurrentDay ? '1px solid #4F75FF' : '1px solid #E6E7ED',
                      borderBottom: date.isCurrentDay ? '1px solid #E6E7ED' : '',
                    }}
                  >
                    <span>
                      {format(new Date(date.date), 'MMM')}
                      <br />
                    </span>
                    <span>
                      {new Date(date.date).getDate()}
                      <br />
                    </span>
                    <span className={styles.weekday}>{formatDateToDayName(date.date)}</span>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {data?.map((item: WorkloadTeamItem) => (
                <React.Fragment key={item.id}>
                  <TeamTableRow
                    tableData={item}
                    dateRange={dateRange}
                    expandTable={expandTable}
                    hoveredColumn={hoveredColumn}
                    setHoveredColumn={setHoveredColumn}
                  />
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default WorkloadCalendarTable;
