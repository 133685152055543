import React from 'react';
import { Table } from 'reactstrap';
import cx from 'classnames';

import { RateCardInterface } from '../../../models/dto/bdm.dto';

import styles from '../../tableStyles.module.scss';

type TProps = { data: Array<RateCardInterface> };

const RateCardTable: React.FC<TProps> = ({ data = [] }) => {
  return (
    <div className={styles.tableWrapper}>
      <Table
        responsive
        hover
        className={cx(styles.tableContainer, styles.minWidth1000, 'align-middle mb-3 custom-table text-left')}
      >
        <thead>
          <tr>
            <th className={styles.firstCellWidth}>#</th>
            <th>Sales Grade</th>
            <th>Team</th>
            <th>Rate</th>
            <th>Effective Date</th>
            <th>Expiration</th>
          </tr>
        </thead>

        <tbody>
          {data?.map((item, index: number) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.grade || '-'}</td>
              <td>{item.team || '-'}</td>
              <td>{item.rate || '-'}</td>
              <td>{item.date || '-'}</td>
              <td>{item.expiration || '-'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default RateCardTable;
