import React from 'react';
import cx from 'classnames';
import { DropdownList } from 'react-widgets';

import { PER_PAGE_DATA, PAGES_NUMBER_LIMIT } from 'constants/common';

import styles from './pagination.module.scss';
import { arrowLeft, arrowRight } from 'constants/icons';

type PaginationType = {
  pages: number;
  perPage: number;
  totalCount: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  setCountPerPage: (value: number) => void;
  count: number;
};

const Pagination: React.FC<PaginationType> = ({
  pages,
  perPage,
  totalCount,
  currentPage,
  setCurrentPage,
  setCountPerPage,
  count,
}: any) => {
  const pageNumbers = Array.from({ length: pages }, (v, i) => i + 1);
  const disabledForward = Number(currentPage) === pages || pages === 0;
  const disabledBack = Number(currentPage) === 1;

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const setNexPage = () => setCurrentPage(Number(currentPage) + 1);
  const setPrevPage = () => setCurrentPage(currentPage - 1);
  const setLastPage = () => setCurrentPage(pages);
  const setFirstPage = () => setCurrentPage(1);
  const changeCountPerPage = (value: number) => {
    setFirstPage();
    setCountPerPage(value);
  };

  const renderPageNumbers = pageNumbers.map(item =>
    item < currentPage + PAGES_NUMBER_LIMIT && item > currentPage - PAGES_NUMBER_LIMIT ? (
      <li
        key={item}
        className={cx(styles.paginationItem, { [styles.activeItem]: Number(currentPage) === item })}
        onClick={() => paginate(item)}
      >
        {item}
      </li>
    ) : null
  );

  return (
    <div className={styles.pagination}>
      <div className={styles.action}>
        <div className={styles.actionButtons}>
          <button onClick={setFirstPage} disabled={disabledBack} className={cx({ [styles.disabledBtn]: disabledBack })}>
            <div className={styles.arrowButton}>
              {arrowLeft}
              {arrowLeft}
            </div>
          </button>

          <button onClick={setPrevPage} disabled={disabledBack} className={cx({ [styles.disabledBtn]: disabledBack })}>
            <div className={styles.arrowButton}>{arrowLeft}</div>
          </button>
        </div>

        <ul className={styles.paginationWrapper}>{renderPageNumbers}</ul>

        <div className={styles.actionButtons}>
          <button
            onClick={setNexPage}
            disabled={disabledForward}
            className={cx({ [styles.disabledBtn]: disabledForward })}
          >
            <div className={styles.arrowButton}>{arrowRight}</div>
          </button>

          <button
            onClick={setLastPage}
            disabled={disabledForward}
            className={cx({ [styles.disabledBtn]: disabledForward })}
          >
            <div className={styles.arrowButton}>
              {arrowRight}
              {arrowRight}
            </div>
          </button>
        </div>
      </div>

      <div className={styles.selectPerPage}>
        <DropdownList
          data={PER_PAGE_DATA}
          onChange={changeCountPerPage}
          containerClassName={styles.selectSize}
          value={perPage}
        />

        <div className={styles.showing}>
          Showing {perPage * currentPage - perPage + 1} -{' '}
          {perPage * currentPage -
            count -
            (currentPage === pageNumbers.length ? perPage * currentPage - totalCount : 0)}{' '}
          of {totalCount - count}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
