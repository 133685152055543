import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import { DropdownList } from 'react-widgets';

import { AppState } from 'store/configureStore';
import { getProjectsList } from 'store/projects/projects.thunk';
import { useQuery } from 'hooks/queryHook';
import { formatProjects } from 'utils/formatDatas';
import { ProjectT } from 'models/interfaces/projects.interface';

import styles from '../../components/tableStyles.module.scss';

interface ProjectFilterProps {
  setFilterValue: (value: string, search?: string) => void;
  searchValue?: string;
}

const ProjectFilter = ({ setFilterValue, searchValue }: ProjectFilterProps) => {
  const dispatch = useDispatch();

  const { projects, loading } = useSelector((state: AppState) => state.projectsReducer);

  const { projectKey } = useQuery();

  useEffect(() => {
    dispatch(getProjectsList({ page: 1, size: 50 }));
  }, []);

  const onProjectChange = (project: ProjectT) => {
    setFilterValue(project.projectKey, searchValue);
  };

  const onProjectSearch = useCallback(
    debounce((event: KeyboardEvent) => {
      const target = event.target as HTMLInputElement;
      dispatch(getProjectsList({ page: 1, size: 50, search: target.value }));
    }, 250),
    []
  );

  const projectList = formatProjects(projects || []);

  return (
    <div className="dropdown-filter">
      <p className="label-wrapper">Project</p>
      <DropdownList
        containerClassName={cx(styles.inputsField, styles.dropdown)}
        value={projectKey || ''}
        placeholder="All"
        data={projectList}
        textField="name"
        filter="contains"
        onChange={onProjectChange}
        onKeyDown={onProjectSearch}
        busy={loading}
      />
    </div>
  );
};

export default ProjectFilter;
