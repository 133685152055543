import React, { FC } from 'react';
import cx from 'classnames';

import styles from './Toggle.module.scss';

interface ToggleProps {
  checked: boolean;
  onChange: () => void;
  toggleMargin?: string;
  disabled?: boolean;
}

const Toggle: FC<ToggleProps> = ({ checked, onChange, toggleMargin, disabled }) => {
  return (
    <label className={cx(styles.toggle, toggleMargin)}>
      <input
        className={styles.toggle__checkbox}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className={styles.toggle__switch}></span>
    </label>
  );
};

export default Toggle;
