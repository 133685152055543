import { createAsyncThunk } from '@reduxjs/toolkit';
import employeesService from 'services/employeesService';
import issueService from 'services/issueService';
import { EmployeeDto } from 'models/dto/employee.dto';
import { EmployeesVsdList } from 'models/interfaces/vsd.interface';
import { RequestParams } from 'utils/mapParams';
import { ISSUE_TYPES } from 'constants/issues.constants';

export const getVsdData = createAsyncThunk('vsd/getVsdData', async (params: Partial<RequestParams>, thunkAPI) => {
  try {
    const { employees } = await employeesService.getEmployeesFullData({
      date: params.fromDate,
      team: params?.team,
      department: params?.department,
    });

    const directEmployees: string[] = [];
    const indirectEmployees: string[] = [];

    employees?.forEach((employee: EmployeeDto) => {
      if (employee.atlassianId) {
        employee.isDirectCost
          ? directEmployees.push(employee.atlassianId)
          : indirectEmployees.push(employee.atlassianId);
      }
    });

    const data: EmployeesVsdList = {};

    if (directEmployees.length) {
      const directVsd = await issueService.getIssuesList(ISSUE_TYPES.vsd, directEmployees, {
        fromDate: params.fromDate,
        toDate: params.toDate,
      });
      data.direct = directVsd;
    }

    if (indirectEmployees.length) {
      const indirectVsd = await issueService.getIssuesList(ISSUE_TYPES.vsd, indirectEmployees, {
        fromDate: params.fromDate,
        toDate: params.toDate,
      });
      data.indirect = indirectVsd;
    }

    return data;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

export const getSelectedEmployeeVsdData = createAsyncThunk(
  'vsd/getSelectedEmployeeVsdData',
  async (params: { fromDate: string; toDate: string; employees: string[] }, thunkAPI) => {
    try {
      const res = await issueService.getIssuesList(ISSUE_TYPES.vsd, params.employees, {
        fromDate: params.fromDate,
        toDate: params.toDate,
      });

      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
