import React, { FC, useRef } from 'react';
import { startOfMonth } from 'date-fns';
import cx from 'classnames';

import { InputGroup, FormGroup, Form } from 'reactstrap';
import DatePicker from 'react-datepicker';

import { calendar } from 'constants/icons';

import './style.scss';

interface DatepickerInputT {
  selected: Date;
  dateFormat: string;
  onChange: (date: Date) => void;
  showMonthYearPicker: boolean;
  showQuarterYearPicker?: boolean;
}

export interface DatePickerT {
  ref: InputGroup;
  className: string;
  selected: Date;
  dateFormat: string;
  onChange: (date: Date) => void;
  showMonthYearPicker: boolean;
  showQuarterYearPicker: boolean;
  setOpen: (value: boolean) => void;
}

const Datepicker: FC<DatepickerInputT> = ({
  selected,
  dateFormat,
  onChange,
  showMonthYearPicker,
  showQuarterYearPicker,
}) => {
  const dateRef = useRef<DatePickerT>();
  const currentMonth = startOfMonth(new Date());

  const setThisMonth = () => {
    onChange(currentMonth);
    dateRef.current?.setOpen(false);
  };

  return (
    <Form className="calendarWrapper">
      <FormGroup>
        <InputGroup>
          <DatePicker
            ref={dateRef}
            className="form-control"
            selected={selected}
            dateFormat={dateFormat}
            onChange={onChange}
            showMonthYearPicker={showMonthYearPicker}
            showQuarterYearPicker={showQuarterYearPicker}
          >
            <div
              className={cx('greyLine', showMonthYearPicker ? 'year' : showQuarterYearPicker ? 'quarter' : 'month')}
            ></div>
            <button className="selectThisMonth" onClick={setThisMonth}>
              {showQuarterYearPicker ? 'This quarter' : 'This month'}
            </button>
          </DatePicker>
        </InputGroup>
      </FormGroup>
      <div className="calendarIcon" onClick={() => dateRef.current?.setOpen(true)}>
        {calendar}
      </div>
    </Form>
  );
};

export default Datepicker;
