import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import RateCardTable from './RateCardTable';
import Datepicker from '../../Datepicker/Datepicker';
import Loader from '../../../common/Loader';

import { DATE_PICKER } from '../../../constants/common';
import { getRatesCards } from 'store/bdm/bdm.thunk';
import { AppState } from '../../../store/configureStore';

import styles from '../../tableStyles.module.scss';

const RateCard: React.FC = () => {
  const dispatch = useDispatch();

  const { ratesCards, loading } = useSelector((state: AppState) => state.bdmReducer);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const getRateCard = useCallback(() => {
    dispatch(getRatesCards({}));
  }, [dispatch, selectedDate]);

  useEffect(() => {
    getRateCard();
  }, [getRateCard, dispatch]);

  return (
    <div className={cx(styles.wrapper, 'container-fluid')}>
      <div className={styles.container}>
        <div className="d-flex align-items-center justify-content-space-between">
          <div className="filters-block">
            <div className={styles.inputWrap}>
              <div>Date:</div>
              <div className="dropdown-filter mb-0">
                <Datepicker
                  selected={selectedDate}
                  dateFormat={DATE_PICKER.dateFormatMonth}
                  showMonthYearPicker
                  onChange={(date: Date) => {
                    setSelectedDate(date);
                    sessionStorage.DealDate = date;
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mb-3">{loading && <Loader />}</div>
        </div>

        <div>{!!ratesCards?.length ? <RateCardTable data={ratesCards} /> : <div>Rate Cards are empty</div>}</div>
      </div>
    </div>
  );
};

export default RateCard;
