import httpService, { HttpService } from './httpService';
import { cleanEmptyParams, RequestParams } from '../utils/mapParams';

export class IssueService {
  constructor(private _httpService: HttpService) {}

  public async getIssuesList(issueType: string, body: string[], params: Partial<RequestParams>) {
    return await this._httpService.post(`/issues/${issueType}`, {
      queryStringParameters: cleanEmptyParams(params),
      body,
    });
  }
}

export default new IssueService(httpService);
