import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { Card, CardBody } from 'reactstrap';

import TableSticky from './TableSticky';
import AvailabilityInputs from './AvailabilityInputs';
import Pagination from 'components/Pagination/Pagination';
import Loader from 'common/Loader';

import { getFullAvailabilityData, getSalesGradesList } from '../../../store/bdm/bdm.thunk';
import { AppState } from '../../../store/configureStore';

import { AVAILABILITY_TABLE_HEADERS } from 'constants/peopleTableHeaders';
import { useQuery } from 'hooks/queryHook';
import { usePageInUrlCheck } from 'hooks/usePageInUrlCheck';

import { FilterT } from 'models/dto/bdm.dto';
import { RequestParams } from 'utils/mapParams';

import styles from '../../tableStyles.module.scss';
import '../../common.scss';

const Availability: React.FC = () => {
  const dispatch = useDispatch();

  const { availabilityData, salesGrades, pages, total, loading, errors } = useSelector(
    (state: AppState) => state.bdmReducer
  );

  const pagePresentInUrl = usePageInUrlCheck();

  const {
    page,
    pageSize,
    team,
    search,
    salesGrade,
    available,
    setPage,
    setPageSize,
    setTeamValue,
    setSearch,
    setSalesGrade,
    setAvailable,
  } = useQuery();

  const [searchInput, setSearchInput] = useState(search ? search : '');

  const salesGradeValue = salesGrades?.find((item: FilterT) => item.id === +salesGrade);

  const getData = useCallback(() => {
    if (!pagePresentInUrl) {
      setAvailable(true);
    } else {
      const params: Partial<RequestParams> = {
        expand: 'deals',
        page: +page,
        size: +pageSize,
      };

      if (team) {
        params.team = +team;
      }
      if (available) {
        params.available = true;
      }
      if (search) {
        params.search = search;
      }
      if (salesGrade) {
        params.salesGrade = +salesGrade;
      }

      dispatch(getFullAvailabilityData(params));
    }
  }, [dispatch, team, salesGrade, search, available, page, pageSize]);

  useEffect(() => {
    if (!salesGrades.length) {
      dispatch(getSalesGradesList());
    } else {
      getData();
    }
  }, [salesGrades, getData]);

  const onSalesGradeChange = (value: FilterT) => {
    setSalesGrade(value.id ? String(value.id) : '', searchInput);
  };

  const onCheckedInput = (value: boolean) => {
    setAvailable(value);
  };

  const updateSearchInput = (event: React.FormEvent) => {
    setSearch(searchInput.trim());
    event.preventDefault();
  };

  const setCurrentPageHandle = (value: number) => {
    if (value !== +page) {
      setPage(value, searchInput);
    }
  };

  const setCountPerPageHandler = (value: number) => {
    setPageSize(value, searchInput);
  };

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <AvailabilityInputs
          team={team}
          setTeamValue={setTeamValue}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          updateSearchInput={updateSearchInput}
          salesGradeValue={salesGradeValue}
          onSalesGradeChange={onSalesGradeChange}
          checked={Boolean(available)}
          onCheckedInput={onCheckedInput}
          error={errors}
        />

        {loading ? (
          <Loader />
        ) : availabilityData.length ? (
          <div className={cx(styles.tableWrapper, 'mb-1')} style={{ overflow: 'unset' }}>
            <TableSticky
              data={availabilityData || []}
              headers={AVAILABILITY_TABLE_HEADERS}
              currentPage={+page}
              perPage={+pageSize}
            />

            <div className={styles.marginBottomTable}>
              <Pagination
                pages={pages}
                perPage={+pageSize}
                totalCount={total}
                setCurrentPage={setCurrentPageHandle}
                currentPage={+page}
                setCountPerPage={setCountPerPageHandler}
                count={0}
              />
            </div>
          </div>
        ) : (
          'Workforce availability is empty'
        )}
      </CardBody>
    </Card>
  );
};

export default Availability;
