export function checkValidEnumValue<T>(value: string | null, enumeration: { [key: string]: T }): T | null {
  if (value === null) return null;

  const enumValues = Object.values(enumeration);
  if (enumValues.includes(value as T)) {
    return value as T;
  }

  return null;
}
