import httpService, { HttpService } from './httpService';
import { RequestParams } from '../utils/mapParams';
import { CalendarDayDto, CalendarDto } from '../models/dto/calendar.dto';
import { CalendarAdapter } from '../models/adapters/calendar.adapter';

export class CalendarService {
  constructor(private _httpService: HttpService) {}

  async getCalendar(params: Partial<RequestParams>) {
    const res: CalendarDto = await this._httpService.get('/calendar', {
      queryStringParameters: params,
    });
    return res?.days.map((day: CalendarDayDto) => CalendarAdapter.mapCalendarDayDtoToScheduleDay(day));
  }
}

const calendarService = new CalendarService(httpService);

export default calendarService;
