import { createSlice } from '@reduxjs/toolkit';
import { getOvertimeRequests } from './overtimeRequests.thunk';
import { transformOvertimeRequests } from 'components/OvertimeRequestDashboard/constants';
import { ORInitialState } from 'models/interfaces/overtimeRequests.interface';

const initialState: ORInitialState = {
  overtimeData: null,
  loading: false,
  error: null,
};

const overtimeRequestsSlice = createSlice({
  name: 'overtimeRequests',
  initialState,
  reducers: {},
  extraReducers: {
    [getOvertimeRequests.pending.toString()]: state => {
      state.overtimeData = null;
      state.loading = true;
      state.error = null;
    },
    [getOvertimeRequests.fulfilled.toString()]: (state, { payload }) => {
      state.overtimeData = transformOvertimeRequests(payload);
      state.loading = false;
      state.error = null;
    },
    [getOvertimeRequests.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default overtimeRequestsSlice.reducer;
