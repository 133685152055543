import httpService, { HttpService } from './httpService';
import { RequestParams } from '../utils/mapParams';
import WorkingYearAdapter from '../models/adapters/workingYear.adapter';

export class WorkingYear {
  constructor(private _httpService: HttpService) {}

  async getWorkingYear(params: Partial<RequestParams>) {
    const { items } = await this._httpService.get('/workingtime', {
      queryStringParameters: params,
    });
    return WorkingYearAdapter.mapWorkingYearDtoToWorkingYearModel(items);
  }
}

const workingYear = new WorkingYear(httpService);

export default workingYear;
