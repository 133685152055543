import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import Loader from 'common/Loader';

import { AppState } from 'store/configureStore';

import styles from '../../../modal.module.scss';

type PropsType = {
  title: string;
  submitButton: string;
  onClose: () => void;
  onChange: () => void;
};

const ChangeStatusPRModal: React.FC<PropsType> = ({ submitButton, title, onClose, onChange }) => {
  const { loadingModal } = useSelector((state: AppState) => state.performanceReviewReducer);

  return (
    <div className={styles.modal}>
      <div className={styles.backDrop} onClick={onClose}></div>
      <div className={cx(styles.block, 'text-left')}>
        <button className={styles.close} onClick={onClose} />
        <div className={styles.header}>{submitButton} PR</div>
        <h6 className={styles.title}>{title}</h6>
        <div className="ml-3">{loadingModal && <Loader />}</div>

        <div className={styles.buttonWrapper}>
          <button
            className={submitButton === 'Delete' ? styles.redLineButton : styles.blueLineButton}
            onClick={onClose}
          >
            Cancel
          </button>

          <button className={submitButton === 'Delete' ? styles.redButton : styles.blueButton} onClick={onChange}>
            {submitButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeStatusPRModal;
