import { createSlice } from '@reduxjs/toolkit';

interface NotificationsState {
  notifications: NotificationT[];
}

const initialState: NotificationsState = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    enqueueSnackbar: (state, action) => {
      const notifications = [
        ...state.notifications,
        {
          key: action.payload.options.key,
          ...action.payload,
        },
      ];
      if (notifications?.length > 3) {
        notifications.shift();
      }
      return {
        ...state,
        notifications,
      };
    },
    removeSnackbar: (state, action) => {
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.payload.key),
      };
    },
  },
});

export const { enqueueSnackbar, removeSnackbar } = notificationsSlice.actions;

export default notificationsSlice.reducer;
