import React, { FC } from 'react';
import cx from 'classnames';
import { Field } from 'formik';
import { Button } from 'reactstrap';

import FormikInput from '../../../common/Formik/FormikInput';
import { CONTACTS_TITLE } from '../constants';
import FormikInputMask from '../../../common/Formik/FormikInputMask';

import { cancel, save } from '../../../constants/icons';
import styles from '../modal.module.scss';
import stylesCurrent from './ContactsModal.module.scss';

type ContactsModalT = {
  handleClose(): void;
  handleSubmit(): void;
  title: string;
};

const ContactsModal: FC<ContactsModalT> = ({ handleClose, title, handleSubmit }) => {
  return (
    <div className={styles.modal}>
      <div className={cx(styles.block, 'text-center')}>
        <button className={styles.close} onClick={handleClose} />
        <h2 className="mb-4">{title}</h2>
        <div className={cx('mb-4', stylesCurrent.inputWrap)}>
          {title === CONTACTS_TITLE.phone ? (
            <>
              <FormikInputMask
                name="value"
                mask="+38(999)999-99-99"
                placeholder={`Enter ${title}`}
                className={stylesCurrent.input}
              />
            </>
          ) : (
            <>
              <FormikInput name="value" placeholder={`Enter ${title}`} className={stylesCurrent.input} />
            </>
          )}
        </div>
        <div className={cx('d-flex justify-content-center btn-wrap', stylesCurrent.btnWrap)}>
          <Field as="div" onClick={handleClose}>
            <Button color="danger" outline>
              {cancel}
              <span className="ml-2">cancel</span>
            </Button>
          </Field>
          <Field as="div" onClick={handleSubmit}>
            <Button color="primary" type="submit">
              {save}
              <span className="ml-2">save</span>
            </Button>
          </Field>
        </div>
      </div>
    </div>
  );
};

export default ContactsModal;
