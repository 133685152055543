class EmployeeHrAssigneeModel {
  id: number;
  firstName: string;
  lastName: string;
  hr: {
    id: number | null;
    firstName: string;
    lastName: string;
  };

  constructor(data: EmployeeHrAssigneeModel) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.hr = {
      id: data.hr.id,
      firstName: data.hr.firstName,
      lastName: data.hr.lastName,
    };
  }
}

export default EmployeeHrAssigneeModel;
