class EmployeeWithMentorModel {
  id: number;
  firstName: string;
  lastName: string;
  mentor: {
    id: number | null;
    firstName: string;
    lastName: string;
  };

  constructor(data: EmployeeWithMentorModel) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.mentor = {
      id: data.mentor.id,
      firstName: data.mentor.firstName,
      lastName: data.mentor.lastName,
    };
  }
}

export default EmployeeWithMentorModel;
