import httpService, { HttpService } from './httpService';
import { CategoryInitialState } from 'models/interfaces/companyExpenses.interface';

export class ExpenseCategoriesService {
  constructor(private _httpService: HttpService) {}

  async getExpenseCategories(params: { page: number; size: number }) {
    const res = await this._httpService.get('/company-expense-categories', {
      queryStringParameters: params,
    });
    return res;
  }

  async getExpenseCategoryById(id: number) {
    const res = await this._httpService.get(`/company-expense-categories/${id}`);
    return res;
  }

  async createExpenseCategory(value: CategoryInitialState) {
    const res = await this._httpService.post('/company-expense-categories', {
      body: value,
    });
    return res;
  }

  async updateExpenseCategory(params: { id: number; value: CategoryInitialState }) {
    const res = await this._httpService.put(`/company-expense-categories/${params.id}`, {
      body: params.value,
    });
    return res;
  }

  async disableExpenseCategory(params: { id: number; isEnabled: boolean }) {
    await this._httpService.patch(`/company-expense-categories/${params.id}`, {
      body: {
        isEnabled: params.isEnabled,
      },
    });
    return params;
  }

  async deleteExpenseCategory(id: number, errorHandler: (err: any, handler: (err: Error) => void) => void) {
    const res = await this._httpService.delete(`/company-expense-categories/${id}`, { errorHandler });
    return res;
  }
}

const expensesCategoriesService = new ExpenseCategoriesService(httpService);

export default expensesCategoriesService;
