import { API } from 'aws-amplify';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from 'App';
import qs from 'qs';

import { enqueueSnackbar } from '../notifications';

import {
  getWorklogsRequest,
  getWorklogsSuccess,
  getWorklogsFailure,
  updateWorklogRequest,
  updateWorklogSuccess,
  updateWorklogFailure,
} from './vpDashboard.action';

import { NotificationType } from '../../services/notificationService';
import { ParamsT, UpdateWorklogsParams } from 'models/interfaces/vpDashboard.interface';

export const getWorklogsApprovalList =
  (params: ParamsT): ThunkAction<void, RootState, unknown, AnyAction> =>
  async dispatch => {
    dispatch(getWorklogsRequest());
    try {
      const data = await API.get('helloAPI', `/worklog-approvals?${qs.stringify(params)}`, {});
      dispatch(getWorklogsSuccess({ worklogs: data.items, total: data.total, pages: data.pages }));
    } catch (err: any) {
      dispatch(getWorklogsFailure(err));
      dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.error,
            body: err?.message ? err.message : 'Error',
            title: 'Worklog Issues',
          },
        })
      );
      return Promise.reject(err);
    }
  };

export const updateWorklogThunk =
  (params: UpdateWorklogsParams): ThunkAction<void, RootState, unknown, AnyAction> =>
  async dispatch => {
    const { id, status, callback } = params;
    dispatch(updateWorklogRequest(id));
    try {
      await API.post('helloAPI', `/worklog-approvals/${id}/${status}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      dispatch(updateWorklogSuccess(id));
      dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: `Worklog issue ${status} successfully!`,
            title: 'Worklog Issues',
          },
        })
      );
      if (callback) {
        callback();
      }
    } catch (err: any) {
      dispatch(updateWorklogFailure(err));
      dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.error,
            body: err?.message ? err.message : 'Error',
            title: 'Worklog Issues',
          },
        })
      );
      return Promise.reject(err);
    }
  };
