import { matchPath } from 'react-router-dom';
import { RoutesConfigT } from 'config/routes';

export function findRouteMatch(path: string, routes: RoutesConfigT[]) {
  for (const route of routes) {
    const match = matchPath(path, {
      path: route.path,
      exact: route.exact,
    });
    if (match) {
      return route;
    }
  }
  return null;
}
