import React, { Fragment } from 'react';

import PageTitle from '../../Layout/AppMain/PageTitle';
import VpDashboard from '../../components/VpDashboard';
import { worklogIssuesIcon } from 'constants/icons';

const WorklogIssuesPage = () => {
  return (
    <Fragment>
      <PageTitle heading="Worklog Issues" icon={worklogIssuesIcon} />
      <VpDashboard />
    </Fragment>
  );
};

export default WorklogIssuesPage;
