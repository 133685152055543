import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody } from 'reactstrap';

import Loader from '../../common/Loader';
import VpDashboardInputs from './VpDashboardInputs';
import VpDashboardTable from './VpDashboardTable';
import Pagination from 'components/Pagination/Pagination';

import { getWorklogsApprovalList } from '../../store/VpDashboard/vpDashboard.thunk';
import { AppState } from 'store/configureStore';

import { Role } from '../../constants/roles';
import { useQuery } from 'hooks/queryHook';
import { usePageInUrlCheck } from 'hooks/usePageInUrlCheck';
import EmployeeModel from 'models/employee.model';

import styles from '../tableStyles.module.scss';

const WORKLOGS_FOR_ADDITIONAL_DOWNLOAD = 0;

const VpDashboard = () => {
  const dispatch = useDispatch();
  const { loading, worklogsApproval, pages, totalCount, error } = useSelector(
    (state: AppState) => state.vpDashboardReducer
  );

  const { role, userId } = useSelector((state: AppState) => state.account.user);

  const isApproveRole = role === Role.vp || role === Role.teamlead;

  const [count, setCount] = useState(0);

  const pagePresentInUrl = usePageInUrlCheck();

  const { page, pageSize, employee, team, projectKey, setPage, setPageSize, setTeamValue, setEmployee, setProjectKey } =
    useQuery();

  const getWorklogs = useCallback(
    () => {
      if (!pagePresentInUrl) {
        setPage(1);
      } else {
        dispatch(
          getWorklogsApprovalList({
            page: page,
            size: pageSize,
            team: team || undefined,
            projectKey: projectKey || undefined,
            employee: employee || undefined,
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, pageSize, page, team, employee, projectKey]
  );

  const approveCallback = () => {
    if (+page !== 1 && worklogsApproval?.length === 1) {
      setPage(+page - 1);
      setCount(0);
    }
  };

  useEffect(() => {
    if (count === WORKLOGS_FOR_ADDITIONAL_DOWNLOAD) {
      getWorklogs();
      setCount(0);
    }
  }, [count, getWorklogs]);

  const setCurrentPageHandle = (value: number) => {
    if (value !== +page) {
      setPage(value);
    }
  };

  const setCountPerPageHandler = (value: number) => {
    setPageSize(value);
  };

  const onChangeEmployee = (employee: EmployeeModel) => {
    setProjectKey('');
    setEmployee(employee.id);
  };

  const renderTable = () => {
    return (
      <>
        {worklogsApproval?.length && !loading ? (
          <div className={styles.tableWrapper} style={{ overflow: 'unset' }}>
            <VpDashboardTable
              worklogData={worklogsApproval}
              approveCallback={approveCallback}
              isApproveRole={isApproveRole}
              currentPage={+page}
              perPage={+pageSize}
              userId={userId}
              role={role}
              setCount={setCount}
            />

            <div className={styles.marginBottomTable}>
              <Pagination
                pages={pages}
                perPage={+pageSize}
                totalCount={totalCount}
                setCurrentPage={setCurrentPageHandle}
                currentPage={+page}
                setCountPerPage={setCountPerPageHandler}
                count={count}
              />
            </div>
          </div>
        ) : loading ? (
          <Loader />
        ) : (
          <div>Worklog approval requests are empty</div>
        )}
      </>
    );
  };

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <VpDashboardInputs
          role={role}
          error={error}
          setTeamValue={setTeamValue}
          onChangeEmployee={onChangeEmployee}
          setProjectSearch={setProjectKey}
        />
        {renderTable()}
      </CardBody>
    </Card>
  );
};

export default VpDashboard;
