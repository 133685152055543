import { createSelector } from 'reselect';
import { AppState } from '../configureStore';
import TeamLeadWorkforceModel from '../../models/teamLead.model';
import { EmployeesDto } from '../../models/dto/employees.dto';

const employeesState = (state: AppState) => state.eventsEmployees.temLeadEmployees;

export const teamLeadWorkforce = createSelector(
  employeesState,
  (employeesList: EmployeesDto): TeamLeadWorkforceModel => {
    return {
      employees: employeesList?.employees?.map(employee => ({
        id: employee.id,
        email: employee.email,
        isDirectCost: employee.isDirectCost,
        salaryPrediction: employee.salaryPrediction,
        timeByMonth: employee.timeByMonth,
        startDate: employee.startDate,
        trialEndDate: employee.trialEndDate,
        title: employee.title,
        location: employee?.location,
        dismissalDate: employee.dismissalDate,
        hasStartedThisMonth: employee.hasStartedThisMonth,
      })),
      footer: {
        totalCost: employeesList?.totalCost,
        totalTeamLeadHours: employeesList?.totalTeamLeadHours,
        totalWorkingTime: employeesList?.totalWorkingTime,
        numberOfAdded: employeesList?.numberOfAdded,
        numberOfDismissed: employeesList?.numberOfDismissed,
        totalAddedCost: employeesList?.totalAddedCost,
        totalDismissedCost: employeesList?.totalDismissedCost,
        blendedRate: employeesList?.blendedRate,
      },
    };
  }
);
