import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { createPortal } from 'react-dom';

import Loader from 'common/Loader';
import FormikInput from 'common/Formik/FormikInput';

import { AppState } from 'store/configureStore';
import { getExpenseCategoryById } from 'store/companyExpensesCategories/expensesCategories.thunk';
import { clearCurrentCategory } from 'store/companyExpensesCategories';
import { CategoriesModalProps } from 'models/interfaces/companyExpenses.interface';

import styles from '../../../modal.module.scss';

const CATEGORY_SCHEMA = Yup.object({
  name: Yup.string().min(3, 'Less than 3 characters').max(128, 'Category name is too long').required('Field required'),
});

const AddOrEditCategory: FC<CategoriesModalProps> = ({ title, id, onSubmit, onCloseModal }) => {
  const dispatch = useDispatch();

  const { currentCategory, loadingModal } = useSelector((state: AppState) => state.expenseCategoriesReducer);

  useEffect(() => {
    if (id) {
      dispatch(getExpenseCategoryById(id));
    }
    return () => {
      dispatch(clearCurrentCategory());
    };
  }, [dispatch, id]);

  return createPortal(
    <Formik
      initialValues={{
        name: currentCategory?.name || '',
      }}
      validationSchema={CATEGORY_SCHEMA}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {() => (
        <Form>
          <div className={styles.modal}>
            <div className={styles.backDrop} onClick={onCloseModal}></div>
            <div className={cx(styles.block, 'text-left')}>
              <button className={styles.close} onClick={onCloseModal} />
              <div className={cx(styles.header)}>{title}</div>

              {!loadingModal ? (
                <div className={cx(styles.inputWrap, 'mb-4')}>
                  <FormikInput
                    name="name"
                    label="Expense Category Name"
                    placeholder="Enter Category Name"
                    className={styles.input}
                  />
                </div>
              ) : (
                <div className="mb-4">{<Loader />}</div>
              )}

              <div className={styles.buttonWrapper}>
                <button className={styles.blueLineButton} onClick={onCloseModal} type="button">
                  Cancel
                </button>
                <button className={styles.blueButton} type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>,
    document.body
  );
};

export default AddOrEditCategory;
