import { createSlice } from '@reduxjs/toolkit';
import { getProjectCalendar } from './projectCalendar.thunk';
import { ProjectCalendarInitialState } from 'models/interfaces/projectCalendar.interface';

const initialState: ProjectCalendarInitialState = {
  projects: [],
  nbRRhidden: false,
  openedRowsCounter: 0,
  loading: false,
  error: null,
};

const projectCalendarSlice = createSlice({
  name: 'projectCalendar',
  initialState,
  reducers: {
    setNbRRhidden: (state, { payload }) => {
      return {
        ...state,
        nbRRhidden: payload,
      };
    },
    incrementOpenedRowsCounter: state => {
      return {
        ...state,
        openedRowsCounter: state.openedRowsCounter + 1,
      };
    },
    decrementOpenedRowsCounter: state => {
      return {
        ...state,
        openedRowsCounter: state.openedRowsCounter - 1,
      };
    },
    clearOpenedRowsCounter: state => {
      return {
        ...state,
        openedRowsCounter: 0,
      };
    },
  },
  extraReducers: {
    [getProjectCalendar.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [getProjectCalendar.fulfilled.toString()]: (state, { payload }) => {
      state.projects = payload.items;
      state.loading = false;
    },
    [getProjectCalendar.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { setNbRRhidden, incrementOpenedRowsCounter, decrementOpenedRowsCounter, clearOpenedRowsCounter } =
  projectCalendarSlice.actions;

export default projectCalendarSlice.reducer;
