import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';

import './assets/base.scss';
import App from './App';

const rootElement = document.getElementById('root');

ReactDOM.render(<App />, rootElement);
