export enum Role {
  vp = 'vp',
  teamlead = 'teamlead',
  finance = 'finance',
  leadgen = 'leadgen',
  sales = 'sales',
  pm = 'pm',
  hr = 'hr',
  user = 'user',
  delivery = 'delivery',
}

export const ALL_PERMISSIONS: Role[] = [
  Role.vp,
  Role.teamlead,
  Role.finance,
  Role.leadgen,
  Role.sales,
  Role.pm,
  Role.hr,
  Role.user,
  Role.delivery,
];
export const TOP_PERMISSIONS: Role[] = [Role.vp, Role.hr, Role.finance, Role.delivery];
export const SALES_PERMISSIONS: Role[] = [Role.vp, Role.finance, Role.sales, Role.delivery];
