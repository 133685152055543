import httpService, { HttpService } from './httpService';

import EmployeeAdapter from '../models/adapters/employee.adapter';
import { EmployeeDto } from 'models/dto/employee.dto';

export type updateEmployeeByIdT = {
  id: string;
  edit?: 'hr' | 'mentor';
  employeeData: Partial<EmployeeDto>;
};

export class EmployeeService {
  constructor(private _httpService: HttpService) {}

  async getEmployeeById(id: string, expand: string) {
    const expandUrl = expand ? `?expand=${expand}` : '';
    const res = await this._httpService.get(`/employees/${id}${expandUrl}`);
    return EmployeeAdapter.mapEmployeeDtoToEmployeeProfile(res);
  }

  async updateEmployeeById({ id, employeeData }: updateEmployeeByIdT) {
    return await this._httpService.put(`/employees/${id}`, {
      body: employeeData,
    });
  }

  async editEmployeeById({ id, employeeData }: updateEmployeeByIdT) {
    return await this._httpService.patch(`/employees/${id}`, {
      body: employeeData,
    });
  }

  async getSalesGrades() {
    return await this._httpService.get('/sales-grades');
  }
}

export default new EmployeeService(httpService);
