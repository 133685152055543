import PerformanceReviewDetailsModel from 'models/performanceReviewsDetails.model';
import { PerformanceReviewsDetailsDto, PerformanceReviewsDetailsListDto } from './../dto/performanceReview.dto';
import { PerformanceReviewDto } from '../dto/performanceReview.dto';
import PerformanceReviewModel from '../performanceReview.model';

class PerformanceReviewAdapter {
  static mapPerformanceReviewDtoToPerformanceReview(data: PerformanceReviewDto[]): PerformanceReviewModel[] {
    return data.map(
      item =>
        new PerformanceReviewModel({
          id: item.id,
          title: item.title,
          startDate: item.startDate,
          endDate: item.endDate,
          status: item.status,
        })
    );
  }

  static mapPerformanceReviewsDetailsDtoToPerformanceReviews(
    data: PerformanceReviewsDetailsDto
  ): PerformanceReviewsDetailsListDto {
    return {
      items: data.items.map(item => new PerformanceReviewDetailsModel(item)),
      pages: data.pages,
      total: data.total,
    };
  }
}

export default PerformanceReviewAdapter;
