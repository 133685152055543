import cx from 'classnames';
import { arrowLeft, arrowRight } from 'constants/icons';
import { MonthNavigationProps } from 'models/interfaces/projectCalendar.interface';
import styles from '../projectCalendarStyles.module.scss';

const MonthNavigationButtons = ({ onMonthChange }: MonthNavigationProps) => {
  return (
    <div className={styles.navButtons}>
      <button onClick={() => onMonthChange('previous')} className={cx(styles.arrowButton, styles.projectArrow)}>
        {arrowLeft}
      </button>

      <button onClick={() => onMonthChange('next')} className={cx(styles.arrowButton, styles.projectArrow)}>
        {arrowRight}
      </button>
    </div>
  );
};

export default MonthNavigationButtons;
