import { EmployeeDto } from './dto/employee.dto';

export default class TeamModel {
  id: number;
  title: string;
  type?: {
    id: number;
    title: string;
    managerTitle: string;
  };
  typeId?: number;
  email: string | null;
  isAvailableForPlanning: boolean;
  closeDate?: string | null;
  openDate?: string;
  teamleadId?: number;
  confluencePageId?: string | null;
  confluenceTeamleadsGroup?: string | null;
  department?: {
    id: number;
    title: string;
  };
  parent?: TeamModel | null;
  parentId?: number | null;
  children?: TeamModel[];
  departmentId?: number;
  members?: EmployeeDto[];
  teamLead?: EmployeeDto;

  constructor(data: TeamModel) {
    this.id = data.id;
    this.title = data.title;
    this.type = data.type;
    this.typeId = data.typeId;
    this.email = data.email;
    this.isAvailableForPlanning = data.isAvailableForPlanning;
    this.closeDate = data.closeDate;
    this.openDate = data.openDate;
    this.teamleadId = data.teamleadId;
    this.confluencePageId = data.confluencePageId;
    this.confluenceTeamleadsGroup = data.confluenceTeamleadsGroup;
    this.department = data.department;
    this.departmentId = data.department?.id;
    this.parent = data.parent;
    this.parentId = data.parentId;
    this.children = data.children;
    this.members = data.members;
    this.teamLead = data.teamLead;
  }
}
