import { DealsAvailabilityDto } from './dto/bdm.dto';

class AvailabilityModel {
  id: number;
  index: number;
  firstName: string;
  lastName: string;
  email: string;
  team: string;
  title: string;
  englishLevel: string;
  expiration: string;
  deals?: DealsAvailabilityDto[];
  salesGrade: string;
  location: string;
  styleRow: string;

  constructor(data: AvailabilityModel) {
    this.id = data.id;
    this.index = data.index;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.team = data.team;
    this.title = data.title;
    this.englishLevel = data.englishLevel;
    this.expiration = data.expiration;
    this.deals = data.deals;
    this.salesGrade = data.salesGrade;
    this.location = data.location;
    this.styleRow = data.styleRow;
  }
}

export default AvailabilityModel;
