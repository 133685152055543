import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import styles from './sortButton.module.scss';

type SortButtonT = {
  onClick(): void;
  sortKey: string;
  sortOrder: string | null;
  sortField: string;
  sortTitle: string;
};

const SortButton: FC<SortButtonT> = ({ onClick, sortKey, sortOrder, sortField, sortTitle }) => {
  const stylesForUp = [
    styles.upArrow,
    styles.arrow,
    sortOrder === 'asc' && sortKey === sortField && styles.activeUpArrow,
  ].join(' ');

  const stylesForDown = [
    styles.downArrow,
    styles.arrow,
    sortOrder === 'desc' && sortKey === sortField && styles.activeDownArrow,
  ].join(' ');

  const arrow = useMemo(() => {
    return (
      <div className={styles.arrowBox}>
        <div className={stylesForUp}></div>
        <div className={stylesForDown}></div>
      </div>
    );
  }, [sortKey, sortOrder]);

  return (
    <div className={cx(styles.title, styles.cursorPointer)} onClick={onClick}>
      {sortTitle}
      <button className={styles.button}>{arrow}</button>
    </div>
  );
};

export default SortButton;
