import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationType } from 'services/notificationService';
import obligationService from 'services/obligationService';
import { enqueueSnackbar } from 'store/notifications';
import { ExpectationInterface, ItemObligationInterface } from 'models/interfaces/obligation.interface';
import ObligationModel from 'models/obligation.model';
import ObligationAdapter from 'models/adapters/obligation.adapter';

export const getObligations = createAsyncThunk(
  'obligation/getObligation',
  async (params: { employee: string; active?: boolean }, thunkAPI) => {
    try {
      const obligations: ObligationModel[] = await obligationService.getObligationList(params);
      return obligations;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const createObligation = createAsyncThunk(
  'obligation/createObligation',
  async (dataObligation: ItemObligationInterface, thunkAPI) => {
    try {
      const res = await obligationService.createObligation(dataObligation);

      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Obligation created successfully!',
            title: 'Obligations',
          },
        })
      );
      return ObligationAdapter.mapObligationDtoToObligation(res);
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const updateObligation = createAsyncThunk(
  'obligation/updateObligation',
  async (obligation: ItemObligationInterface, thunkAPI) => {
    try {
      const res = await obligationService.updateObligation(obligation);

      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Obligation updated successfully!',
            title: 'Obligations',
          },
        })
      );
      return ObligationAdapter.mapObligationDtoToObligation(res);
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const updateExpirationDate = createAsyncThunk(
  'obligation/updateExpirationDate',
  async (data: ExpectationInterface, thunkAPI) => {
    try {
      await obligationService.updateExpiration(data);

      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: 'Obligation updated successfully!',
            title: 'Obligations',
          },
        })
      );
      return { id: data.id, expirationDate: data.expirationDate ? new Date(data.expirationDate) : data.expirationDate };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const approveOrCancelObligation = createAsyncThunk(
  'obligation/approveOrCancelObligation',
  async (params: { id: number; command: string; approvedBy?: number }, thunkAPI) => {
    try {
      await obligationService.updateObligationApproval(params);

      thunkAPI.dispatch(
        enqueueSnackbar({
          options: {
            key: new Date().getTime() + Math.random(),
            variant: NotificationType.success,
            body: `Obligation ${params.command === 'approve' ? 'approved' : 'canceled'} successfully!`,
            title: 'Obligations',
          },
        })
      );
      return params;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);

export const deleteObligation = createAsyncThunk('obligation/deleteObligation', async (id: number, thunkAPI) => {
  try {
    await obligationService.deleteObligation(id);

    thunkAPI.dispatch(
      enqueueSnackbar({
        options: {
          key: new Date().getTime() + Math.random(),
          variant: NotificationType.success,
          body: 'Obligation delete successfully!',
          title: 'Obligations',
        },
      })
    );
    return id;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});
