import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import TeamManager from './TeamManager';
import { TeamsListProps } from 'models/interfaces/companyStructure.interface';
import { arrowRight } from 'constants/icons';

import styles from './companyStructure.module.scss';

const TeamsList = ({ teams, employees }: TeamsListProps) => {
  return (
    <div className={styles.teams}>
      {teams?.map(item => {
        return (
          <Fragment key={item.id}>
            <Link
              key={item.id}
              to={`/dashboard/team_profile/${item.id}`}
              className={cx(styles.team, item.children?.length ? 'mt-2' : '')}
            >
              <div className={item.children?.length ? styles.parentTeam : ''}>{item.title}</div>
              <span className={styles.arrow}>{arrowRight}</span>
            </Link>

            {item.children?.length ? (
              <>
                <TeamManager team={item} employees={employees} className="mb-1 mt-1" />
                <TeamsList teams={item.children} employees={employees} />
              </>
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
};

export default TeamsList;
