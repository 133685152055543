import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/Loader';
import MenteesTable from './MenteesTable';
import TeamFilter from 'common/TeamFilter/TeamFilter';

import { getEmployees } from 'store/allEmployees/allEmployees.thunk';
import { AppState } from 'store/configureStore';
import { RequestParams } from 'utils/mapParams';

import styles from '../../tableStyles.module.scss';

const Mentees: FC<{ employeeId: string }> = ({ employeeId }) => {
  const dispatch = useDispatch();

  const { employees, loading } = useSelector((state: AppState) => state.allEmployeesReducer);
  const [selectedTeam, setSelectedTeam] = useState('');

  useEffect(() => {
    const params: Partial<RequestParams> = {
      mentor: employeeId,
    };
    if (selectedTeam) {
      params.team = +selectedTeam;
    }
    dispatch(getEmployees({ params }));
  }, [employeeId, selectedTeam]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className="bp-header">
          <div className="filters-block">
            <TeamFilter team={selectedTeam} setTeam={setSelectedTeam} />
          </div>
        </div>
        {employees.length ? (
          <MenteesTable employees={employees} />
        ) : loading ? (
          <Loader />
        ) : (
          <div>Mentees are empty</div>
        )}
      </div>
    </div>
  );
};

export default Mentees;
