import { createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import workingYear from 'services/workingYearServise';

export const getWorkingYearList = createAsyncThunk('holidays/getWorkingYearList', async (params: any, thunkAPI) => {
  try {
    const res = await workingYear.getWorkingYear(params);
    return res;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});

const initialState = {
  workingYearList: [],
  loading: false,
  error: null,
};

export const workingYearSlice = createSlice({
  name: 'workingYear',
  initialState,
  reducers: {},

  extraReducers: {
    [getWorkingYearList.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [getWorkingYearList.fulfilled.toString()]: (state, { payload }) => {
      state.workingYearList = payload;
      state.loading = false;
    },
    [getWorkingYearList.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default workingYearSlice.reducer;
