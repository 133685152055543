import { Auth } from 'aws-amplify';
import StorageService from './storageService';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import AccountModel from '../models/account.model';
import AccountAdapter from '../models/adapters/account.adapter';
import AccountDto from '../models/dto/account.dto';
import { Role } from 'constants/roles';
import { checkValidEnumValue } from 'utils/checkValidEnumValue';

class AccountService {
  constructor(private _auth: typeof Auth, private _storage: StorageService) {}

  public async getUser(): Promise<CognitoUser | undefined> {
    try {
      const cognitoUser = await this._auth.currentAuthenticatedUser();
      this.setUser();
      return cognitoUser;
    } catch (err) {
      this.logOutUser();
    }
  }

  public getUserInfo(user: CognitoUser): AccountModel {
    const signInUserSession: CognitoUserSession | null = user.getSignInUserSession();
    const selectedRole: Role | null = checkValidEnumValue(localStorage.getItem('selectedRole'), Role);
    const userInfo = signInUserSession && signInUserSession.getIdToken().payload;
    const mappedUserInfo = AccountAdapter.mapAccountDtoToAccount(userInfo as AccountDto, selectedRole);

    if (!selectedRole) {
      localStorage.setItem('selectedRole', mappedUserInfo.role);
    }
    return mappedUserInfo;
  }

  public setUser() {
    this._storage.setItem('true');
  }

  public logOutUser() {
    this._storage.removeItem();
  }
}

export default AccountService;
