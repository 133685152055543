import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { Tooltip } from 'reactstrap';
import ItemDuration from './ItemDuration';
import ColoredCircle from 'components/ColoredCircle/ColoredCircle';
import { AppState } from 'store/configureStore';

import { RRrowProps, Month } from 'models/interfaces/projectCalendar.interface';
import { PAYMENT_TYPE } from 'models/enums/payment-type.enum';
import { secondsToHours } from 'utils/dates';
import { getResourceRequestStatusColor, isItemWithinMonth } from '../constants';

import styles from '../projectCalendarStyles.module.scss';

const ResourceRequestRow: FC<RRrowProps> = ({ resourceRequest, monthsList, canCreateRR, parentPirId }) => {
  const { nbRRhidden } = useSelector((state: AppState) => state.projectCalendarReducer);

  const [hoveredCell, setHoveredCell] = useState<null | number>(null);

  let isFirstCellWithFill: number;

  const statusColor = useMemo(() => {
    return getResourceRequestStatusColor(resourceRequest.status);
  }, [resourceRequest]);

  return (
    <>
      {(resourceRequest.paymentType.toLowerCase() === PAYMENT_TYPE.billable || !nbRRhidden) && (
        <tr className={cx(styles.wrapPir, styles.resourceRequestRow)} style={{ padding: 0 }}>
          <td className={cx(styles.resourceRequestName, styles.project)}>
            {resourceRequest.team?.title && (
              <div className={cx(styles.rrItem, styles.teamWrapper)}>{resourceRequest.team.title}</div>
            )}

            {resourceRequest.paymentType.toLowerCase() === PAYMENT_TYPE.nonBillable && (
              <div className={cx(styles.rrItem, styles.nonBillableRR)}>NB</div>
            )}

            <a
              target="_blank"
              href={resourceRequest.issueLink}
              className={styles.employeeName}
              id={`rr_${resourceRequest.id}`}
              onMouseEnter={() => setHoveredCell(resourceRequest.id)}
              onMouseLeave={() => setHoveredCell(null)}
            >
              {resourceRequest.workforce?.firstName && resourceRequest.workforce?.lastName
                ? `${resourceRequest.workforce.firstName} ${resourceRequest.workforce.lastName}`
                : `${resourceRequest.summary}`}
            </a>

            <Tooltip placement="auto" isOpen={hoveredCell === resourceRequest.id} target={`rr_${resourceRequest.id}`}>
              <div>
                Status: <strong>{resourceRequest.status}</strong>
                <span className="ml-2">
                  <ColoredCircle color={statusColor} />
                </span>
              </div>

              <div>
                Date: from <strong>{resourceRequest.periodStartDate}</strong> to{' '}
                <strong>{resourceRequest.periodEndDate}</strong>
              </div>

              <div>
                Hours per day:{' '}
                <strong>{resourceRequest.timePerDay ? secondsToHours(resourceRequest.timePerDay) : 0}</strong>
              </div>

              <div>
                Estimated workload hours:{' '}
                <strong>
                  {resourceRequest.estimatedBillableTime ? secondsToHours(resourceRequest.estimatedBillableTime) : 0}
                </strong>
              </div>

              <div>
                Actual workload hours:{' '}
                <strong>
                  {resourceRequest.factualBillableTime ? secondsToHours(resourceRequest.factualBillableTime) : 0}
                </strong>
              </div>
            </Tooltip>
          </td>

          {monthsList.map((month: Month, index: number) => {
            const isRRWithinCurrentMonth = isItemWithinMonth({
              startOfMonth: month.startDate,
              endOfMonth: month.endDate,
              itemStartDate: resourceRequest.periodStartDate,
              itemEndDate: resourceRequest.periodEndDate,
            });

            if (isFirstCellWithFill === undefined && isRRWithinCurrentMonth) {
              isFirstCellWithFill = index;
            }

            return (
              <td key={month.name} style={{ padding: 0 }}>
                {isRRWithinCurrentMonth && (
                  <ItemDuration
                    month={month}
                    item={resourceRequest}
                    isFirstCellWithFill={isFirstCellWithFill === index}
                    status={resourceRequest.status}
                    color={statusColor}
                    canCreateRR={canCreateRR}
                    parentPirId={parentPirId}
                    isLastMontInList={index + 1 === monthsList.length}
                  />
                )}
              </td>
            );
          })}
        </tr>
      )}
    </>
  );
};

export default ResourceRequestRow;
