import { createSlice } from '@reduxjs/toolkit';
import { getProjectInitRequests } from './projectInitRequests.thunk';

const initialState = {
  projectInitRequests: [],
  loading: false,
  error: null,
};

const projectInitRequestsSlice = createSlice({
  name: 'projectInitRequests',
  initialState,
  reducers: {
    clearProjectInitRequests(state) {
      return {
        ...state,
        projectInitRequests: [],
      };
    },
  },
  extraReducers: {
    [getProjectInitRequests.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [getProjectInitRequests.fulfilled.toString()]: (state, { payload }) => {
      state.loading = false;
      state.projectInitRequests = payload.items;
    },
    [getProjectInitRequests.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { clearProjectInitRequests } = projectInitRequestsSlice.actions;

export default projectInitRequestsSlice.reducer;
