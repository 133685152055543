import httpService, { HttpService } from './httpService';

export class TeamTypesService {
  constructor(private _httpService: HttpService) {}

  async getTeamTypes() {
    const res = this._httpService.get('/team-types');
    return res;
  }
}

const teamTypesService = new TeamTypesService(httpService);

export default teamTypesService;
