import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { Authenticator, translations } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';
import Loader from 'common/Loader';

import '@aws-amplify/ui-react/styles.css';
import './amplifyStyles.scss';
import styles from './LoginPage.module.scss';

type PropsT = {
  isAuthorized: boolean;
  loading: boolean;
};

type LocationState = {
  from: string;
};

const LoginPage = ({ isAuthorized, loading }: PropsT) => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  I18n.putVocabularies(translations);
  I18n.setLanguage('en');

  I18n.putVocabularies({
    en: {
      'Sign In with Google': 'Sign in with corporate ID',
    },
  });

  useEffect(() => {
    const verifiedKey: string | null = sessionStorage.getItem('SESSION_IS_AUTHENTICATED_KEY');
    const fromUrl: string = location.state?.from ?? '/';
    if (isAuthorized && verifiedKey) history.push(fromUrl);
  }, [history, location, isAuthorized]);

  return (
    <div className="h-100">
      <div className={cx(styles.mainContainer, 'h-100')}>
        <div className={cx(styles.whiteBox, 'bg-white')}>
          <div className={styles.towerLogo}>Tower</div>
          <div className={styles.signInBlock}>
            <div className={styles.signInText}>
              <div className={styles.welcomeText}>Welcome back to Tower</div>
              {loading ? (
                <p className={styles.text}>
                  You are being redirected to another <br />
                  page, please wait a few seconds
                </p>
              ) : (
                <p className={styles.text}>Please sign in to your account</p>
              )}
            </div>
            {loading ? (
              <div className={styles.loaderWrapper}>
                <Loader />
              </div>
            ) : (
              <Authenticator socialProviders={['google']} variation="default" hideSignUp={true} />
            )}
          </div>
        </div>

        <div className={styles.grayBox}>
          <div className={styles.grayBlock}>
            <div className={styles.grayText}>
              <div className={styles.mainText}>Stay in control</div>
              <p className={styles.text}>
                Tower is like a dream. Some think it's too good <br />
                to be true! Extensive overview of your work and goals.
              </p>
            </div>
            <div className={styles.mainImg}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
