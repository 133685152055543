import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import Nav from '../AppNav/VerticalNavWrapper';

import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import PerfectScrollbar from 'react-perfect-scrollbar';
import HeaderLogo from '../AppLogo';

import { setEnableMobileMenu } from '../../store/themeOptions';

const AppSidebar = () => {
  const dispatch = useDispatch();
  const {
    enableBackgroundImage,
    enableSidebarShadow,
    enableMobileMenu,
    backgroundColor,
    backgroundImage,
    backgroundImageOpacity,
  } = useSelector(state => state.themeOptions);

  const toggleMobileSidebar = useCallback(() => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  }, [dispatch, enableMobileMenu]);

  return (
    <Fragment>
      <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
      <CSSTransitionGroup
        component="div"
        className={cx('app-sidebar', backgroundColor, {
          'sidebar-shadow': enableSidebarShadow,
        })}
        transitionName="SidebarAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <HeaderLogo />
        <PerfectScrollbar>
          <div className="app-sidebar__inner">
            <Nav />
          </div>
        </PerfectScrollbar>
        <div
          className={cx('app-sidebar-bg', backgroundImageOpacity)}
          style={{
            backgroundImage: enableBackgroundImage ? 'url(' + backgroundImage + ')' : null,
          }}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default AppSidebar;
