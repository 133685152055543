import { ExpenseCategoryT } from 'models/interfaces/companyExpenses.interface';

export const checkEmergencyContact = (value: string) => {
  let valueArray: string[] = [];
  if (value.includes(' ')) {
    valueArray = value.split(' ');
  } else if (value.includes(',')) {
    valueArray = value.split(',');
  } else {
    valueArray = [value, ''];
  }
  return valueArray;
};

export const setWorkingTimeSecondsHoliday = (value: string) =>
  ({
    'Day off': 0,
    '7h': 25200,
    '6h': 21600,
    '5h': 18000,
    '4h': 14400,
    '3h': 10800,
    '2h': 7200,
    '1h': 3600,
  }[value]);

export const capitalizeWords = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatStage = (stage: string) => {
  if (stage === 'All' || !stage) return '';
  return stage.split(' ').join('_');
};

export const formatPipeline = (pipeline: string) => {
  if (pipeline === 'All' || !pipeline) return '';
  return pipeline.split(' ').join('_');
};

type DropdownItemT = {
  id: number;
  title: string;
};

export const formatDropdown = (items: DropdownItemT[]) => {
  if (items.length) {
    return items.map((item: DropdownItemT) => {
      return item.title;
    });
  } else {
    return null;
  }
};

export const formatDropdownPayload = (items: DropdownItemT[], payload: string | undefined) => {
  if (payload) {
    const filterItems = items.filter(item => item.title === payload);

    return filterItems[0]?.id;
  }
};

export const formatCategories = (items: ExpenseCategoryT[], payload: string) => {
  const findItem = items.find((item: ExpenseCategoryT) => item.name === payload);

  return findItem?.id;
};
