import DealsPage from '../pages/DealsPage/DealsPage';
import DealDetailsPage from '../pages/DealDetailsPage/DealDetailsPage';
import AbsenceCalendarPage from '../pages/AbsenceCalendarPage/AbsenceCalendarPage';
import ResourceRequestsPage from '../pages/ResourceRequestsPage/ResourceRequestsPage';
import ExternalInterviewPage from '../pages/ExternalInterviewPage/ExternalInterviewPage';
import OvertimeRequestsPage from '../pages/OvertimeRequestsPage/OvertimeRequestsPage';
import CompanyStructurePage from '../pages/CompanyStructurePage/CompanyStructurePage';
import TeamProfilePage from '../pages/TeamProfilePage/TeamProfilePage';
import ProfileDashboard from '../pages/ProfilePage/ProfilePage';
import ObligationPage from '../pages/ObligationPage/ObligationPage';
import AvailabilityPage from '../pages/AvailabilityPage/AvailabilityPage';
import WorkforseHoursPage from '../pages/WorkforseHoursPage/WorkforseHoursPage';
import WorklogIssuesPage from '../pages/WorklogIssuesPage/WorklogIssuesPage';
import OutstuffFunnelPage from '../pages/OutstuffFunnelPage/OutstuffFunnelPage';
import TeamLeadWorkforcePage from '../pages/TeamLeadWorkforcePage/TeamLeadWorkforcePage';
import HolidaysPage from '../pages/HolidaysPage/HolidaysPage';
import RateCardPage from '../pages/RateCardPage/RateCardPage';
import PerformanceReviewPage from '../pages/PerformanceReviewPage/PerformanceReviewPage';
import PerformanceReviewDetailsPage from 'pages/PerformanceReviewDetailsPage/PerformanceReviewDetailsPage';
import TotalsPage from '../pages/TotalsPage/TotalsPage';
import WorkforceCostsPage from 'pages/WorkforceCostsPage/WorkforceCostsPage';
import CompanyExpensesPage from 'pages/CompanyExpensesPage/CompanyExpensesPage';
import ExpenseCategoriesPage from 'pages/ExpenseCategoriesPage/ExpenseCategoriesPage';
import EmployeesPage from 'pages/EmployeesPage/EmployeesPage';
import WorkloadCalendarPage from 'pages/WorkloadCalendarPage/WorkloadCalendarPage';
import CompensationOrdersPage from 'pages/CompensationOrdersPage/CompensationOrdersPage';
import ProjectCalendarPage from 'pages/ProjectCalendarPage/ProjectCalendarPage';
import AssigneesPage from 'pages/AssigneesPage/AssigneesPage';
import MentorsAssignmentPage from 'pages/MentorsAssignmentPage/MentorsAssignmentPage';
import TeamBudgetPage from 'pages/TeamBudgetPage/TeamBudgetPage';
import { ALL_PERMISSIONS, Role } from '../constants/roles';

export type RoutesConfigT = {
  path: string;
  component: () => JSX.Element;
  exact: boolean;
  permissions: Role[];
  navigationTitle: string;
};

const routes: RoutesConfigT[] = [
  // company
  {
    path: '/dashboard/company_structure/',
    component: CompanyStructurePage,
    exact: false,
    permissions: ALL_PERMISSIONS,
    navigationTitle: 'Company Structure',
  },
  {
    path: '/dashboard/team_profile/:id?',
    component: TeamProfilePage,
    exact: false,
    permissions: ALL_PERMISSIONS,
    navigationTitle: 'TeamProfile',
  },
  {
    path: '/dashboard/holidays',
    component: HolidaysPage,
    exact: false,
    permissions: ALL_PERMISSIONS,
    navigationTitle: 'Holidays',
  },
  {
    path: '/dashboard/absence_calendar',
    component: AbsenceCalendarPage,
    exact: false,
    permissions: ALL_PERMISSIONS,
    navigationTitle: 'Absence Calendar',
  },

  // finance
  {
    path: '/dashboard/workforce_costs',
    component: WorkforceCostsPage,
    exact: false,
    permissions: [Role.vp, Role.finance],
    navigationTitle: 'Workforce Costs',
  },
  {
    path: '/dashboard/company_expenses',
    component: CompanyExpensesPage,
    exact: false,
    permissions: [Role.vp, Role.hr, Role.finance],
    navigationTitle: 'Company Expenses',
  },
  {
    path: '/dashboard/company_expense_categories',
    component: ExpenseCategoriesPage,
    exact: false,
    permissions: [Role.vp, Role.hr, Role.finance],
    navigationTitle: 'Expense Categories',
  },
  {
    path: '/dashboard/compensation_orders',
    component: CompensationOrdersPage,
    exact: false,
    permissions: [Role.vp, Role.finance],
    navigationTitle: 'Compensation Orders',
  },

  // project management
  {
    path: '/dashboard/resource_requests',
    component: ResourceRequestsPage,
    exact: false,
    permissions: [Role.vp, Role.teamlead, Role.finance, Role.pm, Role.delivery, Role.sales],
    navigationTitle: 'Resource Requests',
  },
  {
    path: '/dashboard/overtime_requests',
    component: OvertimeRequestsPage,
    exact: false,
    permissions: [Role.vp, Role.teamlead, Role.finance, Role.pm, Role.delivery],
    navigationTitle: 'Overtime Requests',
  },
  {
    path: '/dashboard/project_calendar',
    component: ProjectCalendarPage,
    exact: false,
    permissions: [Role.vp, Role.teamlead, Role.finance, Role.pm, Role.delivery],
    navigationTitle: 'Project Calendar',
  },

  // human resources
  {
    path: '/dashboard/employees',
    component: EmployeesPage,
    exact: false,
    permissions: [Role.vp, Role.hr],
    navigationTitle: 'Employees',
  },
  {
    path: '/dashboard/performance_review',
    component: PerformanceReviewPage,
    exact: true,
    permissions: [Role.vp, Role.hr],
    navigationTitle: 'Performance Review',
  },
  {
    path: '/dashboard/performance_review_details/:id',
    component: PerformanceReviewDetailsPage,
    exact: false,
    permissions: [Role.vp, Role.hr],
    navigationTitle: 'PerformanceReviewDetails',
  },
  {
    path: '/dashboard/assignees',
    component: AssigneesPage,
    exact: false,
    permissions: [Role.vp, Role.hr],
    navigationTitle: 'Assignees',
  },

  // team lead
  {
    path: '/dashboard/team_lead_workforce',
    component: TeamLeadWorkforcePage,
    exact: false,
    permissions: [Role.vp, Role.teamlead],
    navigationTitle: 'Team Profile',
  },
  {
    path: '/dashboard/mentors_assignment',
    component: MentorsAssignmentPage,
    exact: false,
    permissions: [Role.vp, Role.teamlead],
    navigationTitle: 'Mentors Assignment',
  },
  {
    path: '/dashboard/worklog_issues',
    component: WorklogIssuesPage,
    exact: false,
    permissions: ALL_PERMISSIONS,
    navigationTitle: 'Worklog Issues',
  },
  {
    path: '/dashboard/team_budget',
    component: TeamBudgetPage,
    exact: false,
    permissions: [Role.vp, Role.teamlead, Role.finance],
    navigationTitle: 'Team Budget',
  },

  // business development
  {
    path: '/dashboard/availability',
    component: AvailabilityPage,
    exact: false,
    permissions: [Role.vp, Role.leadgen, Role.sales, Role.delivery],
    navigationTitle: 'Workforce Availability',
  },
  {
    path: '/dashboard/deals',
    component: DealsPage,
    exact: true,
    permissions: [Role.vp, Role.sales, Role.leadgen, Role.delivery],
    navigationTitle: 'Deals',
  },
  {
    path: '/dashboard/deal-details/:id',
    component: DealDetailsPage,
    exact: false,
    permissions: [Role.vp, Role.sales, Role.leadgen, Role.delivery],
    navigationTitle: 'DealDetails',
  },
  {
    path: '/dashboard/rate_card',
    component: RateCardPage,
    exact: false,
    permissions: [Role.vp],
    navigationTitle: 'Rate Card',
  },
  {
    path: '/dashboard/outstuff_funnel',
    component: OutstuffFunnelPage,
    exact: false,
    permissions: [Role.vp, Role.leadgen, Role.sales, Role.delivery],
    navigationTitle: 'BDM Funnel',
  },
  {
    path: '/dashboard/totals',
    component: TotalsPage,
    exact: false,
    permissions: [Role.vp],
    navigationTitle: 'Totals',
  },

  // workload
  {
    path: '/dashboard/workforse_hours',
    component: WorkforseHoursPage,
    exact: false,
    permissions: [Role.vp, Role.teamlead, Role.leadgen, Role.sales, Role.delivery, Role.pm],
    navigationTitle: 'Available Hours',
  },
  {
    path: '/dashboard/workload_calendar',
    component: WorkloadCalendarPage,
    exact: false,
    permissions: [Role.vp, Role.teamlead],
    navigationTitle: 'Workload Calendar',
  },
  {
    path: '/dashboard/external_interviews',
    component: ExternalInterviewPage,
    exact: false,
    permissions: [Role.vp, Role.teamlead, Role.leadgen, Role.sales, Role.delivery, Role.pm],
    navigationTitle: 'External Interviews',
  },

  // profile
  {
    path: '/dashboard/profile/:id?',
    component: ProfileDashboard,
    exact: false,
    permissions: ALL_PERMISSIONS,
    navigationTitle: 'Profile',
  },
  {
    path: '/dashboard/obligation/:id?',
    component: ObligationPage,
    exact: false,
    permissions: [Role.vp, Role.finance, Role.hr, Role.teamlead],
    navigationTitle: 'Obligation History',
  },
];

export default routes;
