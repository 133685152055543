import React, { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import * as Yup from 'yup';

import { Formik } from 'formik';
import FormikInput from 'common/Formik/FormikInput';
import { Form, Table } from 'reactstrap';
import RequestStatus from 'components/RequestStatus/RequestStatus';

import {
  editCompensationOrder,
  changeCompensationOrderByCommand,
} from 'store/compensationOrders/compensationOrders.thunk';

import { secondsToHours } from 'utils/dates';
import { capitalizeWords } from 'utils/functions';
import { CompensationOrder, CompensationOrderCommand, TableProps } from 'models/compensationOrders.interface';

import styles from '../tableStyles.module.scss';
import orderStyles from './ordersStyle.module.scss';
import obligationStyles from '../Obligation/obligation.module.scss';

const TOTAL_PAYOUT_SCHEMA = Yup.object({
  amount: Yup.number()
    .integer()
    .positive()
    .max(99999, `Amount can't have length more than 5 characters`)
    .required('Field is required'),
});

const CompensationOrdersTable: FC<TableProps> = ({ data, loading, currentPage, perPage }) => {
  const dispatch = useDispatch();

  const [updatedOrder, setUpdatedOrder] = useState(0);
  const [editMode, setEditMode] = useState<number | string>('');

  const coefForNumberFirstColumn = useMemo(() => perPage * currentPage - perPage + 1, [currentPage, perPage]);

  const handleRecalculateOrResendOrders = (id: number, command: CompensationOrderCommand, employeeId: number) => {
    dispatch(changeCompensationOrderByCommand({ id, command }));
    setUpdatedOrder(employeeId);
  };

  return (
    <Table responsive hover className={cx(styles.tableContainer, 'align-middle mb-0 custom-table text-left')}>
      <thead>
        <tr className={orderStyles.headerCell}>
          <th rowSpan={2} style={{ verticalAlign: 'top' }}>
            #
          </th>
          <th rowSpan={2} className={orderStyles.employeeCell} style={{ verticalAlign: 'top' }}>
            Employee
          </th>
          <th rowSpan={2} style={{ verticalAlign: 'top', minWidth: '75px' }}>
            Total
            <br />
            Payout
          </th>
          <th colSpan={4} className={orderStyles.hoursMainCell} style={{ borderBottom: '1px solid #E6E7ED' }}>
            Payout details
          </th>
          <th rowSpan={2} style={{ verticalAlign: 'top' }}>
            Expected
            <br />
            Bonus
          </th>
          <th rowSpan={2} style={{ verticalAlign: 'top' }}>
            VSD Cost
          </th>
          <th colSpan={5} className={orderStyles.hoursMainCell} style={{ borderBottom: '1px solid #E6E7ED' }}>
            Hours details
          </th>
          <th colSpan={2} className={orderStyles.hoursMainCell} style={{ borderBottom: '1px solid #E6E7ED' }}>
            Order details
          </th>
          <th colSpan={3} className={orderStyles.hoursMainCell} style={{ borderBottom: '1px solid #E6E7ED' }}>
            Employee details
          </th>
        </tr>

        <tr className={orderStyles.detailsCell}>
          <th className={orderStyles.compensationCell}>Base Compensation</th>
          <th className={orderStyles.payoutCell}>Compensation</th>
          <th className={orderStyles.requestsCell}>Compensation Requests</th>
          <th className={orderStyles.payoutCell}>Factual Bonus</th>
          <th className={orderStyles.hoursCell}>Expected Hours</th>
          <th className={orderStyles.hoursCell}>Tracked Hours</th>
          <th className={orderStyles.hoursCell}>Billable Absence</th>
          <th className={orderStyles.hoursCell}>N/B Absence</th>
          <th className="align-center">Shifted</th>
          <th className="text-center">Status</th>
          <th className="text-center">Actions</th>
          <th>Cost Center</th>
          <th className="text-center">Team</th>
          <th className="text-center">Title</th>
        </tr>
      </thead>

      <tbody>
        {data.map((item: CompensationOrder, index: number) => {
          const canEditTotalPayout = ['pending', 'rejected'].includes(item.status.toLowerCase());

          return (
            <tr key={item.employee.id}>
              <td>{index + coefForNumberFirstColumn}</td>
              <td>
                <Link to={`/dashboard/profile/${item.employee.id}`}>
                  {`${item.employee.firstName} ${item.employee.lastName}`}
                </Link>
              </td>
              <td style={{ padding: `6px ${canEditTotalPayout ? '4px' : '8px'}` }}>
                {editMode === item.employee.id ? (
                  <Formik
                    initialValues={{ amount: item?.amount && +item.amount > 0 ? item.amount : '' }}
                    validationSchema={TOTAL_PAYOUT_SCHEMA}
                    onSubmit={(values: { amount: string | number }) => {
                      dispatch(editCompensationOrder({ id: item.id, value: { amount: String(values.amount) } }));
                    }}
                  >
                    {({ handleSubmit }) => (
                      <Form className="filters-block" style={{ maxWidth: '220px' }}>
                        <FormikInput
                          name="amount"
                          type="number"
                          placeholder="Amount"
                          className={orderStyles.inputWrap}
                          errorClassName={obligationStyles.error}
                          onSubmit={handleSubmit}
                          setCurrentlyEditing={setEditMode}
                        />
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div
                    role="button"
                    onClick={() => (canEditTotalPayout ? setEditMode(item.employee.id) : undefined)}
                    className={canEditTotalPayout ? orderStyles.amount : ''}
                  >
                    {item?.amount || 0}
                  </div>
                )}
              </td>
              <td>{item.baseCompensation}</td>
              <td>{item.compensation}</td>
              <td>{item.requestedCompensation}</td>
              <td>{item.factualBonusCompensation || 0}</td>
              <td>
                <Link to={`/dashboard/obligation/${item.employee.id}`}>{item?.plannedBonusCompensation || 0}</Link>
              </td>
              <td>{item.vsdCost}</td>
              <td>{secondsToHours(item.expectedWorkingTime)}</td>
              <td>{secondsToHours(item.trackedWorkingTime)}</td>
              <td>{secondsToHours(item.billableAbsenceTime)}</td>
              <td>{secondsToHours(item.nonBillableAbsenceTime)}</td>
              <td>{secondsToHours(item.shiftedWorkingTime)}</td>
              <td style={{ minWidth: '160px' }}>
                <RequestStatus status={capitalizeWords(item.status.toLowerCase())} />
              </td>
              <td>
                <div className={orderStyles.buttonWrap}>
                  <button
                    className={orderStyles.buttonRecalculate}
                    onClick={() => handleRecalculateOrResendOrders(item.id, 'recalculate', item.employee.id)}
                    disabled={loading && updatedOrder === item.employee.id}
                  >
                    Recalculate
                  </button>

                  {item.status !== 'CONFIRMED' && (
                    <button
                      className={orderStyles.buttonResend}
                      onClick={() => handleRecalculateOrResendOrders(item.id, 'resend', item.employee.id)}
                      disabled={loading && updatedOrder === item.employee.id}
                    >
                      Resend
                    </button>
                  )}
                </div>
              </td>
              <td>{item.employee?.isDirectCost ? 'Direct' : 'Indirect'}</td>
              <td className={orderStyles.employeeDetailCell}>{item.employee?.team?.title}</td>
              <td className={orderStyles.employeeDetailCell}>{item.employee?.title}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default CompensationOrdersTable;
