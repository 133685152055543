import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { AppState } from 'store/configureStore';
import { Form, Formik } from 'formik';
import { Table } from 'reactstrap';

import FormikInput from 'common/Formik/FormikInput';
import FormikDropdownList from 'common/Formik/FormikDropdownList';
import FormikMultiselect from 'common/Formik/FormikMultiselect';
import FormikDatePicker from 'common/Formik/FormikDatePicker';

import { createObligation } from 'store/obligation/obligation.thunk';
import { ObligationFormProps, ObligationValues } from 'models/interfaces/obligation.interface';
import cleanFormEmptyFields from 'helpers/cleanFormEmptyFields';
import formatDatesFields from 'helpers/formatDatesFields';
import { getDisabledExpenseTypes } from './helpers';
import { DATE_PICKER } from 'constants/common';
import { approveIcon, rejectIcon } from 'constants/icons';
import {
  CURRENCIES,
  CURRENCY,
  DATE_FIELDS,
  OBLIGATION_SCHEMA,
  OBLIGATION_TYPES,
  PAYMENT_DELAY,
  PAYMENT_FREQUENCY,
  CONFIRMATION_DAYS,
} from 'constants/obligations.constants';

import styles from './obligation.module.scss';
import buttonStyles from '../buttons.module.scss';

const NewObligationForm: FC<ObligationFormProps> = ({ employeeId, setAddObligation, index }) => {
  const dispatch = useDispatch();

  const { expenseTypes } = useSelector((state: AppState) => state.expenseTypeReducer);

  const initialValues: ObligationValues = {
    employeeId: +employeeId,
    amount: '',
    currency: '',
    type: '',
    effectiveDate: null,
    expirationDate: null,
    expenseTypes: [],
    paymentDelay: '',
    paymentFrequency: null,
    confirmationDay: null,
  };

  const onSubmit = (values: ObligationValues) => {
    const clearValues = cleanFormEmptyFields(values);
    const formattedValues: any = formatDatesFields(clearValues, DATE_FIELDS);

    if (formattedValues.currency === CURRENCY.percent) {
      delete formattedValues.currency;
    }
    formattedValues.amount = String(formattedValues.amount);

    dispatch(
      createObligation({
        ...formattedValues,
        expenseTypes: formattedValues.expenseTypes.map((item: { id: number }): number => item.id),
      })
    );

    setAddObligation(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={OBLIGATION_SCHEMA} onSubmit={onSubmit}>
      {({ values }) => (
        <Form>
          {
            <div className="d-flex justify-content-between align-center mb-2 mt-3">
              <div className={styles.heading}>Obligation {index}</div>
              <div className={buttonStyles.buttonsWrap}>
                <button type="submit" className={buttonStyles.approve}>
                  {approveIcon}
                  Save
                </button>

                <button type="button" onClick={() => setAddObligation(false)} className={buttonStyles.reject}>
                  {rejectIcon}
                  Cancel
                </button>
              </div>
            </div>
          }
          <div className={styles.tableWrap}>
            <Table className={cx('align-middle mb-0 custom-table text-left')} style={{ overflow: 'visible' }}>
              <tbody>
                <tr className={styles.removeTopBorder}>
                  <td className={styles.greyCell}>Amount</td>
                  <td>
                    <FormikInput
                      name="amount"
                      type="number"
                      placeholder="Enter amount"
                      className={styles.inputWrap}
                      errorClassName={styles.error}
                    />
                  </td>
                  <td className={styles.greyCell}>Obligation type</td>
                  <td>
                    <FormikDropdownList
                      name="type"
                      data={OBLIGATION_TYPES}
                      placeholder={!values.expenseTypes?.length ? 'Select obligation type' : ''}
                      className="obligationDropdownWrap dropdownPadding"
                      errorClassName={styles.error}
                    />
                  </td>
                </tr>

                <tr>
                  <td className={styles.greyCell}>Expense type</td>
                  <td>
                    <FormikMultiselect
                      name="expenseTypes"
                      data={expenseTypes || []}
                      placeholder="Select expense type"
                      className="obligationDropdownWrap multiselectPadding"
                      errorClassName={styles.error}
                      textField="title"
                      disabled={getDisabledExpenseTypes(values.expenseTypes, expenseTypes)}
                    />
                  </td>
                  <td className={styles.greyCell}>Currency</td>
                  <td>
                    <FormikDropdownList
                      name="currency"
                      data={CURRENCIES}
                      placeholder="Select currency"
                      className="obligationDropdownWrap dropdownPadding"
                      errorClassName={styles.error}
                    />
                  </td>
                </tr>

                <tr>
                  <td className={styles.greyCell}>Effective Date</td>
                  <td>
                    <FormikDatePicker
                      name="effectiveDate"
                      dateFormat={DATE_PICKER.dateFormatToPayload}
                      placeholderText={'YYYY-MM-DD'}
                      className={styles.inputWrap}
                      errorClassName={styles.error}
                      fixedHeight={true}
                    />
                  </td>
                  <td className={styles.greyCell}>Expiration Date</td>
                  <td>
                    <FormikDatePicker
                      name="expirationDate"
                      dateFormat={DATE_PICKER.dateFormatToPayload}
                      placeholderText={'YYYY-MM-DD'}
                      className={styles.inputWrap}
                      errorClassName={styles.error}
                      fixedHeight={true}
                    />
                  </td>
                </tr>

                <tr className={styles.lastDoubleCell}>
                  <td className={styles.greyCell}>Payment Delay</td>
                  <td>
                    <FormikDropdownList
                      name="paymentDelay"
                      data={PAYMENT_DELAY}
                      placeholder="Select payment delay"
                      className="obligationDropdownWrap dropdownPadding"
                      errorClassName={styles.error}
                    />
                  </td>
                  <td className={styles.greyCell}>Payment Frequency</td>
                  <td>
                    <FormikDropdownList
                      name="paymentFrequency"
                      data={PAYMENT_FREQUENCY}
                      placeholder="Select payment frequency"
                      className="obligationDropdownWrap dropdownPadding"
                      errorClassName={styles.error}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={styles.greyCell}>Confirmation Day</td>
                  <td>
                    <FormikDropdownList
                      name="confirmationDay"
                      data={CONFIRMATION_DAYS}
                      placeholder="Select confirmation day"
                      className="obligationDropdownWrap dropdownPadding"
                      errorClassName={styles.error}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NewObligationForm;
