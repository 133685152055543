import { TotalDto } from 'models/dto/bdm.dto';
import TotalModel from 'models/totals.model';

class TotalsAdapter {
  static mapTotalsDtoToTotalsModel(data: TotalDto[]): TotalModel[] {
    return data.map(item => new TotalModel(item));
  }
}

export default TotalsAdapter;
