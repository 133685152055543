import { EmployeeDto } from '../dto/employee.dto';
import EmployeeModel from '../employee.model';
import EmployeeListModel from '../employeeList.model';
import { EmployeesDto } from '../dto/employees.dto';
import { EmployeeProfileModel } from '../employeeProfile.model';
import EmployeeAvailabilityModel from '../employeeAvailability.model';
import EmployeeHrAssigneeModel from 'models/employeeHrAssignee.model';
import EmployeeWithMentorModel from 'models/employeeWithMentor.model';

class EmployeeAdapter {
  static mapEmployeeDtoToEmployee(employee: EmployeeDto): EmployeeModel {
    return new EmployeeModel({
      id: employee.id,
      firstName: employee.firstName,
      lastName: employee.lastName,
      availability: employee.availability,
      isTeamlead: employee.isTeamlead,
      isTechlead: employee.isTechlead,
      isIntern: employee.isIntern,
      isDirectCost: employee.isDirectCost || false,
      timeByMonth: employee.timeByMonth || 0,
      title: employee.title || '',
      atlassianId: employee.atlassianId || '',
      isTeamAvailableForPlanning: employee?.team?.isAvailableForPlanning,
    });
  }

  static mapEmployeeListDtoToEmployeeList(employeesList: EmployeesDto): EmployeeListModel {
    return new EmployeeListModel({
      employees: employeesList.employees?.map((employee: EmployeeDto) =>
        EmployeeAdapter.mapEmployeeDtoToEmployee(employee)
      ),
    });
  }

  static mapEmployeeDtoToEmployeeProfile(employee: EmployeeDto): EmployeeProfileModel {
    return new EmployeeProfileModel({
      ...employee,
      isDirectCost: employee?.isDirectCost || false,
      timeByMonth: employee?.timeByMonth || 0,
      title: employee?.title || '',
      atlassianId: employee?.atlassianId || '',
      team: employee?.team || null,
      departmentTitle: employee?.department?.title || '',
      grade: employee?.grade || '',
      emailPublic: employee?.email,
      location: employee?.location || '',
      birthday: employee?.birthday || '',
      startDate: employee?.startDate,
      trialEndDate: employee?.trialEndDate || '',
      dismissalDate: employee?.dismissalDate || '',
      privateInfo: employee?.privateInfo || null,
      vsdStats: employee?.vsdStats || null,
      emails: employee?.contacts?.emails || [],
      phones: employee?.contacts?.phones || [],
      englishLevel: employee?.englishLevel || '',
      mentor: employee.mentor || null,
      hr: employee.hr || null,
      childrens: employee?.persons
        ? employee.persons.map(person => {
            return {
              employeeId: employee.id,
              type: person.type,
              value: person.name,
              id: person.id ?? 0,
            };
          })
        : [],
    });
  }

  static mapEmployeeDtoToAvailability(employees: EmployeeDto[]): EmployeeAvailabilityModel[] {
    return employees?.map(
      employee =>
        new EmployeeAvailabilityModel({
          id: employee?.id,
          firstName: employee?.firstName || '',
          lastName: employee?.lastName || '',
          email: employee?.email || '',
          link: employee?.link || '',
          team: employee?.team?.title || '',
          title: employee?.title || '',
          englishLevel: employee?.englishLevel || '',
          dismissalDate: employee?.dismissalDate || '',
          atlassianId: employee?.atlassianId || '',
        })
    );
  }

  static mapEmployeeDtoToHrAssignees(employees: EmployeeDto[]): EmployeeHrAssigneeModel[] {
    return employees?.map(
      employee =>
        new EmployeeHrAssigneeModel({
          id: employee?.id,
          firstName: employee?.firstName || '',
          lastName: employee?.lastName || '',
          hr: {
            id: employee.hr?.id || null,
            firstName: employee.hr?.firstName || '',
            lastName: employee.hr?.lastName || '',
          },
        })
    );
  }

  static mapEmployeeDtoToMentors(employees: EmployeeDto[]): EmployeeWithMentorModel[] {
    return employees?.map(
      employee =>
        new EmployeeWithMentorModel({
          id: employee?.id,
          firstName: employee?.firstName || '',
          lastName: employee?.lastName || '',
          mentor: {
            id: employee.mentor?.id || null,
            firstName: employee.mentor?.firstName || '',
            lastName: employee.mentor?.lastName || '',
          },
        })
    );
  }
}

export default EmployeeAdapter;
