import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import { createPortal } from 'react-dom';

import FormikInput from '../../common/Formik/FormikInput';
import FormikDropdown from '../../common/Formik/FormikDropdown';
import FormikDatePicker from '../../common/Formik/FormikDatePicker';
import Loader from '../../common/Loader';

import { getHolidayById } from '../../store/holidays/holidays.thunk';
import { InitialHolidayDto } from '../../models/dto/holidays.dto';
import { resetStateHoliday } from '../../store/holidays';
import { AppState } from '../../store/configureStore';

import { durationHolidayOptions } from '../../utils/staticValues';
import { formatDatetoString } from 'utils/dates';

import styles from '../modal.module.scss';

const HOLIDAY_SCHEMA = Yup.object({
  date: Yup.string().required('Field required'),
  duration: Yup.string().required('Field required'),
  title: Yup.string()
    .min(6, 'The reason must be at least 6 characters')
    .max(255, 'The reason is too long')
    .required('Field required'),
});

type EditModalT = {
  title: string;
  submitButton: string;
  handleClose: () => void;
  handleSubmit: (values: InitialHolidayDto) => void;
  holidayId: number;
};

const EditModal: React.FC<EditModalT> = ({ submitButton, title, handleClose, handleSubmit, holidayId }) => {
  const dispatch = useDispatch();

  const { holiday, loadingModal } = useSelector((state: AppState) => state.holidaysReducer);

  useEffect(() => {
    !!holidayId && dispatch(getHolidayById(holidayId));

    return () => {
      dispatch(resetStateHoliday());
    };
  }, [dispatch, holidayId]);

  return createPortal(
    <Formik
      initialValues={{
        date: holiday?.date ? new Date(holiday?.date) : '',
        duration: holiday?.duration || 'Day off',
        title: holiday?.title || '',
      }}
      validationSchema={HOLIDAY_SCHEMA}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {() => (
        <Form>
          <div className={styles.modal}>
            <div className={styles.backDrop} onClick={handleClose}></div>
            <div className={cx(styles.block, 'text-left')} style={{ overflow: 'unset' }}>
              <button className={styles.close} onClick={handleClose} />
              <div className={cx(styles.header)}>{title}</div>

              {!loadingModal ? (
                <div className={styles.inputWrap}>
                  <FormikDatePicker
                    name="date"
                    label="Non-working day"
                    dateFormat={'MMM dd, yyyy EEEE'}
                    className={styles.input}
                    placeholderText={`Date, e.g. ${formatDatetoString(new Date())}`}
                    showYearDropdown
                  />

                  <div className="filters-block">
                    <div className={cx('dropdown-filter')} style={{ maxWidth: '375px' }}>
                      <p className="label-wrapper">Duration</p>
                      <FormikDropdown name="duration" data={durationHolidayOptions} />
                    </div>
                  </div>

                  <div className={styles.dropdownWrap}>
                    <FormikInput
                      name="title"
                      label="Reason"
                      placeholder="Reason name, e.g. New Year"
                      className={styles.input}
                    />
                  </div>
                </div>
              ) : (
                <div className="ml-3">{loadingModal && <Loader />}</div>
              )}

              {!loadingModal && (
                <div className={styles.buttonWrapper}>
                  <button type="button" className={styles.blueLineButton} onClick={handleClose}>
                    Cancel
                  </button>

                  <button className={styles.blueButton} type="submit">
                    {submitButton}
                  </button>
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>,
    document.body
  );
};

export default EditModal;
