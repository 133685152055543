import httpService, { HttpService } from './httpService';

export class CompensationService {
  constructor(private _httpService: HttpService) {}

  async getCompensation() {
    const res = await this._httpService.get('/compensation', {});
    return res;
  }
}

const compensationService = new CompensationService(httpService);

export default compensationService;
