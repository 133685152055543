export interface StorageInterface {
  getItem(key: string): void;
  setItem(key: string, data?: any): void;
  removeItem(key: string): void;
  clear(): void;
}

class StorageService {
  constructor(private _storage: StorageInterface, private _key: string) {}

  public getItem() {
    return this._storage.getItem(this._key);
  }

  public setItem(data?: any) {
    return this._storage.setItem(this._key, data);
  }

  public removeItem() {
    return this._storage.removeItem(this._key);
  }

  public clear() {
    return this._storage.clear();
  }
}

export default StorageService;
