import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import { DropdownList } from 'react-widgets';
import { Card, CardBody } from 'reactstrap';

import OutstuffFunnelTable from './OutstuffFunnelTable';
import Datepicker from '../../Datepicker/Datepicker';
import Loader from '../../../common/Loader';
import { DATE_PICKER } from '../../../constants/common';

import { getDealGroupsList } from '../../../store/bdm/bdm.thunk';
import { getDealStages } from '../../../store/bdm/bdm.thunk';
import { AppState } from '../../../store/configureStore';

import { formatStage } from 'utils/functions';
import { get3MonthsAgo } from '../../../utils';
import { useQuery } from 'hooks/queryHook';
import DealGroupsModel from '../../../models/dealGroups.model';
import { CommonRequestParams } from 'models/dto/params.dto';

const OutstuffFunnel: React.FC = () => {
  const dispatch = useDispatch();

  const { dealGroups, loading, tempStages } = useSelector((state: AppState) => state.bdmReducer);

  const threeMonthsAgo = useMemo(get3MonthsAgo, []);

  const { fromDate, toDate, stage, setFromToDate, setFromDate, setToDate, setStageValue } = useQuery();

  const stageValue = tempStages?.find((item: string) => formatStage(item) === stage);

  useEffect(() => {
    if (tempStages?.length < 4) {
      dispatch(getDealStages({ pipeline: 'outstaff' }));
    }
  }, [dispatch, tempStages]);

  const getDealGroups = useCallback(() => {
    if (!fromDate || !toDate) {
      setFromToDate(
        format(threeMonthsAgo, DATE_PICKER.dateFormatToPayload),
        format(new Date(), DATE_PICKER.dateFormatToPayload)
      );
    } else {
      const params: CommonRequestParams = {
        pipeline: 'outstaff',
        fromDate: fromDate,
        toDate: toDate,
      };

      if (stage) {
        params.stage = stage;
      }

      dispatch(
        getDealGroupsList({
          type: 'stage',
          params,
        })
      );
    }
  }, [dispatch, fromDate, toDate, stage]);

  useEffect(() => {
    getDealGroups();
  }, [getDealGroups]);

  const renderTable = () => {
    if (loading) return <Loader />;
    return (
      <>
        {dealGroups?.length ? (
          dealGroups.map((dealGroup: DealGroupsModel, index: number) => (
            <OutstuffFunnelTable data={dealGroup} loadingUpdate={loading} key={index} />
          ))
        ) : (
          <div>Deals list is empty</div>
        )}
      </>
    );
  };

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className="filters-block">
          <div className="dropdown-filter">
            <div className="label-wrapper">Date from</div>
            <Datepicker
              selected={fromDate ? new Date(fromDate) : threeMonthsAgo}
              dateFormat={DATE_PICKER.dateFormatMonthAndDay}
              showMonthYearPicker={false}
              onChange={(date: Date) => setFromDate(format(date, DATE_PICKER.dateFormatToPayload))}
            />
          </div>

          <div className="dropdown-filter">
            <div className="label-wrapper">Date to</div>
            <Datepicker
              selected={toDate ? new Date(toDate) : new Date()}
              dateFormat={DATE_PICKER.dateFormatMonthAndDay}
              showMonthYearPicker={false}
              onChange={(date: Date) => setToDate(format(date, DATE_PICKER.dateFormatToPayload))}
            />
          </div>

          <div className="dropdown-filter">
            <div className="label-wrapper">Stage</div>
            <DropdownList
              value={stageValue}
              data={tempStages || []}
              placeholder="All"
              filter="contains"
              onChange={stage => setStageValue(stage ? formatStage(stage) : '')}
            />
          </div>
        </div>
        {renderTable()}
      </CardBody>
    </Card>
  );
};

export default OutstuffFunnel;
