import React, { FC } from 'react';
import cx from 'classnames';

import { CalendarTdInfo, ProjectDetailsProps } from 'models/interfaces/workloadCalendar.interface';
import { PAYMENT_TYPE } from 'models/enums/payment-type.enum';
import { secondsToHours } from 'utils/dates';
import { getBorderColor, getHoverColor, getPlannedWorkload } from '../constants';
import projectIcon from '../../../assets/utils/images/avatars/projects/6.webp';

import styles from '../workloadStyle.module.scss';

const EmployeeProjectsInfo: FC<ProjectDetailsProps> = ({
  project,
  absences,
  shifts,
  startDate,
  endDate,
  expandTable,
  dateRange,
  hoveredColumn,
  setHoveredColumn,
}) => {
  return (
    <tr className={cx(styles.projectRow, expandTable ? styles.tableCellsFixed : styles.firstCellFixed)}>
      <td className={styles.project}>
        <div className={cx(styles.projectTitleCell, styles.flex)}>
          <img className={styles.projectIcon} src={projectIcon} alt="" />
          <a href={project.issueLink} target="_blank">
            {project.linkedProjectKey}
          </a>
        </div>
      </td>

      {expandTable && (
        <>
          <td className={styles.project}></td>
          <td className={styles.project}>
            {project.paymentType.toLowerCase() === PAYMENT_TYPE.billable
              ? secondsToHours(project.estimatedBillableTime)
              : '0'}
          </td>
          <td className={styles.project}>
            {project.paymentType.toLowerCase() === PAYMENT_TYPE.nonBillable
              ? secondsToHours(project.estimatedBillableTime)
              : '0'}
          </td>
          <td className={styles.project}></td>
          <td className={styles.project}>{project.totalLoggedTime ? secondsToHours(project.totalLoggedTime) : '0'}</td>
          <td className={styles.project}></td>
          <td className={styles.project}></td>
        </>
      )}

      {getPlannedWorkload({
        dateRange,
        absences,
        shifts,
        resourceRequests: project.rrDatesRange,
        worklogs: project.worklogs,
        startDate,
        endDate,
        isProjectRow: true,
      }).map((project: CalendarTdInfo, index: number) => (
        <td
          key={index}
          className={cx(styles[project.issueType], index === hoveredColumn ? styles.columnHover : '')}
          onMouseEnter={() => setHoveredColumn(index)}
          onMouseLeave={() => setHoveredColumn(null)}
          style={{
            background: index === hoveredColumn ? getHoverColor(project.issueType) : '',
            borderColor: getBorderColor(project),
          }}
        >
          {project.issue}
        </td>
      ))}
    </tr>
  );
};

export default EmployeeProjectsInfo;
