import { createAsyncThunk, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { getTeamsListFailure, getTeamsListRequest, getTeamsListSuccess } from '.';
import teamService from 'services/teamService';
import { AppState } from 'store/configureStore';
import TeamModel from 'models/team.model';

export const getTeamsListThunk =
  (params?: { expand?: string; planning?: boolean }) =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(getTeamsListRequest());
    try {
      const teams: TeamModel[] = await teamService.getTeamsList(params);
      dispatch(getTeamsListSuccess(teams));
    } catch (err) {
      dispatch(getTeamsListFailure(err as Error));
    }
  };

export const getTeamById = createAsyncThunk(
  'teams/getTeamById',
  async (params: { id: string; searchParams?: { expand: string } }, thunkAPI) => {
    try {
      const res = await teamService.getTeamsListById(params);
      return res;
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
