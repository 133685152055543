import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './index';

type AppReducerT = ReturnType<typeof rootReducer>;
export type AppState = ReturnType<AppReducerT>;

export default function configureStore() {
  let composeEnhancers = compose;
  if (process.env.NODE_ENV === 'development') {
    composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
  }
  return createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));
}
