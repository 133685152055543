import cx from 'classnames';
import styles from './totalsStyles.module.scss';

export interface TotalItem {
  title: string;
  value: string;
  color: string;
}

interface TotalsProps {
  data: TotalItem[];
  className?: string;
}

const Totals = ({ data, className }: TotalsProps) => {
  return (
    <div className={cx(styles.block, className)}>
      {data.map((totalItem: TotalItem) => (
        <div key={totalItem.title} className={styles.blockItem} style={{ backgroundColor: totalItem.color }}>
          <p>{totalItem.value}</p>
          <span>{totalItem.title}</span>
        </div>
      ))}
    </div>
  );
};

export default Totals;
