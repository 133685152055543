import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';

import FormikInput from 'common/Formik/FormikInput';
import FormikDatePicker from 'common/Formik/FormikDatePicker';
import Loader from 'common/Loader';

import { InitialCreatePRDto } from 'models/dto/performanceReview.dto';
import { resetPerfomanceReview } from 'store/performanceReview';
import { AppState } from 'store/configureStore';
import { DATE_PICKER } from 'constants/common';

import styles from '../../../modal.module.scss';

const PERFOMANCE_REVIEW_SCHEMA = Yup.object({
  title: Yup.string().required('Field required'),
  startDate: Yup.string().required('Field required'),
});

type PropsType = {
  title: string;
  onClose: () => void;
  handleSubmit: (values: InitialCreatePRDto) => void;
};

const CreatePRModal: React.FC<PropsType> = ({ title, onClose, handleSubmit }) => {
  const dispatch = useDispatch();

  const { performanceReview, loadingModal } = useSelector((state: AppState) => state.performanceReviewReducer);

  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight);

    return () => {
      dispatch(resetPerfomanceReview());
    };
  }, [dispatch]);

  const maxScreenHeightWithoutDatePickerCutting = 700;

  return (
    <Formik
      initialValues={{
        title: performanceReview?.title || '',
        startDate: performanceReview?.startDate ? new Date(performanceReview.startDate) : '',
      }}
      validationSchema={PERFOMANCE_REVIEW_SCHEMA}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <div className={styles.modal}>
          <div className={styles.backDrop} onClick={onClose}></div>
          <div className={cx(styles.block, 'text-left', 'modalWrapper')} style={{ overflow: 'unset' }}>
            <button className={styles.close} onClick={onClose} />
            <div className={cx(styles.header)}>{title}</div>
            <div className="ml-3">{loadingModal && <Loader />}</div>

            <div className={styles.inputWrap}>
              <FormikInput
                name="title"
                label="Performance Review Title"
                placeholder="Performance Review Title"
                className={styles.input}
              />

              <FormikDatePicker
                name="startDate"
                label="Start date"
                dateFormat={DATE_PICKER.dateFormatMonth}
                placeholderText="2022/10/10"
                className={
                  screenHeight > maxScreenHeightWithoutDatePickerCutting
                    ? styles.input
                    : cx(styles.input, 'smallScreenHeightWrapper')
                }
                minDate={new Date()}
              />
            </div>

            <div className={styles.buttonWrapper}>
              <button className={styles.blueLineButton} onClick={onClose}>
                Cancel
              </button>
              <Field as="div" onClick={handleSubmit}>
                <button className={styles.blueButton} type="submit">
                  Save
                </button>
              </Field>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default CreatePRModal;
