import React, { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { Table } from 'reactstrap';
import ActionButtons from '../../../common/Buttons/ActionButtons';
import Toggle from 'common/Toggle/Toggle';

import { disableExpenseCategory } from 'store/companyExpensesCategories/expensesCategories.thunk';
import { CategoriesTableProps, ExpenseCategoryT } from 'models/interfaces/companyExpenses.interface';

import expenseStyles from '../ExpensesStyle.module.scss';
import styles from '../../tableStyles.module.scss';

const ExpenseCategoriesTable: FC<CategoriesTableProps> = ({
  data,
  isEditRole,
  currentPage,
  perPage,
  handleClickOnEdit,
  handleClickOnDelete,
}) => {
  const dispatch = useDispatch();

  const coefForNumberFirstColumn = useMemo(() => perPage * currentPage - perPage + 1, [currentPage, perPage]);

  return (
    <Table responsive hover className={cx(styles.tableContainer, 'align-middle mb-0 custom-table text-left')}>
      <thead>
        <tr>
          <th className={styles.firstCellWidth}>#</th>
          <th>Name</th>
          {isEditRole ? <th>Actions</th> : null}
        </tr>
      </thead>

      <tbody>
        {data?.map((category: ExpenseCategoryT, index: number) => (
          <tr key={category.id}>
            <td className={styles.firstCellWidth}>{index + coefForNumberFirstColumn}</td>
            <td className={!category.isEnabled ? expenseStyles.toggleLabel : ''}>{category?.name}</td>
            {isEditRole ? (
              <td>
                <div className="d-flex align-items-center">
                  <span className={!category.isEnabled ? expenseStyles.toggleLabel : expenseStyles.toggleLabelMargin}>
                    {category.isEnabled ? 'Enabled' : 'Disabled'}
                  </span>

                  <Toggle
                    checked={category.isEnabled}
                    onChange={() =>
                      dispatch(disableExpenseCategory({ id: category.id, isEnabled: !category.isEnabled }))
                    }
                    toggleMargin="mr-3 ml-2"
                  />

                  <ActionButtons
                    handleClickOnEdit={() => handleClickOnEdit(category.id, category.isEnabled)}
                    handleClickOnDelete={() => handleClickOnDelete(category.id)}
                  />
                </div>
              </td>
            ) : null}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ExpenseCategoriesTable;
