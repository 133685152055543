import httpService, { HttpService } from './httpService';
import { BDMAdapter, DealGroupsAdapter, DealsAdapter } from '../models/adapters/bdm.adapter';

import { DealsInterface } from '../models/dto/bdm.dto';
import { CommonRequestParams } from '../models/dto/params.dto';
export class BdmService {
  constructor(private _httpService: HttpService) {}

  async getAvailableHoursList(params: CommonRequestParams) {
    const res = await this._httpService.get('/hours/available', {
      queryStringParameters: params,
    });

    return BDMAdapter.mapAvailableHoursDtoToTeam(res);
  }

  async getDealGroupsList(data: { type: string; params: CommonRequestParams }) {
    const res = await this._httpService.get(`/deal-groups/${data.type}`, {
      queryStringParameters: data.params,
    });

    return DealGroupsAdapter.mapDealGroupsDtoToOutstuffFunnel(res);
  }

  async getDealsList(params: CommonRequestParams) {
    const res: DealsInterface = await this._httpService.get('/deals', {
      queryStringParameters: params,
    });
    return DealsAdapter.mapDeasToDealsFullInfo(res);
  }

  async getDealById(data: { id: number; params?: CommonRequestParams }) {
    const res = await this._httpService.get(`/deals/${data.id}`, {
      queryStringParameters: data.params,
    });

    return DealsAdapter.mapItemDealsDtoToDealDetails(res);
  }

  async getDealPipelines() {
    const res = await this._httpService.get('/deal-pipelines');
    return res;
  }

  async getDealStages(params: { pipeline?: string }) {
    const res = await this._httpService.get('/deal-stages', {
      queryStringParameters: params,
    });
    return res;
  }

  async getRatesCards(params: { date: string }) {
    const res = await this._httpService.get('/rate-card', {
      queryStringParameters: params,
    });
    return res;
  }

  async getTotals(params: { date: string }) {
    const res = await this._httpService.get('/totals', {
      queryStringParameters: params,
    });
    return res;
  }
}

export default new BdmService(httpService);
