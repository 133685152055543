import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import Amplify from 'aws-amplify';
import awsconfig from './config/amplify';
import { SnackbarProvider } from 'react-notistack';
import Notifier from './components/Notifier';

import './App.css';

export const store = configureStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

Amplify.configure(awsconfig);

const App = () => {
  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHide
      >
        <Notifier />
        <Router>
          <Routes />
        </Router>
      </SnackbarProvider>
    </Provider>
  );
};

export default App;
