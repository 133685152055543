import React from 'react';
import { Table } from 'reactstrap';
import cx from 'classnames';

import { EmployeesTeamLeadDto } from '../../models/dto/teamLead.dto';
import TeamLeadWorkforceModel from '../../models/teamLead.model';
import { secondsToHours } from '../../utils/dates';

import styles from '../tableStyles.module.scss';

type TProps = { data: TeamLeadWorkforceModel };

const TeamLeadWorkforceTable: React.FC<TProps> = ({ data }) => (
  <div className={styles.tableWrapper}>
    <Table
      responsive
      hover
      className={cx(styles.tableContainer, 'align-middle mb-0 custom-table text-truncate text-left')}
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Email</th>
          <th>Cost Center</th>
          <th>Costs</th>
          <th>Hours by month</th>
          <th>Start Date</th>
          <th>Dismissal Date</th>
          <th>Title</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        {data?.employees?.map((employee: EmployeesTeamLeadDto, index: number) => {
          return (
            <tr
              key={index}
              className={styles.removeBorder}
              style={
                employee.dismissalDate
                  ? { color: 'var(--danger)' }
                  : employee.hasStartedThisMonth
                  ? { color: 'var(--success)' }
                  : {}
              }
            >
              <td>{index + 1}</td>
              <td>
                <a
                  style={
                    employee.dismissalDate
                      ? { color: 'var(--danger)' }
                      : employee.hasStartedThisMonth
                      ? { color: 'var(--success)' }
                      : {}
                  }
                  href={`#/dashboard/profile/${employee.id}`}
                >
                  {employee.email}
                </a>
              </td>
              <td>{employee.isDirectCost ? 'direct' : 'indirect'}</td>
              <td>{employee.salaryPrediction}</td>
              <td>{employee.timeByMonth ? secondsToHours(employee.timeByMonth) : ''}</td>
              <td>{employee.startDate}</td>
              <td>{employee.dismissalDate}</td>
              <td>{employee.title}</td>
              <td>{employee.location}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  </div>
);

export default TeamLeadWorkforceTable;
