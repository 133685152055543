import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

import { Card, CardBody } from 'reactstrap';
import Loader from 'common/Loader';
import ObligationDetails from './ObligationDetails';
import NewObligationForm from './NewObligationForm';

import { AppState } from 'store/configureStore';
import { getExpenseTypesThunk } from 'store/expenseTypes';
import { getObligations } from 'store/obligation/obligation.thunk';
import { clearObligations } from 'store/obligation';
import { getEmployeeByIdThunk } from 'store/employee';
import ObligationModel from 'models/obligation.model';

import { Role } from '../../constants/roles';
import { plus } from 'constants/icons';
import noObligationsImage from '../../assets/utils/images/noItems.png';

import tableStyles from '../tableStyles.module.scss';
import styles from './obligation.module.scss';

const Obligation = () => {
  const dispatch = useDispatch();

  const { id: paramsId } = useParams<{ id: string }>();

  const { role, userId, teams } = useSelector((state: AppState) => state.account?.user);
  const employeeId: string = paramsId ? paramsId : userId;

  const { obligations, loading, isUpdatedObligation } = useSelector((state: AppState) => state.obligationsReducer);
  const { employee } = useSelector((state: AppState) => state.employeeReducer);

  const [addObligation, setAddObligation] = useState(false);

  const isAllowedToApprove: boolean = role === Role.vp || role === Role.finance;
  const isEditRole: boolean =
    (role === Role.teamlead && paramsId !== userId && teams?.split(',')?.includes(String(employee?.team?.id))) ||
    isAllowedToApprove;

  useEffect(() => {
    if (paramsId !== userId) {
      dispatch(getEmployeeByIdThunk(paramsId));
    }
    dispatch(getObligations({ employee: employeeId }));
  }, [dispatch, employeeId]);

  useEffect(() => {
    dispatch(getExpenseTypesThunk());

    return () => {
      dispatch(clearObligations());
    };
  }, []);

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className={tableStyles.buttonWrap}>
          {isEditRole && (
            <button
              className={cx(tableStyles.button, tableStyles.addButton)}
              onClick={() => {
                setAddObligation(true);
              }}
              disabled={addObligation || loading}
            >
              {plus} Add obligation
            </button>
          )}
        </div>

        {addObligation && (
          <>
            <NewObligationForm
              setAddObligation={setAddObligation}
              employeeId={employeeId}
              index={obligations?.length ? obligations.length + 1 : 1}
            />
            {!!obligations.length && <hr />}
          </>
        )}

        {obligations?.length ? (
          obligations.map((obligation: ObligationModel, index: number) => (
            <Fragment key={obligation.id}>
              <ObligationDetails
                obligation={obligation}
                addObligation={addObligation}
                employeeId={employeeId}
                userId={userId}
                isEditRole={isEditRole}
                isAllowedToApprove={isAllowedToApprove}
                isUpdatedObligation={isUpdatedObligation}
                index={obligations.length - index}
              />
              {index + 1 !== obligations.length && <hr />}
            </Fragment>
          ))
        ) : loading ? (
          <Loader />
        ) : (
          !addObligation && (
            <div className={styles.noObligations}>
              <img src={noObligationsImage} alt="" />
              <div className="mt-2">No obligations yet</div>
            </div>
          )
        )}
      </CardBody>
    </Card>
  );
};

export default Obligation;
