import PageTitle from '../../Layout/AppMain/PageTitle';
import Obligation from '../../components/Obligation/Obligation';
import { obligationIcon } from 'constants/icons';

const ObligationPage = () => {
  return (
    <>
      <PageTitle heading="Obligation History" icon={obligationIcon} />
      <Obligation />
    </>
  );
};

export default ObligationPage;
