import React, { FC } from 'react';
import cx from 'classnames';

import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { EmployeeDto } from 'models/dto/employee.dto';

import styles from '../../tableStyles.module.scss';
import profileStyles from '../Profile.module.scss';
import gradeStyle from '../ProfileHeader/profileHeader.module.scss';

const MenteesTable: FC<{ employees: EmployeeDto[] }> = ({ employees }) => {
  return (
    <div className={cx(styles.tableWrapper, profileStyles.menteesTable)}>
      <Table responsive hover className={cx(styles.tableContainer, 'align-middle mb-0 custom-table text-left')}>
        <thead>
          <tr>
            <th className={styles.firstCellWidth}>#</th>
            <th>Employee</th>
            <th>Title</th>
            <th>Grade</th>
          </tr>
        </thead>

        <tbody>
          {employees.map((employee: EmployeeDto, index: number) => (
            <tr key={employee.id} className={styles.removeBorder}>
              <td>{index + 1}</td>
              <td>
                <Link to={`/dashboard/profile/${employee.id}`}>
                  {employee.firstName} {employee.lastName}
                </Link>
              </td>
              <td>{employee.title}</td>
              <td>
                <div className={gradeStyle.gradeWrap}>
                  <div className={employee.grade && gradeStyle[employee.grade.toLowerCase()]}>{employee.grade}</div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MenteesTable;
