export const secondsToDays = (seconds: number | undefined) => {
  const SECONDS_TO_DAYS = 60 * 60 * 8;
  return seconds && (seconds / SECONDS_TO_DAYS).toFixed(2);
};

export const currentDay = (planned: number = 0) => {
  const formatTwoDigits = (digit: number) => ('0' + digit).slice(-2);
  const tempDate = new Date();
  tempDate.setMonth(tempDate.getMonth() + planned);
  return `${tempDate.getFullYear()}-${formatTwoDigits(tempDate.getMonth() + 1)}-${formatTwoDigits(tempDate.getDate())}`;
};
