export default class WorkingYearModel {
  month: number;
  workingTime: number;
  workingDays: number;
  nonWorkingDays: number;

  constructor(data: WorkingYearModel) {
    this.month = data.month;
    this.workingTime = data.workingTime;
    this.workingDays = data.workingDays;
    this.nonWorkingDays = data.nonWorkingDays;
  }
}
