import { createSlice } from '@reduxjs/toolkit';
import { getSalesGradesThunk } from './profile.thunk';

interface ProfileStateInterface {
  salesGrades: { id: number; title: string }[];
  loading: boolean;
  error: Error | null;
}

const initialState: ProfileStateInterface = {
  salesGrades: [],
  loading: false,
  error: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    [getSalesGradesThunk.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [getSalesGradesThunk.fulfilled.toString()]: (state, { payload }) => {
      state.salesGrades = payload;
      state.loading = false;
    },
    [getSalesGradesThunk.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default profileSlice.reducer;
