import ObligationDto from '../dto/obligation.dto';
import ObligationModel from '../obligation.model';

export default class ObligationAdapter {
  static mapObligationDtoToObligation(obligation: ObligationDto): ObligationModel {
    return new ObligationModel({
      approvedBy: obligation.approvedBy,
      employeeId: obligation.employeeId,
      amount: obligation.amount,
      currency: obligation.currency,
      type: obligation.type,
      effectiveDate: obligation.effectiveDate,
      expirationDate: obligation.expirationDate,
      expenseTypes: obligation.expenseTypes,
      id: obligation.id,
      syncedAt: obligation.syncedAt,
      paymentDelay: obligation.paymentDelay,
      paymentFrequency: obligation.paymentFrequency,
      confirmationDay: obligation.confirmationDay,
    });
  }
}
