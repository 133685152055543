import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { Input } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import TeamFilter from 'common/TeamFilter/TeamFilter';
import Search from 'common/Search/Search';

import { AppState } from 'store/configureStore';
import { FilterT } from 'models/dto/bdm.dto';

import styles from '../../tableStyles.module.scss';

interface InputProps {
  setTeamValue: (value: string, searchData?: string) => void;
  team: string;
  onSalesGradeChange: (value: FilterT) => void;
  salesGradeValue: FilterT;
  searchInput: string;
  setSearchInput: (value: string) => void;
  updateSearchInput: (event: React.FormEvent) => void;
  checked: boolean;
  onCheckedInput: (value: boolean) => void;
  error: null | Error;
}

const AvailabilityInputs: FC<InputProps> = ({
  setTeamValue,
  team,
  onSalesGradeChange,
  salesGradeValue,
  searchInput,
  setSearchInput,
  updateSearchInput,
  checked,
  onCheckedInput,
  error,
}) => {
  const { salesGrades } = useSelector((state: AppState) => state.bdmReducer);

  return (
    <>
      <div className="bp-header">
        <div className="filters-block">
          <Search searchValue={searchInput} setSearchValue={setSearchInput} updateSearchValue={updateSearchInput} />

          <TeamFilter team={team} setTeam={setTeamValue} searchData={searchInput} />

          <div className="dropdown-filter">
            <p className="label-wrapper">Sales Grade</p>
            <DropdownList
              value={salesGradeValue ? salesGradeValue.title : ''}
              placeholder="All"
              data={salesGrades || []}
              textField="title"
              onChange={onSalesGradeChange}
              busy={!salesGrades && !error}
            />
          </div>

          <div className={styles.checkboxFilter}>
            <Input
              className={styles.checkboxWrapper}
              type="checkbox"
              checked={checked}
              onChange={e => onCheckedInput(e.target.checked)}
            />
            <p className={styles.checkboxText}>Available only</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailabilityInputs;
