import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useQuery = () => {
  const { push } = useHistory();
  const location = useLocation<string>();
  const params = new URLSearchParams(location.search);

  const page = params.get('page') || 1;
  const size = params.get('size') || 50;
  const projectKey = params.get('projectKey') || '';
  const employee = params.get('employee') || '';
  const team = params.get('team') || '';
  const costs = params.get('costs') || '';
  const status = params.get('status') || '';
  const pirStatus = params.get('pirStatus') || '';
  const billingSource = params.get('billingSource') || '';
  const fromDate = params.get('fromDate') || '';
  const toDate = params.get('toDate') || '';
  const date = params.get('date') || '';
  const search = params.get('search') || '';
  const salesGrade = params.get('salesGrade') || '';
  const available = params.get('available') || '';
  const pipeline = params.get('pipeline') || '';
  const stage = params.get('stage') || '';
  const delivery = params.get('delivery') || '';

  const employeeValue = employee ? `&employee=${employee}` : '';
  const projectSearch = projectKey ? `&projectKey=${projectKey}` : '';
  const teamValue = team ? `&team=${team}` : '';
  const costsValue = costs ? `&costs=${costs}` : '';
  const statusValue = status ? `&status=${status}` : '';
  const pirStatusValue = pirStatus ? `&pirStatus=${pirStatus}` : '';
  const billingSourceValue = billingSource ? `&billingSource=${billingSource}` : '';
  const fromDateValue = fromDate ? `&fromDate=${fromDate}` : '';
  const fromDateData = fromDate ? `fromDate=${fromDate}` : '';
  const toDateValue = toDate ? `&toDate=${toDate}` : '';
  const dateValue = date ? `&date=${date}` : '';
  const dateData = date ? `date=${date}` : '';
  const searchValue = search ? `&search=${search}` : '';
  const salesGradeValue = salesGrade ? `&salesGrade=${salesGrade}` : '';
  const availableValue = available ? `&available=${available}` : '';
  const pipelineValue = pipeline ? `&pipeline=${pipeline}` : '';
  const stageValue = stage ? `&stage=${stage}` : '';
  const deliveryValue = delivery ? `&delivery=${delivery}` : '';

  const setPage = useCallback(
    (value: number, searchData?: string) => {
      const isSearchData = searchData ? `&search=${searchData}` : '';
      const searchStr = search === searchData ? searchValue : isSearchData;

      push(
        `?page=${value}&size=${size}${fromDateValue}${toDateValue}${teamValue}${employeeValue}${projectSearch}${searchStr}${statusValue}${billingSourceValue}${salesGradeValue}${availableValue}${pipelineValue}${stageValue}`
      );
    },
    [
      push,
      size,
      employeeValue,
      teamValue,
      fromDateValue,
      toDateValue,
      statusValue,
      searchValue,
      billingSourceValue,
      projectSearch,
      salesGradeValue,
      availableValue,
      pipelineValue,
      stageValue,
    ]
  );

  const setPageSize = useCallback(
    (value: number, searchData?: string) => {
      const isSearchData = searchData ? `&search=${searchData}` : '';
      const searchStr = search === searchData ? searchValue : isSearchData;

      let query = '';

      if (value) {
        query = `?page=1&size=${value}${fromDateValue}${toDateValue}${teamValue}${employeeValue}${projectSearch}${searchStr}${statusValue}${billingSourceValue}${salesGradeValue}${availableValue}${pipelineValue}${stageValue}`;
      } else {
        query = `?page=1&size=50`;
      }

      push(query);
    },
    [
      page,
      push,
      employeeValue,
      teamValue,
      fromDateValue,
      toDateValue,
      statusValue,
      searchValue,
      projectSearch,
      billingSourceValue,
      salesGradeValue,
      availableValue,
      pipelineValue,
      stageValue,
    ]
  );

  const setEmployee = (value: number) => {
    let query = '';

    if (value) {
      query = `?page=1&size=${size}&employee=${value}`;
    } else {
      query = `?page=1&size=${size}`;
    }

    push(query);
  };

  const setDelivery = (value: string) => {
    let query = '';

    if (value) {
      query = `?${fromDateData}${toDateValue}${projectSearch}&delivery=${value}${pirStatusValue}`;
    } else {
      query = `?${fromDateData}${toDateValue}${projectSearch}${pirStatusValue}`;
    }

    push(query);
  };

  const setProjectKey = (value: string) => {
    let query = '';

    if (value) {
      query = `?page=1&size=${size}&projectKey=${value}`;
    } else {
      query = `?page=1&size=${size}`;
    }

    push(query);
  };

  const setProjectKeyValue = (value: string) => {
    let query = '';

    if (value) {
      query = `?${fromDateData}${toDateValue}&projectKey=${value}${deliveryValue}${pirStatusValue}`;
    } else {
      query = `?${fromDateData}${toDateValue}${deliveryValue}`;
    }

    push(query);
  };

  const setProjectKeyData = (value: string, searchData?: string) => {
    const isSearchData = searchData ? `&search=${searchData}` : '';
    const searchStr = search === searchData ? searchValue : isSearchData;

    let query = '';

    if (value) {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}&projectKey=${value}${teamValue}${searchStr}${statusValue}${billingSourceValue}`;
    } else {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}${teamValue}${searchStr}${statusValue}${billingSourceValue}`;
    }

    push(query);
  };

  // date
  const setDateRange = (from: string, to: string, searchData?: string) => {
    const isSearchData = searchData ? `&search=${searchData}` : '';
    const searchStr = search === searchData ? searchValue : isSearchData;

    push(
      `?page=1&size=${size}&fromDate=${from}&toDate=${to}${teamValue}${searchStr}${projectSearch}${statusValue}${billingSourceValue}${pipelineValue}${stageValue}`
    );
  };

  const setFromToDate = (from: string, to: string, pirStatusData?: string) => {
    const pirStatusStr = pirStatusData ? `&pirStatus=${pirStatusData}` : pirStatusValue;

    push(
      `?fromDate=${from}&toDate=${to}${teamValue}${deliveryValue}${projectSearch}${pirStatusStr}${statusValue}${billingSourceValue}`
    );
  };

  const setFromDate = (value: string) => {
    push(`?fromDate=${value}${toDateValue}${stageValue}`);
  };

  const setToDate = (value: string) => {
    push(`?fromDate=${fromDate}&toDate=${value}${stageValue}`);
  };

  const setDate = (value: string, searchData?: string) => {
    const isSearchData = searchData ? `&search=${searchData}` : '';
    const searchStr = search === searchData ? searchValue : isSearchData;

    push(`?date=${value}${teamValue}${searchStr}${costsValue}`);
  };

  const setStatus = (value: string, searchData?: string) => {
    const isSearchData = searchData ? `&search=${searchData}` : '';
    const searchStr = search === searchData ? searchValue : isSearchData;

    let query = '';

    if (value) {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}${teamValue}${projectSearch}${searchStr}&status=${value}${billingSourceValue}`;
    } else {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}${teamValue}${projectSearch}${searchStr}${billingSourceValue}`;
    }

    push(query);
  };

  const setStatusValue = (value: string) => {
    let query = '';

    if (value) {
      query = `?${fromDateData}${toDateValue}${teamValue}&status=${value}`;
    } else {
      query = `?${fromDateData}${toDateValue}${teamValue}`;
    }

    push(query);
  };

  const setPirStatus = (value: string) => {
    let query = '';

    if (value) {
      query = `?${fromDateData}${toDateValue}${projectSearch}${deliveryValue}&pirStatus=${value}`;
    } else {
      query = `?${fromDateData}${toDateValue}${projectSearch}${deliveryValue}`;
    }

    push(query);
  };

  const setBillingSourceValue = (value: string, searchData?: string) => {
    const isSearchData = searchData ? `&search=${searchData}` : '';
    const searchStr = search === searchData ? searchValue : isSearchData;

    let query = '';

    if (value) {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}${teamValue}${projectSearch}${searchStr}${statusValue}&billingSource=${value}`;
    } else {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}${teamValue}${projectSearch}${searchStr}${statusValue}`;
    }

    push(query);
  };

  const setBillingSource = (value: string) => {
    let query = '';

    if (value) {
      query = `?${fromDateValue}${toDateValue}${teamValue}&billingSource=${value}`;
    } else {
      query = `?${fromDateValue}${toDateValue}${teamValue}`;
    }

    push(query);
  };

  // team
  const setTeamValue = (value: string, searchData?: string) => {
    const isSearchData = searchData ? `&search=${searchData}` : '';
    const searchStr = search === searchData ? searchValue : isSearchData;

    let query = '';

    if (value) {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}&team=${value}${projectSearch}${searchStr}${statusValue}${billingSourceValue}${salesGradeValue}${availableValue}`;
    } else {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}${projectSearch}${searchStr}${statusValue}${billingSourceValue}${salesGradeValue}${availableValue}`;
    }

    push(query);
  };

  const setTeam = (value: string, searchData?: string) => {
    const isSearchData = searchData ? `&search=${searchData}` : '';
    const searchStr = search === searchData ? searchValue : isSearchData;
    let query = '';

    if (value) {
      query = `?${fromDateData}${toDateValue}${dateData}&team=${value}${searchStr}${costsValue}${billingSourceValue}`;
    } else {
      query = `?${fromDateData}${toDateValue}${dateData}${searchStr}${costsValue}${billingSourceValue}`;
    }

    push(query);
  };

  const setTeamData = (value: string) => {
    push(value ? `?team=${value}` : location.pathname);
  };

  const setCosts = (value: string) => {
    let query = '';

    if (value) {
      query = `?${dateData}${teamValue}&costs=${value}`;
    } else {
      query = `?${dateData}${teamValue}`;
    }

    push(query);
  };

  const setSearch = (value: string) => {
    let query = '';

    if (value) {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}${dateValue}${teamValue}&search=${value}${projectSearch}${statusValue}${billingSourceValue}${salesGradeValue}${availableValue}`;
    } else {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}${dateValue}${teamValue}${projectSearch}${statusValue}${billingSourceValue}${salesGradeValue}${availableValue}`;
    }

    push(query);
  };

  const setSearchData = (value: string) => {
    let query = '';

    if (value) {
      query = `?${dateData}${teamValue}&search=${value}`;
    } else {
      query = `?${dateData}${teamValue}`;
    }

    push(query);
  };

  const setSalesGrade = (value: string, searchData?: string) => {
    const isSearchData = searchData ? `&search=${searchData}` : '';
    const searchStr = search === searchData ? searchValue : isSearchData;
    let query = '';

    if (value) {
      query = `?page=1&size=${size}${teamValue}${searchStr}&salesGrade=${value}${availableValue}`;
    } else {
      query = `?page=1&size=${size}${teamValue}${searchStr}${availableValue}`;
    }

    push(query);
  };

  const setAvailable = (value: boolean, searchData?: string) => {
    const isSearchData = searchData ? `&search=${searchData}` : '';
    const searchStr = search === searchData ? searchValue : isSearchData;
    let query = '';

    if (value) {
      query = `?page=1&size=${size}${teamValue}${searchStr}${salesGradeValue}&available=${value}`;
    } else {
      query = `?page=1&size=${size}${teamValue}${searchStr}${salesGradeValue}`;
    }

    push(query);
  };

  const setPipeline = (value: string) => {
    let query = '';

    if (value) {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}&pipeline=${value}`;
    } else {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}`;
    }

    push(query);
  };

  const setStage = (value: string) => {
    let query = '';

    if (value) {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}${pipelineValue}&stage=${value}`;
    } else {
      query = `?page=1&size=${size}${fromDateValue}${toDateValue}${pipelineValue}`;
    }

    push(query);
  };

  const setStageValue = (value: string) => {
    let query = '';

    if (value) {
      query = `?${fromDateData}${toDateValue}&stage=${value}`;
    } else {
      query = `?${fromDateData}${toDateValue}`;
    }

    push(query);
  };

  return {
    page: page ? page : 1,
    pageSize: size ? size : 50,
    employee,
    delivery,
    team,
    costs,
    projectKey,
    status,
    pirStatus,
    billingSource,
    fromDate,
    toDate,
    date,
    search,
    salesGrade,
    available,
    pipeline,
    stage,
    setPage,
    setPageSize,
    setTeamValue,
    setEmployee,
    setDelivery,
    setProjectKey,
    setProjectKeyValue,
    setProjectKeyData,
    setDateRange,
    setFromToDate,
    setFromDate,
    setToDate,
    setDate,
    setTeam,
    setTeamData,
    setCosts,
    setStatus,
    setStatusValue,
    setPirStatus,
    setBillingSource,
    setBillingSourceValue,
    setSearch,
    setSearchData,
    setSalesGrade,
    setAvailable,
    setPipeline,
    setStage,
    setStageValue,
  };
};
