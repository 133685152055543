import { createAsyncThunk } from '@reduxjs/toolkit';
import compensationService from 'services/compensationService';

export const getCompensation = createAsyncThunk('compensation/getCompensation', async (_, thunkAPI) => {
  try {
    const res = await compensationService.getCompensation();
    return res;
  } catch (err: any) {
    if (!err.message) {
      throw err;
    }
    return thunkAPI.rejectWithValue(err.message.original);
  }
});
