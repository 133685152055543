import { COLORS } from 'config/theme';
import { ISSUE_STATUS, ISSUE_TYPES } from 'constants/issues.constants';
import {
  EmployeeTotalVsdData,
  EmployeesVsdList,
  FormattedVsdIssue,
  FormattedVsdList,
  VsdIssue,
  VsdItem,
} from 'models/interfaces/vsd.interface';

export const STATUSES = ['Approved', 'Unapproved', 'Closed'];

export const formatVSDEvent = (item: VsdIssue): FormattedVsdIssue => {
  const colors: Record<string, string> = {
    [ISSUE_STATUS.approved]: COLORS.indigo,
    [ISSUE_STATUS.hold]: COLORS.red,
    [ISSUE_STATUS.closed]: COLORS.gray600,
  };

  return {
    id: item.id,
    title: item.summary,
    start: item.startDate,
    end: item.endDate + 'T23:59:00Z',
    allDay: true,
    url: item.link,
    backgroundColor: colors?.[item.status] || COLORS.gray300,
    borderColor: colors?.[item.status] || COLORS.gray300,
  };
};

export const formatVsdData = (data: EmployeesVsdList): FormattedVsdList | null => {
  if (!data) return null;

  const arrOfIssues: VsdIssue[] = [];

  Object.values(data).forEach((value: EmployeeTotalVsdData) => {
    Object.values(value.items).forEach((vsdIssue: VsdItem) => {
      if (vsdIssue.issues?.length) {
        arrOfIssues.push(...vsdIssue.issues);
      }
    });
  });

  const issues = arrOfIssues.reduce((acc: FormattedVsdIssue[], item: VsdIssue) => {
    if (item.type === ISSUE_TYPES.shift) {
      acc.push(
        formatVSDEvent({
          ...item,
          startDate: item.startDate,
          endDate: item.startDate,
        })
      );
      acc.push(
        formatVSDEvent({
          ...item,
          startDate: item.endDate,
          endDate: item.endDate,
        })
      );
    } else {
      acc.push(formatVSDEvent(item));
    }
    return acc;
  }, []);

  const emptyFooter = {
    billablePlaned: 0,
    billableUsed: 0,
    notBillablePlaned: 0,
    notBillableUsed: 0,
    totalPlaned: 0,
    totalUsed: 0,
  };

  const directFooter = data.direct?.footer?.totalVsd || emptyFooter;
  const indirectFooter = data.indirect?.footer?.totalVsd || emptyFooter;

  const footer = [
    { title: 'Direct', ...directFooter },
    { title: 'Indirect', ...indirectFooter },
    {
      title: 'Total',
      billablePlaned: directFooter.billablePlaned + indirectFooter.billablePlaned,
      billableUsed: directFooter.billableUsed + indirectFooter.billableUsed,
      notBillablePlaned: directFooter.notBillablePlaned + indirectFooter.notBillablePlaned,
      notBillableUsed: directFooter.notBillableUsed + indirectFooter.notBillableUsed,
      totalPlaned: directFooter.totalPlaned + indirectFooter.totalPlaned,
      totalUsed: directFooter.totalUsed + indirectFooter.totalUsed,
    },
  ];

  return { issues, footer };
};
