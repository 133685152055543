import AvailabilityModel from '../availability.model';
import AvailableHoursModel from '../availableHours.model';
import DealGroupsModel from '../dealGroups.model';
import DealsModel from '../deals.model';
import DealsFullInfo from 'models/dealsFullInfo.model';
import DealDetailsModel from '../dealDetails.model';
import {
  AvailableHoursDto,
  DealGroupsInterface,
  DealsInterface,
  ItemDealGroupsInterface,
  ItemDealGroupsOutInterface,
  ItemDealsInterface,
  ItemDealsOutInterface,
  WorkforceAvailabilityDto,
  EmployeeDealsDto,
} from '../dto/bdm.dto';
import { getStyleRowFromEndDate } from '../../utils/dates';

export class BDMAdapter {
  static mapAvailableHoursDtoToTeam(availableHours: AvailableHoursDto): AvailableHoursModel {
    return new AvailableHoursModel({
      items: availableHours.items.map(el => ({
        employeeId: el.employee.id,
        fullName: `${el.employee.firstName} ${el.employee.lastName}`,
        working: el.working,
        available: el.available,
        team: el.employee.team,
      })),
      totals: availableHours.totals,
    });
  }

  static mapAvailablityDtoToModel(availability: WorkforceAvailabilityDto[]): AvailabilityModel[] {
    return availability?.map((item: WorkforceAvailabilityDto, index: number) => {
      return new AvailabilityModel({
        index: index + 1,
        id: item.employee?.id || 0,
        firstName: item?.employee?.firstName || '-',
        lastName: item?.employee?.lastName || '-',
        email: item?.employee?.email || '-',
        team: item?.employee?.team?.title || '-',
        title: item?.employee?.title || '-',
        englishLevel: item?.employee?.englishLevel || '-',
        expiration: item?.expiration || '-',
        deals:
          item?.employee?.deals?.map((deal: EmployeeDealsDto) => {
            return { id: deal?.id, title: deal?.name };
          }) || [],
        salesGrade: item?.employee?.salesGrade?.title || '-',
        location: item?.employee?.location || '-',

        styleRow: getStyleRowFromEndDate(item.expiration),
      });
    });
  }
}

export class DealGroupsAdapter {
  static mapDealGroupsDtoToOutstuffFunnel(dealGroups: DealGroupsInterface[]): DealGroupsModel[] {
    return dealGroups.map(
      dealGroup =>
        new DealGroupsModel({
          groupName: dealGroup.groupName,
          items: dealGroup.items.map(item => DealGroupsAdapter.mapItemDealGroupDtoTo(item)),
        })
    );
  }

  static mapItemDealGroupDtoTo(item: ItemDealGroupsInterface): ItemDealGroupsOutInterface {
    return {
      id: item.id,
      deal: item.name ? item.name : '-',
      stage: item.stage ? item.stage : '-',
      lastTouch: item.lastTouchReport ? item.lastTouchReport : '-',
      resource: item.executors ? item.executors : [],
      createdAt: item.createdAt ? item.createdAt : '-',
      closerEmail: item.closerEmail ? item.closerEmail : '-',
      closer: {
        email: item.closer?.email ? item.closer.email : '-',
      },
    };
  }
}

export class DealsAdapter {
  static mapDeasToDealsFullInfo(deals: DealsInterface): DealsFullInfo {
    return new DealsFullInfo({
      total: deals.total,
      pages: deals.pages,
      items: DealsAdapter.mapDealsDtoToDealsList(deals),
    });
  }

  static mapDealsDtoToDealsList(deals: DealsInterface): DealsModel[] {
    return deals.items.map(item => DealsAdapter.mapItemDealsDtoToDealsList(item));
  }

  static mapItemDealsDtoToDealsList(item: ItemDealsInterface): ItemDealsOutInterface {
    return new DealsModel({
      id: item.id,
      name: item.name,
      startDate: item.startDate,
      endDate: item.endDate,
      rate: item.rate,
      owner: item.owner,
      closer: item.closer,
    });
  }

  static mapItemDealsDtoToDealDetails(item: ItemDealsInterface): DealDetailsModel {
    return new DealDetailsModel({
      id: item.id,
      status: item.stage,
      name: item.name,
      owner: item.owner,
      closer: item.closer,
      amount: item.amount,
      rate: item.rate,
      workload: item.workload,
      startDate: item.startDate,
      endDate: item.endDate,
      projectInitRequest: item.projectInitRequestJiraLink,
      projectManager: item.projectManager,
      hubspotLink: item.hubspotLink,
      executors: item.lineItems.map(item => item.executors),
    });
  }
}
