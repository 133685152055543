import { createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import EmployeeListModel from '../../models/employeeList.model';
import { AppState } from '../configureStore';
import employeeService from '../../services/employeesService';

interface EmployeeState extends EmployeeListModel {
  loading: boolean;
  error: Error | null;
}

const initialState: EmployeeState = {
  loading: false,
  employees: [],
  error: null,
};

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    getEmployeeListRequest: state => {
      return {
        ...state,
        loading: true,
        employees: [],
        error: null,
      };
    },
    getEmployeeListSuccess: (state, action) => {
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null,
      };
    },
    getEmployeeListFailure: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        loading: false,
        employees: [],
        error: action.payload,
      };
    },
  },
});

export const { getEmployeeListRequest, getEmployeeListSuccess, getEmployeeListFailure } = employeeSlice.actions;

type PropsT = {
  date: string;
  team?: number;
  department?: number;
};

export const getEmployeeListThunk =
  (props: PropsT) => async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(getEmployeeListRequest());
    try {
      const employees = await employeeService.getEmployeesList(props);
      dispatch(getEmployeeListSuccess(employees));
    } catch (err) {
      dispatch(getEmployeeListFailure(err as Error));
    }
  };

export default employeeSlice.reducer;
