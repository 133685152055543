import React, { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { Table } from 'reactstrap';
import { Form, Formik } from 'formik';
import FormikDropdown from 'common/Formik/FormikDropdown';

import { updateEmployeeById, updateEmployees } from 'store/allEmployees/allEmployees.thunk';
import EmployeeHrAssigneeModel from 'models/employeeHrAssignee.model';
import { AssigneesRequestParams, AssigneesTableProps } from 'models/interfaces/assignees.interface';

import { editIcon } from 'constants/icons';
import { getFullName } from 'helpers/companyStructureHeplers';

import styles from '../../tableStyles.module.scss';
import stylesButton from '../PerformanceReview/performanceReview.module.scss';

const AssigneesTable: FC<AssigneesTableProps> = ({ data, hrTeamEmployees, isEditRole, team }) => {
  const dispatch = useDispatch();
  const fieldRef = useRef<HTMLDivElement>(null);
  const [editMode, setEditMode] = useState<number | boolean>(false);

  const updateAllEmployees = (values: { hr: number | null }) => {
    const employeesWithSelectedHR = data.filter(employee =>
      !values.hr === null ? !employee.hr : employee.hr?.id === values.hr
    );

    if (employeesWithSelectedHR.length !== data.length) {
      const employeesIds = data.map(employee => employee.id);

      const employeesData: AssigneesRequestParams = {
        hrId: values.hr,
        employees: employeesIds,
      };

      dispatch(updateEmployees(employeesData));
    }

    setEditMode(false);
  };

  return (
    <div className={styles.tableWrapper} style={{ overflow: 'visible' }}>
      <Table className={cx(styles.tableContainer, 'align-middle mb-0 custom-table  text-left')}>
        <thead>
          <tr>
            <th>Member</th>
            <th>HR Manager</th>
          </tr>
        </thead>
        <tbody>
          {data.map((employee: EmployeeHrAssigneeModel, index: number) => (
            <tr key={employee.id} className={cx(styles.removeBorder, styles.rowHover)}>
              <td style={{ width: '50%' }}>{`${employee.firstName} ${employee.lastName}`}</td>
              <td>
                {editMode === employee.id ? (
                  <Formik
                    initialValues={{ hr: employee.hr?.id || null }}
                    onSubmit={(values: { hr: number | null }) => {
                      dispatch(
                        updateEmployeeById({ id: String(employee.id), edit: 'hr', employeeData: { hrId: values.hr } })
                      );
                      setEditMode(false);
                    }}
                  >
                    {({ values, handleSubmit }) => (
                      <div className={cx(styles.itemsWrapper)}>
                        <Form className="filters-block" style={{ maxWidth: '220px' }}>
                          <FormikDropdown
                            name="hr"
                            placeholder="Unassigned"
                            data={hrTeamEmployees}
                            textField={item => getFullName(item)}
                            defaultValue={employee.hr?.id !== null ? employee.hr : hrTeamEmployees[0]}
                            className={cx('dropdown-filter', styles.dropdownWrapper)}
                            handleSubmit={handleSubmit}
                            setEditMode={setEditMode}
                            dataKey="id"
                            clearValue={true}
                            elementRef={team ? fieldRef : null}
                          />
                        </Form>

                        {team && (
                          <div ref={fieldRef} className={cx(stylesButton.buttonsWrap, 'ml-4')}>
                            <button
                              type="button"
                              className={stylesButton.edit}
                              onClick={() => updateAllEmployees(values)}
                            >
                              Apply for all
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </Formik>
                ) : (
                  <div
                    role="button"
                    className={styles.itemsWrapper}
                    onClick={() => (isEditRole ? setEditMode(employee.id) : undefined)}
                  >
                    {(employee.hr.firstName || employee.hr.lastName) && (
                      <div className="mr-2">{`${employee.hr.firstName} ${employee.hr.lastName}`}</div>
                    )}
                    {isEditRole && editIcon}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AssigneesTable;
