import { createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from '../configureStore';
import { RequestParams } from '../../utils/mapParams';
import calendarService from '../../services/calendarService';
import { format } from 'date-fns';

interface CalendarStateInterface {
  loading: boolean;
  selectedMonthDaysList: string[] | null;
  error: Error | null;
}

const initialState: CalendarStateInterface = {
  loading: false,
  selectedMonthDaysList: null,
  error: null,
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getCalendarRequest: state => {
      return {
        ...state,
        loading: true,
        selectedMonthDaysList: null,
        error: null,
      };
    },
    getCalendarSuccess: (state, action) => {
      return {
        ...state,
        selectedMonthDaysList: action.payload,
        loading: false,
        error: null,
      };
    },
    getCalendarFailure: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        loading: false,
        selectedMonthDaysList: null,
        error: action.payload,
      };
    },
  },
});

export const { getCalendarRequest, getCalendarSuccess, getCalendarFailure } = calendarSlice.actions;

const currentDate = new Date();
const firstDate = format(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd');
const lastDate = format(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0), 'yyyy-MM-dd');

export const getCalendarThunk =
  ({ fromDate = firstDate, toDate = lastDate }: Partial<RequestParams>) =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(getCalendarRequest());
    try {
      const calendar: any[] = await calendarService.getCalendar({ fromDate, toDate });
      dispatch(getCalendarSuccess(calendar));
    } catch (err) {
      dispatch(getCalendarFailure(err as Error));
    }
  };

export default calendarSlice.reducer;
