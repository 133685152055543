import { ISSUE_TYPES } from '../constants/issues.constants';

export const getIssueType = (issueKey: string, issueType: ISSUE_TYPES | string): ISSUE_TYPES | string => {
  return issueKey === ISSUE_TYPES.vsd ? issueType : issueKey.toLowerCase();
};

export const get3MonthsAgo = () => {
  let date = new Date();
  date.setMonth(date.getMonth() - 3);
  return date;
};
