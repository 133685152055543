export const WORKFORCE_COSTS_TABLE_HEADERS = [
  { id: 1, title: 'Email', value: 'email', sortType: 'string' },
  { id: 2, title: 'Cost Center', value: 'isDirectCost', sortType: 'numeric' },
  { id: 3, title: 'Start date', value: 'startDate', sortType: 'string' },
  { id: 4, title: 'Dismissal date', value: 'dismissalDate', sortType: 'string' },
  { id: 5, title: 'Cost', value: 'salaryPrediction', sortType: 'numeric' },
  { id: 6, title: 'Hours in month', value: 'timeByMonth', sortType: 'numeric' },
  { id: 7, title: 'Team', value: 'team', sortType: 'string' },
  { id: 8, title: 'Title', value: 'title', sortType: 'string' },
  { id: 9, title: 'Sales grade', value: 'salesGrade', sortType: 'string' },
  { id: 10, title: 'Country', value: 'location', sortType: 'string' },
  { id: 11, title: 'Address', value: 'address', sortType: 'string' },
];

export const EMPLOYEES_PAGE_TABLE_HEADERS = [
  { id: 1, title: 'Email', value: 'email', sortType: 'string' },
  { id: 2, title: 'Grade', value: 'grade', sortType: 'string' },
  { id: 3, title: 'Team', value: 'team', sortType: 'string' },
  { id: 4, title: 'Country', value: 'location', sortType: 'string' },
  { id: 5, title: 'Address', value: 'address', sortType: 'string' },
  { id: 6, title: 'Date of Birth', value: 'birthday', sortType: 'string' },
  { id: 7, title: 'English Level', value: 'englishLevel', sortType: 'string' },
  { id: 8, title: 'Start Date', value: 'startDate', sortType: 'string' },
  { id: 9, title: 'Trial End Date', value: 'trialEndDate', sortType: 'string' },
  { id: 10, title: 'Dismissal Date', value: 'dismissalDate', sortType: 'string' },
  { id: 11, title: 'Cost', value: 'salaryPrediction', sortType: 'numeric' },
  { id: 12, title: 'Confluence link', value: 'fullName', sortType: 'string' },
  { id: 13, title: 'Next PR date', value: 'nextPerformanceReviewDate', sortType: 'string' },
];

export const AVAILABILITY_TABLE_HEADERS = [
  { id: 1, title: 'Email', value: 'email', sortType: 'string' },
  { id: 2, title: 'Team', value: 'team', sortType: 'string' },
  { id: 3, title: 'Sales Grade', value: 'salesGrade', sortType: 'string' },
  { id: 4, title: 'Title', value: 'title', sortType: 'string' },
  { id: 5, title: 'English Level', value: 'englishLevel', sortType: 'string' },
  { id: 6, title: 'Unavailable till', value: 'expiration', sortType: 'string' },
  { id: 7, title: 'Deals', value: 'deals', sortType: 'string' },
  { id: 8, title: 'Country', value: 'location', sortType: 'string' },
];

export const TEAM_BUDGET_TABLE_HEADERS = [
  { id: 1, title: 'Employee' },
  { id: 2, title: 'Grade' },
  { id: 3, title: 'Project' },
  { id: 4, title: 'Billing Source' },
  { id: 5, title: 'Est. Hours' },
  { id: 6, title: 'Est. Budget' },
  { id: 7, title: 'Est. Cost' },
  { id: 8, title: 'Fact. Hours' },
  { id: 9, title: 'Fact. Budget' },
  { id: 10, title: 'Fact. Cost' },
];
