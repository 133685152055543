import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  EmployeeResourceRequests,
  InitialWorkloadItem,
  RequestBodyForWorklogs,
} from 'models/interfaces/workloadCalendar.interface';
import workloadCalendarService from 'services/workloadCalendarService';
import worklogService from 'services/worklogService';
import { RequestParams } from 'utils/mapParams';

export const getWorkloadCalendar = createAsyncThunk(
  'workloadCalendar/getWorkloadCalendar',
  async (params: Partial<RequestParams>, thunkAPI) => {
    try {
      const res = await workloadCalendarService.getWorkloadCalendar(params);

      // get object with employee's id and projects
      const requestBody = res.items.reduce((acc: RequestBodyForWorklogs, item: InitialWorkloadItem) => {
        !!item?.employee?.atlassianId &&
          item.resourceRequests?.length &&
          (acc[item?.employee?.atlassianId] = item.resourceRequests.reduce(
            (acc: string[], rr: EmployeeResourceRequests) => {
              const projectTitle = rr.linkedProjectKey;
              const projectIndex = acc.findIndex((project: string) => project === projectTitle);
              if (projectIndex === -1) {
                acc.push(projectTitle);
              }
              return acc;
            },
            []
          ));
        return acc;
      }, {});

      const response = await worklogService.getWorklogs(requestBody, {
        fromDate: params.fromDate,
        toDate: params.toDate,
      });

      const items = response !== null ? response.items : null;

      return { res, items };
    } catch (err: any) {
      if (!err.message) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.message.original);
    }
  }
);
