import { createSlice } from '@reduxjs/toolkit';
import { getTeamInfo, createTeam, editTeamProfile } from './companyStructure.thunk';

import TeamModel from './../../models/team.model';
import { EmployeeDto } from 'models/dto/employee.dto';

interface CompanyStructureStateInterface {
  loading: boolean;
  error: string | null;
  errorEdit: string | null;
  team: TeamModel | null;
  employees: EmployeeDto[] | null;
}

const initialState: CompanyStructureStateInterface = {
  loading: false,
  error: null,
  errorEdit: null,
  team: null,
  employees: null,
};

const companyStructureSlice = createSlice({
  name: 'companyStructureReducer',
  initialState,
  reducers: {
    clearState: () => {
      return {
        ...initialState,
      };
    },
    resetPerfomanceReview: state => {
      return {
        ...state,
        performanceReview: null,
      };
    },
  },
  extraReducers: {
    [editTeamProfile.pending.toString()]: state => {
      return {
        ...state,
        loading: true,
        errorEdit: null,
      };
    },
    [editTeamProfile.fulfilled.toString()]: state => {
      return {
        ...state,
        loading: false,
      };
    },
    [editTeamProfile.rejected.toString()]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        errorEdit: payload,
      };
    },
    [getTeamInfo.pending.toString()]: state => {
      return {
        ...state,
        loading: true,
        errorEdit: null,
      };
    },
    [getTeamInfo.fulfilled.toString()]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        ...payload,
      };
    },
    [getTeamInfo.rejected.toString()]: state => {
      return {
        ...state,
        loading: false,
      };
    },
    [createTeam.pending.toString()]: state => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },
    [createTeam.fulfilled.toString()]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        ...payload,
      };
    },
    [createTeam.rejected.toString()]: state => {
      return {
        ...state,
        loading: false,
      };
    },
  },
});
export const { clearState, resetPerfomanceReview } = companyStructureSlice.actions;

export default companyStructureSlice.reducer;
