import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation, matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Login from './pages/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Dashboard from './layouts/Dashboard';

import { checkSessionThunk } from './store/account';
import { AppState } from './store/configureStore';
import { getTeamsListThunk } from 'store/teams/teams.thunk';

import routes from './config/routes';
import { Role } from './constants/roles';

const Routes: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthorized, user, loading } = useSelector((state: AppState) => state.account);

  const backendVersion = sessionStorage.getItem('backendVersion');

  useEffect(() => {
    dispatch(checkSessionThunk());

    if (!backendVersion) {
      dispatch(getTeamsListThunk());
    }
  }, [dispatch, backendVersion]);

  const { role, userId } = user || '';

  const canActivate = useMemo((): boolean => {
    const currentRoute = routes.reduce((res: any, item: any) => {
      return matchPath(location.pathname, item?.path)
        ? { ...matchPath(location.pathname, item?.path), permissions: item?.permissions }
        : res;
    }, null);

    if (currentRoute && userId === currentRoute?.params?.id) {
      switch (currentRoute?.path) {
        case '/dashboard/obligation/:id?':
          return true;
        default:
          break;
      }
    }

    return !!currentRoute?.permissions.includes(role);
  }, [role, location, userId]);

  const redirectPath = useMemo(() => {
    switch (role) {
      case Role.user: {
        return `/dashboard/profile${location.search || ''}`;
      }
      case Role.teamlead: {
        return `/dashboard/workload_calendar${location.search || ''}`;
      }
      case Role.hr:
      case Role.finance:
      case Role.vp:
      default: {
        return `/dashboard/profile${location.search || ''}`;
      }
    }
  }, [role, location.search]);

  return (
    <Switch>
      <Route path="/login" render={() => <Login isAuthorized={isAuthorized} loading={loading} />} />
      <Redirect exact from="/" to={redirectPath} />
      <Redirect exact from="/dashboard/" to={redirectPath} />
      <PrivateRoute isAuthorized={isAuthorized} canActivate={canActivate}>
        <Dashboard>
          {routes.map(item => (
            <Route key={item.path} path={item.path} component={item.component} exact={item.exact} />
          ))}
        </Dashboard>
      </PrivateRoute>
    </Switch>
  );
};

export default Routes;
