import httpService, { HttpService } from './httpService';
import { RequestParams } from 'utils/mapParams';

export class WorkloadCalendarService {
  constructor(private _httpService: HttpService) {}

  async getWorkloadCalendar(params: Partial<RequestParams>) {
    const res = await this._httpService.get('/hours/workload', {
      queryStringParameters: params,
    });
    return res;
  }
}

const workloadCalendarService = new WorkloadCalendarService(httpService);

export default workloadCalendarService;
