export default class ExpenseTypeModel {
  id: number;
  title: string;
  isConstant: boolean;

  constructor(data: ExpenseTypeModel) {
    this.id = data.id;
    this.title = data.title;
    this.isConstant = data.isConstant;
  }
}
