import React, { FC } from 'react';
import cx from 'classnames';

import { Table } from 'reactstrap';
import { secondsToHours } from 'utils/dates';
import { FormattedVsdFooterItem } from 'models/interfaces/vsd.interface';

import tableStyles from '../tableStyles.module.scss';
import styles from './VsdPage.module.scss';

const AbsenceFooterTable: FC<{ item: FormattedVsdFooterItem }> = ({ item }) => {
  return (
    <div className={cx(tableStyles.tableWrapper, styles.footerTableWrapper)}>
      <Table responsive hover className="align-middle mb-0 custom-table text-left">
        <thead>
          <tr>
            <th style={{ fontSize: '18px' }}>{item.title}</th>
            <th>Estimated</th>
            <th>Logged</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className={tableStyles.greenRow}>Billable</td>
            <td>{secondsToHours(item.billablePlaned)}</td>
            <td>{secondsToHours(item.billableUsed)}</td>
          </tr>
          <tr className={item.title === 'Total' ? '' : tableStyles.removeBorder}>
            <td className={tableStyles.redRow}>Not Billable</td>
            <td>{secondsToHours(item.notBillablePlaned)}</td>
            <td>{secondsToHours(item.notBillableUsed)}</td>
          </tr>

          {item.title === 'Total' && (
            <tr className={tableStyles.removeBorder}>
              <td className={tableStyles.purpleRow}>IN SUM</td>
              <td>{secondsToHours(item.totalPlaned)}</td>
              <td>{secondsToHours(item.totalUsed)}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default AbsenceFooterTable;
