import PageTitle from 'Layout/AppMain/PageTitle';
import MentorsAssignment from 'components/MentorsAssignment/MentorsAssignment';
import { mentorsAssignmentIcon } from 'constants/icons';

const MentorsAssignmentPage = () => {
  return (
    <>
      <PageTitle heading="Mentors Assignment" icon={mentorsAssignmentIcon} />
      <MentorsAssignment />
    </>
  );
};

export default MentorsAssignmentPage;
