import { createSlice } from '@reduxjs/toolkit';
import { getWorkloadCalendar } from './workloadCalendar.thunk';
import { InitialStateWorkload } from 'models/interfaces/workloadCalendar.interface';

const initialState: InitialStateWorkload = {
  workloadData: null,
  totals: null,
  worklogs: null,
  loading: false,
  error: null,
};

const workloadCalendarSlice = createSlice({
  name: 'workloadCalendar',
  initialState,
  reducers: {},
  extraReducers: {
    [getWorkloadCalendar.pending.toString()]: state => {
      state.loading = true;
      state.workloadData = null;
      state.totals = null;
      state.worklogs = null;
    },
    [getWorkloadCalendar.fulfilled.toString()]: (state, { payload }) => {
      state.workloadData = payload.res.items;
      state.totals = payload.res.totals;
      state.worklogs = payload.items;
      state.loading = false;
      state.error = null;
    },
    [getWorkloadCalendar.rejected.toString()]: state => {
      state.loading = false;
      state.error = null;
    },
  },
});

export default workloadCalendarSlice.reducer;
