import React, { FC, MutableRefObject, useRef, useState } from 'react';
import { useField } from 'formik';
import { FieldConfig } from 'formik/dist/Field';
import DatePicker from 'react-datepicker';
import { Tooltip } from 'reactstrap';
import { useClickOutside } from 'hooks/useClickOutside';

import stylesModal from '../../../components/modal.module.scss';
import styles from '../Formik.module.scss';

type FormikDatePickerT = {
  dateFormat: string;
  placeholderText?: string;
  label?: string;
  className?: string;
  errorClassName?: string;
  showYearDropdown?: boolean;
  minDate?: Date | string | undefined;
  maxDate?: Date | string;
  disabled?: boolean;
  onSubmit?: () => void;
  onChange?: (value: Date) => void;
  setCurrentlyEditing?: (value: string) => void;
  fieldRef?: MutableRefObject<null>;
  fixedHeight?: boolean;
} & FieldConfig;

const FormikDatePicker: FC<FormikDatePickerT> = ({
  className,
  errorClassName,
  label,
  onSubmit,
  onChange,
  setCurrentlyEditing,
  fieldRef,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { error, value, touched } = meta;

  const elementRef = useRef(null);
  const [openTooltip, setOpenTooltip] = useState(false);

  if (onSubmit && setCurrentlyEditing) {
    const handleSubmit = () => {
      if (field.value != meta.initialValue) {
        onSubmit();
      }
      setCurrentlyEditing('');
    };

    useClickOutside(elementRef, handleSubmit, fieldRef);
  }

  const handleChange = (value: Date) => {
    if (onChange) {
      onChange(value);
    }
    helpers.setValue(value);
  };

  return (
    <div
      className={className}
      style={{ position: 'relative' }}
      ref={elementRef}
      onMouseEnter={() => setOpenTooltip(true)}
      onMouseLeave={() => setOpenTooltip(false)}
    >
      {label && <p className="label-wrapper">{label}</p>}
      <DatePicker
        {...props}
        {...field}
        selected={value}
        onChange={handleChange}
        className={error && touched ? (errorClassName ? errorClassName : stylesModal.error) : ''}
      />
      {error && touched ? (
        errorClassName ? (
          <Tooltip placement="bottom" isOpen={openTooltip} target={elementRef}>
            <div>{error}</div>
          </Tooltip>
        ) : (
          <div className={styles.dateError}>{error}</div>
        )
      ) : null}
    </div>
  );
};

export default FormikDatePicker;
