import { CompensationOrderBtnActions } from 'models/interfaces/employeeProfileProps';

export const getAvailableActionForCompensationOrder = (status: string): CompensationOrderBtnActions | null => {
  const actions: Record<string, CompensationOrderBtnActions> = {
    'needs approval': 'recalculate',
    pending: 'recalculate',
    sent: 'confirm',
  };
  return actions[status] || null;
};
