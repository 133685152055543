import AbsenceCalendar from 'components/AbsenceCalendar/AbsenceCalendar';
import PageTitle from '../../Layout/AppMain/PageTitle';
import { absenceCalendarIcon } from 'constants/icons';

const AbsenceCalendarPage = () => {
  return (
    <>
      <PageTitle heading="Absence Calendar" icon={absenceCalendarIcon} />
      <AbsenceCalendar />
    </>
  );
};

export default AbsenceCalendarPage;
