import { ExpenseTypeInterface } from 'models/interfaces/obligation.interface';

export const getDisabledExpenseTypes = (
  values: ExpenseTypeInterface[],
  expenseTypes: ExpenseTypeInterface[]
): ExpenseTypeInterface[] | boolean => {
  return values.reduce((acc: ExpenseTypeInterface[], curr: ExpenseTypeInterface) => {
    if (curr.title === 'Unified Tax 2.5%') {
      return expenseTypes?.filter(({ title }: { title: string }) => title === 'Unified Tax 5%');
    } else if (curr.title === 'Unified Tax 5%') {
      return expenseTypes?.filter(({ title }: { title: string }) => title === 'Unified Tax 2.5%');
    }
    return acc;
  }, []);
};
