import cx from 'classnames';
import { closeIcon } from 'constants/icons';
import styles from './styles.module.scss';
import { DrawerProps } from 'models/interfaces/createRR.interface';
import modalStyles from '../../../components/modal.module.scss';

const Drawer = ({ className, title, onClose, children }: DrawerProps) => {
  return (
    <div className={modalStyles.modal}>
      <div className={modalStyles.backDrop} onClick={onClose}></div>
      <div className={className}>
        <div className={cx(styles.drawerHeader, 'd-flex align-items-center')}>
          <button type="button" className={cx(styles.closeIcon, 'mr-3')} onClick={onClose}>
            {closeIcon}
          </button>
          <div className={cx(styles.title)}>{title}</div>
        </div>

        <div className={styles.drawerBody}>{children}</div>
      </div>
    </div>
  );
};

export default Drawer;
