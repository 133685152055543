import React, { Fragment, FC } from 'react';
import cx from 'classnames';

import { useSelector } from 'react-redux';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { AppState } from '../../store/configureStore';
import { HeaderInterface } from '../../models/interfaces/header.interface';
import { mappedProfile } from '../../store/Profile/mappedProfile.selector';

import HeaderLogo from '../AppLogo';

import UserBox from './Components/UserBox';

const Header: FC = () => {
  const user = useSelector(mappedProfile);

  const { theme, account }: HeaderInterface = useSelector((state: AppState) => {
    return {
      theme: {
        enableHeaderShadow: state.themeOptions.enableHeaderShadow,
        closedSmallerSidebar: state.themeOptions.closedSmallerSidebar,
        headerBackgroundColor: state.themeOptions.headerBackgroundColor,
        enableMobileMenuSmall: state.themeOptions.enableMobileMenuSmall,
      },
      account: {
        username: `${
          state.account.user
            ? `${state.account.user.firstName || ''} ${state.account.user.lastName || state.account.user.userName}`
            : `${user?.firstName} ${user?.lastName}`
        }`,
        role: state.account.user.role,
        availableRoles: state.account.user.availableRoles,
      },
    };
  });

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        className={cx('app-header', theme.headerBackgroundColor, {
          'header-shadow': theme.enableHeaderShadow,
        })}
        transitionName="HeaderAnimation"
        transitionAppear
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <HeaderLogo />
        <div
          className={cx('app-header__content', {
            'header-mobile-open': theme.enableMobileMenuSmall,
          })}
        >
          <div className="app-header-right">
            <UserBox username={account.username} role={account.role} availableRoles={account.availableRoles} />
          </div>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default Header;
