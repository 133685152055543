import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Table } from 'reactstrap';

import { ItemDealsOutInterface } from '../../../models/dto/bdm.dto';

import styles from '../../tableStyles.module.scss';

type TProps = { data: Array<ItemDealsOutInterface>; currentPage: number; perPage: number };

const DealsTable: React.FC<TProps> = ({ data = [], currentPage, perPage }) => {
  const coefForNumberFirstColumn = useMemo(() => perPage * currentPage - perPage + 1, [currentPage, perPage]);
  return (
    <Table
      responsive
      hover
      className={cx(styles.tableContainer, styles.minWidth1400, 'align-middle mb-0 custom-table text-left')}
    >
      <thead>
        <tr>
          <th className={styles.firstCellWidth}>#</th>
          <th>Deal Name</th>
          <th>Deal Start Date</th>
          <th>Deal End Date</th>
          <th>Deal Rate</th>
          <th>Deal Owner</th>
          <th>Deal Closer</th>
        </tr>
      </thead>

      <tbody>
        {data?.map((item, index: number) => (
          <tr key={index}>
            <td>{index + coefForNumberFirstColumn}</td>
            <td>
              <Link to={`/dashboard/deal-details/${item.id}`}>{item.name || '-'}</Link>
            </td>
            <td>{item.startDate || '-'}</td>
            <td>{item.endDate || '-'}</td>
            <td>{item.rate || '-'}</td>
            <td>{!!item?.owner?.email ? item.owner.email : '-'}</td>
            <td>{!!item?.closer?.email ? item.closer.email : '-'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DealsTable;
