import { PerformanceReviewsDetailsDto } from './../../models/dto/performanceReview.dto';
import { createSlice } from '@reduxjs/toolkit';
import { PerformanceReviewDto } from 'models/dto/performanceReview.dto';
import {
  getPerformanceReviews,
  createPerformanceReview,
  changeStatusPerformanceReview,
  deletePerformanceReview,
  getPerformanceReviewDetails,
  updatePerformanceReview,
  getPerformanceReviewById,
  deletePerformanceReviewCycle,
  createPerformanceReviewCycle,
} from './performanceReview.thunk';

interface BdmStateInterface {
  performanceReviews: PerformanceReviewDto[];
  performanceReview: PerformanceReviewDto | null;
  performanceReviewDetails: PerformanceReviewsDetailsDto | null;
  openCreatePR: string | null;
  openChangeStatusPR: string | null;
  openModalPRDetails: string | null;
  selectedEmployeePR: any;
  selectedCycle: any;
  selectedCycleEmployee: any;
  loading: boolean;
  loadingModal: boolean;
  errors: string | null;
  pages: number;
  total: number;
}

const initialState: BdmStateInterface = {
  performanceReviews: [],
  performanceReview: null,
  performanceReviewDetails: null,
  openCreatePR: null,
  openChangeStatusPR: null,
  openModalPRDetails: null,
  selectedEmployeePR: null,
  selectedCycle: null,
  selectedCycleEmployee: null,
  loading: false,
  loadingModal: false,
  errors: null,
  pages: 0,
  total: 0,
};

const performanceReviewSlice = createSlice({
  name: 'performanceReview',
  initialState,
  reducers: {
    setStatePerfomanceRequest(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    clearState: () => {
      return {
        ...initialState,
      };
    },
    resetPerfomanceReview: state => {
      return {
        ...state,
        performanceReview: null,
      };
    },
  },
  extraReducers: {
    [getPerformanceReviews.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [getPerformanceReviews.fulfilled.toString()]: (state, { payload }) => {
      state.performanceReviews = payload;
      state.loading = false;
    },
    [getPerformanceReviews.rejected.toString()]: state => {
      state.loading = false;
      state.errors = null;
    },
    [getPerformanceReviewById.pending.toString()]: state => {
      state.selectedCycle = null;
      state.loading = true;
      state.errors = null;
    },
    [getPerformanceReviewById.fulfilled.toString()]: (state, { payload }) => {
      const current = state.performanceReviews.find(item => item.id === payload.id);
      if (current) {
        state.performanceReview = current;
      }
      state.selectedCycle = payload;
      state.loading = false;
    },
    [getPerformanceReviewById.rejected.toString()]: state => {
      state.loading = false;
      state.errors = null;
    },
    [createPerformanceReview.pending.toString()]: state => {
      state.loadingModal = true;
      state.errors = null;
    },
    [createPerformanceReview.fulfilled.toString()]: (state, { payload }) => {
      state.performanceReviews = [...state.performanceReviews, payload];
      state.loadingModal = false;
    },
    [createPerformanceReview.rejected.toString()]: state => {
      state.loadingModal = false;
      state.errors = null;
    },
    [changeStatusPerformanceReview.pending.toString()]: state => {
      state.loadingModal = true;
      state.errors = null;
    },
    [changeStatusPerformanceReview.fulfilled.toString()]: (state, { payload }) => {
      const currentIndex = state.performanceReviews.findIndex(item => item.id === payload.id);
      state.performanceReviews[currentIndex] = payload;
      state.loadingModal = false;
    },
    [changeStatusPerformanceReview.rejected.toString()]: state => {
      state.loadingModal = false;
      state.errors = null;
    },
    [deletePerformanceReview.pending.toString()]: state => {
      state.loadingModal = true;
      state.errors = null;
    },
    [deletePerformanceReview.fulfilled.toString()]: (state, { payload }) => {
      state.performanceReviews = state.performanceReviews.filter(item => item.id !== payload);
      state.loadingModal = false;
    },
    [deletePerformanceReview.rejected.toString()]: state => {
      state.loadingModal = false;
      state.errors = null;
    },
    [updatePerformanceReview.pending.toString()]: state => {
      state.loadingModal = true;
      state.errors = null;
    },
    [updatePerformanceReview.fulfilled.toString()]: (state, { payload }) => {
      const currentIndex = state.performanceReviews.findIndex(item => item.id === payload.id);
      state.performanceReviews[currentIndex] = payload;
      state.loadingModal = false;
    },
    [updatePerformanceReview.rejected.toString()]: state => {
      state.loadingModal = false;
      state.errors = null;
    },
    [getPerformanceReviewDetails.pending.toString()]: state => {
      state.performanceReviewDetails = null;
      state.loading = true;
      state.errors = null;
    },
    [getPerformanceReviewDetails.fulfilled.toString()]: (state, { payload }) => {
      state.performanceReviewDetails = payload;
      state.pages = payload.pages;
      state.total = payload.total;
      state.loading = false;
    },
    [getPerformanceReviewDetails.rejected.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [createPerformanceReviewCycle.pending.toString()]: state => {
      state.loadingModal = true;
      state.errors = null;
    },
    [createPerformanceReviewCycle.fulfilled.toString()]: (state, { payload }) => {
      if (state.performanceReviewDetails) {
        state.performanceReviewDetails = {
          ...state.performanceReviewDetails,
          items: [payload, ...state.performanceReviewDetails.items],
        };
      }
      state.loadingModal = false;
    },
    [createPerformanceReviewCycle.rejected.toString()]: state => {
      state.loadingModal = false;
      state.errors = null;
    },
    [deletePerformanceReviewCycle.pending.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
    [deletePerformanceReviewCycle.fulfilled.toString()]: (state, { payload }) => {
      if (state.performanceReviewDetails) {
        state.performanceReviewDetails = {
          ...state.performanceReviewDetails,
          items: state.performanceReviewDetails.items.filter(item => item.id !== payload),
        };
      }
      state.loading = false;
    },
    [deletePerformanceReviewCycle.rejected.toString()]: state => {
      state.loading = true;
      state.errors = null;
    },
  },
});
export const { setStatePerfomanceRequest, clearState, resetPerfomanceReview } = performanceReviewSlice.actions;

export default performanceReviewSlice.reducer;
