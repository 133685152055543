import React, { FC } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import './RefreshButton.scss';

type RefreshButtonT = {
  onClick(): void;
};
const RefreshButton: FC<RefreshButtonT> = ({ onClick }) => (
  <Button className="refresh-btn" onClick={onClick}>
    <FontAwesomeIcon icon={faSyncAlt} />
  </Button>
);

export default RefreshButton;
