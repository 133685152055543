import httpService, { HttpService } from './httpService';
import { RequestParams } from '../utils/mapParams';

export class BudgetingService {
  constructor(private _httpService: HttpService) {}

  async getBudgeting(params: Partial<RequestParams>) {
    const res = await this._httpService.get('/budget', {
      queryStringParameters: params,
    });
    return res;
  }
}

const budgetingService = new BudgetingService(httpService);

export default budgetingService;
