import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import Datepicker from '../Datepicker/Datepicker';
import { DATE_PICKER } from 'constants/common';

export default function ExternalInterviewTable() {
  const [date, setDate] = useState(new Date());

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <Row className="d-flex">
          <Col className="col-auto ml-auto">
            <Datepicker
              selected={date}
              dateFormat={DATE_PICKER.dateFormatMonth}
              showMonthYearPicker
              onChange={newDate => {
                setDate(newDate);
              }}
            />
          </Col>
        </Row>
        <Table responsive bordered hover className="align-middle text-truncate mb-5">
          <thead>
            <tr>
              <th>Type of work</th>
              <th>Ticket key</th>
              <th>Summary</th>
              <th>Description</th>
              <th>Resource email</th>
              <th>Components</th>
              <th>Is trial interview required</th>
              <th>Priority</th>
              <th>Status</th>
              <th>Interview fail reason</th>
              <th>Time spent</th>
            </tr>
          </thead>
          <tbody></tbody>
        </Table>
        <Table responsive bordered hover className="align-middle text-truncate">
          <thead>
            <tr>
              <th>Department</th>
              <th>Failed interviews</th>
              <th>Time spent (Fl)(h)</th>
              <th>Successful interviews</th>
              <th>Time spent (Sl)(h)</th>
              <th>Total number of interviews</th>
              <th>R. Low tech level</th>
              <th>R. English level</th>
              <th>Opened positions</th>
              <th>Closed positions</th>
              <th>Planned interviews</th>
              <th>Waiting feedback</th>
            </tr>
          </thead>
          <tbody></tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
