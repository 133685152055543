import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { Table, Tooltip } from 'reactstrap';
import RequestStatus from 'components/RequestStatus/RequestStatus';

import { changeCompensationOrderByCommand } from 'store/compensationOrders/compensationOrders.thunk';
import { EmployeeCompensationOrderProps } from 'models/interfaces/employeeProfileProps';
import { CompensationOrderCommand } from 'models/compensationOrders.interface';

import { useClickOutside } from 'hooks/useClickOutside';
import { getPreviousMonthName, secondsToHours } from 'utils/dates';
import { getAvailableActionForCompensationOrder } from './utils';
import { capitalizeWords } from 'utils/functions';
import { verticalDotsIcon } from 'constants/icons';

import tableStyles from '../../tableStyles.module.scss';
import styles from './obligationsStyles.module.scss';
import obligationStyles from '../../Obligation/obligation.module.scss';

const EmployeeCompensationOrder = ({ order, employeeId, userId, loading }: EmployeeCompensationOrderProps) => {
  const dispatch = useDispatch();

  const btnRef = useRef(null);
  const [openActions, setOpenActions] = useState(false);

  const canSeeActions = employeeId === userId;
  const previousMonth = getPreviousMonthName();
  const action = getAvailableActionForCompensationOrder(order.status.toLowerCase());

  const closeTooltip = () => {
    setOpenActions(false);
  };
  useClickOutside(btnRef, closeTooltip);

  const handleTooltipActionsClick = (action: CompensationOrderCommand) => {
    dispatch(changeCompensationOrderByCommand({ id: order.id, command: action }));
    closeTooltip();
  };

  return (
    <div className={styles.orderWrapper}>
      <div className={cx(styles.topInfo, 'd-flex justify-content-between align-items-center')}>
        <div className={styles.orderInfo}>
          <div className={styles.month}>{previousMonth}</div>
          <RequestStatus status={capitalizeWords(order.status.toLowerCase())} />
        </div>

        {canSeeActions && action && (
          <div className="d-flex align-items-center">
            <button
              onClick={() => dispatch(changeCompensationOrderByCommand({ id: order.id, command: action }))}
              disabled={loading}
              className={cx(tableStyles.button, 'mr-3')}
              style={{ padding: '7px 24px', textTransform: 'capitalize' }}
            >
              {action}
            </button>

            {action === 'confirm' && (
              <>
                <button
                  ref={btnRef}
                  className={styles.lineBtn}
                  onClick={() => setOpenActions(!openActions)}
                  disabled={loading}
                >
                  {verticalDotsIcon}
                </button>

                <Tooltip placement="bottom" isOpen={openActions} target={btnRef} className="orderActionsTooLtip">
                  <div role="button" onClick={() => handleTooltipActionsClick('recalculate')}>
                    Recalculate
                  </div>
                  <div role="button" onClick={() => handleTooltipActionsClick('reject')}>
                    Report an issue
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        )}
      </div>

      <div className={cx(obligationStyles.tableWrap, styles.tableContainer)}>
        <Table responsive className={cx('align-middle mb-0 custom-table text-left')}>
          <tbody>
            <tr className={obligationStyles.removeTopBorder}>
              <td>Total payout</td>
              <td className={styles.amount}>{order.amount || 0}</td>
              <td>Expected Hours</td>
              <td>{secondsToHours(order.expectedWorkingTime)}</td>
            </tr>
            <tr>
              <td>Base Compensation</td>
              <td>{order.baseCompensation}</td>
              <td>Tracked Hours</td>
              <td>{secondsToHours(order.trackedWorkingTime)}</td>
            </tr>
            <tr>
              <td>Compensation</td>
              <td>{order.compensation}</td>
              <td>Billable VSD</td>
              <td>{secondsToHours(order.billableAbsenceTime)}</td>
            </tr>
            <tr>
              <td>Compensation Requests</td>
              <td>{order.requestedCompensation}</td>
              <td>N/B VSD</td>
              <td>{secondsToHours(order.nonBillableAbsenceTime)}</td>
            </tr>
            <tr>
              <td>Factual Bonus</td>
              <td>{order.factualBonusCompensation}</td>
              <td>Shifted</td>
              <td>{secondsToHours(order.shiftedWorkingTime)}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default EmployeeCompensationOrder;
