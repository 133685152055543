import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { createPortal } from 'react-dom';

import Loader from 'common/Loader';
import FormikInput from 'common/Formik/FormikInput';
import FormikDatePicker from 'common/Formik/FormikDatePicker';
import FormikDropdown from 'common/Formik/FormikDropdown';
import FormikRadioButtons from 'common/Formik/FormikRadioButtons';

import { AppState } from 'store/configureStore';
import { getCompanyExpensesById } from 'store/companyExpenses/companyExpenses.thunk';
import { getExpensesCategories } from 'store/companyExpensesCategories/expensesCategories.thunk';
import { clearCurrentExpense } from 'store/companyExpenses';
import { ExpenseModalProps, InitialExpenseValues } from 'models/interfaces/companyExpenses.interface';

import { CURRENCY, DATE_PICKER, MONTH_COUNT } from 'constants/common';

import expenseStyles from '../ExpensesStyle.module.scss';
import styles from '../../modal.module.scss';

const COMPANY_EXPENSES_SCHEMA = Yup.object({
  amount: Yup.number().required('Field required'),
  currency: Yup.string().required('Field required'),
  description: Yup.string()
    .min(3, 'Less than 3 characters')
    .max(255, 'Description is too long')
    .required('Field required'),
  categoryId: Yup.object().nullable().required('Field required'),
  effectiveDate: Yup.string().required('Field required'),
  expirationDate: Yup.string().when('repeat', {
    is: (repeat: boolean) => !repeat,
    then: Yup.string().required('Field required').nullable(),
    otherwise: Yup.string().nullable(),
  }),
  recurringPeriod: Yup.number().when('repeat', {
    is: (repeat: boolean) => !repeat,
    then: Yup.number(),
    otherwise: Yup.number().required('Field required'),
  }),
});

const repeatOptions = [
  { key: 'Does not repeat', value: false },
  { key: 'Repeat', value: true },
];

const CreateOrEditExpense: FC<ExpenseModalProps> = ({ title, id, onSubmitExpense, onCloseModal }) => {
  const dispatch = useDispatch();

  const { currentExpense, loadingModal } = useSelector((state: AppState) => state.companyExpensesReducer);
  const { expenseCategories } = useSelector((state: AppState) => state.expenseCategoriesReducer);

  useEffect(() => {
    if (id) {
      dispatch(getCompanyExpensesById(id));
    }
    return () => {
      dispatch(clearCurrentExpense());
    };
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getExpensesCategories({ page: 1, size: 50, enabled: true }));
  }, []);

  const initialValues: InitialExpenseValues = {
    amount: currentExpense?.amount || '',
    currency: currentExpense?.currency || '',
    description: currentExpense?.description || '',
    categoryId: currentExpense?.category ? currentExpense.category : '',
    effectiveDate: currentExpense?.effectiveDate ? new Date(currentExpense.effectiveDate) : '',
    expirationDate: currentExpense?.expirationDate ? new Date(currentExpense.expirationDate) : '',
    recurringPeriod: currentExpense?.recurringPeriod || '',
    repeat: currentExpense?.recurringPeriod ? true : false,
  };

  return createPortal(
    <Formik
      initialValues={initialValues}
      validationSchema={COMPANY_EXPENSES_SCHEMA}
      onSubmit={onSubmitExpense}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <div className={styles.modal}>
            <div className={styles.backDrop} onClick={onCloseModal}></div>
            <div className={cx(styles.block, 'text-left')} style={{ overflow: 'unset' }}>
              <button className={styles.close} onClick={onCloseModal} />
              <div className={cx(styles.header)}>{title}</div>

              {!loadingModal ? (
                <>
                  <div className={styles.inputWrap}>
                    <div>
                      <div className="d-flex">
                        <p className="label-wrapper">Planned Expense</p>
                        <p className={styles.required}>*</p>
                      </div>
                      <FormikInput
                        name="amount"
                        type="number"
                        placeholder="Enter Planned Expense"
                        className={styles.input}
                      />
                    </div>

                    <div className="filters-block">
                      <div className="dropdown-filter" style={{ maxWidth: '375px' }}>
                        <div className="d-flex">
                          <p className="label-wrapper">Currency</p>
                          <p className={styles.required}>*</p>
                        </div>
                        <FormikDropdown
                          name="currency"
                          placeholder="Select Currency"
                          data={CURRENCY}
                          textField="title"
                        />
                      </div>
                    </div>

                    <div>
                      <div className="d-flex">
                        <p className="label-wrapper">Description</p>
                        <p className={styles.required}>*</p>
                      </div>
                      <FormikInput name="description" placeholder="Expense Description" className={styles.input} />
                    </div>

                    <div className="filters-block">
                      <div className="dropdown-filter" style={{ maxWidth: '375px' }}>
                        <div className="d-flex">
                          <p className="label-wrapper">Category</p>
                          <p className={styles.required}>*</p>
                        </div>
                        <FormikDropdown
                          name="categoryId"
                          placeholder="Select Category"
                          data={expenseCategories}
                          textField="name"
                          busy={!expenseCategories}
                          addFilter={true}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="d-flex">
                        <p className="label-wrapper">Start Date</p>
                        <p className={styles.required}>*</p>
                      </div>
                      <FormikDatePicker
                        name="effectiveDate"
                        dateFormat={DATE_PICKER.dateFormatToPayload}
                        placeholderText="yyyy/mm/dd"
                        className={styles.input}
                        maxDate={values?.expirationDate ? values?.expirationDate : ''}
                        showYearDropdown
                      />
                    </div>

                    <div>
                      <div className="d-flex">
                        <p className="label-wrapper">End Date</p>
                        {!values.repeat && <p className={styles.required}>*</p>}
                      </div>
                      <FormikDatePicker
                        name="expirationDate"
                        dateFormat={DATE_PICKER.dateFormatToPayload}
                        placeholderText="yyyy/mm/dd"
                        className={styles.input}
                        minDate={values?.effectiveDate}
                        showYearDropdown
                      />
                    </div>

                    <div className={cx('d-flex', values.repeat ? expenseStyles.marginBottom : 'mb-4')}>
                      <FormikRadioButtons name="repeat" className={expenseStyles.repeatWrap} options={repeatOptions} />
                    </div>

                    {values.repeat && (
                      <div>
                        <p className="label-wrapper">Recurring Period</p>
                        <div className="d-flex align-items-center mb-4">
                          <div className="mr-3">Repeat every</div>
                          <div className={cx(styles.dropdown, 'dropdown-filter')}>
                            <FormikDropdown
                              dropUp
                              name="recurringPeriod"
                              placeholder="0"
                              containerClassName={expenseStyles.dropdownHeight}
                              className={expenseStyles.removeMargin}
                              data={MONTH_COUNT}
                            />
                          </div>
                          <div className="ml-3">month(s)</div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={styles.buttonWrapper}>
                    <button className={styles.blueLineButton} onClick={onCloseModal} type="button">
                      Cancel
                    </button>
                    <button className={styles.blueButton} type="submit">
                      Save
                    </button>
                  </div>
                </>
              ) : (
                <div className="ml-3">{<Loader />}</div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>,
    document.body
  );
};

export default CreateOrEditExpense;
