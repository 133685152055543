import { createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from '../configureStore';
import holidaysService from '../../services/holidaysService';
import { RequestParams } from '../../utils/mapParams';
import { getHolidays, getHolidayById, createHoliday, editHoliday, deleteHoliday } from './holidays.thunk';
import HolidaysModel from '../../models/holidays.model';

interface HolidaysStateInterface {
  holidays: string[] | null;
  holidaysList: HolidaysModel[];
  holiday: HolidaysModel | null;
  loadingModal: boolean;
  loading: boolean;
  error: Error | null;
}

const initialState: HolidaysStateInterface = {
  holidays: null,
  holidaysList: [],
  holiday: null,
  loadingModal: false,
  loading: false,
  error: null,
};

export const holidaysSlice = createSlice({
  name: 'holidays',
  initialState,
  reducers: {
    getHolidaysListRequest: state => {
      return {
        ...state,
        loading: true,
        holidays: null,
        error: null,
      };
    },
    getHolidaysListSuccess: (state, action) => {
      return {
        ...state,
        holidays: action.payload,
        loading: false,
        error: null,
      };
    },
    getHolidaysListFailure: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        loading: false,
        holidays: null,
        error: action.payload,
      };
    },
    setStateHolidays(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    resetStateHoliday(state) {
      return {
        ...state,
        holiday: null,
      };
    },
  },
  extraReducers: {
    [getHolidays.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [getHolidays.fulfilled.toString()]: (state, { payload }) => {
      state.holidaysList = payload;
      state.loading = false;
    },
    [getHolidays.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getHolidayById.pending.toString()]: state => {
      state.loadingModal = true;
      state.error = null;
    },
    [getHolidayById.fulfilled.toString()]: (state, { payload }) => {
      state.holiday = payload;
      state.loadingModal = false;
    },
    [getHolidayById.rejected.toString()]: (state, { payload }) => {
      state.loadingModal = false;
      state.error = payload;
    },
    [createHoliday.pending.toString()]: state => {
      state.loadingModal = true;
      state.error = null;
    },
    [createHoliday.fulfilled.toString()]: (state, { payload }) => {
      state.holidaysList = [...state.holidaysList, { ...payload }];
      state.loadingModal = false;
    },
    [createHoliday.rejected.toString()]: (state, { payload }) => {
      state.loadingModal = false;
      state.error = payload;
    },
    [editHoliday.pending.toString()]: state => {
      state.loadingModal = true;
      state.error = null;
    },
    [editHoliday.fulfilled.toString()]: (state, { payload }) => {
      const currentIndex = state.holidaysList?.findIndex(holiday => holiday.id === payload.id);
      state.holidaysList[currentIndex] = {
        ...payload,
      };
      state.holiday = payload;
      state.loadingModal = false;
    },
    [editHoliday.rejected.toString()]: (state, { payload }) => {
      state.loadingModal = false;
      state.error = payload;
    },
    [deleteHoliday.pending.toString()]: state => {
      state.loadingModal = true;
      state.error = null;
    },
    [deleteHoliday.fulfilled.toString()]: (state, { payload }) => {
      state.holidaysList = state.holidaysList.filter(holiday => holiday.id !== payload);
      state.loadingModal = false;
    },
    [deleteHoliday.rejected.toString()]: (state, { payload }) => {
      state.loadingModal = false;
      state.error = payload;
    },
  },
});

export const {
  getHolidaysListRequest,
  getHolidaysListSuccess,
  getHolidaysListFailure,
  setStateHolidays,
  resetStateHoliday,
} = holidaysSlice.actions;

const currentDate = new Date();

export const getHolidaysListThunk =
  ({
    fromDate = `${currentDate.getFullYear()}-01-01`,
    toDate = `${currentDate.getFullYear()}-12-31`,
  }: Partial<RequestParams>) =>
  async (dispatch: ThunkDispatch<AppState, {}, PayloadAction<any>>) => {
    dispatch(getHolidaysListRequest());
    try {
      const holidays: string[] = await holidaysService.getHolidaysList({ fromDate, toDate });
      dispatch(getHolidaysListSuccess(holidays));
    } catch (err) {
      dispatch(getHolidaysListFailure(err as Error));
    }
  };

export default holidaysSlice.reducer;
