import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import cx from 'classnames';

import { Card, CardBody } from 'reactstrap';
import Loader from '../../../common/Loader';
import DealsTable from './DealsTable';
import ResourceRequestTable from '../../ResourceRequestsDashboard/ResourceRequestTable';

import { getDealById } from '../../../store/bdm/bdm.thunk';
import { getResourceRequestsIssues } from 'store/resourceRequests/resourceRequests.thunk';
import { AppState } from '../../../store/configureStore';
import { clearState } from '../../../store/bdm';
import { ExecutorInterface } from 'models/dto/bdm.dto';

import styles from '../../tableStyles.module.scss';
import wrapStyles from '../wrapper.module.scss';

const DealDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { deal, loading } = useSelector((state: AppState) => state.bdmReducer);
  const { rrForDeals } = useSelector((state: AppState) => state.resourceRequestsReducer);

  const mappedData = deal?.executors?.flatMap((arr: ExecutorInterface[]) => [...arr]);

  useEffect(() => {
    !!id && dispatch(getDealById({ id: +id }));

    return () => {
      dispatch(clearState());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (deal?.executors?.length && deal?.endDate) {
      const usersId: string[] = mappedData?.reduce((acc: string[], executor: ExecutorInterface) => {
        if (executor.atlassianId) {
          acc.push(executor.atlassianId);
        }
        return acc;
      }, []);

      dispatch(getResourceRequestsIssues({ usersId, params: { fromDate: deal.startDate, toDate: deal.endDate } }));
    }
  }, [dispatch, deal]);

  if (loading) {
    return <div className="mb-4">{<Loader />}</div>;
  }

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className="bp-header">
          <div className={wrapStyles.headerName}>{deal?.name}</div>
        </div>

        <div className={wrapStyles.form}>
          <div className={wrapStyles.form_section}>
            <div className={wrapStyles.item}>
              <p className="label-wrapper">Status</p>
              <div>{deal?.status}</div>
            </div>
            <div className={wrapStyles.item}>
              <p className="label-wrapper">Deal Owner</p>
              {!!deal?.owner?.email ? deal.owner.email : <div>-</div>}
            </div>
            <div className={wrapStyles.item}>
              <p className="label-wrapper">Deal Closer</p>
              {!!deal?.closer?.email ? deal.closer.email : <div>-</div>}
            </div>
            <div className={wrapStyles.item}>
              <p className="label-wrapper">Amount</p>
              <div>{deal?.amount}</div>
            </div>
            <div className={wrapStyles.item}>
              <p className="label-wrapper">Rate</p>
              <div>{deal?.rate}</div>
            </div>
            <div className={wrapStyles.item}>
              <p className="label-wrapper">Workload</p>
              <div>{deal?.workload}</div>
            </div>
          </div>

          <div className={wrapStyles.form_section}>
            <div className={wrapStyles.item}>
              <p className="label-wrapper">Start Date</p>
              <div>{deal?.startDate}</div>
            </div>
            <div className={wrapStyles.item}>
              <p className="label-wrapper">End Date</p>
              <div>{deal?.endDate}</div>
            </div>
            <div className={wrapStyles.item}>
              <p className="label-wrapper">Project Init Request</p>
              <a href={deal?.projectInitRequest} className={styles.textEllipsis}>
                {deal?.projectInitRequest}
              </a>
            </div>
            <div className={wrapStyles.item}>
              <p className="label-wrapper">Project Manager</p>
              {!!deal?.projectManager ? (
                <Link to={`/dashboard/profile/${deal.projectManager.id}`}>
                  {`${deal?.projectManager.firstName} ${deal.projectManager.lastName}`}
                </Link>
              ) : (
                <div>-</div>
              )}
            </div>
            <div className={wrapStyles.item}>
              <p className="label-wrapper">Hubspot Link</p>
              <a href={deal?.hubspotLink} className={styles.textEllipsis}>
                {deal?.hubspotLink}
              </a>
            </div>
          </div>
        </div>
        <DealsTable data={mappedData} />
        <div className={cx(wrapStyles.subheader, 'mb-3 mt-4')}>Resource Request</div>
        <div className={styles.tableWrapper}>
          <ResourceRequestTable data={rrForDeals} />
        </div>
      </CardBody>
    </Card>
  );
};

export default DealDetails;
