class EmployeeAvailabilityModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  link: string;
  team: string;
  title: string;
  englishLevel: string;
  dismissalDate: string;
  atlassianId: string;

  constructor(data: EmployeeAvailabilityModel) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.link = data.link;
    this.team = data.team;
    this.title = data.title;
    this.englishLevel = data.englishLevel;
    this.dismissalDate = data.dismissalDate;
    this.atlassianId = data.atlassianId;
  }
}

export default EmployeeAvailabilityModel;
