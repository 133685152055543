import { Link } from 'react-router-dom';
import TeamManager from './TeamManager';
import TeamsList from './TeamsList';

import { EmployeeInfoT } from 'store/eventsEmployees';
import TeamModel from 'models/team.model';
import { getChildTeamsIds } from 'helpers/companyStructureHeplers';

import styles from './companyStructure.module.scss';

const TeamCard = ({ team, employees }: { team: TeamModel; employees: EmployeeInfoT[] }) => {
  const childrenIds = getChildTeamsIds(team.children || []);

  const teamEmployees = employees?.filter((employee: EmployeeInfoT) =>
    [...childrenIds, team.id].includes(employee.team?.id)
  );

  return (
    <div className={styles.departmentCard}>
      <Link to={`/dashboard/team_profile/${team.id}`} className={styles.department}>
        {team.title}
      </Link>

      <TeamManager team={team} employees={employees} className="mb-1 mt-1" />

      {team.children?.length ? <TeamsList teams={team.children} employees={employees} /> : null}
      <div className={styles.total}>Total - {teamEmployees?.length}</div>
    </div>
  );
};

export default TeamCard;
